import type * as Types from '../../../../../../../__generated__/types';

import { gql } from '@apollo/client';
import { ApprovalsOnObjectCardFragmentFragmentDoc } from '../../../../Card/ApprovalsOnObjectCardFragment.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type AddTemplateImageMutationVariables = Types.Exact<{
  input: Types.UpdateModularObjectInput;
}>;


export type AddTemplateImageMutation = { __typename?: 'Mutation', updateModularObject: Array<{ __typename?: 'ModularObject', id: string, files: Array<{ __typename?: 'File', id: string, name: string, location: string, externalId: string, externalType: string, createdAt: any | null, revision: number | null, approved: boolean | null, tags: Array<string | null> | null }> | null, approvals: Array<{ __typename?: 'Approval', id: string, approvalType: Types.ApprovalTypes, status: Types.ApprovalStatus, referenceType: string, referenceName: string, referenceParentName: string, requestedBy: { __typename?: 'User', id: string }, requestedChanges: Array<{ __typename?: 'ApprovalRequestChange', field: string, fieldId: string, to: string, from: string } | null> }> | null }> };


export const AddTemplateImageDocument = gql`
    mutation AddTemplateImage($input: UpdateModularObjectInput!) {
  updateModularObject(input: $input) {
    id
    files {
      id
      name
      location
      externalId
      externalType
      createdAt
      revision
      approved
      tags
    }
    approvals {
      ...ApprovalsOnObjectCardFragment
    }
  }
}
    ${ApprovalsOnObjectCardFragmentFragmentDoc}`;
export type AddTemplateImageMutationFn = Apollo.MutationFunction<AddTemplateImageMutation, AddTemplateImageMutationVariables>;

/**
 * __useAddTemplateImageMutation__
 *
 * To run a mutation, you first call `useAddTemplateImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTemplateImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTemplateImageMutation, { data, loading, error }] = useAddTemplateImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTemplateImageMutation(baseOptions?: Apollo.MutationHookOptions<AddTemplateImageMutation, AddTemplateImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTemplateImageMutation, AddTemplateImageMutationVariables>(AddTemplateImageDocument, options);
      }
export type AddTemplateImageMutationHookResult = ReturnType<typeof useAddTemplateImageMutation>;
export type AddTemplateImageMutationResult = Apollo.MutationResult<AddTemplateImageMutation>;
export type AddTemplateImageMutationOptions = Apollo.BaseMutationOptions<AddTemplateImageMutation, AddTemplateImageMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddTemplateImageMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updateModularObject })
 *   )
 * })
 */
export const mockAddTemplateImageMutation = (resolver: Parameters<typeof graphql.mutation<AddTemplateImageMutation, AddTemplateImageMutationVariables>>[1]) =>
  graphql.mutation<AddTemplateImageMutation, AddTemplateImageMutationVariables>(
    'AddTemplateImage',
    resolver
  )
