import { type StepContentProps } from '@/components/Stepper/Stepper';
import { getUsersFromEmailAddresses } from '@/util/requests.functions';
import { useEffect } from 'react';
import { useUserSelectionContext } from '../../UserSelection.context';
import UserConfirmation from './UserConfirmation';
import UserError from './UserError';

interface UserLookupStepProps extends StepContentProps {}

export default function UserLookupStep ({ decrementStep }: UserLookupStepProps): JSX.Element {
  const { userEmail, selectedUser, setSelectedUser } = useUserSelectionContext();

  const lookupUser = async (): Promise<void> => {
    const users = await getUsersFromEmailAddresses([userEmail]);
    setSelectedUser(users?.[0]);
  };

  useEffect(() => {
    void lookupUser();
  }, []);

  return selectedUser ? <UserConfirmation /> : <UserError onGoBack={decrementStep} />;
}
