import { useEditContext } from '@/components';
import HookFormInput from './HookFormInput';
import HookFormLabel from './HookFormLabel';

export interface TextInputProps {
  inputProps: Omit<React.ComponentProps<typeof HookFormInput>, 'type'>;
  labelProps: React.ComponentProps<typeof HookFormLabel>;
  className?: HTMLDivElement['className'];
}

export default function Checkbox ({ className, labelProps, inputProps }: TextInputProps): JSX.Element {
  const { className: inputClassName = '', ...rest } = inputProps;
  const { canUserEdit } = useEditContext();

  return (
    <div className={className}>
      <HookFormLabel className='mr-2 label' {...labelProps} />
      <HookFormInput
        {...rest}
        className={`input-checkbox custom-checkbox checked:bg-primary ${inputClassName}`}
        type='checkbox'
        disabled={!canUserEdit}
      />
    </div>
  );
}
