import type * as Types from '../../../../../../../__generated__/types';

import { gql } from '@apollo/client';
export type ParentObjectFragmentFragment = { __typename?: 'ModularObject', name: string | null, id: string, parent: { __typename?: 'ModularObject', id: string, name: string | null, template: { __typename?: 'Template', id: string | null, name: string | null, type: Types.TemplateTypes | null } | null, duration: { __typename?: 'ModularObjectDuration', startDate: any | null, targetDate: any | null } | null } | null };

export const ParentObjectFragmentFragmentDoc = gql`
    fragment ParentObjectFragment on ModularObject {
  name
  id
  parent {
    id
    name
    template {
      id
      name
      type
    }
    duration {
      startDate
      targetDate
    }
  }
}
    `;