import { type UserModel } from '@/models';
import { createContext, type Dispatch, type SetStateAction, useContext, useMemo } from 'react';

interface UserSelectionValue {
  userEmail?: string;
  setUserEmail?: Dispatch<SetStateAction<string>>;
  selectedUser?: UserModel;
  setSelectedUser?: Dispatch<SetStateAction<UserModel>>;
  onClose?: () => void;
}

interface UserSelectionProviderProps {
  userEmail?: string;
  setUserEmail?: Dispatch<SetStateAction<string>>;
  selectedUser?: UserModel;
  setSelectedUser?: Dispatch<SetStateAction<UserModel>>;
  onClose?: () => void;
  children: React.ReactNode;
}

const UserSelectionContext = createContext<UserSelectionValue>(null);

export const useUserSelectionContext = (): UserSelectionValue => {
  const context = useContext(UserSelectionContext);

  if (!context) {
    throw new Error('UserSelectionContext must be used within a UserSelectionContextProvider');
  }

  return context;
};

export default function UserSelectionContextProvider ({
  children,
  userEmail,
  setUserEmail,
  selectedUser,
  setSelectedUser,
  onClose,
}: Readonly<UserSelectionProviderProps>): JSX.Element {
  const value = useMemo(() => ({
    userEmail,
    setUserEmail,
    selectedUser,
    setSelectedUser,
    onClose,
  }), [userEmail, setUserEmail, selectedUser, setSelectedUser, onClose]);

  return (
    <UserSelectionContext.Provider value={value}>
      {children}
    </UserSelectionContext.Provider>
  );
}
