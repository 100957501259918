import type { User } from '@/__generated__/types';
import ImpersonationConfirmModal from '@/components/modals/ImpersonationConfirmModal/ImpersonationConfirmModal';
import { useModal } from '@/hooks/useModal';
import { createContext, type PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';

interface ImpersonationContextAPI {
  impersonationUser: User;
  setImpersonationUser: (user: User) => void;
  skeletonKey: string;
  setSkeletonKey: (key: string) => void;
}

const ImpersonationContext = createContext(null);

function ImpersonationModalProvider (): JSX.Element {
  const value = useImpersonationContext();
  const { showModal, closeModal } = useModal();

  useEffect(() => {
    if (value.impersonationUser) {
      showModal(
        (
          <ImpersonationContext.Provider value={value}>
            <ImpersonationConfirmModal closeModal={closeModal} />
          </ImpersonationContext.Provider>
        ),
        {},
      );
    }
  }, [value.impersonationUser]);

  return null;
}

export function useImpersonationContext (): ImpersonationContextAPI {
  const context = useContext(ImpersonationContext);

  if (context === undefined) {
    throw new Error('useImpersonationContext must be used within a ImpersonationContextProvider');
  }

  return context;
}

export default function ImpersonationContextProvider ({
  children,
}: PropsWithChildren): JSX.Element {
  const [impersonationUser, setImpersonationUser] = useState<User>(null);
  const [skeletonKey, setSkeletonKey] = useState('');

  const value = useMemo(
    () => ({
      impersonationUser,
      setImpersonationUser,
      skeletonKey,
      setSkeletonKey,
    }),
    [impersonationUser, skeletonKey],
  );

  return (
    <ImpersonationContext.Provider value={value}>
      <ImpersonationModalProvider />
      {children}
    </ImpersonationContext.Provider>
  );
}
