const HideSVG = () => (
  <svg
    className=''
    width='14'
    height='14'
    viewBox='0 0 28.56 21.36'
    fill='black'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M28.12,10.09c-.16-.21-2.52-3.36-6.11-5.73l2.95-2.95-1.41-1.41-3.32,3.32c-1.79-.92-3.8-1.56-5.94-1.56C6.66,1.76,.69,9.75,.44,10.09l-.44,.59,.44,.59c.16,.21,2.52,3.36,6.11,5.73l-2.95,2.95,1.41,1.41,3.32-3.32c1.79,.92,3.8,1.56,5.94,1.56,7.62,0,13.59-7.98,13.84-8.32l.44-.59-.44-.59Zm-25.6,.59c1.49-1.78,6.27-6.92,11.76-6.92,1.56,0,3.06,.42,4.44,1.06l-.98,.98c-.98-.7-2.17-1.11-3.46-1.11-3.3,0-5.99,2.69-5.99,5.99,0,1.29,.41,2.49,1.11,3.46l-1.4,1.4c-2.61-1.64-4.61-3.83-5.48-4.86Zm15.75,0c0,2.2-1.79,3.99-3.99,3.99-.74,0-1.42-.21-2.01-.56l5.44-5.44c.35,.59,.56,1.27,.56,2.01Zm-7.98,0c0-2.2,1.79-3.99,3.99-3.99,.74,0,1.42,.21,2.01,.56l-5.44,5.44c-.35-.59-.56-1.28-.56-2.01Zm3.99,6.92c-1.56,0-3.06-.42-4.44-1.06l.98-.98c.98,.7,2.17,1.11,3.46,1.11,3.3,0,5.99-2.69,5.99-5.99,0-1.29-.41-2.48-1.11-3.46l1.4-1.4c2.61,1.64,4.61,3.83,5.48,4.86-1.49,1.78-6.27,6.92-11.76,6.92Z' />
  </svg>
);

export default HideSVG;
