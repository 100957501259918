export enum TaskStatus {
  ToDo = 'To Do',
  InProgress = 'In Progress',
  Complete = 'Complete',
  Waived = 'Waived',
}

export const TaskStatusOptions = Object.values(TaskStatus);

export enum TaskPillColor {
  Black = 'black',
  White = 'white',
  Teal = 'teal',
  Purple = 'purple',
  Gold = 'gold',
}

export function isValidTaskStatus (status: string): boolean {
  if (!status) return false;
  return TaskStatusOptions.some(validStatus =>
    validStatus.toLowerCase().replace(' ', '') === status.toLowerCase().replace(' ', '')
  );
}

export function mapToValidTaskStatus (status: string): string {
  // Try to map the string to a valid task status
  for (const validStatus of TaskStatusOptions) {
    if (status.toLowerCase().replace(' ', '') === validStatus.toLowerCase().replace(' ', '')) {
      return validStatus;
    }
  }
  return TaskStatus.ToDo;
}
