import ImpersonationBanner from '@/components/banners/ImpersonationBanner';
import MaintenanceBanner from '@/components/banners/MaintenanceBanner';
import UnsupportedBrowserBanner from '@/components/banners/UnsupportedBrowserBanner';

export default function BannerContainer (): JSX.Element {
  return (
    <section className='flex flex-col w-[100vw] effra'>
      <MaintenanceBanner />
      <ImpersonationBanner />
      <UnsupportedBrowserBanner />
    </section>
  );
}
