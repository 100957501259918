import type { Organization, User } from '@/__generated__/types';
import { restApi } from './rest.api';

const INVITES_API_URL = '/organizations/invites';

export interface ExternalInviteFormState {
  firstName: string;
  lastName: string;
  organizationName: string;
  email: string;
}

export interface ResendInvitePayload {
  userId: string;
}

export interface PostInviteResponse {
  organization: Organization;
  user: User;
}

interface GetInvitesResponse {
  organizations: Organization[];
  users?: User[];
}

export const invitesApi = restApi.injectEndpoints({
  endpoints: (build) => ({
    // GET all organizations and users that the currentUser's org invited
    GetExternalInvites: build.query<GetInvitesResponse, undefined>({
      query: () => ({
        url: INVITES_API_URL,
        method: 'GET',
      }),
      transformResponse: (rawResult: { data: GetInvitesResponse }) => {
        return rawResult.data;
      },
    }),

    // POST EXTERNAL INVITE
    postExternalOrgInvite: build.mutation<
      PostInviteResponse,
      ExternalInviteFormState
    >({
      query: (body) => ({
        url: INVITES_API_URL,
        method: 'POST',
        body,
      }),
      transformResponse: (rawResult: { data: PostInviteResponse }) => {
        return rawResult.data;
      },
    }),

    // POST RESEND INVITE
    resendExternalInvite: build.mutation<undefined, ResendInvitePayload>({
      query: (body) => ({
        url: `${INVITES_API_URL}/resend`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  usePostExternalOrgInviteMutation,
  useResendExternalInviteMutation,
  useGetExternalInvitesQuery,
  util,
} = invitesApi;
