// import { useEditContext } from '@/components/cards';
import CollaboratorDiscoveryModal from '@/components/modals/CollaboratorDiscovery/CollaboratorDiscoveryModal';
import cx from 'classnames';
import { useWatch } from 'react-hook-form';

export interface CollaborationColumnProps {
  open: boolean;
  setIsOpen: (open: boolean) => void;
}

export default function CollaborationColumn ({ open, setIsOpen }: CollaborationColumnProps): JSX.Element {
  // const { canUserEdit } = useEditContext();
  const objectId = useWatch({ name: 'id' });

  const closeModal = () => {
    setIsOpen(false);
  };

  if (!open) {
    return null;
  }

  return (
    <div
      className={cx('absolute z-30 flex flex-col w-full h-full', {
        'pointer-events-none': !open,
      })}
    >
      <div
        onClick={closeModal}
        className={cx('absolute z-0 w-full h-full transition-all bg-white/90 duration-300', {
          'opacity-0': !open,
          'opacity-100': open,
        })}
      />
      <div
        className={cx(
          'absolute bg-white w-[75%] max-h-[80%] top-[70px] right-[125px] shadow-lg transition-all ease-in-out flex flex-col',
          {
            'opacity-0 translate-y-9': !open,
            'opacity-100 translate-y-0 delay-75 duration-300': open,
          },
        )}
      >
        <CollaboratorDiscoveryModal objectId={objectId} closeModal={closeModal} />
      </div>
    </div>
  );
}
