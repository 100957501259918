import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type ApprovalsOnObjectCardFragmentFragment = { __typename?: 'Approval', id: string, approvalType: Types.ApprovalTypes, status: Types.ApprovalStatus, referenceType: string, referenceName: string, referenceParentName: string, requestedBy: { __typename?: 'User', id: string }, requestedChanges: Array<{ __typename?: 'ApprovalRequestChange', field: string, fieldId: string, to: string, from: string } | null> };

export const ApprovalsOnObjectCardFragmentFragmentDoc = gql`
    fragment ApprovalsOnObjectCardFragment on Approval {
  id
  approvalType
  status
  referenceType
  referenceName
  referenceParentName
  requestedBy {
    id
  }
  requestedChanges {
    field
    fieldId
    to
    from
  }
}
    `;