import cx from 'classnames';
import { type ComponentProps } from 'react';

export default function Pill ({ children, className, ...props }: ComponentProps<'div'>): JSX.Element {
  return (
    <span className={cx('rounded-[20px] effra-10', className)} {...props}>
      {children}
    </span>
  );
}
