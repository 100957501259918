import type { MappableTaskField, TaskMap } from '@/components/flows/UploadCsvUserFlow/steps/MapHeader/constants';
import { isValidTaskStatus } from '@/models/task.model';
import type { TaskError } from '../../../types';
import { isValidTaskType } from '../../MapHeader/utils';

export default function getRowErrors (
  row: TaskMap,
  taskKeyMap: Record<MappableTaskField, string>,
  emails: string[] = [],
): Record<string, TaskError> {
  const rowErrors = {};

  const taskType = row[taskKeyMap?.type];
  const subtaskType = row[taskKeyMap?.subtaskType];
  const taskStatus = row[taskKeyMap?.status];
  const taskCollaboratorEmails = row[taskKeyMap?.collaboratorEmail];
  const taskCollaboratorRoles = row[taskKeyMap?.collaboratorRole];
  const taskAssignee = row[taskKeyMap?.assignee]?.toLowerCase().trim();

  if (!row[taskKeyMap?.name]) {
    rowErrors[taskKeyMap.name] = { column: 'name', error: `Missing ${taskKeyMap.name}` };
  }

  if (taskStatus && !isValidTaskStatus(taskStatus)) {
    rowErrors[taskKeyMap.status] = { column: 'status', error: `Invalid ${taskKeyMap.status} (${taskStatus})` };
  }

  if (!subtaskType && taskType && !isValidTaskType(taskType)) {
    rowErrors[taskKeyMap.type] = {
      column: 'type',
      error: `Invalid ${taskKeyMap.type} "${taskType}" will be defaulted to "Task"`,
    };
  }

  if (subtaskType && !isValidTaskType(subtaskType)) {
    rowErrors[taskKeyMap.subtaskType] = {
      column: 'subtaskType',
      error: `Invalid ${taskKeyMap.subtaskType} "${subtaskType}" will be defaulted to "Task"`,
    };
  }

  if (taskCollaboratorEmails) {
    const collaboratorEmails = taskCollaboratorEmails?.split(',').map(email => email.toLowerCase().trim());
    const invalidEmails = collaboratorEmails?.filter(email => !emails.includes(email));
    if (invalidEmails.length) {
      rowErrors[taskKeyMap?.collaboratorEmail] = {
        column: 'collaboratorEmail',
        error: `Collaborator (${invalidEmails.join(', ')}) email not found`,
      };
    }
  }

  if (taskAssignee && !emails.includes(taskAssignee)) {
    rowErrors[taskKeyMap.assignee] = { column: 'assignee', error: `Assignee (${taskAssignee}) email not found` };
  }

  if ((taskAssignee && !taskCollaboratorEmails) || (taskAssignee && !taskCollaboratorEmails?.includes(taskAssignee))) {
    rowErrors[taskKeyMap.collaboratorEmail] = {
      column: 'collaboratorEmail',
      error: `Assignee (${taskAssignee}) needs to also exist in collaborator column`,
    };
  }

  if (taskCollaboratorRoles?.split(',').length !== taskCollaboratorEmails?.split(',').length) {
    rowErrors[taskKeyMap.collaboratorRole] = {
      column: 'collaboratorRole',
      error: 'Collaborator roles do not match the number of collaborator emails',
    };
  }

  return rowErrors;
}
