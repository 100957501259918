import type { User } from '@/__generated__/types';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import cx from 'classnames';
import Image from 'next/image';
import { memo } from 'react';
import type { UserByIdFragment } from '../User/getUserById.generated';

const imageSizeMap = {
  xsmall: 16,
  small: 24,
  collaborator: 40,
  medium: 48,
  large: 52,
  xl: 80,
  default: 32,
};

interface ProfileImageProps {
  user: Partial<User | UserByIdFragment>;
  size?: 'small' | 'collaborator' | 'medium' | 'large' | 'xl' | 'xsmall' | 'default';
  testId?: string;
  className?: string;
}

export const ProfileImage = memo(({ size = 'default', user, testId, className }: ProfileImageProps) => {
  const profileDimensions = imageSizeMap[size];
  const loggedInUser = useLoggedInUser();

  const isInternal = user?.organization?.id === loggedInUser?.organizationId
    || user?.organizationId === loggedInUser?.organizationId;

  return (
    <div
      data-testid='profile-image-container'
      className={cx('shrink-0 flex items-center rounded-full border-2', className, {
        'bg-zinc-200 border-zinc-500 opacity-30': !user,
        'bg-primary border-primary': isInternal,
        'bg-tertiary border-tertiary': user && !isInternal,
      })}
      style={{ width: profileDimensions, height: profileDimensions }}
    >
      <Image
        data-testid={testId || 'profile-image'}
        src={user?.profileImage || '/images/default-profile-icon.png'}
        alt='Profile Image'
        height={profileDimensions}
        width={profileDimensions}
        className='rounded-full'
        style={{ minWidth: '100%', minHeight: '100%', maxWidth: '100%', maxHeight: '100%' }}
      />
    </div>
  );
});

ProfileImage.displayName = 'ProfileImage';
