import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagSwallowtail, faRhombus, faShield } from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';
import { useMemo } from 'react';
import Pill from '../Pill';

export interface TaskPillProps {
  light?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>;
  type?: string;
}

export default function TaskPill ({ type, light = false }: Readonly<TaskPillProps>): JSX.Element {
  const task = useMemo(() => {
    switch (type) {
      case 'L-Minus Milestone':
        return {
          icon: faRhombus,
          text: 'lmin',
        };
      case 'Milestones':
        return {
          icon: faRhombus,
          text: 'mile',
        };
      case 'Requirements':
        return {
          icon: faShield,
          text: 'req',
        };
      case 'Tasks':
        return {
          icon: faFlagSwallowtail,
          text: 'task',
        };
      default:
        return {
          icon: faFlagSwallowtail,
          text: 'task',
        };
    }
  }, [type]);

  if (type == null) return null;

  return (
    <Pill
      data-testid='pill'
      className={cx(
        'inline-flex shrink border gap-[4px] px-[8px] py-[4px] items-center justify-center max-w-[56px] h-[24px]',
        {
          'border-black text-black': !light,
          'text-white border-white': light,
        },
      )}
    >
      <FontAwesomeIcon icon={task.icon} /> {task.text}
    </Pill>
  );
}
