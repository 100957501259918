import { useEditContext } from '@/components';
import PrismaticProvider, { usePrismaticContext } from '@/components/Contexts/prismatic.context';
import ExcelIntegrationMappingModal from '@/components/modals/ExcelIntegrationMappingModal/ExcelIntegrationMappingModal';
import DisplayValue from '@/components/modules/DisplayValue';
import PrismaticExcelFieldMessage from '@/components/tooltip/PrismaticExcelFieldMessage';
import { useModal } from '@/hooks/useModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSpreadsheet } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import HookFormInput from './HookFormInput';
import HookFormLabel from './HookFormLabel';
import useToggleGroup from './useToggleGroup';

export interface TextInputProps {
  path?: string;
  inputProps?: Partial<React.ComponentProps<typeof HookFormInput>>;
  labelProps?: Partial<React.ComponentProps<typeof HookFormLabel>>;
  className?: HTMLDivElement['className'];
  prefix?: string;
  suffix?: string;
  large?: boolean;
  enableSubscription?: boolean;
  disablePrismaticField?: boolean;
  fieldToWatch?: string;
  toggleValue?: string;
}

export default function ModuleTextInput ({
  path = '',
  className = '',
  prefix = null,
  suffix = null,
  labelProps = {},
  inputProps = {},
  large = false,
  enableSubscription,
  disablePrismaticField,
  fieldToWatch,
  toggleValue,
}: TextInputProps): JSX.Element {
  const { className: inputClassName = '', ...rest } = inputProps;
  const placeholderName = (rest?.placeholder || labelProps?.label || prefix || '').replace(':', '').toLowerCase();
  const placeholderText = placeholderName ? `Enter ${placeholderName}` : 'Enter value';
  const [displayPlaceholderText, setDisplayPlaceholderText] = useState(placeholderText);
  const { canUserEdit, isOwner } = useEditContext();
  const { excelIntegrationSetup, excelMappingData, objectId, templateType } = usePrismaticContext();
  const modalMethods = useModal();
  const value = useWatch({ name: rest.name });
  const shouldShow = useToggleGroup({
    fieldToWatch,
    toggleValue,
    enableSubscription,
  });

  const moduleId = path?.split('.')?.pop();
  const dataMapping = excelMappingData?.cellMapping?.[moduleId];

  useEffect(() => {
    if (excelIntegrationSetup && dataMapping) {
      if (!value) {
        setDisplayPlaceholderText('No data');
      }
    } else {
      setDisplayPlaceholderText(placeholderText);
    }
  }, [dataMapping, excelIntegrationSetup, placeholderText, value]);

  return shouldShow &&
    (
      <div className={cx('flex flex-row', className)}>
        {canUserEdit ?
          (
            <div className={cx('flex flex-col flex-1 relative group')}>
              <HookFormLabel {...labelProps} />
              <HookFormInput
                className={inputClassName}
                placeholder={displayPlaceholderText}
                large={large}
                {...rest}
                prefix={prefix}
                suffix={suffix}
                disabled={Boolean(dataMapping)}
              />
              <div
                className={cx('absolute bottom-[7px] hidden hover:cursor-pointer', {
                  'group-hover:block': isOwner && excelIntegrationSetup && !disablePrismaticField,
                })}
                style={{ right: suffix ? `${suffix.length * 8 + 10}px` : '10px' }}
              >
                <PrismaticExcelFieldMessage hasDataMapping={Boolean(dataMapping)}>
                  <FontAwesomeIcon
                    icon={faFileSpreadsheet}
                    className='text-primary'
                    onClick={() => {
                      modalMethods?.showModal(
                        <PrismaticProvider
                          objectId={objectId}
                          templateType={templateType}
                          isOwner={isOwner}
                        >
                          <ExcelIntegrationMappingModal
                            moduleId={moduleId}
                          />
                        </PrismaticProvider>,
                        { isMegaModal: false },
                      );
                    }}
                  />
                </PrismaticExcelFieldMessage>
              </div>
            </div>
          ) :
          <DisplayValue className={className} labelProps={labelProps} />}
      </div>
    );
}
