import ModalBG from '@/components/Portal/ModalBG';
import { Dialog } from '@headlessui/react';
import { useCallback } from 'react';
import { useListActionContext } from '../ListActions.context';
import ConfirmationMessage from './ConfirmationMessage/ConfirmationMessage';
import UserSelection from './UserSelection/UserSelection';

const CONFIRMATION_ACTIONS = ['Delete', 'Mark as Complete', 'Mark as Waived', 'Mark as To Do', 'Mark as In Progress'];
const USER_SELECTION_ACTIONS = ['Update Assignee', 'Update Owner'];

interface ListActionModalProps {
  confirmationOpen: boolean;
  setConfirmationOpen: (value: boolean) => void;
}
export default function ListActionModal (
  { confirmationOpen, setConfirmationOpen }: Readonly<ListActionModalProps>,
): JSX.Element {
  const { selectedAction, setDefaultSelectedObjects } = useListActionContext();
  const isConfirmationAction = CONFIRMATION_ACTIONS.includes(selectedAction);
  const isUserSelectionAction = USER_SELECTION_ACTIONS.includes(selectedAction);
  const onClose = useCallback((): void => {
    setConfirmationOpen(false);
    setDefaultSelectedObjects();
  }, [setConfirmationOpen]);

  return (
    <Dialog data-testid='action-confirmation-modal' open={confirmationOpen} onClose={onClose}>
      <ModalBG open={confirmationOpen} setOpen={setConfirmationOpen} disableBackgroundDismissal={false}>
        {isConfirmationAction && <ConfirmationMessage onClose={onClose} />}
        {isUserSelectionAction && <UserSelection onClose={onClose} selectedAction={selectedAction} />}
      </ModalBG>
    </Dialog>
  );
}
