import React from 'react';
import { type Id, toast, type ToastContent, type ToastOptions } from 'react-toastify';

const OWNER_SAVE_SUCCESS_MESSAGE = 'Saved changes.';
const EDITOR_SAVE_SUCCESS_MESSAGE = 'Submitted changes for approval.';
// Will definitely generalize this more later!
export function addSaveSuccessToast (isOwner?: boolean, message?: string): Id {
  const toastMessage = message ?? (isOwner ? OWNER_SAVE_SUCCESS_MESSAGE : EDITOR_SAVE_SUCCESS_MESSAGE);
  return addToastSuccess(toastMessage);
}

export function addToastError (message: React.ReactNode): Id {
  const toastContent = (
    <div className='flex items-center'>
      <div className='mr-3 font-bold text-secondary shrink-0'>
        Oops!
      </div>
      <div>
        {message}
      </div>
    </div>
  );
  return addToast(toastContent);
}

export function addToastSuccess (message: React.ReactNode): Id {
  return addToast(message, {
    icon: <i className='text-[24px] text-primary fa-sharp fa-solid fa-check' />,
  });
}

export function addToast (content: ToastContent, options: ToastOptions = {}): Id {
  if (content) {
    return toast(content, {
      position: 'bottom-center',
      className: 'bg-black !rounded-none shadow-md whitespace-no-wrap',
      bodyClassName: 'effra-lg font-medium',
      hideProgressBar: true,
      progressClassName: 'bg-primary text-primary',
      closeOnClick: false,
      draggable: true,
      progress: 0,
      pauseOnHover: true,
      theme: 'dark',
      ...options,
    });
  }
}
