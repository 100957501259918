import Dropdown from '@/designSystemComponents/Dropdown';
import { memo } from 'react';
import useDropdownOptionMap, { DropdownOption } from './hooks/useDropdownOptionMap';

interface CollaboratorRoleDropDownProps {
  value: DropdownOption;
  onChange?: (value: string) => void;
  options?: DropdownOption[];
  collaboratorId?: string;
  isNotEditor?: boolean;
  autoRotate?: boolean;
}

const noop = () => {};
const defaultRoleOptions = Object.values(DropdownOption);

export const StaticRole = memo(function StaticRole ({ role }: { role: DropdownOption }) {
  const [{ icon, displayText }] = useDropdownOptionMap([role]);

  return (
    <span className='flex gap-2 items-center p-2 text-left h-[32px] w-[104px] sculpin-12'>
      <i className={icon} />
      <span>{displayText}</span>
    </span>
  );
});

export default function CollaboratorRoleDropdown (
  { value, onChange = noop, options = defaultRoleOptions, isNotEditor = false, autoRotate = false }:
    CollaboratorRoleDropDownProps,
) {
  const mappedOptions = useDropdownOptionMap(options);

  if (isNotEditor) return <StaticRole role={value ?? DropdownOption.Viewer} />;

  return (
    <div className='w-[104px]'>
      <Dropdown
        hideSelectionHint
        autoRotate={autoRotate}
        initialValue={mappedOptions.find((option) => option.value === value)}
        onChange={onChange}
        options={mappedOptions}
        data-testid='collaborator-role-dropdown'
      />
    </div>
  );
}
