import { ConfirmationModal } from '@/components/modals';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import InfoTooltip from '@/components/tooltip/InfoTooltip';
import { useModal } from '@/hooks/useModal';

interface TimelineUndoProps {
  tooltipMessage?: string;
  isDisabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  undo: () => Promise<any>;
}

export function TimelineUndo ({ tooltipMessage, isDisabled, undo }: TimelineUndoProps): JSX.Element {
  const { showModal, closeModal } = useModal();
  const confirmUndo = () => {
    showModal(
      <ConfirmationModal
        onConfirm={undoDiff}
        onClose={closeModal}
        header='Are you sure?'
        message='Are you sure you want to undo these changes?'
        confirmText='Yes, undo these changes'
        cancelText='No'
      />,
      {
        className: '!w-[500px]',
      },
    );
  };

  const undoDiff = () => {
    undo()
      .then(() => {
        closeModal();
        addToastSuccess('Success! Changes have been undone');
      })
      .catch(() => {
        closeModal();
        addToastError('Failed to undo changes');
      });
  };

  return (
    <DisabledUndoTooltip message={tooltipMessage} disabled={!isDisabled}>
      <div className='pr-4'>
        <button
          type='button'
          onClick={confirmUndo}
          className='px-4 font-medium h-[32px] btn-primary-hollow effra-xs mt-[9px]'
          disabled={isDisabled}
        >
          Undo
        </button>
      </div>
    </DisabledUndoTooltip>
  );
}

function DisabledUndoTooltip (
  { message, disabled, children }: { message: string; disabled: boolean; children: JSX.Element },
): JSX.Element {
  if (disabled) return children;

  return (
    <InfoTooltip message={message} tooltipClassName='w-[210px]'>
      {children}
    </InfoTooltip>
  );
}
