import { LIST_ACTIONS, ListAction } from './constants';

export function getListActions (
  resourceCostUnit: string,
  canDuplicate?: boolean,
  canAddToParent?: boolean,
): Record<string, { tasks: boolean; objects: boolean }> {
  const listActions = { ...LIST_ACTIONS };
  if (!canDuplicate) {
    delete listActions[ListAction.DUPLICATE];
    delete listActions[ListAction.DUPLICATE_TREE];
  }
  if (!canAddToParent) {
    delete listActions[ListAction.ADD_TO_PARENT];
  }
  if (resourceCostUnit === 'points') {
    delete listActions[ListAction.UPDATE_HOURS];
  } else if (resourceCostUnit === 'hours') {
    delete listActions[ListAction.UPDATE_POINTS];
  }
  return listActions;
}
