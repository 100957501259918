import { type PerformanceCalc } from '@/models';
import { useAppSelector } from '@/state/hooks';
import { postCalcModObject } from '@/util/requests.functions';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import HookFormLabel from '../HookFormLabel';
import { getRelation, getRelations } from './calculators.utils';
import { selectIntegratedVehicles } from './selectors/integratedObjects.selector';

const fieldDependencies = {
  orbit: {
    type: 'string',
    fields: ['Orbit', 'Orbit Class', 'orbitClass', 'Orbit Type'],
  },
  apogee: {
    type: 'number',
    fields: ['Apogee', 'apogeeTarget', 'Apogee (km)'],
  },
  perigee: {
    type: 'number',
    fields: ['Perigee', 'perigeeTarget', 'Perigee (km)'],
  },
  inclination: {
    type: 'number',
    fields: ['Inclination', 'inclinationDegrees', 'Inclination (deg)'],
  },
};

const DEFAULT_RELATIONS = {
  self: { fieldDependencies },
  children: {
    fieldDependencies: {
      latitude: {
        type: 'number',
        fields: ['Latitude', 'Latitude (deg)'],
      },
      fairings: {
        type: 'object',
        fields: ['Fairing', 'Fairing (kg)'],
        children: {
          fieldDependencies: {
            mass: {
              type: 'number',
              fields: ['Mass', 'fairingMass', 'Mass In Kilograms', 'Mass (kg)'],
            },
          },
        },
      },
      stages: {
        type: 'object',
        fields: ['Stage', 'Stage (kg)'],
        children: {
          fieldDependencies: {
            averageIsp: {
              type: 'number',
              fields: ['Average Isp', 'AverageIsp', 'Average Isp (s)'],
            },
            dryMass: {
              type: 'number',
              fields: ['Dry Mass', 'DryMass', 'dryMass (kg)'],
            },
            wetMass: {
              type: 'number',
              fields: ['Wet Mass', 'WetMass', 'Wet Mass (kg)'],
            },
          },
        },
      },
    },
  },
};

interface PerformanceCalculatorProps {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  labelProps?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  relations?: any;
  suffix?: string;
}

export default function PerformanceCalculator ({
  className,
  labelProps,
  relations = DEFAULT_RELATIONS,
  suffix = 'kg',
}: PerformanceCalculatorProps) {
  const [totalCalc, setTotalCalc] = useState(0);
  const [data, integrations] = useWatch({ name: ['data', 'integrations'] });

  const integratedObjects = useAppSelector(
    selectIntegratedVehicles(integrations),
  );

  useEffect(() => {
    const { orbit, inclination, ...calcValues } = getRelation(
      relations.self,
      data,
    );

    const vehicleData = integratedObjects.reduce((acc, obj) => {
      const { latitude } = getRelation(relations.children, obj?.data);

      const relationsWithSubfields = Object.entries(relations.children.fieldDependencies).reduce(
        (acc, [key, value]) => {
          const { children } = value as { children: Record<string, unknown> };
          if (children) acc = { ...acc, [key]: value };
          return acc;
        },
        {},
      );

      const rel = getRelations({ fieldDependencies: relationsWithSubfields }, obj?.data);

      const calc = { id: obj.id, ...rel, latitude };

      return [...acc, calc];
    }, []);

    const { apogee, perigee } = calcValues;

    const calcRequest = [{
      orbit,
      apogee: parseFloat(apogee),
      perigee: parseFloat(perigee),
      inclination: parseFloat(inclination),
      vehicleData,
    }];

    postCalcModObject(calcRequest as PerformanceCalc[])
      .then(res => {
        const totalCalculation = Object.values(res).reduce((acc, calc) => {
          if (calc > 0) acc += calc;
          return acc;
        }, 0);

        setTotalCalc(totalCalculation);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  }, [data, integrations]);

  return (
    <div className='flex flex-col'>
      <HookFormLabel {...labelProps} />
      <div>
        {totalCalc} <span className='effra-xs leading=[0.75rem]'>kg</span>
      </div>
    </div>
  );
}
