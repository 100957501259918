import { CollapsibleSection, useEditContext } from '@/components/cards';
import { ModalContext } from '@/components/modals';
import AddCustomFieldModal from '@/components/modals/AddCustomFieldModal/AddCustomFieldModal';
import DeleteCustomFieldConfirmation from '@/components/modals/DeleteCustomFieldConfirmation/DeleteCustomFieldConfirmation';
import ModuleWrapper from '@/components/modules/utils/ModuleWrapper';
import { type Module } from '@/models/template.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { getOrderedModuleEntries } from '../ModularObject/Page/utils';

export default function CustomFields () {
  const { canUserEdit } = useEditContext();
  const { getValues, setValue } = useFormContext();
  const [modules, data]: Array<Record<string, Module>> = getValues(['customFields', 'data']) || [];

  const { showModal, closeModal } = useContext(ModalContext);

  const handleSetValue = (update) => {
    setValue(
      'customFields',
      { ...modules, ...update },
      { shouldDirty: true },
    );
  };

  const handleDeleteCustomField = (id) => {
    const { [id]: deleted, ...updatedModules } = modules;
    const { [id]: deletedData, ...updatedData } = data;
    setValue('customFields', updatedModules, { shouldDirty: true });
    setValue('data', updatedData, { shouldDirty: true });
  };

  return (
    <>
      <CollapsibleSection
        title='Custom Fields'
        noPadding
        className='py-2 px-[24px]'
        lightHeader
      >
        <div className='flex flex-col gap-4 pt-8'>
          {getOrderedModuleEntries(modules)
            .map(([key, module], idx) => {
              return (
                <div key={`data.${key}`}>
                  <div className='relative w-1/2 group'>
                    {canUserEdit &&
                      (
                        <FontAwesomeIcon
                          icon={faXmark}
                          size='lg'
                          className={cx(
                            'opacity-0 group-hover:opacity-100 transition-all absolute cursor-pointer -right-6 top-9',
                            {
                              '!-right-6': module?.properties?.suffix,
                            },
                          )}
                          onClick={() => {
                            showModal(
                              <DeleteCustomFieldConfirmation
                                closeModal={closeModal}
                                handleDeleteCustomField={() => {
                                  handleDeleteCustomField(key);
                                }}
                              />,
                            );
                          }}
                        />
                      )}
                    <ModuleWrapper module={module} index={idx} path={`data.${key}`} />
                  </div>
                </div>
              );
            })}
        </div>
        {canUserEdit &&
          (
            <button
              onClick={e => {
                e.preventDefault();
                showModal(
                  <AddCustomFieldModal
                    closeModal={closeModal}
                    setValue={handleSetValue}
                    existingModules={modules}
                  />,
                );
              }}
              className='font-medium w-[140px] h-[32px] btn-primary-hollow effra-xs'
            >
              Add Custom Field
            </button>
          )}
      </CollapsibleSection>
    </>
  );
}
