import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetCommentsForObjectIdQueryVariables = Types.Exact<{
  modularObjectId: Types.Scalars['UUID']['input'];
}>;


export type GetCommentsForObjectIdQuery = { __typename?: 'Query', getModularObjectByID: { __typename?: 'ModularObject', id: string, comments: Array<{ __typename?: 'Comment', id: string, body: { [key: string]: any }, createdAt: any, createdBy: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null, organizationId: string | null, profileImage: string | null } }> | null } };


export const GetCommentsForObjectIdDocument = gql`
    query GetCommentsForObjectId($modularObjectId: UUID!) {
  getModularObjectByID(id: $modularObjectId) {
    id
    comments {
      id
      body
      createdAt
      createdBy {
        id
        firstName
        lastName
        organizationId
        profileImage
      }
    }
  }
}
    `;

/**
 * __useGetCommentsForObjectIdQuery__
 *
 * To run a query within a React component, call `useGetCommentsForObjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsForObjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsForObjectIdQuery({
 *   variables: {
 *      modularObjectId: // value for 'modularObjectId'
 *   },
 * });
 */
export function useGetCommentsForObjectIdQuery(baseOptions: Apollo.QueryHookOptions<GetCommentsForObjectIdQuery, GetCommentsForObjectIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommentsForObjectIdQuery, GetCommentsForObjectIdQueryVariables>(GetCommentsForObjectIdDocument, options);
      }
export function useGetCommentsForObjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommentsForObjectIdQuery, GetCommentsForObjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommentsForObjectIdQuery, GetCommentsForObjectIdQueryVariables>(GetCommentsForObjectIdDocument, options);
        }
export function useGetCommentsForObjectIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCommentsForObjectIdQuery, GetCommentsForObjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCommentsForObjectIdQuery, GetCommentsForObjectIdQueryVariables>(GetCommentsForObjectIdDocument, options);
        }
export type GetCommentsForObjectIdQueryHookResult = ReturnType<typeof useGetCommentsForObjectIdQuery>;
export type GetCommentsForObjectIdLazyQueryHookResult = ReturnType<typeof useGetCommentsForObjectIdLazyQuery>;
export type GetCommentsForObjectIdSuspenseQueryHookResult = ReturnType<typeof useGetCommentsForObjectIdSuspenseQuery>;
export type GetCommentsForObjectIdQueryResult = Apollo.QueryResult<GetCommentsForObjectIdQuery, GetCommentsForObjectIdQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetCommentsForObjectIdQuery((req, res, ctx) => {
 *   const { modularObjectId } = req.variables;
 *   return res(
 *     ctx.data({ getModularObjectByID })
 *   )
 * })
 */
export const mockGetCommentsForObjectIdQuery = (resolver: Parameters<typeof graphql.query<GetCommentsForObjectIdQuery, GetCommentsForObjectIdQueryVariables>>[1]) =>
  graphql.query<GetCommentsForObjectIdQuery, GetCommentsForObjectIdQueryVariables>(
    'GetCommentsForObjectId',
    resolver
  )
