import { type User } from '@/__generated__/types';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { ADMIN_ROLE, SUPER_ROLE, USER_ROLES } from '@/util/constants';
import metrics from '@/util/metrics';
import { postUsers } from '@/util/requests.functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/sharp-solid-svg-icons';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Dropdown, Input } from '../form';

interface InviteUserFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentUser: any;
  setInviteUsersState?: (state: null | 'success' | 'error') => void;
  closeModal: () => void;
  onInviteCallback: () => Promise<void>;
}

const USER_KEY_PREFIX = 'invited-user';
const generateUserKey = (index: number): string => `${USER_KEY_PREFIX}${index}`;
const emptyFormState = { [generateUserKey(0)]: {} };
const SESSION_STORAGE_KEY = 'inviteUserFormState';

export default function InviteUserForm ({
  currentUser,
  setInviteUsersState,
  closeModal,
  onInviteCallback,
}: Readonly<InviteUserFormProps>): JSX.Element {
  const SELECTABLE_USER_ROLES = USER_ROLES.filter((role) => {
    const lowerCaseRole = role.toLowerCase();
    return ![SUPER_ROLE, ADMIN_ROLE].includes(lowerCaseRole);
  });

  const getInitialFormState = () => {
    const savedFormState = sessionStorage.getItem(SESSION_STORAGE_KEY);
    if (savedFormState) {
      return JSON.parse(savedFormState);
    }
    return emptyFormState;
  };

  const initialFormState = getInitialFormState();
  const [formState, setFormState] = useState<Record<string, User>>(initialFormState);
  const isFormInMultiUserMode = Object.keys(formState).length > 1;

  useEffect(() => {
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(formState));
  }, [formState]);

  const preparePayload = (formState: Record<string, User>): User[] => {
    const invitedUsers = Object.values(formState);
    return invitedUsers.map((invitedUser) => ({ ...invitedUser, organizationId: currentUser.organizationId }));
  };

  const handleInviteInternalUser = async (event) => {
    event.preventDefault();

    metrics.track('invite user', { amount: Object.values(formState).length });

    try {
      await postUsers(preparePayload(formState));
      await onInviteCallback();
      if (setInviteUsersState) {
        setInviteUsersState('success');
      } else {
        addToastSuccess('User(s) successfully invited.');
      }
      sessionStorage.removeItem(SESSION_STORAGE_KEY);
      closeModal();
    } catch (err) {
      console.error(err);
      if (setInviteUsersState) setInviteUsersState('error');
      addToastError('Something went wrong while creating the user. Please try again later.');
    }
  };

  return (
    <form className='flex flex-1' onSubmit={handleInviteInternalUser}>
      <div className='flex flex-col flex-1 gap-5'>
        <h3 className='mb-0 text-3xl font-black font-sculpin'>Add users to your company</h3>
        <div className='flex gap-[16px]'>
          <p className='effra-12'>
            Invite your team members to create their own Integrate account. They will only see what you explicitly share
            or what is set to company-wide visibility.
          </p>
          <div className='flex items-center text-white bg-primary p-[8px] pl-[4px] rounded-[4px] gap-[4px] effra-14 w-[283px] shrink-0'>
            <div className='flex justify-center items-center shrink-0 w-[36px] h-[36px]'>
              <FontAwesomeIcon icon={faUserPlus} className='text-[16px]' />
            </div>
            Internal users and the things they own will display as purple in your account.
          </div>
        </div>
        {Object.keys(formState).map((_, index) => {
          const userKey = generateUserKey(index);
          return (
            <div
              key={userKey}
              className={classNames(
                'flex gap-5',
                {
                  'flex-row': isFormInMultiUserMode,
                  'flex-col': !isFormInMultiUserMode,
                },
              )}
            >
              <div
                className={classNames('basis-0', {
                  'grow-[2]': isFormInMultiUserMode,
                  'shrink-[2]': isFormInMultiUserMode,
                })}
              >
                <Input
                  id={`${userKey}-first-name`}
                  name={`${userKey}-first-name`}
                  type='text'
                  placeholder='First Name'
                  value={formState[userKey].firstName ?? ''}
                  onChange={(e) => {
                    const firstName = e.target.value;
                    setFormState({ ...formState, [userKey]: { ...formState[userKey], firstName } });
                  }}
                  autoComplete='first-name'
                  required
                />
              </div>

              <div
                className={classNames('basis-0', {
                  'grow-[2]': isFormInMultiUserMode,
                  'shrink-[2]': isFormInMultiUserMode,
                })}
              >
                <Input
                  id={`${userKey}-last-name`}
                  type='text'
                  placeholder='Last Name'
                  value={formState[userKey].lastName ?? ''}
                  onChange={(e) => {
                    const lastName = e.target.value;
                    setFormState({ ...formState, [userKey]: { ...formState[userKey], lastName } });
                  }}
                  autoComplete='last-name'
                  required
                />
              </div>

              <div
                className={classNames('basis-0', {
                  'grow-[2]': isFormInMultiUserMode,
                  'shrink-[2]': isFormInMultiUserMode,
                })}
              >
                <Input
                  id={`${userKey}-title`}
                  type='text'
                  placeholder='Title'
                  value={formState[userKey].title ?? ''}
                  onChange={(e) => {
                    const title = e.target.value;
                    setFormState({ ...formState, [userKey]: { ...formState[userKey], title } });
                  }}
                  autoComplete='organization-title'
                  required
                />
              </div>

              <div className={classNames('basis-0', { 'flex-1': isFormInMultiUserMode })}>
                <Dropdown
                  id={`${userKey}-role`}
                  options={currentUser?.role === SUPER_ROLE
                    ? USER_ROLES
                    : SELECTABLE_USER_ROLES}
                  value={formState[userKey].role ?? ''}
                  onChange={(e) => {
                    const role = e.target.value;
                    setFormState({ ...formState, [userKey]: { ...formState[userKey], role } });
                  }}
                  placeholder='Role'
                  required
                />
              </div>

              <div
                className={classNames('basis-0', {
                  'grow-[4]': isFormInMultiUserMode,
                  'shrink-[4]': isFormInMultiUserMode,
                })}
              >
                <Input
                  id={`${userKey}-email`}
                  type='email'
                  value={formState[userKey].email ?? ''}
                  placeholder='Email'
                  onChange={(e) => {
                    const email = e.target.value;
                    setFormState({ ...formState, [userKey]: { ...formState[userKey], email } });
                  }}
                  required
                />
              </div>
            </div>
          );
        })}

        <div
          className={classNames(
            'flex gap-5',
            {
              'flex-row': isFormInMultiUserMode,
              'flex-col': !isFormInMultiUserMode,
              'h-40': !isFormInMultiUserMode,
            },
          )}
        >
          <button type='submit' className='flex-1 w-auto btn-primary'>
            Add User{`${isFormInMultiUserMode ? 's' : ''}`}
          </button>
          <button
            type='button'
            className='flex-1 w-auto btn-ghost'
            onClick={() => {
              setFormState({ ...formState, [generateUserKey(Object.keys(formState).length)]: ({} as User) });
            }}
          >
            Add Another User
          </button>
        </div>
      </div>
    </form>
  );
}
