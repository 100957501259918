import type { HardwareIntegration, ModularData, ModularObject, Module } from '@/__generated__/types';
import { getLminusCalcDueDate } from '@/util/time.functions';
import { isEmpty } from 'ramda';

export interface ModuleWithPath extends Module {
  path: string;
}
export function getFlattenedModules (data: Record<string, Module>, path?: string): ModuleWithPath[] {
  return getOrderedModuleEntries(data)
    .reduce((acc, [key, module]) => {
      const nextPath = path ? `${path}.${key}` : key;
      return [...acc, { ...module, path: nextPath }, ...getFlattenedModules(module.modules, nextPath)];
    }, []);
}

export interface ModuleWithId extends Module {
  id: string;
}

// Takes a tree of modules and returns a array of all modules with their ids
export function getFlattenedModulesWithIds (data: Record<string, Module>): ModuleWithId[] {
  return getOrderedModuleEntries(data)
    .reduce((acc, [key, module]) => {
      return [
        ...acc,
        { ...module, id: key },
        ...getFlattenedModulesWithIds(module.modules),
      ];
    }, []);
}
export function moduleSortFn (moduleA, moduleB): number {
  if (moduleA?.order < moduleB?.order) return -1;
  if (moduleA?.order > moduleB?.order) return 1;
  return 0;
}

export function getOrderedModuleArray (data: Record<string, Module>): Module[] {
  if (!Object.keys(data ?? {})?.length) return [];
  return Object.values(data)
    .filter(module => Boolean(module) && Object.keys(module)?.length > 0)
    .sort(moduleSortFn);
}
export function getOrderedModuleEntries<T> (data: Record<string, T>): Array<[string, T]> {
  if (!Object.keys(data ?? {})?.length) return [];
  return Object.entries(data)
    .filter(([, module]) => {
      return Boolean(module) && Object.keys(module)?.length;
    })
    .sort(([, moduleA], [, moduleB]) => moduleSortFn(moduleA, moduleB));
}

function getParentIdsFromIntegratedObjects (
  id: string,
  integrations: Record<string, HardwareIntegration>,
): string[] {
  let pid = id;
  let prevPid = null;

  // Reduces chance of endless loop if this happens to be undefined
  if (!id) return [];

  const parentIds = [];
  const prevIds = [];

  while ((pid && pid !== prevPid) || pid === id) {
    const current = Object.values(integrations || {})
      .find(integration => integration.objectId === pid && !integration.isPending);

    if (prevIds.includes(pid)) {
      console.error('Circular association detected in getParentIdsFromIntegratedObjects for object id:', id);
      console.error('prevIds', prevIds);
      // Remove last element from parentIds so we don't include the circular reference
      parentIds.pop();
      break;
    }

    parentIds.push(current?.parentId);
    prevIds.push(pid);
    prevPid = pid;
    pid = current?.parentId;
  }

  return parentIds.filter(Boolean);
}

export function getParentWithLaunchDate (
  id: string,
  integrations: Record<string, HardwareIntegration>,
  objectsToSearch: Record<string, ModularObject>,
): ModularData {
  const parentIds = getParentIdsFromIntegratedObjects(id, integrations);

  const reducedModularData: ModularData = {} as ModularData;

  return parentIds.reduce<ModularData>((acc, id) => {
    const { data } = objectsToSearch[id] ?? {};
    const launchDateParent = getFlattenedModules(data)
      .find(({ name }) => name === 'launchDate');

    // @ts-expect-error maybe wrong type?
    if (launchDateParent) acc = launchDateParent;

    return acc;
  }, reducedModularData);
}

export function getTaskDates (task, integrations, modularObjects) {
  const parentWithLaunchDate = getParentWithLaunchDate(
    task?.id,
    integrations,
    modularObjects,
  );

  const lMinusDueDate = !isEmpty(parentWithLaunchDate) && getLminusCalcDueDate(
    task?.relativeEndMonths,
    task?.relativeEndDays,
    parentWithLaunchDate?.value,
  );

  const useLMinusDates = task?.dateRangeSelection && task?.dateRangeSelection === 'L-Minus Dates';

  const targetDate = task?.targetDate && !useLMinusDates
    ? task?.targetDate
    : lMinusDueDate;

  const startDate = task?.targetDate && !useLMinusDates
    ? task?.startDate
    : lMinusDueDate;

  return { startDate, targetDate };
}
