import HookFormLabel from '@/components/modules/HookFormLabel';
import { getParsedDateFormat } from '@/util/time.functions';
import cx from 'classnames';
export interface DisplayValueProps {
  className?: HTMLDivElement['className'];
  dateFormat?: string;
  value?: string;
  preformat?: boolean;
  labelProps?: Partial<React.ComponentProps<typeof HookFormLabel>>;
}

export default function DisplayValue ({
  className = '',
  dateFormat,
  value = '',
  preformat = false,
  labelProps = {},
}: DisplayValueProps): JSX.Element {
  const { name } = labelProps;
  let displayValue = value;
  if (dateFormat) {
    displayValue = getParsedDateFormat(displayValue, dateFormat);
  }

  return (
    <div className={cx('flex flex-col font-medium sculpin-base', className)}>
      <HookFormLabel className='mb-2 label' {...labelProps} />
      <div id={name}>
        {preformat
          ? <pre className='font-medium sculpin-base'>{displayValue || '—'}</pre>
          : <>{displayValue || '—'}</>}
      </div>
    </div>
  );
}
