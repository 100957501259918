import { type ComponentProps } from 'react';

export default function CaretIcon ({ ...otherProps }: ComponentProps<'svg'>): JSX.Element {
  return (
    <svg
      className='fill-current stroke-current'
      width='13'
      height='9'
      viewBox='0 0 13 9'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...otherProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.7169 2.08397L6.60954 8.60983L0.292894 2.29318L1.70711 0.878969L6.56192 5.73378L11.2566 0.717361L12.7169 2.08397Z'
      />
    </svg>
  );
}
