import { type AnyAction, type Reducer } from '@reduxjs/toolkit';
import { identity, propOr } from 'ramda';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Handler = (state: Record<string, any>, action: AnyAction) => Record<string, any>;

export default function createReducer (
  initialState: Record<string, unknown>,
  handlers: Record<string, Handler>,
): Reducer {
  return (state = initialState, action: AnyAction) => {
    const getHandler: Handler = propOr(identity, action.type as string, handlers);
    return getHandler(state, action);
  };
}
