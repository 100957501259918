import type HookFormInput from './HookFormInput';
import HookFormLabel from './HookFormLabel';
import RadioInput from './RadioInput';
interface RadioGroupOption {
  inputProps: React.ComponentProps<typeof HookFormInput>;
  labelProps: React.ComponentProps<typeof HookFormLabel>;
  name: string;
}

interface RadioGroupProps {
  labelProps: React.ComponentProps<typeof HookFormLabel>;
  options: RadioGroupOption[];
  showAsColumn?: boolean;
  className: HTMLDivElement['className'];
  name: string;
}
export default function RadioGroup ({
  labelProps,
  className = '',
  showAsColumn,
  options,
  name,
}: RadioGroupProps): JSX.Element {
  return (
    <div className='flex flex-col gap-4'>
      <HookFormLabel className='label' {...labelProps} />
      <div className={`flex gap-4 ${showAsColumn ? 'flex-col' : ''}`}>
        {options.map((option, idx) => (
          <RadioInput
            className={className}
            key={`${name}-${idx}`}
            inputProps={{ ...option.inputProps, name }}
            labelProps={{ ...option.labelProps, name, showStatus: false }}
          />
        ))}
      </div>
    </div>
  );
}
