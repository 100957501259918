import { useUserContext } from '@/components/User/User.context';
import cx from 'classnames';

export default function UserName ({ className }: { className?: string }): JSX.Element {
  const { user, currentUser, isInternal, isDeleted, config, noUserText } = useUserContext();

  const username = user?.id === currentUser?.id ? 'You' : `${user?.firstName} ${user?.lastName}`;

  const assignedUser = user ? username : noUserText ?? 'Unassigned';

  return (
    <div
      data-testid='user-name'
      className={cx('whitespace-nowrap overflow-hidden text-ellipsis w-full', className, {
        'text-zinc-500': !user,
        'text-primary': isInternal && !isDeleted,
        'text-tertiary': !isInternal && !isDeleted,
        'text-gray-60': isDeleted,
        'order-2': !config.showRoleDesc,
      })}
    >
      <span className='truncate'>
        {assignedUser}
      </span>
    </div>
  );
}
