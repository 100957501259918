import { type Relation } from '@/models';
import { getAllModulesWithName, getModuleByName } from '@/util/lookup.functions';
import type { HydratedModule } from '@/util/typeHelpers';

export function getRelation (relation: Relation, modules: Record<string, HydratedModule>): Record<string, string> {
  const { fieldDependencies } = relation;
  const fields = Object.keys(fieldDependencies).reduce((acc, key) => {
    const { fields, type } = fieldDependencies[key];

    for (const field of fields) {
      if (acc[field]) continue;
      const fieldModule = getModuleByName(modules, field);
      if (!fieldModule) continue;
      const value = type === 'number'
        ? parseFloat(fieldModule.value)
        : fieldModule.value;
      acc[key] = value;
    }
    return acc;
  }, {});
  return fields;
}

export function getRelations (relation: Relation, modules: Record<string, HydratedModule>): Record<string, string> {
  const { fieldDependencies } = relation;
  const fields = Object.keys(fieldDependencies).reduce((acc, key) => {
    const { fields, children } = fieldDependencies[key];
    for (const field of fields) {
      if (acc[field]) continue;
      const fieldModules = getAllModulesWithName(modules, field);
      if (!fieldModules) continue;
      const value = fieldModules.map(module => {
        if (children) {
          return getRelation(children ?? relation, module.modules ?? {});
        }

        return null;
      }).filter(Boolean);
      acc[key] = value;
    }
    return acc;
  }, {});
  return fields;
}
