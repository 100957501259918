import { useAppDispatch } from '@/state/hooks';
import { addShares } from '@/state/slices/shares.slice';
import { postShares } from '@/util/requests.functions';
import { useState } from 'react';
import ConfirmCollaborators from './ConfirmCollaborators';
import EnterEmails from './EnterEmails';
import { useGetUsersByEmailLazyQuery } from './GetUsersByEmail.query.generated';

interface AddCollaboratorsModalProps {
  selectedItems: string[];
  closeModal: () => void;
}

export default function AddCollaboratorsModal ({
  selectedItems,
  closeModal,
}: AddCollaboratorsModalProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [emails, setEmails] = useState<string>('');
  const [getUsersByEmail] = useGetUsersByEmailLazyQuery();
  const [shouldShowUserConfirmation, setShouldShowUserConfirmation] = useState<boolean>(false);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [shareRoles, setShareRoles] = useState<Record<string, string>>({}); // { userId: string, role: string }

  const handleCancel = (): void => {
    closeModal();
  };

  // Handles changing the emails input
  const handleEmailsInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmails(event.target.value);
  };

  // Handles looking up users by email
  const handleLookupEmails = (): void => {
    const parsedEmails = emails.split(',').map((email) => email.trim());
    getUsersByEmail({ variables: { emails: parsedEmails }, fetchPolicy: 'network-only' })
      .then(({ data }) => {
        const users = data?.getUsersByEmail;
        setShouldShowUserConfirmation(true);
        setPendingUsers(users);
      })
      .catch((error) => {
        console.error('Error looking up users by email', error);
      });
  };

  // Handles changing the permission of a user from the dropdown
  const handleChangeShareRole = (userId: string, role: string): void => {
    setShareRoles({ ...shareRoles, [userId]: role });
  };

  // Handles adding collaborators to the selected tasks
  const handleAddCollaborators = (): void => {
    // For each selected milestone,
    // Form new shares for each user
    const newShares = selectedItems?.reduce((acc, taskId) => {
      const shares = pendingUsers?.map((user) => {
        return {
          externalType: 'modular_object',
          externalId: taskId,
          userId: user.id,
          user,
          role: shareRoles[user?.id] ?? 'Viewer',
        };
      });
      return [...acc, ...shares];
    }, []);

    postShares(newShares)
      .then((res) => {
        // Add new shares to redux store
        dispatch(addShares(res));
        closeModal();
      })
      .catch((error) => {
        console.error('Error posting new shares via list actions', error);
      });
  };

  return (
    <div className='flex flex-col flex-1 gap-6 p-3'>
      {!shouldShowUserConfirmation
        ? (
          <EnterEmails
            handleCancel={handleCancel}
            handleLookupEmails={handleLookupEmails}
            handleEmailsInputChange={handleEmailsInputChange}
            emails={emails}
          />
        )
        : (
          <ConfirmCollaborators
            users={pendingUsers}
            handleCancel={handleCancel}
            handleAddCollaborators={handleAddCollaborators}
            handleChangeShareRole={handleChangeShareRole}
          />
        )}
    </div>
  );
}
