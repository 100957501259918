import { getParentWithLaunchDate } from '@/components/common/ModularObject/Page/utils';
import { createSelector } from '@reduxjs/toolkit';

export const selectLaunchDateFromParent = (id = '') =>
  createSelector(
    [({ modularObjects, integrations }) => ({ modularObjects, integrations })],
    ({ modularObjects, integrations }) => {
      const parent = getParentWithLaunchDate(id, integrations, modularObjects);

      const { value } = parent ?? {};

      return value;
    },
  );
