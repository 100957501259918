import { type OrganizationModel } from '@/models';
import { type IntegrationApproval } from '@/models/integration.model';
import { type OrganizationJoinRequestModel } from '@/models/organizationJoinRequest.model';
import { createSlice } from '@reduxjs/toolkit';

interface InitialApprovalsState {
  organizationJoinRequests: Record<string, OrganizationJoinRequestModel>;
  organizations: Record<string, OrganizationModel>;
  integration: Record<string, IntegrationApproval>;
}

export const initialState: InitialApprovalsState = {
  organizationJoinRequests: {},
  organizations: {},
  integration: {},
};

export const approvalSlice = createSlice({
  name: 'approvals',
  initialState,
  reducers: {
    addApprovals(state, { payload: { type, approvals } }) {
      approvals.forEach((approval) => {
        state[type][approval.id] = approval;
      });
    },
    removeApprovalById(state, { payload: { id, type } }) {
      if (state[type][id]) {
        delete state[type][id];
      }
    },
    removeApprovals(state, { payload: { approvals, type } }) {
      approvals.forEach((approval) => {
        if (state[type][approval.id]) {
          delete state[type][approval.id];
        }
      });
    },
  },
});

// exported actions
export const { addApprovals, removeApprovals, removeApprovalById } = approvalSlice.actions;
export const { reducer: approvalsReducer } = approvalSlice;
