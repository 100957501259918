import { apolloClientSideClient } from '@/apollo/apolloClient';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { Button } from '@/designSystemComponents/Button';
import { gql } from '@apollo/client';
import pluralize from 'pluralize';
import { useState } from 'react';
import { useCollabDiscoveryContext } from '../CollabDiscovery.context';
import { useUpdateModularObjectMutation } from '../mutations/updateModularObject.generated';
import { GetCollaboratorListDocument } from '../queries/getCollaboratorList.generated';
import ToggleOption from './ToggleOption';

export default function ApprovalSetting ({ objectId }: { objectId: string }) {
  const { currentUserIsOwner } = useCollabDiscoveryContext();
  const [showPendingNotice, setShowPendingNotice] = useState(false);

  const currentObject = apolloClientSideClient.readFragment({
    id: `ModularObject:${objectId}`,
    fragment: gql`
      fragment currentObject on ModularObject {
        approvalsEnabled
        template {
          name
        }
      }
    `,
  }) ?? {};

  const {
    approvalsEnabled: previousApproval = true,
    template = { name: 'object' },
  } = currentObject;

  const [updateModularObject] = useUpdateModularObjectMutation({
    update: (cache, { data }) => {
      const [updatedObject] = data.updateModularObject;

      cache.writeQuery({
        query: GetCollaboratorListDocument,
        variables: {
          ids: [objectId],
        },
        data: {
          getModularObjectByIDs: [updatedObject],
        },
      });
    },
  });

  const handleApprovalChange = async (e) => {
    if (!currentUserIsOwner) return;
    try {
      const { data } = await updateModularObject({
        variables: {
          input: {
            diffs: [{
              diffType: 'updated',
              externalID: objectId,
              externalType: 'modular_object',
              diff: {
                displayNames: { approvalsEnabled: 'approvalsEnabled' },
                to: { approvalsEnabled: e.target.checked },
              },
              fromMigration: false,
            }],
          },
        },
        onQueryUpdated: (observableQuery) => {
          void observableQuery.refetch();
        },
      });
      const [{ approvalsEnabled = true } = {}] = data?.updateModularObject ?? [];

      addToastSuccess(`Approvals are ${approvalsEnabled ? 'enabled' : 'disabled'}`);
    } catch (err) {
      console.error(err);
      addToastError('Error updating approvals');
      setShowPendingNotice(true);
    }
  };

  if (showPendingNotice) {
    return (
      // * idk why but the div would not respect a custom height from tailwind
      <div className='flex justify-between items-center' style={{ height: '51px' }}>
        <div className='flex gap-2'>
          <i className='text-center fa-sharp fa-solid fa-triangle-exclamation text-warning w-[18px]' />
          <p className='text-center text-black'>
            You must resolve pending approval before changing this setting.
          </p>
        </div>
        <Button
          className='w-[90px] !h-[32px]'
          figmaProps={{
            size: 'small',
            style: 'fill',
          }}
          onClick={() => setShowPendingNotice(false)}
        >
          Confirm
        </Button>
      </div>
    );
  }

  return (
    <ToggleOption
      icon='fa-sharp fa-regular fa-check-double'
      disabled={!currentUserIsOwner}
      onChange={handleApprovalChange}
      value={previousApproval}
      secondaryIcon='fa-kit fa-approval-slash'
      title='Approvals'
      description={`All changes made to this ${
        pluralize.singular(template?.name)
      } will need to be approved by the owner.`}
    />
  );
}
