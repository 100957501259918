import type { Template } from '@/__generated__/types';
import { createSlice } from '@reduxjs/toolkit';

export type TemplateState = Record<string, Template>;

export const initialState: TemplateState = {};

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    addTemplates(state, action) {
      action.payload?.forEach((template) => {
        if (template?.id) {
          state[template.id] = template;
        }
      });
    },
    removeTemplates(state, action) {
      action.payload?.forEach((template) => {
        if (state[template.id]) {
          delete state[template.id];
        }
      });
    },
    createTemplateError(state, { payload }) {
      return {
        ...state,
        error: payload,
      };
    },
  },
});

export const { addTemplates, removeTemplates, createTemplateError } = templateSlice.actions;
export const { reducer: templateReducer } = templateSlice;
