import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetTemplateNameForNewObjectCreationQueryVariables = Types.Exact<{
  input: Types.GetTemplatesInput;
}>;


export type GetTemplateNameForNewObjectCreationQuery = { __typename?: 'Query', getTemplates: Array<{ __typename?: 'Template', id: string | null, name: string | null, type: Types.TemplateTypes | null, imagePath: string | null }> };


export const GetTemplateNameForNewObjectCreationDocument = gql`
    query GetTemplateNameForNewObjectCreation($input: GetTemplatesInput!) {
  getTemplates(input: $input) {
    id
    name
    type
    imagePath
  }
}
    `;

/**
 * __useGetTemplateNameForNewObjectCreationQuery__
 *
 * To run a query within a React component, call `useGetTemplateNameForNewObjectCreationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateNameForNewObjectCreationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateNameForNewObjectCreationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTemplateNameForNewObjectCreationQuery(baseOptions: Apollo.QueryHookOptions<GetTemplateNameForNewObjectCreationQuery, GetTemplateNameForNewObjectCreationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplateNameForNewObjectCreationQuery, GetTemplateNameForNewObjectCreationQueryVariables>(GetTemplateNameForNewObjectCreationDocument, options);
      }
export function useGetTemplateNameForNewObjectCreationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateNameForNewObjectCreationQuery, GetTemplateNameForNewObjectCreationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplateNameForNewObjectCreationQuery, GetTemplateNameForNewObjectCreationQueryVariables>(GetTemplateNameForNewObjectCreationDocument, options);
        }
export function useGetTemplateNameForNewObjectCreationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTemplateNameForNewObjectCreationQuery, GetTemplateNameForNewObjectCreationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTemplateNameForNewObjectCreationQuery, GetTemplateNameForNewObjectCreationQueryVariables>(GetTemplateNameForNewObjectCreationDocument, options);
        }
export type GetTemplateNameForNewObjectCreationQueryHookResult = ReturnType<typeof useGetTemplateNameForNewObjectCreationQuery>;
export type GetTemplateNameForNewObjectCreationLazyQueryHookResult = ReturnType<typeof useGetTemplateNameForNewObjectCreationLazyQuery>;
export type GetTemplateNameForNewObjectCreationSuspenseQueryHookResult = ReturnType<typeof useGetTemplateNameForNewObjectCreationSuspenseQuery>;
export type GetTemplateNameForNewObjectCreationQueryResult = Apollo.QueryResult<GetTemplateNameForNewObjectCreationQuery, GetTemplateNameForNewObjectCreationQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetTemplateNameForNewObjectCreationQuery((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ getTemplates })
 *   )
 * })
 */
export const mockGetTemplateNameForNewObjectCreationQuery = (resolver: Parameters<typeof graphql.query<GetTemplateNameForNewObjectCreationQuery, GetTemplateNameForNewObjectCreationQueryVariables>>[1]) =>
  graphql.query<GetTemplateNameForNewObjectCreationQuery, GetTemplateNameForNewObjectCreationQueryVariables>(
    'GetTemplateNameForNewObjectCreation',
    resolver
  )
