import cx from 'classnames';
import { type ReactNode } from 'react';
import SortIcon from './SortIcon';

interface HeaderNameProps {
  text: ReactNode;
  className?: string;
  sortKey?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export default function HeaderName (
  { text, className = '', sortKey = '', ...props }: Readonly<HeaderNameProps>,
): JSX.Element {
  const shouldShowIcon = text === 'date' || text === 'parent';

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      return props.onClick;
    }
  };

  return (
    <div className={cx('flex items-center gap-1', className)} onClick={props.onClick} onKeyDown={handleKeyDown}>
      {shouldShowIcon && (
        <SortIcon className={cx('w-4 h-4 text-primary', { 'opacity-40': !sortKey })} sortKey={sortKey} />
      )}
      <span className='font-bold capitalize truncate font-effra' {...props}>{text}</span>
    </div>
  );
}
