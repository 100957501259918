import { useEditContext } from '@/components';
import cx from 'classnames';
import HookFormLabel from './HookFormLabel';
import HookFormTextArea from './HookFormTextArea';

export interface TextAreaInputProps {
  inputProps?: Partial<React.ComponentProps<typeof HookFormTextArea>>;
  labelProps?: Partial<React.ComponentProps<typeof HookFormLabel>>;
  className?: string;
}

export default function TextAreaInput ({
  className = '',
  labelProps = {},
  inputProps = {},
}: TextAreaInputProps): JSX.Element {
  const { className: inputClassName = '', ...rest } = inputProps;
  const { canUserEdit } = useEditContext();

  const placeholderName = (rest?.placeholder || labelProps?.label || '').replace(':', '').toLowerCase();
  const placeholderText = placeholderName ? `Enter ${placeholderName}` : 'Enter value';

  return (
    <div data-testid='text-area' className={cx('flex flex-col ', className)}>
      <HookFormLabel {...labelProps} />
      <div className='flex flex-col'>
        <HookFormTextArea
          className={cx(`w-full font-effra p-3 resize-none min-h-[100px] ${inputClassName}`, {
            'input-text': canUserEdit,
          })}
          placeholder={placeholderText}
          isEditable={canUserEdit}
          shouldShowToolbar
          {...rest}
        />
      </div>
    </div>
  );
}
