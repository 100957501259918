import cx from 'classnames';
import { useUserContext } from './User.context';

export default function RoleDesc (): JSX.Element {
  const { user, isInternal, config } = useUserContext();
  const { title, organization } = user ?? {};

  const role = isInternal ? title : organization?.name;

  return config?.showRoleDesc && user
    ? (
      <div data-testid='role-desc' className='truncate'>
        <span
          className={cx('font-bold', {
            'text-primary': isInternal,
            'text-tertiary': !isInternal,
          })}
        />
        {role}
      </div>
    )
    : null;
}
