import { type UserModel } from '@/models';
import { createContext, type ReactNode, useContext, useMemo } from 'react';
import { type HistoryObject } from '../types';

interface TimelineEventValue {
  currentUser: UserModel;
  historyObject: HistoryObject;
}

interface TimelineEventProps {
  currentUser: UserModel;
  historyObject: HistoryObject;
  children: ReactNode;
}

const TimelineEventContext = createContext<TimelineEventValue | null>(null);

export const useTimelineEventContext = (): TimelineEventValue => {
  const context = useContext(TimelineEventContext);

  if (!context) {
    throw new Error('TimelineEventContext must be used within a TimelineEventContextProvider');
  }

  return context;
};

export default function TimelineEventProvider ({
  currentUser,
  historyObject,
  children,
}: TimelineEventProps): JSX.Element {
  const value = useMemo(
    () => ({
      currentUser,
      historyObject,
    }),
    [currentUser, historyObject],
  );

  return <TimelineEventContext.Provider value={value}>{children}</TimelineEventContext.Provider>;
}
