import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useAppDispatch } from '@/state/hooks';
import { useGetSubscriptionQuery } from '@/state/queries/subscription.api';
import { closeModularObjectModal } from '@/state/slices/session.slice';
import { HUMAN_FRIENDLY_SUBSCRIPTION_PLAN_NAMES } from '@/util/constants';
import { env } from '@/util/env.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faCircleUser,
  faFileInvoice,
  faPuzzle,
  faUnlock,
  faUserCog,
  faUsers,
} from '@fortawesome/sharp-solid-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import SidenavTab from '../components/SidenavTab';

export default function SettingsTabs (): JSX.Element {
  const { data: currentSubscription } = useGetSubscriptionQuery(false);
  const dispatch = useAppDispatch();
  const user = useLoggedInUser();
  const { pathname } = useRouter();
  const { thirdPartyIntegrationPage } = useFlags();
  const envName = env('NEXT_PUBLIC_STAGE');

  const shouldHideForUserRole = user?.role && user.role !== 'user';

  const planDisplayName = useMemo(() => {
    return HUMAN_FRIENDLY_SUBSCRIPTION_PLAN_NAMES[currentSubscription?.subscriptionType] ?? null;
  }, [currentSubscription]);

  const shouldShowIntegrationsPage = useMemo(() => {
    return !['il2', 'il4', 'il5', 'il6'].includes(envName)
      && thirdPartyIntegrationPage;
  }, [thirdPartyIntegrationPage, envName]);

  useEffect(() => {
    if (pathname.includes('/settings')) dispatch(closeModularObjectModal());
  }, [pathname, dispatch]);

  return (
    <div className='flex flex-col gap-[16px]'>
      {/* Leave Settings Button */}
      <SidenavTab
        keyName='Leave Settings'
        name='Settings'
        href='/'
        icon={<FontAwesomeIcon icon={faArrowLeft} />}
      />
      <div className='flex flex-col gap-[4px]'>
        <SidenavTab
          name='Profile'
          href='/settings/profile'
          isActive={pathname === '/settings/profile'}
          icon={<FontAwesomeIcon icon={faCircleUser} />}
        />
        <SidenavTab
          name='Company'
          href='/settings/company'
          isActive={pathname === '/settings/company'}
          icon={<FontAwesomeIcon icon={faUsers} />}
        />
        <SidenavTab
          name='Account'
          href='/settings/account'
          isActive={pathname === '/settings/account'}
          icon={<FontAwesomeIcon icon={faUserCog} />}
        />
        {shouldShowIntegrationsPage && (
          <SidenavTab
            name='Integrations'
            href='/settings/integrations'
            isActive={pathname === '/settings/integrations'}
            icon={<FontAwesomeIcon icon={faPuzzle} />}
          />
        )}

        <div className='flex'>
          {shouldHideForUserRole && (
            <SidenavTab
              keyName='Plan'
              name={
                <div className='flex items-center gap-[16px]'>
                  <div>Plan</div>
                  <div className='effra-10'>{planDisplayName}</div>
                </div>
              }
              href='/settings/plan'
              isActive={pathname === '/settings/plan'}
              icon={<FontAwesomeIcon icon={faUnlock} />}
            />
          )}
        </div>
        {shouldHideForUserRole && (
          <SidenavTab
            name='Billing'
            href='/settings/billing'
            isActive={pathname === '/settings/billing'}
            icon={<FontAwesomeIcon icon={faFileInvoice} />}
          />
        )}
      </div>
    </div>
  );
}
