import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
export type NestingColumnItemFragment = { __typename?: 'ModularObject', id: string, name: string | null, template: { __typename?: 'Template', id: string | null, name: string | null, type: Types.TemplateTypes | null } | null, children: Array<{ __typename?: 'ModularObject', id: string, template: { __typename?: 'Template', id: string | null, type: Types.TemplateTypes | null } | null }> | null, owner: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null, profileImage: string | null, organization: { __typename?: 'Organization', id: string, name: string | null } | null } | null };

export const NestingColumnItemFragmentDoc = gql`
    fragment NestingColumnItem on ModularObject {
  id
  name
  template {
    id
    name
    type
  }
  children {
    id
    template {
      id
      type
    }
  }
  owner(includeDeleted: true) {
    id
    firstName
    lastName
    profileImage
    organization {
      id
      name
    }
  }
}
    `;