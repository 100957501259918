import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';

import generateChangeLog from './diffObjects';

import { type HistoryObject } from '@/components/history/types';
import { historyActions } from '@/state/slices/history/history.actions';
import { COMPILE_OBJECT_HISTORY } from './diffObjectsForTimeline.actions';

export const diffObjectForTimeline = (action$, state$) => {
  return action$.pipe(
    ofType(COMPILE_OBJECT_HISTORY),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      // * this can be refactored to be passed in as a payload,
      // * when we want to support more objects

      // ! Change this to get from state instead of payload?
      const diffs = payload.diffs;
      const formattedHistory = diffs.map((diff): HistoryObject => {
        if (diff.diffType === 'created') {
          return {
            id: diff.id,
            externalID: diff.externalID,
            ownerID: diff.createdBy,
            date: diff.createdAt,
            action: diff.diffType,
            changes: [],
          };
        }
        const from = diff.diff.from;
        const to = diff.diff.to;
        const displayNames = diff.diff.displayNames;
        const changes = generateChangeLog(from, to, displayNames, diff.externalType);
        const {
          requestedById,
          requestedOn,
          requestedDiffId,
          addedFiles,
          removedFiles,
          addedShares,
          removedShares,
        } = diff.diff.to;
        return {
          id: diff.id,
          externalID: diff.externalID,
          ownerID: diff.createdBy,
          date: diff.createdAt,
          action: diff.diffType,
          requestedById,
          requestedOn,
          requestedDiffId,
          addedFiles,
          removedFiles,
          collaboratorIdsAdded: addedShares,
          collaboratorIdsRemoved: removedShares,
          changes,
        };
      });

      formattedHistory.sort((a, b) => {
        const dateDiff = new Date(a.date).getTime() - new Date(b.date).getTime();
        if (dateDiff === 0) {
          // If timestamps are the same, sort by action type
          // (migrated diffs for approved/denied/requested actions will have the same date rn)
          return b.action.localeCompare(a.action);
        }
        return dateDiff;
      });

      return of(historyActions.addObjectHistory({ [payload.id]: formattedHistory }));
    }),
  );
};
