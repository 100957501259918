import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetApprovalsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetApprovalsQuery = { __typename?: 'Query', getApprovals: Array<{ __typename?: 'Approval', id: string, approvalType: Types.ApprovalTypes, requestedAt: any, referenceName: string, referenceType: string, referenceId: string, referenceParentName: string, status: Types.ApprovalStatus, requestedBy: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null, organizationId: string | null, profileImage: string | null, workCapacity: number | null, title: string | null, email: string | null, deletedAt: any | null, organization: { __typename?: 'Organization', name: string | null } | null }, requestedChanges: Array<{ __typename?: 'ApprovalRequestChange', field: string, to: string, from: string, fieldId: string, fieldLocation: string } | null>, pendingApprovers: Array<{ __typename?: 'User', id: string } | null> } | null> };


export const GetApprovalsDocument = gql`
    query GetApprovals {
  getApprovals {
    id
    approvalType
    requestedBy {
      id
      firstName
      lastName
      organizationId
      profileImage
      workCapacity
      title
      organization {
        name
      }
      email
      deletedAt
    }
    requestedAt
    referenceName
    referenceType
    referenceId
    referenceParentName
    status
    requestedChanges {
      field
      to
      from
      fieldId
      fieldLocation
    }
    pendingApprovers {
      id
    }
  }
}
    `;

/**
 * __useGetApprovalsQuery__
 *
 * To run a query within a React component, call `useGetApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApprovalsQuery(baseOptions?: Apollo.QueryHookOptions<GetApprovalsQuery, GetApprovalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalsQuery, GetApprovalsQueryVariables>(GetApprovalsDocument, options);
      }
export function useGetApprovalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalsQuery, GetApprovalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalsQuery, GetApprovalsQueryVariables>(GetApprovalsDocument, options);
        }
export function useGetApprovalsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetApprovalsQuery, GetApprovalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetApprovalsQuery, GetApprovalsQueryVariables>(GetApprovalsDocument, options);
        }
export type GetApprovalsQueryHookResult = ReturnType<typeof useGetApprovalsQuery>;
export type GetApprovalsLazyQueryHookResult = ReturnType<typeof useGetApprovalsLazyQuery>;
export type GetApprovalsSuspenseQueryHookResult = ReturnType<typeof useGetApprovalsSuspenseQuery>;
export type GetApprovalsQueryResult = Apollo.QueryResult<GetApprovalsQuery, GetApprovalsQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetApprovalsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ getApprovals })
 *   )
 * })
 */
export const mockGetApprovalsQuery = (resolver: Parameters<typeof graphql.query<GetApprovalsQuery, GetApprovalsQueryVariables>>[1]) =>
  graphql.query<GetApprovalsQuery, GetApprovalsQueryVariables>(
    'GetApprovals',
    resolver
  )
