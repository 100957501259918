import type { Share } from '@/__generated__/types';
import { createSlice } from '@reduxjs/toolkit';
import { set, unset } from 'lodash';

type ShareRecord = Record<string, Share>;
type SharedObjectRecord = Record<string, ShareRecord>;

const initialState: SharedObjectRecord = {};

export const shareSlice = createSlice({
  name: 'shares',
  initialState,
  reducers: {
    setObjectShareState(state, action) {
      if (Array.isArray(action.payload)) {
        const newObjectShares = action.payload[0]?.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {});
        set(state, [action.payload[0]?.[0]?.externalId], newObjectShares);
      }
    },
    setShareState(state, action) {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((share) => {
          set(state, [share.externalId, share.id], share);
        });
      } else {
        set(
          state,
          [action.payload.externalId, action.payload.id],
          action.payload,
        );
      }

      return state;
    },
    addShares(state, action) {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((share) => {
          set(state, [share.externalId, share.id], share);
        });
      } else {
        set(
          state,
          [action.payload.externalId, action.payload.id],
          action.payload,
        );
      }
    },
    removeShares(state, action) {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((share) => {
          unset(state, [share.externalId, share.id]);
        });
      } else {
        unset(state, [action.payload.externalId, action.payload.id]);
      }
    },
  },
});

export const { addShares, removeShares, setShareState, setObjectShareState } = shareSlice.actions;
export const { reducer: sharesReducer } = shareSlice;
