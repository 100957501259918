import { Dropdown, Input, Label, useEditContext } from '@/components';
import { type ObjectToActivateLink } from '@/models';
import { copyToClipboard } from '@/util/functions';
import { postExternalLink } from '@/util/requests.functions';
import { Switch } from '@headlessui/react';
import Image from 'next/legacy/image';
import { hasPath } from 'ramda';
import { useEffect, useState } from 'react';
import StatusTooltip from '../tooltip/StatusTooltip';

interface ShareableLinkSectionProps {
  linkSnapshot: ObjectToActivateLink;
  objectID: string;
  objectLink: ObjectToActivateLink;
  objectType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleObjectEdit: (key: string | string[], value: any, doNotTrack?: boolean) => void;
}
export function ShareableLinkSection ({
  linkSnapshot,
  objectID,
  objectType,
  objectLink,
  handleObjectEdit,
}: ShareableLinkSectionProps): JSX.Element {
  const [copySuccess, setCopySuccess] = useState(null);

  /**
   * Handles copying data to the clipboard
   */
  const copy = async (field: string): Promise<void> => {
    try {
      await copyToClipboard(objectLink?.[field]);
      setCopySuccess(field);
      setTimeout(() => {
        setCopySuccess(null);
      }, 3000);
    } catch (err) {
      console.error(err);
    }
  };

  const requestLink = async (forceGen?: boolean, withStatus?: string): Promise<void> => {
    try {
      const response = await postExternalLink({ objectID }, forceGen);
      // Only track this edit if we are regenerating the link
      const currentStatus = objectLink?.status ?? 'Inactive';
      const status = withStatus ?? (forceGen ? currentStatus : response.status);
      handleObjectEdit(
        'externalLink',
        {
          ...response,
          status,
        },
      );
    } catch (err) {
      console.error(err);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLinkEdit = (key: string, value: any): void => {
    handleObjectEdit(['externalLink', key], value);
  };

  const handleSetLinkStatus = (linkIsActive: boolean): void => {
    const newStatus = linkIsActive ? 'Active' : 'Inactive';
    if (linkIsActive && !objectLink?.url) {
      void requestLink(false, newStatus);
    } else {
      handleLinkEdit('status', newStatus);
    }
  };

  useEffect(() => {
    if (objectLink?.access !== 'Password' && objectLink?.password) {
      handleLinkEdit('password', '');
    } else if (objectLink?.access === 'Password' && !objectLink?.password) {
      handleLinkEdit('password', linkSnapshot?.password);
    }
  }, []);

  const linkIsActive = objectLink?.status === 'Active';

  const { changes } = useEditContext();

  return (
    <div>
      <div className='flex'>
        {hasPath(['externalLink', 'status'], changes) && <StatusTooltip status='staged' />}
        <Label className='mb-2'>Shareable Link</Label>
      </div>
      {/* Revoke toggle switch */}
      <div className='flex gap-2 items-center mb-2'>
        <Switch
          checked={linkIsActive}
          onChange={handleSetLinkStatus}
          className={`${
            linkIsActive ? 'bg-black' : 'bg-gray-200'
          } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span className='sr-only'>{linkIsActive ? 'Revoke' : 'Activate'}</span>
          <span
            className={`${
              linkIsActive ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
        <div>{linkIsActive ? 'Revoke' : 'Activate'}</div>
      </div>

      {/* Generated link */}
      <div className='flex flex-col gap-y-3 gap-x-7 items-start xl:flex-row xl:justify-between xl:items-center'>
        <div className='overflow-hidden whitespace-nowrap md:w-full xl:w-6/12 text-ellipsis grow'>
          {objectLink?.url || 'No link generated yet.'}
        </div>
        <div className='flex gap-2 items-center'>
          <button
            className='flex gap-2 items-center'
            onClick={async () => {
              await requestLink(true);
            }}
          >
            <div className='flex items-center shrink-0'>
              <Image src='/images/regenerate-primary.svg' alt='Regenerate Link' height={14} width={14} />
            </div>
            <div className='font-bold text-primary'>{objectLink?.url ? 'Regenerate' : 'Generate'}</div>
          </button>
          <button
            className='flex gap-2 items-center py-2 px-3 border border-primary'
            onClick={async () => {
              await copy('url');
            }}
            disabled={!objectLink?.url}
          >
            <div className='flex items-center shrink-0'>
              <Image src='/images/link-primary.svg' alt='Copy' height={14} width={16} />
            </div>
            <div className='font-bold text-primary'>
              {copySuccess === 'url' ? 'Copied!' : 'Copy'}
            </div>
          </button>
        </div>
      </div>
      {/* Password option dropdown */}
      <div className='flex flex-col gap-3 mt-3 xl:flex-row'>
        <Dropdown
          id='password-option'
          isDirty={hasPath(['externalLink', 'access'], changes)}
          options={['Password', 'Anyone with link']}
          onChange={(event): void => {
            handleLinkEdit('access', event.target.value);
          }}
          label='Access'
          value={objectLink?.access}
          defaultValue='Password'
          wrapperClass='grow'
        />
        <div className='flex gap-3 items-end'>
          <Input
            id='password'
            name='password'
            placeholder='Enter password'
            label='Password'
            type='text'
            value={objectLink?.password}
            onChange={(event): void => {
              handleLinkEdit('password', event.target.value);
            }}
            wrapperClass='grow self-start'
            disabled={objectLink?.access !== 'Password'}
          />
          <button
            className='flex gap-2 items-center py-2 px-3 border border-primary'
            onClick={async () => {
              await copy('password');
            }}
            disabled={!objectLink?.password}
          >
            <div className='flex items-center shrink-0'>
              <Image src='/images/copy-primary.svg' alt='Copy' width={12} height={14} />
            </div>
            <div className='font-bold text-primary'>
              {copySuccess === 'password' ? 'Copied!' : 'Copy'}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
