import type { Diff, ModularObject } from '@/__generated__/types';
import FormContainer from '@/components/FormContainer/FormContainer';
import Spinner from '@/components/Icons/Spinner';
import { getModuleIdByName } from '@/util/lookup.functions';
import cx from 'classnames';
import { union } from 'ramda';
import { useMemo, useState } from 'react';
import { useListActionContext } from '../../ListActions.context';
import { type ActionMessageProps, type PermissionLevel } from '../constants';
import { UpdatedModularObjectFragmentDoc } from './updatedModularObject.fragment.generated';
import { useUpdateModularObjectMutation } from './updateModularObject.generated';

const actionToStatus = {
  'Mark as Complete': 'Complete',
  'Mark as Waived': 'Waived',
  'Mark as In Progress': 'In Progress',
  'Mark as To Do': 'To Do',
};

interface UpdateStatusMessageProps {
  permissionMap: Record<PermissionLevel, ModularObject[]>;
}

function UpdateStatusMessage ({ permissionMap }: Readonly<UpdateStatusMessageProps>): JSX.Element {
  const { selectedAction } = useListActionContext();
  const { isOwner, needsApproval, notAllowed } = permissionMap;

  if (!isOwner.length && !needsApproval.length && notAllowed.length) {
    return (
      <div data-testid='update-status-message' className='mt-3'>
        You do not have permission to update any of the selected objects or tasks.
      </div>
    );
  }

  return (
    <div data-testid='update-status-message' className='flex flex-col gap-3 mt-3'>
      {Boolean(isOwner.length) && (
        <div className='font-medium'>
          {isOwner.length} task{isOwner.length > 1 ? 's' : ''} will be updated immediately to{' '}
          {`'${actionToStatus[selectedAction]}'`}.
        </div>
      )}
      {needsApproval.length > 0 && (
        <>
          <div>
            <div>Only tasks you own will be updated immediately.</div>
            <div>
              A request to update will be sent to the owner of {needsApproval.length}{' '}
              task{needsApproval.length > 1 ? 's' : ''}.
            </div>
          </div>
          <div>You will be notified when the request has been reviewed.</div>
        </>
      )}
    </div>
  );
}

export default function UpdateConfirmation ({ permissionMap, onClose }: Readonly<ActionMessageProps>): JSX.Element {
  const [isUpdating, setIsUpdating] = useState(false);
  const { selectedAction } = useListActionContext();
  const { isOwner, needsApproval } = permissionMap;

  const [updateModularObject] = useUpdateModularObjectMutation();

  const handleSubmit = async (e): Promise<void> => {
    setIsUpdating(true);
    try {
      e.preventDefault();
      const taskDiffs: Diff[] = union(isOwner, needsApproval).map(
        (object) => {
          const taskStatusId = getModuleIdByName(object?.template?.modules, 'Status');

          return {
            externalID: object.id,
            externalType: 'modular_object',
            diff: {
              displayNames: {
                data: {
                  [taskStatusId]: 'Basic Information - Status',
                },
              },
              to: {
                data: {
                  [taskStatusId]: actionToStatus[selectedAction],
                },
              },
            },
          } as Diff;
        },
      );

      await updateModularObject({
        variables: {
          input: {
            diffs: taskDiffs,
          },
        },
        update: (cache, { data: { updateModularObject } }) => {
          cache.modify({
            fields: {
              getModularObjectByIDs(existingModularObjectRefs, { toReference }) {
                const newModularObjectsRefs = updateModularObject.map(modularObject =>
                  cache.writeFragment({
                    id: toReference(modularObject.id).toString(),
                    data: modularObject,
                    fragment: UpdatedModularObjectFragmentDoc,
                  })
                );

                return [...existingModularObjectRefs, ...newModularObjectsRefs];
              },
            },
          });
        },
      });
      onClose();
    } catch (err) {
      onClose();
    }
  };

  const canUpdate = useMemo(() => {
    return Boolean(isOwner.length || needsApproval.length);
  }, [isOwner, needsApproval]);

  return (
    <FormContainer
      handleSubmit={handleSubmit}
      onClose={onClose}
      headerText={<span className='text-primary'>{canUpdate ? 'Are you sure?' : 'Sorry!'}</span>}
      width='500px'
      content={<UpdateStatusMessage permissionMap={permissionMap} />}
      buttons={
        <>
          <button
            onClick={onClose}
            className={cx('btn btn-primary-hollow', {
              'w-full': !canUpdate,
              'w-1/2': canUpdate,
            })}
          >
            Cancel
          </button>
          {canUpdate && (
            <button className='w-1/2 btn-primary' disabled={isUpdating}>
              {isUpdating
                ? (
                  <div className='flex flex-col items-center grow'>
                    <div className='flex gap-2 items-center'>
                      <Spinner className='w-6 h-6 animate-spin' /> Updating
                    </div>
                  </div>
                )
                : 'Yes'}
            </button>
          )}
        </>
      }
    />
  );
}
