import { type RootState } from '@/state/store';
import { isObjectOwner } from '@/util/lookup.functions';

export interface Integration {
  id?: string;
  objectId?: string;
  objectType?: string;
  parentId?: string;
  parentType?: string;
  isPending?: boolean;
  isRemoved?: boolean;
  fromApproval?: boolean;
  integrations?: Record<string, Integration>;
  asTask?: boolean;
}

export interface IntegrationDecision {
  id?: string;
  approved?: boolean;
}

export interface IntegrationApproval {
  id?: string;
  objectId?: string;
  objectType?: string;
  objectName?: string;
  objectOwnerApproval?: boolean;
  ontoId?: string;
  ontoType?: string;
  ontoName?: string;
  ontoOwnerApproval?: boolean;
  offOfId?: string;
  offOfType?: string;
  offOfName?: string;
  offOfOwnerApproval?: boolean;
  createdById?: string;
  createdAt?: string;
  modifiedAt?: string;
  deletedAt?: string;
}

export function isIntegrationApprovalResolved (approval: IntegrationApproval): boolean {
  // Approval is resolved if it is deleted or if it is approved by all (relevant) parties
  return (
    Boolean(approval.deletedAt) ||
    (
      approval.objectOwnerApproval &&
      (approval.ontoOwnerApproval || !approval.ontoId) &&
      (approval.offOfOwnerApproval || !approval.offOfId)
    )
  );
}

export function needsToApproveIntegration (approval: IntegrationApproval, userId: string, state: RootState): boolean {
  return (
    (!approval.objectOwnerApproval && isObjectOwner(approval.objectType, approval.objectId, userId, state)) ||
    (!approval.offOfOwnerApproval && isObjectOwner(approval.offOfType, approval.offOfId, userId, state)) ||
    (!approval.ontoOwnerApproval && isObjectOwner(approval.ontoType, approval.ontoId, userId, state))
  );
}
