import { useCallback, useReducer } from 'react';
import { addUser, removeUser, setUsers } from './CollabDiscovery.actions';
import reducer, { initialState } from './CollabDiscovery.reducer';

export default function useSearchableUserList () {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleAddUser = useCallback((user) => {
    dispatch(addUser(user));
  }, []);

  const handleRemoveUser = useCallback((id) => {
    dispatch(removeUser(id));
  }, []);

  const handleSetUsers = useCallback((users) => {
    dispatch(setUsers(users));
  }, []);

  return {
    users: state.userList,
    setUsers: handleSetUsers,
    addedUsers: state.addedUsers,
    addUser: handleAddUser,
    removeUser: handleRemoveUser,
  };
}
