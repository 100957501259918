import { createSlice } from '@reduxjs/toolkit';

interface NavigationState {
  lastVisited: string;
}

const initialNavigationState: NavigationState = {
  lastVisited: '',
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: initialNavigationState,
  reducers: {
    setLastVisited(state, { payload }) {
      state.lastVisited = payload;
    },
    clearLastVisited(state) {
      state.lastVisited = '';
    },
  },
});

export const { setLastVisited, clearLastVisited } = navigationSlice.actions;
export const { reducer: navigationReducer } = navigationSlice;
