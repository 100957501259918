import { Comment } from '@/components/CommentSection/Comment';
import { CommentForm } from '@/components/CommentSection/CommentForm/CommentForm';
import {
  GetCommentsForObjectIdDocument,
  useGetCommentsForObjectIdQuery,
} from '@/components/CommentSection/getCommentsForObjectId.generated';
import { addToast } from '@/components/Toast/utils';
import type { JSONContent } from '@tiptap/react';
import { useCallback } from 'react';
import { useObjectContext } from '../../Card/Object.context';
import { useCreateCommentOnModularObjectMutation } from './createCommentForModularObject.generated';

export const CommentSection = () => {
  const { activeModularObject } = useObjectContext();
  const modularObjectId = activeModularObject?.id;
  const [createComment, { loading }] = useCreateCommentOnModularObjectMutation();
  const { data } = useGetCommentsForObjectIdQuery({
    onError: (err) => {
      console.error(err);
    },
    variables: { modularObjectId },
    pollInterval: 5000,
    skip: !modularObjectId,
  });

  const handleSubmitComment = useCallback((body: JSONContent) => {
    return createComment({
      variables: {
        createCommentOnModularObjectInput: {
          body,
          modularObjectId,
          parsingVersion: 'json',
        },
      },
      refetchQueries: [GetCommentsForObjectIdDocument],
      onCompleted: () => {
        addToast('Comment Submitted!');
      },
    });
  }, [modularObjectId]);

  return (
    <div className='flex flex-col p-7' data-testid='comments section'>
      {data?.getModularObjectByID?.comments?.map((comment) => (
        <div key={comment.id} className='flex flex-1 mb-6 last-of-type:mb-0'>
          <Comment comment={comment} />
        </div>
      ))}
      <div className='mt-8'>
        <CommentForm
          isSubmitting={loading}
          handleSubmitComment={handleSubmitComment}
        />
      </div>
    </div>
  );
};
