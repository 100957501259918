import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import { FullTemplateFragmentDoc } from '../fragments/template.fragment.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetTemplatesByIdQueryVariables = Types.Exact<{
  templateId: Types.Scalars['UUID']['input'];
}>;


export type GetTemplatesByIdQuery = { __typename?: 'Query', getTemplates: Array<{ __typename?: 'Template', id: string | null, version: number | null, name: string | null, description: string | null, imagePath: string | null, pageColumns: Array<string | null> | null, createdAt: any | null, ownerId: string | null, createdById: string | null, modules: any | null, subType: Types.TemplateSubTypes | null, type: Types.TemplateTypes | null, visibility: string | null }> };


export const GetTemplatesByIdDocument = gql`
    query GetTemplatesById($templateId: UUID!) {
  getTemplates(input: {byId: $templateId}) {
    ...FullTemplate
  }
}
    ${FullTemplateFragmentDoc}`;

/**
 * __useGetTemplatesByIdQuery__
 *
 * To run a query within a React component, call `useGetTemplatesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesByIdQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGetTemplatesByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTemplatesByIdQuery, GetTemplatesByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplatesByIdQuery, GetTemplatesByIdQueryVariables>(GetTemplatesByIdDocument, options);
      }
export function useGetTemplatesByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplatesByIdQuery, GetTemplatesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplatesByIdQuery, GetTemplatesByIdQueryVariables>(GetTemplatesByIdDocument, options);
        }
export function useGetTemplatesByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTemplatesByIdQuery, GetTemplatesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTemplatesByIdQuery, GetTemplatesByIdQueryVariables>(GetTemplatesByIdDocument, options);
        }
export type GetTemplatesByIdQueryHookResult = ReturnType<typeof useGetTemplatesByIdQuery>;
export type GetTemplatesByIdLazyQueryHookResult = ReturnType<typeof useGetTemplatesByIdLazyQuery>;
export type GetTemplatesByIdSuspenseQueryHookResult = ReturnType<typeof useGetTemplatesByIdSuspenseQuery>;
export type GetTemplatesByIdQueryResult = Apollo.QueryResult<GetTemplatesByIdQuery, GetTemplatesByIdQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetTemplatesByIdQuery((req, res, ctx) => {
 *   const { templateId } = req.variables;
 *   return res(
 *     ctx.data({ getTemplates })
 *   )
 * })
 */
export const mockGetTemplatesByIdQuery = (resolver: Parameters<typeof graphql.query<GetTemplatesByIdQuery, GetTemplatesByIdQueryVariables>>[1]) =>
  graphql.query<GetTemplatesByIdQuery, GetTemplatesByIdQueryVariables>(
    'GetTemplatesById',
    resolver
  )
