import type * as Types from '../../../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type AddObjectsToParentMutationVariables = Types.Exact<{
  objectIds: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input'];
  parentId: Types.Scalars['UUID']['input'];
}>;


export type AddObjectsToParentMutation = { __typename?: 'Mutation', addObjectsToParent: Array<{ __typename?: 'HardwareIntegration', id: string } | { __typename?: 'HardwareIntegrationApproval', id: string } | null> | null };


export const AddObjectsToParentDocument = gql`
    mutation AddObjectsToParent($objectIds: [UUID!]!, $parentId: UUID!) {
  addObjectsToParent(objectIds: $objectIds, parentId: $parentId) {
    ... on HardwareIntegration {
      id
    }
    ... on HardwareIntegrationApproval {
      id
    }
  }
}
    `;
export type AddObjectsToParentMutationFn = Apollo.MutationFunction<AddObjectsToParentMutation, AddObjectsToParentMutationVariables>;

/**
 * __useAddObjectsToParentMutation__
 *
 * To run a mutation, you first call `useAddObjectsToParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddObjectsToParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addObjectsToParentMutation, { data, loading, error }] = useAddObjectsToParentMutation({
 *   variables: {
 *      objectIds: // value for 'objectIds'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useAddObjectsToParentMutation(baseOptions?: Apollo.MutationHookOptions<AddObjectsToParentMutation, AddObjectsToParentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddObjectsToParentMutation, AddObjectsToParentMutationVariables>(AddObjectsToParentDocument, options);
      }
export type AddObjectsToParentMutationHookResult = ReturnType<typeof useAddObjectsToParentMutation>;
export type AddObjectsToParentMutationResult = Apollo.MutationResult<AddObjectsToParentMutation>;
export type AddObjectsToParentMutationOptions = Apollo.BaseMutationOptions<AddObjectsToParentMutation, AddObjectsToParentMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddObjectsToParentMutation((req, res, ctx) => {
 *   const { objectIds, parentId } = req.variables;
 *   return res(
 *     ctx.data({ addObjectsToParent })
 *   )
 * })
 */
export const mockAddObjectsToParentMutation = (resolver: Parameters<typeof graphql.mutation<AddObjectsToParentMutation, AddObjectsToParentMutationVariables>>[1]) =>
  graphql.mutation<AddObjectsToParentMutation, AddObjectsToParentMutationVariables>(
    'AddObjectsToParent',
    resolver
  )
