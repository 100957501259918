import { useObjectCardContext } from '@/state/ObjectCard.context';
import { useApolloClient } from '@apollo/client';
import { useMemo } from 'react';
import Breadcrumb, { type BreadcrumbConfig } from './Breadcrumb';
import { type ParentObjectFragmentFragment, ParentObjectFragmentFragmentDoc } from './ParentObjectFragment.generated';

export default function Breadcrumbs (): JSX.Element {
  const { objectCardData } = useObjectCardContext();

  const { cache } = useApolloClient();

  const breadCrumbs = useMemo(() => {
    let currentParent: typeof objectCardData.parent | ParentObjectFragmentFragment['parent'] = objectCardData?.parent;
    let currentChild: typeof objectCardData.parent | ParentObjectFragmentFragment['parent'];
    if (!currentParent) {
      return [] as BreadcrumbConfig[];
    }

    const breadCrumbs: BreadcrumbConfig[] = [];

    while (currentParent) {
      const breadCrumb: BreadcrumbConfig = {
        parentId: currentParent?.id,
        childId: currentChild?.id,
        parentName: currentParent?.name,
        parentTemplateId: currentParent.template.id,
        parentTemplateName: currentParent.template.name,
      };
      breadCrumbs.unshift(breadCrumb);

      const parentResult = cache.readFragment<ParentObjectFragmentFragment>({
        id: cache.identify(currentParent),
        fragment: ParentObjectFragmentFragmentDoc,
      });

      currentChild = currentParent;
      currentParent = parentResult?.parent;
    }

    return breadCrumbs;
  }, [cache, objectCardData]);

  return (
    <div className='flex gap-2'>
      {breadCrumbs.map((breadcrumb, i) => {
        return <Breadcrumb key={i} breadcrumb={breadcrumb} />;
      })}
    </div>
  );
}
