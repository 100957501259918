import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
export type ScheduleBaseDependencyFragment = { __typename?: 'Dependency', id: string, modularObjectId: string | null, blockedById: string | null, activeAt: any | null };

export const ScheduleBaseDependencyFragmentDoc = gql`
    fragment ScheduleBaseDependency on Dependency {
  id
  modularObjectId
  blockedById
  activeAt
}
    `;