import type { User } from '@/__generated__/types';
import { useGetUsersByOrganizationQuery } from '@/components/Company/UserTable/getUsersByOrganization.generated';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { Button } from '@/designSystemComponents/Button';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { gql } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersLine } from '@fortawesome/sharp-solid-svg-icons';
import { useState } from 'react';
import {
  KeyboardNavigableUserList,
  type KeyboardNavigableUserListProps,
} from '../CollaboratorDiscovery/KeyboardNavigableUserList';
import UserSearchBar from '../CollaboratorDiscovery/UserSearchBar';
import { useCreateTeamMutation } from './CreateTeam.generated';

enum CreateTeamModalSteps {
  InputTeamName = 0,
  InputTeamMembers = 1,
}

interface CreateTeamModalProps {
  closeModal: () => void;
}

export default function CreateTeamModal ({ closeModal }: CreateTeamModalProps): JSX.Element {
  const [teamName, setTeamName] = useState<string>('');
  const [currentStep, setCurrentStep] = useState<number>(CreateTeamModalSteps.InputTeamName);
  return (
    <div className='flex flex-col w-full gap-[24px] p-[8px]'>
      {currentStep === CreateTeamModalSteps.InputTeamName && (
        <InputTeamNameStep
          teamName={teamName}
          setTeamName={setTeamName}
          onContinue={() => {
            setCurrentStep(CreateTeamModalSteps.InputTeamMembers);
          }}
        />
      )}
      {currentStep === CreateTeamModalSteps.InputTeamMembers && (
        <InputTeamMembersStep
          teamName={teamName}
          closeModal={closeModal}
        />
      )}
    </div>
  );
}

function InputTeamNameStep ({ teamName, setTeamName, onContinue }: {
  teamName: string;
  setTeamName: (teamName: string) => void;
  onContinue: () => void;
}): JSX.Element {
  const handleTeamNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTeamName(event.target.value);
  };
  return (
    <>
      <div className='sculpin-20'>Create a team</div>
      <div>
        Teams are groups of collaborators. Use them to organize your company.
      </div>
      <div className='flex flex-col gap-[4px]'>
        <label htmlFor='team-name' className='effra-10 text-gray-40'>Team name</label>
        <input
          id='team-name'
          type='text'
          className='font-medium input-text !sculpin-14'
          placeholder='Enter team name'
          value={teamName}
          onChange={handleTeamNameChange}
        />
      </div>
      <Button
        figmaProps={{ size: 'large', style: 'fill' }}
        onClick={onContinue}
      >
        Continue
      </Button>
    </>
  );
}

function InputTeamMembersStep ({ teamName, closeModal }: {
  teamName: string;
  closeModal: () => void;
}): JSX.Element {
  const [teamMembers, setTeamMembers] = useState<Record<string, User[]>>({
    addedUsers: [],
    userList: [],
  });
  const [createTeam] = useCreateTeamMutation();
  const loggedInUser = useLoggedInUser();
  const { data: orgUsersData } = useGetUsersByOrganizationQuery({
    variables: {
      orgID: loggedInUser?.organizationId,
    },
  });
  const orgUsers = orgUsersData?.getUsersByOrganization ?? [];

  const handleAddTeamMember = (user: User) => {
    setTeamMembers((prev) => ({ ...prev, addedUsers: [...prev.addedUsers, user] }));
  };

  const handleRemoveTeamMember = (userId: string) => {
    setTeamMembers((prev) => ({ ...prev, addedUsers: prev.addedUsers.filter((user) => user.id !== userId) }));
  };

  const handleSubmitTeam = () => {
    // Submit team
    void createTeam({
      variables: {
        input: {
          name: teamName,
          userIDs: teamMembers?.addedUsers.map((member) => member.id),
        },
      },
      onCompleted: () => {
        // Close modal
        closeModal();
        // Show success toast
        addToastSuccess('Team created');
      },
      update: (cache, { data: { createTeam } }) => {
        cache.modify({
          fields: {
            teams(existingTeamRefs = [], { readField }) {
              const newTeamRef = cache.writeFragment({
                data: createTeam,
                fragment: gql`
                  fragment NewTeam on Team {
                    id
                    name
                  }
                `,
              });
              const newTeamRefs = [newTeamRef, ...(existingTeamRefs?.edges ?? [])];
              return { ...existingTeamRefs, edges: newTeamRefs };
            },
          },
        });
      },
      onError: (error) => {
        // Show error
        console.error(error);
        addToastError(`Failed to create team, ${error.message}`);
      },
    });
  };

  return (
    <>
      <div className='sculpin-20'>Add members</div>
      <div className='flex items-center gap-[8px]'>
        <FontAwesomeIcon icon={faUsersLine} />
        <div className='font-medium sculpin-16'>{teamName}</div>
      </div>
      <div className='flex flex-col gap-[4px]'>
        <label htmlFor='team-members' className='effra-10 text-gray-40'>Members</label>
        <UserSearchBar
          users={orgUsers}
          setUsers={setTeamMembers}
          addedUsers={teamMembers?.addedUsers}
          removeUser={handleRemoveTeamMember}
          addUser={handleAddTeamMember}
          userList={TeamMemberList}
          initialUsers={[]}
          disableExternalUsers
          disableAnonymousUsers
        />
      </div>
      <div className='flex grow gap-[24px]'>
        <Button onClick={handleSubmitTeam} figmaProps={{ size: 'large', style: 'outline' }}>
          Skip Members and Create
        </Button>
        <Button
          onClick={handleSubmitTeam}
          figmaProps={{ size: 'large', style: 'fill' }}
          disabled={teamMembers.addedUsers.length === 0}
        >
          Create Team
        </Button>
      </div>
    </>
  );
}

function NoResultsMessage (): JSX.Element {
  return (
    <div className='flex flex-col justify-center items-center px-2 w-full text-center h-[40px] text-gray-60'>
      <p className='flex gap-2'>
        <i className='fa-sharp fa-light text-gray-40 fa-face-smile-upside-down' />
        No team members found.
      </p>
    </div>
  );
}

export function TeamMemberList (props: KeyboardNavigableUserListProps): JSX.Element {
  return <KeyboardNavigableUserList {...props} noResultsMessage={NoResultsMessage} />;
}
