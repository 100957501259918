import { useEditContext } from '@/components';
import DisplayValue from '@/components/modules/DisplayValue';
import { addToastError } from '@/components/Toast/utils';
import { useAppSelector } from '@/state/hooks';
import { selectRootState } from '@/state/selectors';
import { getModulePath } from '@/util/lookup.functions';
import cx from 'classnames';
import { useFormContext, useWatch } from 'react-hook-form';
import HookFormInput from './HookFormInput';
import HookFormLabel from './HookFormLabel';

export interface TimePickerProps {
  className?: HTMLDivElement['className'];
  inputProps?: Partial<React.ComponentProps<typeof HookFormInput>>;
  labelProps?: Partial<React.ComponentProps<typeof HookFormLabel>>;
  large?: boolean;
  prefix?: string;
  suffix?: string;
}

export default function TimePicker ({
  className = '',
  inputProps = {},
  labelProps = {},
  large = false,
  prefix = null,
  suffix = null,
}: TimePickerProps): JSX.Element {
  const { className: inputClassName = '', ...rest } = inputProps;
  const { canUserEdit } = useEditContext();
  const { setValue } = useFormContext();
  const { templates } = useAppSelector(selectRootState);
  const templateId = useWatch({ name: 'templateId' });

  const _maxTimeName = ['data', ...getModulePath(templates[templateId]?.modules, 'maxTime'), 'value'];
  const foundMaxTimeName = _maxTimeName.length > 2 ? _maxTimeName.join('.') : 'maxTime';
  const maxTime = useWatch({ name: foundMaxTimeName });

  const _minTimeName = ['data', ...getModulePath(templates[templateId]?.modules, 'minTime'), 'value'];
  const foundMinTimeName = _minTimeName.length > 2 ? _minTimeName.join('.') : 'minTime';
  const minTime = useWatch({ name: foundMinTimeName });

  const handleTimeChange = (event): void => {
    const selectedTime = event.currentTarget.value;
    const selectedInput = event.currentTarget.name;
    if (selectedInput === foundMinTimeName && selectedTime > maxTime) {
      setValue(selectedInput, selectedTime, { shouldDirty: true });
      setValue(foundMaxTimeName, selectedTime, { shouldDirty: true });
      addToastError(
        'Time adjustment: The selected times will be updated to ensure that the Min time is always less than the Max time.',
      );
    } else if (selectedInput === foundMaxTimeName && selectedTime < minTime) {
      setValue(selectedInput, selectedTime, { shouldDirty: true });
      setValue(foundMinTimeName, selectedTime, { shouldDirty: true });
      addToastError(
        'Time adjustment: The selected times will be updated to ensure that the Min time is always less than the Max time.',
      );
    } else {
      setValue(selectedInput, selectedTime, { shouldDirty: true });
    }
  };

  return (
    <div className={cx(`flex flex-row items-end w-[116px]`)}>
      {canUserEdit ?
        (
          <div className={`flex flex-col flex-1 ${className}`}>
            <HookFormLabel {...labelProps} />
            <HookFormInput
              {...rest}
              className={cx(
                'grow pl-0 gap-x-1.5 bg-white border border-gray-300 rounded py-2 px-3 flex-row-reverse',
                inputClassName,
                {
                  'input-text': !large,
                  'input-large': large,
                },
              )}
              prefix={prefix}
              suffix={suffix}
              type='time'
              onChange={(event) => {
                handleTimeChange(event);
              }}
            />
          </div>
        ) :
        <DisplayValue className={className} labelProps={labelProps} />}
    </div>
  );
}
