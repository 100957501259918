import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';
interface SidenavCollapseButtonProps {
  onClick: () => void;
  isExpanded: boolean;
}

export default function SidenavCollapseButton (
  { onClick, isExpanded }: Readonly<SidenavCollapseButtonProps>,
): JSX.Element {
  return (
    <div
      className={cx(
        'relative h-0 @expanded/sidenav:w-auto w-[16px] group-hover:h-[16px] transition-[height] items-center justify-center @expanded/sidenav:justify-end',
        {
          'hidden @expanded/sidenav:flex': isExpanded,
        },
      )}
    >
      <button
        onClick={onClick}
        className='flex items-center opacity-0 group-hover:opacity-100 gap-[4px] text-white/60 text-[16px] group/collapse-btn'
      >
        <FontAwesomeIcon icon={faChevronLeft} rotation={isExpanded ? null : 180} />
        {isExpanded && (
          <div className='effra-10 group-hover/collapse-btn:opacity-100 opacity-0 w-0 group-hover/collapse-btn:w-[61px] text-white/60 transition-[opacity,width]'>
            Collapse
          </div>
        )}
      </button>
    </div>
  );
}
