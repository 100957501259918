import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
export type FullUserFragment = { __typename?: 'User', id: string, email: string | null, address: string | null, title: string | null, firstName: string | null, lastName: string | null, organizationId: string | null, phoneNumber: string | null, profileImage: string | null, role: Types.UserRoles | null, lastLoggedIn: any | null, onboardingStep: string | null, onboardingCompleted: any | null, mfaEnabled: boolean | null, organization: { __typename?: 'Organization', id: string, name: string | null, logo: string | null, logoHorizontal: string | null, maxUsers: number | null, organizationTypes: Array<string | null> | null, invitationsRemaining: number | null, hardware: Array<string | null> | null, createdAt: any | null, modifiedAt: any | null, createdById: string | null, resourceCostUnit: Types.ResourceCostUnit | null, tld: string | null } | null, acknowledgements: { __typename?: 'Acknowledgements', termsOfUse: boolean } | null, filters: Array<{ __typename?: 'Filter', id: string, name: string } | null> | null };

export const FullUserFragmentDoc = gql`
    fragment FullUser on User {
  id
  email
  address
  title
  firstName
  lastName
  organizationId
  organization {
    id
    name
    logo
    logoHorizontal
    maxUsers
    organizationTypes
    invitationsRemaining
    hardware
    createdAt
    modifiedAt
    createdById
    resourceCostUnit
    tld
  }
  phoneNumber
  profileImage
  role
  acknowledgements {
    termsOfUse
  }
  lastLoggedIn
  onboardingStep
  onboardingCompleted
  mfaEnabled
  filters {
    id
    name
  }
}
    `;