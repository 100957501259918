import { Label, useEditContext } from '@/components';
import cx from 'classnames';
import { type ComponentProps, useMemo } from 'react';
import StatusTooltip from '../tooltip/StatusTooltip';

type TextareaProps = ComponentProps<'textarea'> & {
  className?: string;
  dataTestId?: string;
  wrapperClass?: string;
  label?: string;
  name?: string;
};

export function Textarea (props: TextareaProps) {
  const { className = '', dataTestId, wrapperClass, label, name, ...otherProps } = props;
  const { canUserEdit, changes, requestedChanges } = useEditContext();

  const tooltipStatus = useMemo(() => {
    if (Object.prototype.hasOwnProperty.call(changes ?? {}, name)) {
      return 'staged';
    }
    if (Object.prototype.hasOwnProperty.call(requestedChanges ?? {}, name)) {
      return 'waiting for approval';
    }
  }, [changes, requestedChanges]);

  return (
    <div className={wrapperClass}>
      {canUserEdit && (
        <>
          {Boolean(label) && (
            <div className='flex'>
              <StatusTooltip status={tooltipStatus} />
              <Label>{label}</Label>
            </div>
          )}
          <textarea
            data-private='lipsum'
            data-testid={dataTestId}
            className={cx(
              'effra-base pl-3 pb-1.5 pt-4 text-black bg-transparent border-b border-black focus:border-primary outline-none shadow-normal w-full resize-none transition-all',
              className,
            )}
            {...otherProps}
          />
        </>
      )}
      {!canUserEdit && (
        <>
          {Boolean(label) && <Label>{label}</Label>}
          <div
            className={cx('text-black w-full', className)}
            data-testid={dataTestId}
            data-private='lipsum'
          >
            {otherProps.value || otherProps.defaultValue || '—'}
          </div>
        </>
      )}
    </div>
  );
}
