import { usePrismaticContext } from '@/components/Contexts/prismatic.context';
import { JiraIntegrationStatusLabel } from '@/components/Label/JiraIntegrationStatusLabel';
import { addToast, addToastError } from '@/components/Toast/utils';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import cx from 'classnames';
import { useCallback, useMemo } from 'react';
import useShouldAnimate from '../../../hooks/useShouldAnimate';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import CollaboratorBubbles from './CollaboratorBubbles';
import CloseButton from './HeaderButtons/CloseButton';
import ExternalLinkButton from './HeaderButtons/ExternalLinkButton';
import MenuButton from './HeaderButtons/MenuButton';

export interface HeaderTopProps {
  handleClose: () => void;
  handleDuplicate: () => void;
  handleDelete: () => void;
  openCollaborationModal?: () => void;
  isCompressedHeader?: boolean;
  canDelete: boolean;
  objectId: string;
  open: boolean;
}

export default function HeaderTop ({
  objectId,
  open,
  handleClose,
  handleDuplicate,
  handleDelete,
  isCompressedHeader,
  openCollaborationModal,
  canDelete,
}: HeaderTopProps): JSX.Element {
  const shouldAnimate = useShouldAnimate();
  const loggedInUser = useLoggedInUser();
  const { activeModularObjectId, objectCardData } = useObjectCardContext();
  const isEdit = Boolean(activeModularObjectId);
  const {
    createJiraTicket,
    jiraMappingData,
    canCreateJiraTask,
    isJiraTaskCreated,
    canDeleteJiraTask,
    deleteJiraWebhook,
  } = usePrismaticContext();

  const isOwner = objectCardData?.owner?.id === loggedInUser?.id;
  const canUserEdit = useMemo(
    () =>
      isOwner ||
      objectCardData?.shares?.some((share) => share?.userId === loggedInUser?.id && share?.role !== 'Viewer'),
    [isOwner, objectCardData?.shares, loggedInUser?.id],
  );

  const handleCreateJiraTicket = useCallback(async () => {
    try {
      addToast('Syncing task with Jira...');
      const url = await createJiraTicket();
      addToast(
        <span>
          Task successfully synced with Jira.<br />
          <a href={url} target='_blank' rel='noreferrer'>View Jira ticket</a>
        </span>,
      );
    } catch (err) {
      addToastError('Failed to sync task with Jira');
      console.error(err);
    }
  }, [createJiraTicket]);

  const handleDeleteJiraMapping = useCallback(async () => {
    try {
      addToast('Unsyncing task with Jira...');
      await deleteJiraWebhook();
      addToast(
        <span>
          Task successfully unsynced with Jira.<br />
        </span>,
      );
    } catch (err) {
      addToastError('Failed to unsync task with Jira');
      console.error(err);
    }
  }, [deleteJiraWebhook]);

  return (
    <div
      data-testid='header-top'
      className={cx(
        'flex justify-between gap-[16px] px-[20px] h-[80px] items-center absolute w-full transition-all duration-500 transform-gpu z-[100]',
        {
          'animate-object-change': shouldAnimate,
          'flex-1': isCompressedHeader,
          'right-0': !isCompressedHeader,
        },
      )}
    >
      <div
        className={cx('overflow-x-scroll no-scrollbar transition-all transform-gpu', {
          'opacity-0 duration-200': isCompressedHeader,
          'opacity-100 duration-1000': !isCompressedHeader,
        })}
      >
        <Breadcrumbs />
      </div>
      <div className='flex flex-col gap-2'>
        <div className='flex items-center gap-[16px]'>
          <ExternalLinkButton objectId={objectId} />
          {isEdit && canUserEdit && (
            <MenuButton
              handleDuplicate={handleDuplicate}
              handleDelete={handleDelete}
              handleJiraTaskCreate={handleCreateJiraTicket}
              handleJiraTaskDelete={handleDeleteJiraMapping}
              canDelete={canDelete}
              canCreateJiraTask={canCreateJiraTask}
              canDeleteJiraTask={canDeleteJiraTask}
              isOwner={isOwner}
              shouldShowDuplicateButton={canUserEdit}
            />
          )}
          <CollaboratorBubbles objectId={objectId} shares={objectCardData?.shares} />
          {isEdit && (
            <button
              type='button'
              onClick={openCollaborationModal}
              className='flex items-center justify-center gap-2 px-4 py-3 transition-all bg-white border rounded-sm cursor-pointer shadow-buttonDefault min-w-[86px] border-gray-90 text-primary effra-xs hover:bg-gray-98 !tracking-[1.5px] active:shadow-buttonPress group'
            >
              <i
                className={cx('fa-sharp fa-solid transition-all text-[1.1rem]', {
                  'fa-circle-chevron-right group-hover:rotate-90': !open,
                  'rotate-90': open,
                })}
              />
              <span className='font-bold uppercase'>Share</span>
            </button>
          )}
          <CloseButton onClick={handleClose} />
        </div>
        <div className='flex flex-col'>
          {isJiraTaskCreated && (
            <JiraIntegrationStatusLabel
              enabled
              ticket={jiraMappingData?.jiraIssueKey}
              url={jiraMappingData?.jiraTicketUrl}
            />
          )}
        </div>
      </div>
    </div>
  );
}
