import type { ModularObject } from '@/__generated__/types';
import { useAppSelector } from '@/state/hooks';
import { selectRootState } from '@/state/selectors';
import { createContext, type PropsWithChildren, useContext, useMemo } from 'react';

interface TaskListContextType {
  hasSubtasks: boolean;
  tasks: ModularObject[];
}

const TaskListContext = createContext<TaskListContextType>(null);

export function useTaskListContext (): Readonly<TaskListContextType> {
  const context = useContext(TaskListContext);

  if (context === undefined) {
    throw new Error('useTaskListContext must be used within a TaskListProvider');
  }

  return context;
}

export type TaskListTask = ModularObject & { isPending?: boolean };

interface TaskListProviderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newIntegrations?: any;
  object: TaskListTask;
}

export default function TaskListProvider ({
  children,
  newIntegrations = [],
  object,
}: Readonly<PropsWithChildren<TaskListProviderProps>>): JSX.Element {
  const { modularObjects, templates } = useAppSelector(selectRootState);
  const tasks = useMemo(() => object?.tasks?.edges?.map(({ node }) => node) ?? [], [object?.tasks]);
  const hasSubtasks = useMemo(() => (tasks ?? []).some((task) => Boolean(task?.tasks?.edges.length)), [tasks]);

  const value = useMemo(() => ({ hasSubtasks, tasks }), [hasSubtasks, tasks]);

  newIntegrations.forEach((integration) => {
    // TODO: update to remove redux state lookup
    const modularObject = modularObjects[integration?.objectId];

    const taskIds = tasks?.map((task) => task?.id);
    if (taskIds?.includes(modularObject?.id)) return;

    const template = templates[modularObject?.templateId];
    if (template?.type !== 'task') return;
    const modularObjectWithTemplate = { ...modularObject, template, isPending: true };

    tasks.push(modularObjectWithTemplate);
  });

  return <TaskListContext.Provider value={value}>{children}</TaskListContext.Provider>;
}
