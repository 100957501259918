import type { ModularObject } from '@/__generated__/types';
import { ParentSelectButton } from '@/components/Button/ParentSelectButton';
import { useEditContext } from '@/components/cards';
import AddToParentModal from '@/components/modals/AddToParentModal/AddToParentModal';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { useModal } from '@/hooks/useModal';
import { useNestingMutations } from '@/hooks/useNestingMutations';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { useCallback, useMemo } from 'react';

export default function ParentSelectButtonWrapper (): JSX.Element {
  const { canUserEdit } = useEditContext();
  const { activeModularObjectId, newObjectConfig: { createdFromId }, objectCardData, openViewObjectCardDrawer } =
    useObjectCardContext();
  const { closeModal, showModal } = useModal();

  const { addChild, removeChild } = useNestingMutations();

  const hasPendingParentApproval = objectCardData?.parentsPendingAddApproval?.length > 0;

  const parentObject = useMemo(() => {
    if (!objectCardData?.parent) {
      return null;
    }

    return objectCardData.parent;
  }, [objectCardData?.parent]);

  const parentName = useMemo(() => {
    if (hasPendingParentApproval) {
      return objectCardData?.parentsPendingAddApproval?.[0]?.onTo.name || 'Unknown';
    }

    return objectCardData?.parent?.name || 'Unknown';
  }, [hasPendingParentApproval, objectCardData?.parent?.name, objectCardData?.parentsPendingAddApproval]);

  const hasParent = Boolean(objectCardData?.parent?.name);

  const parentTemplateName = objectCardData?.parent?.template?.name;

  const handleAddToParent = useCallback(async (parentId: string) => {
    const addChildPayload = {
      objectId: activeModularObjectId,
      parentId,
    };

    await addChild({
      variables: { input: addChildPayload },
    });

    closeModal();
  }, [activeModularObjectId, addChild, closeModal]);

  const handleClick = useCallback(() => {
    // This `as ModularObject` casting is a hack bc that modal would need to be refactored to use the new object card context
    showModal(<AddToParentModal modularObjects={[objectCardData as ModularObject]} onSubmit={handleAddToParent} />, {
      isMegaModal: true,
      showCloseIcon: false,
    });
  }, [handleAddToParent, objectCardData, showModal]);

  const handleRemoveParent = useCallback(async () => {
    await removeChild({
      variables: { childObjectId: activeModularObjectId },

      onCompleted: (data) => {
        const isApproval = data?.deleteHardwareIntegration.__typename === 'HardwareIntegrationApproval';

        if (isApproval) {
          return addToastSuccess(`Successfully removed requested approval to de-nest ${objectCardData.name}`);
        }
        return addToastSuccess(`Successfully de-nested ${objectCardData.name}`);
      },
      onError: () => addToastError(`Failed to de-nested ${objectCardData.name}, please try again`),
    });
  }, [activeModularObjectId, objectCardData.name, removeChild]);

  const handleViewClick = useCallback(() => {
    openViewObjectCardDrawer({ modularObjectId: activeModularObjectId });
  }, [activeModularObjectId, openViewObjectCardDrawer]);

  const parentSelectStatus = hasPendingParentApproval ? 'waiting for approval' : null;

  const isDisabled = useMemo(() => {
    if (
      !canUserEdit
      || hasPendingParentApproval
      || Boolean(createdFromId)
      || (hasParent && !parentObject)
    ) return true;

    return false;
  }, [canUserEdit, createdFromId, hasParent, hasPendingParentApproval, parentObject]);

  return (
    <div data-testid='parent-select-button-wrapper'>
      <ParentSelectButton
        label={hasParent && parentName ? parentName : 'Add to parent'}
        iconTemplateName={hasParent && parentName ? parentTemplateName : null}
        onClick={handleClick}
        onDeleteClick={handleRemoveParent}
        onSwapClick={handleClick}
        onViewClick={handleViewClick}
        disabled={isDisabled}
        status={parentSelectStatus}
      />
    </div>
  );
}
