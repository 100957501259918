import type { ModularObject } from '@/__generated__/types';
import { Button, TableHeader, TableRow } from '@/components';
import cx from 'classnames';
import { type ReactNode } from 'react';
import { type ColumnConfig } from './utils';

interface TableProps {
  rowConfig: (data?: ModularObject) => ColumnConfig[];
  objectList: ModularObject[];
  objectType: string;
  handleOpenObject: (object) => void;
  placeholder?: ReactNode;
  headerClassName?: string;
  rowClassName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payloadCounts?: any[];
  placeholderClassName?: string;
}

export default function Table ({
  rowConfig,
  objectList,
  objectType,
  handleOpenObject,
  placeholder,
  headerClassName,
  rowClassName,
  placeholderClassName = 'h-[70vh]',
}: TableProps): JSX.Element {
  return (
    <>
      {/* Table Header */}
      <TableHeader rowConfig={rowConfig} className={headerClassName} />
      {/* Table Rows */}
      <div className='flex overflow-auto flex-col no-scrollbar'>
        {objectList.map((object) => {
          return (
            <TableRow
              key={`${objectType}-${object.id}`}
              rowConfig={rowConfig}
              object={object}
              objectType={objectType}
              handleOpenObject={handleOpenObject}
              className={rowClassName}
            />
          );
        })}
        {objectList?.length === 0 && (
          <div className='flex flex-col justify-center items-center h-full'>
            <div className={cx('flex items-center justify-center gap-x-1', placeholderClassName)}>
              {!placeholder &&
                (
                  <>
                    No {objectType}s yet.
                    <Button className='text-primary' onClick={handleOpenObject}>
                      Add one.
                    </Button>
                  </>
                )}
              {placeholder}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
