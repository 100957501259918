import { createContext, useContext, useMemo } from 'react';
const DEFAULT_EDIT_CONTEXT = {
  canUserEdit: false,
  changes: {},
  isOwner: false,
};
export const EditContext = createContext<EditContextType>(DEFAULT_EDIT_CONTEXT);

interface EditContextType {
  canUserEdit: boolean;
  changes?: Record<string, unknown>;
  isOwner?: boolean;
  requestedChanges?: Record<string, unknown> | string[];
}

interface EditContextProviderProps {
  canUserEdit: boolean;
  changes?: Record<string, unknown>;
  isOwner?: boolean;
  children: React.ReactNode;
  requestedChanges?: Record<string, unknown> | string[];
}

export function useEditContext () {
  const context = useContext(EditContext);

  if (!context) throw new Error('EditContext must be used within a EditContextProvider');

  return context;
}

export function EditContextProvider (
  { children, canUserEdit, isOwner, changes = {}, requestedChanges = {} }: Readonly<EditContextProviderProps>,
): JSX.Element {
  const value = useMemo(() => ({
    canUserEdit,
    changes,
    isOwner,
    requestedChanges,
  }), [canUserEdit, changes, isOwner, requestedChanges]);

  return (
    <EditContext.Provider value={value}>
      {children}
    </EditContext.Provider>
  );
}
