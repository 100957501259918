import { evolve, mergeLeft } from 'ramda';

// ! *********************
// ! Adding a handler to this file for some reason requires a restart of the app
// ! in order to be picked up and attached to the actions object, not sure why, will fix
// ! *********************

export const ADD_OBJECT_HISTORY = (state, { payload }) =>
  evolve({
    history: {
      spacecraft: mergeLeft(payload),
      modular_object: mergeLeft(payload),
    },
  }, state);
