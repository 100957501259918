import { graphqlApi, graphqlApi2 } from '@/state/queries/graphql.api';
import {
  type AnyAction,
  combineReducers,
  configureStore,
  type PreloadedState,
  type Store,
  type ThunkDispatch,
} from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware, type Epic } from 'redux-observable';
import { catchError } from 'rxjs/operators';
import { diffObjectForTimeline } from './epics/diffObjectsForTimeline/diffObjectsForTimeline.epic';
import { restApi } from './queries/rest.api';
import { approvalsReducer } from './slices/approvals.slice';
import { diffsReducer } from './slices/diffs.slice';
import draftsReducer from './slices/drafts/drafts.reducer';
import historyReducer from './slices/history/history.reducer';
import { integrationReducer } from './slices/integration.slice';
import { modularObjectsReducer } from './slices/modularObjects.slice';
import { navigationReducer } from './slices/navigation.slice';
import { sessionReducer } from './slices/session.slice';
import { sharesReducer } from './slices/shares.slice';
import { templateReducer } from './slices/templates.slice';
import { usersReducer } from './slices/user.slice';

const allReducers = combineReducers({
  approvals: approvalsReducer,
  navigation: navigationReducer,
  session: sessionReducer,
  shares: sharesReducer,
  diffs: diffsReducer,
  drafts: draftsReducer,
  history: historyReducer,
  integrations: integrationReducer,
  modularObjects: modularObjectsReducer,
  templates: templateReducer,
  users: usersReducer,
  // API REDUCERS BELOW THIS LINE
  [restApi.reducerPath]: restApi.reducer,
  [graphqlApi.reducerPath]: graphqlApi.reducer,
  [graphqlApi2.reducerPath]: graphqlApi2.reducer,
});

const epics = [
  // * Imported Epics Go Here
  diffObjectForTimeline,
];

export type RootState = ReturnType<typeof allReducers>;
export type MyEpic = Epic<AnyAction, AnyAction, void, RootState>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type AppStore = Omit<Store<RootState, AnyAction>, 'dispatch'> & {
  dispatch: AppThunkDispatch;
};
export type AppDispatch = AppStore['dispatch'];

const rootEpic = (action$, store$, dependencies) =>
  combineEpics(...epics)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    }),
  );

const rootReducer = (state, action): RootState => {
  if (action.type === 'session/logout') {
    state = undefined;
  }

  return allReducers(state, action);
};

export const makeStore = (): AppStore => {
  const epicMiddleware = createEpicMiddleware();

  const instantiatedStore = configureStore({
    reducer: rootReducer,
    devTools: {
      features: {
        persist: false,
      },
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(restApi.middleware)
        .concat(graphqlApi.middleware)
        .concat(graphqlApi2.middleware)
        .concat(epicMiddleware)
        .filter(Boolean),
  });

  epicMiddleware.run(rootEpic);

  return instantiatedStore;
};

export const makeStoreWithPreloadedState = (preloadedState: PreloadedState<RootState>): AppStore => {
  const epicMiddleware = createEpicMiddleware();

  const instantiatedStore = configureStore({
    reducer: rootReducer,
    devTools: true,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(restApi.middleware)
        .concat(graphqlApi.middleware)
        .concat(graphqlApi2.middleware)
        .concat(epicMiddleware)
        .filter(Boolean),
  });

  epicMiddleware.run(rootEpic);

  return instantiatedStore;
};
