import { useGetTemplatesByIdQuery } from '@/graphql/template/getTemplatesById.generated';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import cx from 'classnames';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useObjectCardScrollContext } from '../../Card/ObjectCardScroll.context';
import HeaderLower from './HeaderLower';
import HeaderMiddle from './HeaderMiddle/HeaderMiddle';
import HeaderTop from './HeaderTop/HeaderTop';

export interface HeaderProps {
  open: boolean;
  handleClose: () => void;
  handleDuplicate: () => void;
  handleDelete: () => void;
  openCollaborationModal?: () => void;

  canDelete: boolean;
}

export default function Header ({
  open,
  handleClose,
  handleDuplicate,
  handleDelete,
  openCollaborationModal,
  canDelete,
}: HeaderProps): JSX.Element {
  const { activeModularObjectId } = useObjectCardContext();
  const isEdit = Boolean(activeModularObjectId);
  const { scrollDirection } = useObjectCardScrollContext();

  const templateId = useWatch({ name: 'templateId' });
  const { data: templateData } = useGetTemplatesByIdQuery({
    variables: { templateId },
    skip: !templateId,
  });
  const template = templateData?.getTemplates?.[0];

  const isCompressedHeader = useMemo(() => scrollDirection === 'down', [scrollDirection]);

  return (
    <div
      className={cx('z-20 bg-white', {})}
    >
      <div
        className={cx('flex flex-col relative transition-all duration-500 transform-gpu', {
          'h-[80px]': isCompressedHeader,
          'h-[200px]': !isCompressedHeader,
        })}
      >
        <HeaderTop
          objectId={activeModularObjectId}
          open={open}
          handleClose={handleClose}
          handleDuplicate={handleDuplicate}
          handleDelete={handleDelete}
          openCollaborationModal={openCollaborationModal}
          isCompressedHeader={isCompressedHeader}
          canDelete={canDelete}
        />
        <HeaderMiddle
          templateName={template?.name}
          isCompressedHeader={isCompressedHeader}
        />
      </div>
      {!isEdit ? <div className='h-[24px]' /> : <HeaderLower isCompressedHeader={isCompressedHeader} />}
    </div>
  );
}
