import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type CreatePrismaticJiraMappingMutationVariables = Types.Exact<{
  input: Types.JiraMappingCreateInput;
}>;


export type CreatePrismaticJiraMappingMutation = { __typename?: 'Mutation', createPrismaticJiraMapping: { __typename?: 'JiraMapping', id: string, modularObjectId: string, jiraIssueId: string, jiraIssueKey: string, jiraWebhookId: string, jiraTicketUrl: string } | null };


export const CreatePrismaticJiraMappingDocument = gql`
    mutation CreatePrismaticJiraMapping($input: JiraMappingCreateInput!) {
  createPrismaticJiraMapping(input: $input) {
    id
    modularObjectId
    jiraIssueId
    jiraIssueKey
    jiraWebhookId
    jiraTicketUrl
  }
}
    `;
export type CreatePrismaticJiraMappingMutationFn = Apollo.MutationFunction<CreatePrismaticJiraMappingMutation, CreatePrismaticJiraMappingMutationVariables>;

/**
 * __useCreatePrismaticJiraMappingMutation__
 *
 * To run a mutation, you first call `useCreatePrismaticJiraMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrismaticJiraMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrismaticJiraMappingMutation, { data, loading, error }] = useCreatePrismaticJiraMappingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePrismaticJiraMappingMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrismaticJiraMappingMutation, CreatePrismaticJiraMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePrismaticJiraMappingMutation, CreatePrismaticJiraMappingMutationVariables>(CreatePrismaticJiraMappingDocument, options);
      }
export type CreatePrismaticJiraMappingMutationHookResult = ReturnType<typeof useCreatePrismaticJiraMappingMutation>;
export type CreatePrismaticJiraMappingMutationResult = Apollo.MutationResult<CreatePrismaticJiraMappingMutation>;
export type CreatePrismaticJiraMappingMutationOptions = Apollo.BaseMutationOptions<CreatePrismaticJiraMappingMutation, CreatePrismaticJiraMappingMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreatePrismaticJiraMappingMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ createPrismaticJiraMapping })
 *   )
 * })
 */
export const mockCreatePrismaticJiraMappingMutation = (resolver: Parameters<typeof graphql.mutation<CreatePrismaticJiraMappingMutation, CreatePrismaticJiraMappingMutationVariables>>[1]) =>
  graphql.mutation<CreatePrismaticJiraMappingMutation, CreatePrismaticJiraMappingMutationVariables>(
    'CreatePrismaticJiraMapping',
    resolver
  )
