import { Button } from '@/components/Button/Button';
import { Modal } from '@/components/modals/Modal';
import { useAppDispatch } from '@/state/hooks';
import { logout } from '@/state/slices/session.slice';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';

interface TermsOfUseModalProps {
  isModalOpen: boolean;
  onSubmit: () => void;
}

export function TermsOfUseModal ({ isModalOpen, onSubmit }: TermsOfUseModalProps): JSX.Element {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const _onSubmit = (): void => {
    onSubmit();
  };

  const onClose = (): void => {
    dispatch(logout());
    void router.push('/login');
  };

  return (
    <Modal
      isOpen={isModalOpen}
      width='w-[37rem]'
      columns={[
        [
          {
            custom: (
              <div key='terms-of-use-modal-content'>
                <div className='flex justify-center pt-0 pb-6'>
                  <Image src='/images/wordmark-primary.svg' alt='Wordmark' height={48} width={219} />
                </div>
                <div className='pb-6 text-center'>
                  <p>Welcome to Integrate.</p>
                  <p>
                    Please accept the{' '}
                    <a
                      className='text-primary'
                      href='https://integrate.co/terms-of-use'
                      rel='noreferrer'
                      target='_blank'
                    >
                      terms of use
                    </a>{' '}
                    to continue.
                  </p>
                </div>
                <div className='flex flex-row gap-y-4 justify-between'>
                  <Button
                    className='flex justify-center items-center py-6 w-64 h-4 text-sm border border-solid cursor-pointer border-primary text-primary'
                    onClick={onClose}
                    data-testid='tos-decline-button'
                  >
                    <span className='inline-block pr-2.5'>
                      <Image src='/images/x.svg' alt='X' height={12} width={16} />
                    </span>
                    REJECT AND LOGOUT
                  </Button>
                  <Button
                    dataTestId='tos-accept-and-continue'
                    className='flex justify-center items-center py-6 w-64 h-4 text-sm text-white border border-solid cursor-pointer border-primary bg-primary'
                    onClick={_onSubmit}
                  >
                    <span className='inline-block pr-2.5'>
                      <Image src='/images/check-white.svg' alt='Check Mark' height={12} width={16} />
                    </span>
                    ACCEPT AND CONTINUE
                  </Button>
                </div>
              </div>
            ),
          },
        ],
      ]}
    />
  );
}
