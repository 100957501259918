import { Loader } from '@/components';
import { Input } from '@/components/form/Input';
import MegaModalHeader from '@/components/modals/MegaModal/MegaModalHeader/MegaModalHeader';
import type { StepContentProps } from '@/components/Stepper/Stepper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSpreadsheet, faInputNumeric, faTable } from '@fortawesome/sharp-regular-svg-icons';
import { useCallback, useState } from 'react';

interface InputCellMappingScreenProps extends StepContentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedFile: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedSheet: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapping: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitMapping: (mapping: any) => void;
  moduleId: string;
  isFetchingData: boolean;
}

export default function InputCellMappingScreen (
  { selectedFile, selectedSheet, mapping, submitMapping, moduleId, isFetchingData, decrementStep }:
    InputCellMappingScreenProps,
): JSX.Element {
  const [column, setColumn] = useState('');
  const [row, setRow] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const columnNameToColumnNumber = useCallback((columnName: string): number => {
    const upperCaseColumnName = columnName.toUpperCase();
    let columnNumber = 0;
    for (let i = 0; i < upperCaseColumnName.length; i++) {
      columnNumber *= 26;
      columnNumber += upperCaseColumnName.charCodeAt(i) - 'A'.charCodeAt(0) + 1;
    }
    return columnNumber;
  }, []);

  return (
    <>
      <MegaModalHeader
        title='What cell do you want to link?'
        subtext={'Enter the column letter and row number of the cell you\'d like to link.'}
        subTextBlack
      />
      <div className='flex flex-row gap-4 pt-5 sculpin-14'>
        <FontAwesomeIcon icon={faFileSpreadsheet} className='self-center text-[14px]' />
        {isFetchingData && !isSubmitting && !selectedFile?.name
          ? <Loader className='w-5 h-5' />
          : selectedFile?.name}
      </div>
      <div className='flex flex-row gap-4 pt-2 sculpin-14'>
        <FontAwesomeIcon icon={faTable} className='self-center text-[14px]' />
        {isFetchingData && !isSubmitting && !selectedSheet?.name
          ? <Loader className='w-5 h-5' />
          : selectedSheet?.name}
      </div>
      <div className='flex flex-col gap-4 pt-5'>
        <div className='flex flex-row gap-8'>
          <div className='flex flex-col gap-4'>
            <label className='label' htmlFor='input-cell-mapping-screen-column'>Column</label>
            <Input
              value={column}
              className='grow input-text'
              type='text'
              id='input-cell-mapping-screen-column'
              onChange={(e) => {
                setColumn(e.target.value);
              }}
              placeholder='Enter a column letter'
            />
          </div>
          <div className='flex flex-col gap-4'>
            <label className='label' htmlFor='input-cell-mapping-screen-row'>Row</label>
            <Input
              value={row}
              className='grow input-text'
              type='text'
              id='input-cell-mapping-screen-row'
              onChange={(e) => {
                setRow(e.target.value);
              }}
              placeholder='Enter a row number'
            />
          </div>
        </div>
      </div>
      <div className='flex flex-row gap-4 pt-5 sculpin-14'>
        <FontAwesomeIcon icon={faInputNumeric} className='self-center text-[14px]' />
        {isFetchingData && !isSubmitting && !selectedSheet?.data
          ? <Loader className='w-5 h-5' />
          : selectedSheet?.data?.[+row - 1]?.[columnNameToColumnNumber(column) - 1] || 'No data'}
      </div>
      <div className='flex gap-8 pt-8'>
        <button
          className='w-full btn-ghost h-[36px]'
          onClick={decrementStep}
          disabled={Object.keys(mapping ?? {}).length > 0}
        >
          back
        </button>
        <button
          disabled={!column || !row || (isSubmitting && isFetchingData)}
          onClick={() => {
            const actualMapping = mapping || {};
            setIsSubmitting(true);
            submitMapping({
              ...actualMapping,
              [moduleId]: {
                column: `${columnNameToColumnNumber(column) - 1}`,
                row: `${+row - 1}`,
              },
            });
          }}
          className='w-full btn-primary h-[36px]'
        >
          {isFetchingData && isSubmitting
            ? <Loader className='w-10 h-10' />
            : 'Link Cell Data'}
        </button>
      </div>
    </>
  );
}
