import type * as Types from '../../../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type DuplicateModularObjectsMutationVariables = Types.Exact<{
  ids: Array<Types.InputMaybe<Types.Scalars['UUID']['input']>> | Types.InputMaybe<Types.Scalars['UUID']['input']>;
}>;


export type DuplicateModularObjectsMutation = { __typename?: 'Mutation', duplicateModularObjects: Array<{ __typename?: 'ModularObject', id: string, name: string | null }> };


export const DuplicateModularObjectsDocument = gql`
    mutation DuplicateModularObjects($ids: [UUID]!) {
  duplicateModularObjects(ids: $ids) {
    id
    name
  }
}
    `;
export type DuplicateModularObjectsMutationFn = Apollo.MutationFunction<DuplicateModularObjectsMutation, DuplicateModularObjectsMutationVariables>;

/**
 * __useDuplicateModularObjectsMutation__
 *
 * To run a mutation, you first call `useDuplicateModularObjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateModularObjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateModularObjectsMutation, { data, loading, error }] = useDuplicateModularObjectsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDuplicateModularObjectsMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateModularObjectsMutation, DuplicateModularObjectsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateModularObjectsMutation, DuplicateModularObjectsMutationVariables>(DuplicateModularObjectsDocument, options);
      }
export type DuplicateModularObjectsMutationHookResult = ReturnType<typeof useDuplicateModularObjectsMutation>;
export type DuplicateModularObjectsMutationResult = Apollo.MutationResult<DuplicateModularObjectsMutation>;
export type DuplicateModularObjectsMutationOptions = Apollo.BaseMutationOptions<DuplicateModularObjectsMutation, DuplicateModularObjectsMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDuplicateModularObjectsMutation((req, res, ctx) => {
 *   const { ids } = req.variables;
 *   return res(
 *     ctx.data({ duplicateModularObjects })
 *   )
 * })
 */
export const mockDuplicateModularObjectsMutation = (resolver: Parameters<typeof graphql.mutation<DuplicateModularObjectsMutation, DuplicateModularObjectsMutationVariables>>[1]) =>
  graphql.mutation<DuplicateModularObjectsMutation, DuplicateModularObjectsMutationVariables>(
    'DuplicateModularObjects',
    resolver
  )
