import { identity } from 'ramda';
import { CollabDiscoveryActionTypes } from './CollabDiscovery.actions';

export interface CollaboratorDiscoveryState {
  addedUsers: [];
  userList: [];
}

export const initialState: CollaboratorDiscoveryState = {
  addedUsers: [],
  userList: [],
};

const { SET_USERS, ADD_USER, REMOVE_USER } = CollabDiscoveryActionTypes;

const handlers = {
  [SET_USERS]: (state, { users }) => ({
    addedUsers: users?.addedUsers ?? state.addedUsers,
    userList: users?.userList ?? state.userList,
  }),
  [ADD_USER]: (state, { user }) => {
    const isUserNameOrEmailInList = u =>
      Boolean(
        u.email?.toLowerCase().includes(user) || u.fullName?.toLowerCase().includes(user),
      );

    const userToAdd = state.userList.find(isUserNameOrEmailInList) ?? user;

    return {
      addedUsers: [...state.addedUsers, userToAdd],
      userList: state.userList.filter(user => user.id !== userToAdd?.id),
    };
  },
  [REMOVE_USER]: (state, { id }) => ({
    addedUsers: state.addedUsers.filter(user => user.id !== id),
    userList: [...state.userList, state.addedUsers.find(user => user.id === id)],
  }),
};

function reducer (state: CollaboratorDiscoveryState, { type, payload }): CollaboratorDiscoveryState {
  return (handlers[type] ?? identity)(state, payload);
}

export default reducer;
