import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type UpdatePrismaticExcelMappingMutationVariables = Types.Exact<{
  input: Types.ExcelMappingUpdateInput;
}>;


export type UpdatePrismaticExcelMappingMutation = { __typename?: 'Mutation', updatePrismaticExcelMapping: { __typename?: 'ExcelMapping', id: string, modularObjectId: string, sharepointFileId: string, sheetName: string, cellMapping: any } | null };


export const UpdatePrismaticExcelMappingDocument = gql`
    mutation UpdatePrismaticExcelMapping($input: ExcelMappingUpdateInput!) {
  updatePrismaticExcelMapping(input: $input) {
    id
    modularObjectId
    sharepointFileId
    sheetName
    cellMapping
  }
}
    `;
export type UpdatePrismaticExcelMappingMutationFn = Apollo.MutationFunction<UpdatePrismaticExcelMappingMutation, UpdatePrismaticExcelMappingMutationVariables>;

/**
 * __useUpdatePrismaticExcelMappingMutation__
 *
 * To run a mutation, you first call `useUpdatePrismaticExcelMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrismaticExcelMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrismaticExcelMappingMutation, { data, loading, error }] = useUpdatePrismaticExcelMappingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePrismaticExcelMappingMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrismaticExcelMappingMutation, UpdatePrismaticExcelMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrismaticExcelMappingMutation, UpdatePrismaticExcelMappingMutationVariables>(UpdatePrismaticExcelMappingDocument, options);
      }
export type UpdatePrismaticExcelMappingMutationHookResult = ReturnType<typeof useUpdatePrismaticExcelMappingMutation>;
export type UpdatePrismaticExcelMappingMutationResult = Apollo.MutationResult<UpdatePrismaticExcelMappingMutation>;
export type UpdatePrismaticExcelMappingMutationOptions = Apollo.BaseMutationOptions<UpdatePrismaticExcelMappingMutation, UpdatePrismaticExcelMappingMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdatePrismaticExcelMappingMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updatePrismaticExcelMapping })
 *   )
 * })
 */
export const mockUpdatePrismaticExcelMappingMutation = (resolver: Parameters<typeof graphql.mutation<UpdatePrismaticExcelMappingMutation, UpdatePrismaticExcelMappingMutationVariables>>[1]) =>
  graphql.mutation<UpdatePrismaticExcelMappingMutation, UpdatePrismaticExcelMappingMutationVariables>(
    'UpdatePrismaticExcelMapping',
    resolver
  )
