import { Listbox, Transition } from '@headlessui/react';
import cx from 'classnames';
import { useLayoutEffect, useRef, useState } from 'react';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

function getMappedIcons (options): Map<string, JSX.Element> {
  const map = options.map(({ value, icon }) => [value, <i key={icon} className={icon} />]);
  return new Map(map);
}
interface OptionListProps {
  options: Array<{ value: string; displayText: string; icon?: string }>;
  hideSelectionHint?: boolean;
}

function OptionList ({ options, hideSelectionHint }: OptionListProps) {
  return (
    <>
      <div
        className={cx('p-2 text-gray-40 sculpin-12', {
          'hidden': hideSelectionHint,
        })}
      >
        Select an option
      </div>
      {options.map(({ value, displayText, icon }) => {
        const shouldShowIcon = Boolean(icon);
        return (
          <Listbox.Option
            key={uuidv5(value, uuidv4())}
            value={{ value, displayText, icon }}
            className='flex gap-2 items-center p-2 cursor-pointer h-[32px] sculpin-12 hover:bg-gray-90'
          >
            {shouldShowIcon && (
              <span className='flex justify-center items-center w-[16px] h-[16px]'>
                <i className={icon} />
              </span>
            )}
            <span>{displayText}</span>
          </Listbox.Option>
        );
      })}
    </>
  );
}

interface ListBoxButtonProps {
  placeholder: string;
  selected: { displayText: string; value: string; icon: string };
  initialValue: { displayText: string; value: string; icon?: string };
  icons: Map<string, JSX.Element>;
}

function ListBoxButton ({ selected, placeholder, initialValue, icons }: ListBoxButtonProps) {
  const displayText = selected?.displayText ?? initialValue?.displayText ?? placeholder;

  const textColor = displayText === placeholder ? 'text-gray-60' : 'text-black';
  // bit too complicated to fix this totally.  Since a component is aways pulled from this, you have to check some bizarre props to determine if an icon shows
  const shouldShowIcon = Boolean(icons.get(selected?.value ?? initialValue?.value).props.className);

  return (
    <Listbox.Button className='flex justify-between items-center p-2 w-full text-left border h-[32px] border-gray-80 sculpin-12'>
      <span className={cx('flex gap-2 items-center', textColor)}>
        {shouldShowIcon && (
          <span className='flex justify-center items-center w-[16px] h-[16px]'>
            {icons.get(selected?.value ?? initialValue?.value)}
          </span>
        )}
        <span>{displayText}</span>
      </span>
      <i className='fa-regular text-gray-60 fa-chevron-down' />
    </Listbox.Button>
  );
}

interface DropDownProps {
  label?: string;
  options: Array<{ value: string; displayText: string; icon?: string }>;
  disabled?: boolean;
  button?: typeof ListBoxButton;
  initialValue?: { displayText: string; value: string; icon?: string };
  optionList?: typeof OptionList;
  hideSelectionHint?: boolean;
  onChange?: (value: string) => void;
  autoRotate?: boolean;
  className?: string;
}

export default function DropDown ({
  options,
  onChange,
  initialValue = null,
  label = null,
  button = ListBoxButton,
  optionList = OptionList,
  disabled = false,
  hideSelectionHint = false,
  autoRotate = false,
  ...rest
}: Readonly<DropDownProps>) {
  const [shouldDisplayAbove, setShouldDisplayAbove] = useState(false);
  const listOptionContainerRef = useRef(null);
  const listOptionsRef = useRef(null);
  const placeholder = 'Select an option';

  const [selected, setSelected] = useState({
    displayText: null,
    value: null,
    icon: null,
  });
  const [filteredOptions, setFilteredOptions] = useState(
    options.filter((option) => option.value !== initialValue?.value),
  );

  const handleChange = (option) => {
    onChange(option?.value);
    setSelected(option);
    setFilteredOptions(options.filter((opt) => opt.value !== option.value));
  };

  useLayoutEffect(() => {
    const containerEl = listOptionContainerRef.current?.offsetParent?.getBoundingClientRect();
    const optionsEl = listOptionsRef.current?.getBoundingClientRect();

    setShouldDisplayAbove((optionsEl?.bottom + filteredOptions.length * 32) > containerEl?.bottom);
  }, [filteredOptions.length, options, listOptionContainerRef, listOptionsRef]);

  const iconMap = getMappedIcons(options);

  return (
    <div ref={listOptionContainerRef} {...rest}>
      <span ref={listOptionsRef}>
        {label}
        <Listbox disabled={disabled} value={selected?.value ?? initialValue?.value} onChange={handleChange}>
          {button({ selected, placeholder, initialValue, icons: iconMap })}
          <div className='relative w-full'>
            <Transition
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
              className={cx('absolute w-full z-[9999] overflow-auto shadow-lg', {
                'top-[5px]': !shouldDisplayAbove,
                'bottom-[37px]': autoRotate && shouldDisplayAbove,
              })}
            >
              <Listbox.Options className='w-full max-h-60 bg-white border border-gray-90'>
                {optionList({ options: filteredOptions, hideSelectionHint })}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </span>
    </div>
  );
}
