import { type File } from '@/__generated__/types';
import { Button, CollapsibleSection, Loader, useEditContext } from '@/components';
import { FileUploader, type FileUploaderRenderProps } from '@/components/form/fileUploader';
import { getObjectEditNewFiles } from '@/util/object-edit.utils';
import { deleteFiles, type FileAndSigned } from '@/util/requests.functions';
import Image from 'next/legacy/image';

interface FileUploaderProps {
  objectType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  objectEdit: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleObjectEdit: (key: string, value: any) => void;
}

function PopulatedFileUpload ({
  handleFileDownload,
  handleFileRef,
  handleFileUpload,
  isUploading,
  objectFiles,
  handleFileRemove,
  objectType,
}: FileUploaderRenderProps) {
  const { canUserEdit } = useEditContext();
  return (
    <CollapsibleSection
      title='Relevant Files'
      className='py-7 px-10'
      noPadding
      actionButton={canUserEdit ?
        (
          <button
            className='flex items-center font-bold font-effra text-primary'
            onClick={handleFileUpload}
          >
            <span className='mr-1'>Upload File</span>
            <Image src='/images/document-primary.svg' width={10.32} height={14.8} />
          </button>
        ) :
        null}
    >
      <div className='overflow-y-scroll relative grow'>
        {isUploading && (
          <div className='flex absolute z-10 items-center w-full h-full bg-white/90'>
            <Loader className='m-auto max-h-[4rem] max-w-[4rem]' />
          </div>
        )}
        {objectFiles?.map((file: File, idx) =>
          file && (
            <div
              key={`${objectType}-file-${file.name}-${idx}`}
              className='flex gap-5 items-center py-2.5 px-2.5 w-full transition-colors group font-effra'
            >
              <div className='flex self-start h-5'>
                <Image src='/images/paperclip.svg' height={20} width={8.8} />
                <span className='ml-2 align-middle'>{file.name}</span>
              </div>
              <div className='flex ml-auto'>
                <div className='hover:scale-105 shrink-0'>
                  <a
                    ref={(el) => {
                      handleFileRef(el, idx);
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    download
                    className='hidden'
                  />
                  <Button
                    onClick={async () => {
                      await handleFileDownload(file, idx);
                    }}
                  >
                    <Image src='/images/download.svg' width={20} height={16} />
                  </Button>
                </div>
                <div className='ml-2.5 opacity-0 transition-opacity group-hover:opacity-100 hover:scale-105 shrink-0'>
                  {canUserEdit && (
                    <Button
                      onClick={(event) => {
                        event.stopPropagation();
                        const deleteConfirmation = window.confirm(
                          'Are you sure you want to delete this file?',
                        );
                        if (deleteConfirmation) {
                          deleteFiles([file])
                            .then(() => {
                              handleFileRemove(file.id);
                            })
                            .catch(console.error);
                        }
                      }}
                    >
                      <Image src='/images/x-gray.svg' height={14.4} width={10.4} />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </CollapsibleSection>
  );
}

function EmptyFileUpload ({
  handleFileUpload,
  isUploading,
}: FileUploaderRenderProps) {
  const { canUserEdit } = useEditContext();
  return (
    <CollapsibleSection
      title='Relevant Files'
      className='py-7 px-10'
      noPadding
      actionButton={canUserEdit && (
        <button
          className='flex items-center font-bold font-effra text-primary'
          onClick={handleFileUpload}
        >
          <span className='mr-1'>Upload File</span>
          <Image src='/images/document-primary.svg' width={10.32} height={14.8} />
        </button>
      )}
    >
      <div className='flex relative items-center grow h-inherit'>
        {isUploading && <Loader className='absolute z-10 p-2 w-full h-full bg-white/60' />}
        <div className='p-3 text-center opacity-70 grow'>No documents uploaded</div>
      </div>
    </CollapsibleSection>
  );
}

export function CardFileUploader (props: FileUploaderProps): JSX.Element {
  const handleObjectEdit = (key, fileAndSigned: FileAndSigned): void => {
    props.handleObjectEdit('files', getObjectEditNewFiles(props.objectEdit, fileAndSigned.file));
  };

  return (
    <FileUploader
      objectType={props.objectType}
      objectEdit={props.objectEdit}
      handleObjectEdit={handleObjectEdit}
      render={PopulatedFileUpload}
      renderWhenEmpty={EmptyFileUpload}
      tags={['file']}
    />
  );
}
