import { has } from 'ramda';
import { MODULE_COMPONENT_MAP, type ModuleType } from './module.constants';

export function getModuleComponent (moduleType: ModuleType): (props) => JSX.Element {
  const map = MODULE_COMPONENT_MAP;

  if (has(moduleType)(map)) {
    if (!map[moduleType]) {
      console.warn(`Module ${moduleType} not implemented yet. Please implement and update MODULE_COMPONENT_MAP.`);
      return null;
    } else {
      return map[moduleType];
    }
  } else {
    console.warn(
      `Module ${moduleType} not found in MODULE_COMPONENT_MAP. This module is likely deprecated and will be removed from the template.`,
    );
    return null;
  }
}
