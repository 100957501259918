import { Button } from '@/designSystemComponents/Button';
import { type Editor, type JSONContent } from '@tiptap/react';
import { type PropsWithChildren } from 'react';

interface CommentSubmitButtonProps {
  isSubmitting: boolean;
  handleSubmitComment: (content: JSONContent) => void;
  editor: Editor;
}

export const CommentSubmitButton = (
  { children, isSubmitting, handleSubmitComment, editor }: PropsWithChildren<CommentSubmitButtonProps>,
) => {
  if (!editor) return null;
  const onSubmit = () => {
    handleSubmitComment(editor.getJSON());
    editor.commands.clearContent();
  };

  return (
    <Button
      figmaProps={{
        style: 'fill',
        size: 'xsmall',
      }}
      disabled={editor.isEmpty || isSubmitting}
      onClick={onSubmit}
    >
      {children}
    </Button>
  );
};
