import cx from 'classnames';
import StatusTooltip, { type ApprovalStatus } from '../tooltip/StatusTooltip';

export interface FormLabelProps {
  className?: string;
  label?: string | JSX.Element;
  name?: string;
  onDoubleClick?: () => void;
  status?: ApprovalStatus;
}

const FormLabel = ({
  className,
  name,
  label,
  onDoubleClick,
  status,
}: FormLabelProps) => {
  if (!label) return null;

  return (
    <label className={cx('flex', 'items-center', 'mb-2', 'label', className)} htmlFor={name}>
      {Boolean(status) && <StatusTooltip status={status} />}
      <span onDoubleClick={onDoubleClick}>{label}</span>
    </label>
  );
};

export default FormLabel;
