import type { User } from '@/__generated__/types';
import { restApi } from './rest.api';

export const organizationsApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationUsers: builder.query<User[], string>({
      query: (organizationId) => ({
        url: `/organizations/${organizationId}/users`,
        method: 'GET',
      }),
      transformResponse: (rawResult: { data: { users: User[] } }) => {
        return rawResult.data.users;
      },
    }),
  }),
});

export const {
  useGetOrganizationUsersQuery,
  util,
} = organizationsApi;
