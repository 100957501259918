import { useAppSelector } from '@/state/hooks';
import { getModulePath } from '@/util/lookup.functions';
import { useMemo, useRef } from 'react';

export default function useGetTemplateModuleName (templateId, fieldToWatch) {
  const modulePath = useRef(templateId);
  const templates = useAppSelector(state => state.templates);

  useMemo(() => {
    modulePath.current = getModulePath(
      templates[templateId]?.modules,
      fieldToWatch,
    ).join('.');
  }, [templateId, fieldToWatch, templates]);

  return `data.${modulePath.current}.value`;
}
