import type { ModularObject } from '@/__generated__/types';
import type { TaskFragment } from '@/components/Schedule/queries/taskFragment.generated';
import SelectTask from './SelectTask';

interface SelectTasksProps {
  handleSelect: (selected: string[]) => void;
  tableRef: React.RefObject<HTMLDivElement>;
  tasks: TaskFragment[];
  selectedTasks: string[];
}
export default function SelectTasks ({
  handleSelect,
  tasks,
  tableRef,
  selectedTasks,
}: Readonly<SelectTasksProps>): JSX.Element {
  return (
    <div className='overflow-auto' ref={tableRef}>
      {tasks
        .map((task: ModularObject & { Status: string } & { selected: boolean }) => (
          <SelectTask key={task.id} task={task} onChange={handleSelect} selectedTasks={selectedTasks} />
        ))}
    </div>
  );
}
