import type * as Types from '../../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type CreateCommentOnModularObjectMutationVariables = Types.Exact<{
  createCommentOnModularObjectInput: Types.CreateCommentOnModularObjectInput;
}>;


export type CreateCommentOnModularObjectMutation = { __typename?: 'Mutation', createCommentOnModularObject: { __typename?: 'Comment', body: { [key: string]: any }, id: string, parsingVersion: string, createdAt: any, createdBy: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null, organizationId: string | null, profileImage: string | null } } | null };


export const CreateCommentOnModularObjectDocument = gql`
    mutation CreateCommentOnModularObject($createCommentOnModularObjectInput: CreateCommentOnModularObjectInput!) {
  createCommentOnModularObject(input: $createCommentOnModularObjectInput) {
    body
    id
    parsingVersion
    createdBy {
      id
      firstName
      lastName
      organizationId
      profileImage
    }
    createdAt
  }
}
    `;
export type CreateCommentOnModularObjectMutationFn = Apollo.MutationFunction<CreateCommentOnModularObjectMutation, CreateCommentOnModularObjectMutationVariables>;

/**
 * __useCreateCommentOnModularObjectMutation__
 *
 * To run a mutation, you first call `useCreateCommentOnModularObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentOnModularObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentOnModularObjectMutation, { data, loading, error }] = useCreateCommentOnModularObjectMutation({
 *   variables: {
 *      createCommentOnModularObjectInput: // value for 'createCommentOnModularObjectInput'
 *   },
 * });
 */
export function useCreateCommentOnModularObjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentOnModularObjectMutation, CreateCommentOnModularObjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentOnModularObjectMutation, CreateCommentOnModularObjectMutationVariables>(CreateCommentOnModularObjectDocument, options);
      }
export type CreateCommentOnModularObjectMutationHookResult = ReturnType<typeof useCreateCommentOnModularObjectMutation>;
export type CreateCommentOnModularObjectMutationResult = Apollo.MutationResult<CreateCommentOnModularObjectMutation>;
export type CreateCommentOnModularObjectMutationOptions = Apollo.BaseMutationOptions<CreateCommentOnModularObjectMutation, CreateCommentOnModularObjectMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateCommentOnModularObjectMutation((req, res, ctx) => {
 *   const { createCommentOnModularObjectInput } = req.variables;
 *   return res(
 *     ctx.data({ createCommentOnModularObject })
 *   )
 * })
 */
export const mockCreateCommentOnModularObjectMutation = (resolver: Parameters<typeof graphql.mutation<CreateCommentOnModularObjectMutation, CreateCommentOnModularObjectMutationVariables>>[1]) =>
  graphql.mutation<CreateCommentOnModularObjectMutation, CreateCommentOnModularObjectMutationVariables>(
    'CreateCommentOnModularObject',
    resolver
  )
