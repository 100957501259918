import InfoTooltip from '@/components/tooltip/InfoTooltip';
import User from '@/components/User/User';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListTree } from '@fortawesome/sharp-regular-svg-icons';
import { faXmark } from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';
import { useCallback, useMemo, useState } from 'react';

import type { User as UserType } from '@/__generated__/types';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import StatusTooltip from '@/components/tooltip/StatusTooltip';
import { useModal } from '@/hooks/useModal';
import { useNestingMutations } from '@/hooks/useNestingMutations';
import getIcon from '@/util/getIcon';
import { Transition } from '@headlessui/react';
import { ConfirmRemoveNestedItemModal } from './ConfirmRemoveNestedItemModal';
import { useGetNestedChildrenOnExpandQuery } from './GetNestedChildrenOnExpand.generated';
import type { NestingColumnItemFragment } from './NestingColumnItemFragment.generated';

export interface NestedItemProps {
  nestedItem: NestingColumnItemFragment;
  isPendingRemovalApproval?: boolean;
  isPendingAddApproval?: boolean;
  nestedDepth?: number;
}

export default function NestedItem (
  { nestedItem, nestedDepth = 0, isPendingRemovalApproval, isPendingAddApproval }: NestedItemProps,
): JSX.Element {
  const { openViewObjectCardDrawer } = useObjectCardContext();
  const { showModal, closeModal } = useModal();

  const { removeChild } = useNestingMutations();

  const [isChildrenExpanded, setIsChildrenExpanded] = useState(false);

  const childrenIds = useMemo(() => {
    if (!nestedItem?.children) return [];

    return nestedItem.children.filter(child => child?.template?.type !== 'task').map((child) => child.id);
  }, [nestedItem?.children]);

  const numChildren = childrenIds.length;
  const hasChildren = numChildren > 0;

  const objectName = nestedItem?.name ?? '';
  const objectOwner = nestedItem?.owner ?? {};
  const objectOrg = nestedItem?.owner?.organization?.name ?? '—';
  const shouldShowStatusDot = isPendingAddApproval || isPendingRemovalApproval;

  const openCard = useCallback(() => {
    openViewObjectCardDrawer({ modularObjectId: nestedItem?.id });
  }, [nestedItem?.id, openViewObjectCardDrawer]);

  const toggleChildrenExpanded = useCallback(() => {
    setIsChildrenExpanded((prev) => !prev);
  }, []);

  const removeIntegration = useCallback(() => {
    showModal(
      <ConfirmRemoveNestedItemModal
        closeModal={closeModal}
        nestedItemName={nestedItem.name}
        handleConfirm={async () => {
          await removeChild({
            variables: { childObjectId: nestedItem.id },
            onCompleted: (data) => {
              const isApproval = data?.deleteHardwareIntegration.__typename === 'HardwareIntegrationApproval';

              if (isApproval) {
                return addToastSuccess(`Request to de-nest ${nestedItem.name} sent for approval. .`);
              }
              return addToastSuccess(`Successfully de-nested ${nestedItem.name}`);
            },
            onError: () => addToastError(`Failed to de-nested ${nestedItem.name}, please try again`),
          });

          closeModal();
        }}
      />,
    );
  }, [closeModal, nestedItem?.id, nestedItem?.name, removeChild, showModal]);

  const templateName = nestedItem?.template?.name?.toLowerCase();
  const templateTypeIcon = getIcon(templateName, 'w-6 h-6 shrink-0');

  return (
    <div
      data-testid={`integration-item-${nestedItem?.id}`}
    >
      <div className='px-6 mb-4 h-14'>
        <div className='flex items-center w-full h-full font-medium font-sculpin group'>
          <div className='w-[50%] flex items-center relative h-full' style={{ paddingLeft: `${nestedDepth * 2.5}rem` }}>
            {shouldShowStatusDot && (
              <div
                className={cx('absolute', {
                  'left-[-15px]': !hasChildren,
                  'left-[-65px]': hasChildren,
                })}
              >
                <StatusTooltip status='waiting for approval' />
              </div>
            )}
            {hasChildren && (
              <InfoTooltip
                message={`${numChildren} items nested\nClick to expand`}
                className='absolute left-[-50px]'
              >
                <div data-testid='child-integration-button'>
                  <button
                    type='button'
                    aria-label='Expand children'
                    onClick={toggleChildrenExpanded}
                    className={cx(
                      'font-effra font-normal flex items-center gap-2 border rounded-full px-3 py-1 text-neutral-500 transition-colors hover:bg-neutral-200',
                      {
                        'border-neutral-200': !isChildrenExpanded,
                        'border-neutral-500': isChildrenExpanded,
                      },
                    )}
                  >
                    {numChildren}
                    <FontAwesomeIcon size='sm' icon={faListTree} />
                  </button>
                </div>
              </InfoTooltip>
            )}
            <div className='gap-3 rounded-l-md truncate integration-row grow' title={objectName}>
              {templateTypeIcon}
              <button type='button' onClick={openCard} className='truncate link-primary'>
                {objectName}
              </button>
            </div>
          </div>
          <div className='w-[25%] integration-row'>
            <User user={objectOwner as Partial<UserType>} display='single' />
          </div>
          <div className='w-[20%] integration-row'>
            <div className='truncate' title={objectOrg}>
              {objectOrg}
            </div>
          </div>
          <div className='relative w-0 rounded-r-md integration-row'>
            {!nestedDepth && (
              <button
                type='button'
                onClick={removeIntegration}
                className='absolute text-gray-500 opacity-0 transition-opacity -translate-x-3 group-hover:opacity-100'
                title='Remove'
              >
                <FontAwesomeIcon size='xl' icon={faXmark} />
              </button>
            )}
          </div>
        </div>
      </div>

      <Transition
        show={isChildrenExpanded}
        enter={`transition-all duration-300`}
        enterFrom='h-0 opacity-20'
        enterTo='opacity-100 py-1'
      >
        <Children childrenIds={childrenIds} nestedDepth={nestedDepth + 1} />
      </Transition>
    </div>
  );
}

const Children = ({ childrenIds, nestedDepth }: { childrenIds: string[]; nestedDepth: number }) => {
  const { data } = useGetNestedChildrenOnExpandQuery({
    variables: { childrenIds },
  });

  const children = data?.getModularObjectByIDs ?? [];

  return (
    <>
      {children.map((child) => <NestedItem key={child.id} nestedItem={child} nestedDepth={nestedDepth} />)}
    </>
  );
};
