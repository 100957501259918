import type { User } from '@/__generated__/types';
import { createSlice } from '@reduxjs/toolkit';

export type UserState = Record<string, User>;

export const initialState: UserState = {};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUsers(state, action) {
      action.payload?.forEach((user) => {
        if (user?.id) {
          state[user.id] = user;
        }
      });
    },
    removeUsers(state, action) {
      action.payload?.forEach((user) => {
        if (state[user.id]) {
          delete state[user.id];
        }
      });
    },
  },
});

export const { addUsers, removeUsers } = userSlice.actions;
export const { reducer: usersReducer } = userSlice;
