import type { ModularObject } from '@/__generated__/types';
import { createSlice } from '@reduxjs/toolkit';
import { mergeDeepRight } from 'ramda';

export type ModularObjectsState = Record<string, ModularObject>;

export const initialState: ModularObjectsState = {};

export const modularObjectSlice = createSlice({
  name: 'modularObject',
  initialState,
  reducers: {
    addModularObjects(state, action) {
      action.payload?.forEach((modularObject) => {
        if (modularObject?.id) {
          state[modularObject.id] = modularObject;
        }
      });
    },
    removeModularObjects(state, action) {
      action.payload?.forEach((modularObject) => {
        if (state[modularObject.id]) {
          delete state[modularObject.id];
        }
      });
    },
    removeFiles(state, action) {
      action.payload?.forEach((file) => {
        const newFiles = state[file.externalId]?.files?.filter((f) => f.id !== file.id);
        state[file.externalId].files = newFiles;
      });
    },
    updateModularObject(state, action) {
      const { id, updates } = action.payload;
      state[id] = mergeDeepRight(state[id], updates);
    },
    addExternalLink(state, { payload: link }) {
      if (state?.[link.objectID]) {
        state[link.objectID].externalLink = link;
      }
    },
    deactivateExternalLink(state, { payload: link }) {
      if (state?.[link.objectID]?.externalLink) {
        state[link.objectID].externalLink.status = 'Pending';
      }
    },
  },
});

export const {
  addModularObjects,
  removeModularObjects,
  removeFiles,
  addExternalLink,
  deactivateExternalLink,
} = modularObjectSlice.actions;
export const { reducer: modularObjectsReducer } = modularObjectSlice;
