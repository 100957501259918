import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
export type FullTemplateFragment = { __typename?: 'Template', id: string | null, version: number | null, name: string | null, description: string | null, imagePath: string | null, pageColumns: Array<string | null> | null, createdAt: any | null, ownerId: string | null, createdById: string | null, modules: any | null, subType: Types.TemplateSubTypes | null, type: Types.TemplateTypes | null, visibility: string | null };

export const FullTemplateFragmentDoc = gql`
    fragment FullTemplate on Template {
  id
  version
  name
  description
  imagePath
  pageColumns
  createdAt
  ownerId
  createdById
  modules
  subType
  type
  visibility
}
    `;