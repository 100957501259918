import { useIsChromeBrowser } from '@/hooks/useIsChromeBrowser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComputerClassic, faXmark } from '@fortawesome/sharp-solid-svg-icons';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chrome?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    opr?: any;
  }
}

export default function UnsupportedBrowserBanner (): JSX.Element {
  const isChromeBrowser = useIsChromeBrowser();
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  useEffect(() => {
    const unsupportedBannerDismissed = localStorage.getItem('unsupported-banner-dismissed');

    let unsupportedBannerDismissedObject;
    try {
      unsupportedBannerDismissedObject = unsupportedBannerDismissed && JSON.parse(unsupportedBannerDismissed);
    } catch (e) {
      unsupportedBannerDismissedObject = null;
    }

    const isLastDismissedOutsideWindow = !unsupportedBannerDismissedObject?.date ||
      dayjs(unsupportedBannerDismissedObject?.date).add(1, 'month').isBefore(dayjs());

    if (isLastDismissedOutsideWindow) {
      localStorage.removeItem('unsupported-banner-dismissed');

      if (!isChromeBrowser) {
        setIsBannerVisible(true);
      }
    }
  }, [isChromeBrowser]);

  const handleCloseBanner = (): void => {
    const dismissedObject = {
      date: new Date().toISOString(),
    };
    localStorage.setItem('unsupported-banner-dismissed', JSON.stringify(dismissedObject));
    setIsBannerVisible(false);
  };

  const shouldShowBanner = isBannerVisible && !isChromeBrowser;

  return (
    <>
      {shouldShowBanner && (
        <section className='flex justify-center items-center p-6 bg-white'>
          <FontAwesomeIcon icon={faComputerClassic} className='pr-2.5' />
          For the smoothest experience, use Google Chrome
          <div className='cursor-pointer' onClick={handleCloseBanner}>
            <FontAwesomeIcon icon={faXmark} className='pr-1 pl-2.5' />
          </div>
        </section>
      )}
    </>
  );
}
