import type * as Types from '../../../../../__generated__/types';

import { gql } from '@apollo/client';
import { ApprovalsOnObjectCardFragmentFragmentDoc } from '../../Card/ApprovalsOnObjectCardFragment.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type UpdateEstimationMutationVariables = Types.Exact<{
  input: Types.UpdateModularObjectInput;
}>;


export type UpdateEstimationMutation = { __typename?: 'Mutation', updateModularObject: Array<{ __typename?: 'ModularObject', id: string, startDate: any | null, resourceCost: string | null, approvals: Array<{ __typename?: 'Approval', id: string, approvalType: Types.ApprovalTypes, status: Types.ApprovalStatus, referenceType: string, referenceName: string, referenceParentName: string, requestedBy: { __typename?: 'User', id: string }, requestedChanges: Array<{ __typename?: 'ApprovalRequestChange', field: string, fieldId: string, to: string, from: string } | null> }> | null }> };


export const UpdateEstimationDocument = gql`
    mutation UpdateEstimation($input: UpdateModularObjectInput!) {
  updateModularObject(input: $input) {
    id
    startDate
    resourceCost
    approvals {
      ...ApprovalsOnObjectCardFragment
    }
  }
}
    ${ApprovalsOnObjectCardFragmentFragmentDoc}`;
export type UpdateEstimationMutationFn = Apollo.MutationFunction<UpdateEstimationMutation, UpdateEstimationMutationVariables>;

/**
 * __useUpdateEstimationMutation__
 *
 * To run a mutation, you first call `useUpdateEstimationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEstimationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEstimationMutation, { data, loading, error }] = useUpdateEstimationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEstimationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEstimationMutation, UpdateEstimationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEstimationMutation, UpdateEstimationMutationVariables>(UpdateEstimationDocument, options);
      }
export type UpdateEstimationMutationHookResult = ReturnType<typeof useUpdateEstimationMutation>;
export type UpdateEstimationMutationResult = Apollo.MutationResult<UpdateEstimationMutation>;
export type UpdateEstimationMutationOptions = Apollo.BaseMutationOptions<UpdateEstimationMutation, UpdateEstimationMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateEstimationMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updateModularObject })
 *   )
 * })
 */
export const mockUpdateEstimationMutation = (resolver: Parameters<typeof graphql.mutation<UpdateEstimationMutation, UpdateEstimationMutationVariables>>[1]) =>
  graphql.mutation<UpdateEstimationMutation, UpdateEstimationMutationVariables>(
    'UpdateEstimation',
    resolver
  )
