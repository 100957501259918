export const TIMELINE_GREETING = 'Hello, there.';
export const TIMELINE_FAREWELL = 'You\'re all caught up.';

export const ACTION_TO_ICON = {
  dependencyUpdate: {
    src: '/images/check-white.svg',
    color: 'bg-black',
  },
  updated: {
    src: '/images/check-white.svg',
    color: 'bg-black',
  },
  approved: {
    src: '/images/check-white.svg',
    color: 'bg-black',
  },
  denied: {
    src: '/images/x-white.svg',
    color: 'bg-warning',
  },
  requested: {
    src: '/images/question-mark-white.svg',
    color: 'bg-neutral-300',
  },
};

export const OBJECT_TYPE_TO_ICON = {
  spacecraft: '/images/spacecraft-white.svg',
  vehicle: '/images/vehicle-white.svg',
  deployer: '/images/deployer-white.svg',
  mission: '/images/manifest-white.svg',
  milestone: '/images/milestone-white.svg',
};
