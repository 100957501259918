import { env } from '@/util/env.utils';
import { doNotRenderInProduction } from '@/util/functions';
import cx from 'classnames';

const ENVIRONMENT_DISPLAY_MAP = {
  il2: { expanded: 'IL2 (Non-CUI)', collapsed: 'IL2' },
  il4: { expanded: 'IL4 (CUI)', collapsed: 'IL4' },
  il5: { expanded: 'IL5 (CUI-Special)', collapsed: 'IL5' },
  il6: { expanded: 'IL6 (SECRET)', collapsed: 'IL6' },
  staging: { expanded: '🏜️ Mojave 🏜️', collapsed: '🏜️' },
  'pull-request': { expanded: 'PR', collapsed: '👀' },
};

const LOCAL_ENVIRONMENT_DISPLAY = {
  expanded: 'Local',
  collapsed: '🚧',
};

interface EnvironmentIndicatorProps {
  isExpanded: boolean;
}
export default function EnvironmentIndicator ({ isExpanded }: Readonly<EnvironmentIndicatorProps>): JSX.Element {
  if (!doNotRenderInProduction()) return;

  const isGovEnv = ['il2', 'il4', 'il5', 'il6'].includes(env('NEXT_PUBLIC_STAGE'));
  const environmentDisplay = ENVIRONMENT_DISPLAY_MAP[env('NEXT_PUBLIC_STAGE')] ?? LOCAL_ENVIRONMENT_DISPLAY;
  const isStaging = env('NEXT_PUBLIC_STAGE') === 'staging';
  const isPR = env('NEXT_PUBLIC_STAGE') === 'pull-request';
  const isLocal = !isStaging && !isPR && !isGovEnv;

  return (
    <div
      className={cx('flex effra-12 font-bold text-white h-[24px] justify-center items-center', {
        'bg-pink': isGovEnv,
        'bg-secondary': isLocal,
        'bg-teal-600': isStaging,
        'bg-blue-500': isPR,
      })}
    >
      <span className='hidden @expanded/sidenav:block'>{environmentDisplay.expanded}</span>
      <span className='@expanded/sidenav:hidden'>{environmentDisplay.collapsed}</span>
    </div>
  );
}
