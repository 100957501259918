import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersLine } from '@fortawesome/sharp-solid-svg-icons';
import { useRouter } from 'next/router';
import SidenavSection from '../../components/SidenavSection';
import SidenavTab from '../../components/SidenavTab';
import { useTeamsForTabsQuery } from './TeamsForTabs.generated';

export default function TeamsTabs (): JSX.Element {
  const router = useRouter();
  const { data: teamsData } = useTeamsForTabsQuery();
  const teams = teamsData?.teams?.edges || [];

  return (
    <SidenavSection title='Teams'>
      {teams.map((team) => (
        <SidenavTab
          key={`nav-team-${team.id}`}
          name={team.name}
          icon={<FontAwesomeIcon icon={faUsersLine} />}
          href={`/team?teamId=${team.id}`}
          isActive={router.pathname === `/team` && router?.query?.teamId === team.id}
        />
      ))}
    </SidenavSection>
  );
}
