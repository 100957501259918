import type { Approval } from '@/__generated__/types';
import { ApprovalRows } from '@/components/tables/Approvals/ApprovalRows';
import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import AstroLizzyMessage from '../AstroLizzyMessage/AstroLizzyMessage';
import { addToastError } from '../Toast/utils';
import { useApproveApprovalMutation } from './approveApproval.generated';
import { useDenyApprovalMutation } from './denyApproval.generated';
import { useGetApprovalsQuery } from './getApprovals.generated';

const columns = [
  { title: 'Requested on', class: 'w-[18%]' },
  { title: 'Requested by', class: 'w-[20%]' },
  { title: 'Item', class: 'w-[18%]' },
  { title: 'Parent', class: 'w-[18%]' },
];

export function ApprovalTable (): JSX.Element {
  const POLL_INTERVAL = 10000; // 10 seconds

  const { data: approvalsData, error } = useGetApprovalsQuery({
    initialFetchPolicy: 'network-only',
    pollInterval: POLL_INTERVAL,
  });

  if (error) {
    console.error(error);
  }

  const approvals = useMemo(() => {
    if (!approvalsData?.getApprovals) {
      return [];
    }

    return approvalsData.getApprovals;
  }, [approvalsData]);

  const [approveApprovalMutation, { loading: approveLoading }] = useApproveApprovalMutation();
  const [denyApprovalMutation, { loading: denyLoading }] = useDenyApprovalMutation();

  const isLoading = approveLoading || denyLoading;

  const handleSubmitApproval = async (approval) => {
    try {
      await approveApprovalMutation({
        variables: {
          id: approval.id,
          type: approval.approvalType,
        },
        update: (cache, { data: { approveApproval } }) => {
          cache.modify({
            fields: {
              getApprovals(existingApprovalRefs, { readField }) {
                const clonedExistingApprovalRefs = cloneDeep(existingApprovalRefs);
                const updatedExistingApprovalRefs = clonedExistingApprovalRefs.filter((item) =>
                  readField('id', item) !== approveApproval?.id
                );
                return updatedExistingApprovalRefs;
              },
            },
          });
        },
      });
    } catch (error) {
      console.error(error);
      addToastError('Error approving approval');
    }
  };

  const handleSubmitDenial = async (approval) => {
    try {
      await denyApprovalMutation({
        variables: {
          id: approval.id,
          type: approval.approvalType,
        },
        update: (cache, { data: { denyApproval } }) => {
          cache.modify({
            fields: {
              getApprovals(existingApprovalRefs, { readField }) {
                const clonedExistingApprovalRefs = cloneDeep(existingApprovalRefs);
                const updatedExistingApprovalRefs = clonedExistingApprovalRefs.filter((item) =>
                  readField('id', item) !== denyApproval?.id
                );
                return updatedExistingApprovalRefs;
              },
            },
          });
        },
      });
    } catch (error) {
      console.error(error);
      addToastError('Error denying approval');
    }
  };

  return (
    <div
      id='approvals-releases-table'
      className='flex overflow-y-auto relative flex-col grow gap-[16px] pb-[16px] no-scrollbar'
    >
      {/* Table Header */}
      <div className='sticky top-0 z-10 bg-zinc-50' data-testid='approval-table-header'>
        <div className='flex font-bold text-left shadow-[0_2px_4px_0px_#E6E6E6] px-[32px] py-[8px]'>
          {columns?.map((v, idx) => (
            <div className={cx(v.class)} key={`approvals-table-header-${idx}`}>
              {v.title}
            </div>
          ))}
        </div>
      </div>
      {/* Table Body */}
      <div className={'flex flex-col gap-[16px] grow'} data-testid='approval-table-body'>
        {!approvals.length ?
          (
            <AstroLizzyMessage
              image='/images/on-my-way.gif'
              alt='flying rocket person'
              message="You're all caught up"
              subMessage='Approvals from your collaborators will appear here.'
            />
          )
          : (
            <ApprovalRows
              approvals={approvals as Approval[]}
              isLoading={isLoading}
              onSubmitApproval={handleSubmitApproval}
              onSubmitDenial={handleSubmitDenial}
            />
          )}
      </div>
    </div>
  );
}
