import { ScrollDirection } from '@/hooks/useScrollDirection';
import { createContext, type MutableRefObject, useCallback, useContext, useMemo, useState } from 'react';

interface ObjectCardScrollContextType {
  scrollDirection: ScrollDirection;
  setScrollDirection: (scrollDirection: ScrollDirection) => void;
  setScrollElement: (el: MutableRefObject<HTMLDivElement>) => void;
  scrollToElement: (el: HTMLElement, offSet?: number) => void;
  scrollToId: (id: string, offSet?: number) => void;
}

interface ObjectCardScrollProviderProps {
  children: React.ReactNode;
}

const ObjectCardScrollContext = createContext<ObjectCardScrollContextType>(null);

export function useObjectCardScrollContext (): ObjectCardScrollContextType {
  const context = useContext(ObjectCardScrollContext);

  if (context === undefined) {
    throw new Error('useObjectCardScrollContext must be used within a ObjectCardScrollProvider');
  }

  return context;
}

export default function ObjectCardScrollProvider ({
  children,
}: Readonly<ObjectCardScrollProviderProps>): JSX.Element {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(ScrollDirection.down);
  const [scrollElement, setScrollElement] = useState<MutableRefObject<HTMLDivElement>>(null);

  const scrollToElement = useCallback((el: HTMLElement, offSet: number = 0): void => {
    const position = el.offsetTop - offSet;
    scrollElement?.current?.scrollTo({
      top: position,
      behavior: 'smooth',
    });
  }, [scrollElement]);

  const scrollToId = useCallback((id: string, offSet: number = 0): void => {
    const el = document.getElementById(id);
    if (el) {
      scrollToElement(el, offSet);
    }
  }, [scrollToElement]);

  const value = useMemo(
    () => ({
      scrollDirection,
      setScrollDirection,
      setScrollElement,
      scrollToElement,
      scrollToId,
    }),
    [
      scrollDirection,
      setScrollDirection,
      setScrollElement,
      scrollToElement,
      scrollToId,
    ],
  );

  return <ObjectCardScrollContext.Provider value={value}>{children}</ObjectCardScrollContext.Provider>;
}
