import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { ApprovalsOnObjectCardFragmentFragmentDoc } from '../Card/ApprovalsOnObjectCardFragment.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type RemoveFileFromObjectMutationVariables = Types.Exact<{
  input: Types.UpdateModularObjectInput;
}>;


export type RemoveFileFromObjectMutation = { __typename?: 'Mutation', updateModularObject: Array<{ __typename?: 'ModularObject', id: string, files: Array<{ __typename?: 'File', id: string, name: string, location: string, externalId: string, externalType: string, createdAt: any | null, revision: number | null, approved: boolean | null, tags: Array<string | null> | null }> | null, approvals: Array<{ __typename?: 'Approval', id: string, approvalType: Types.ApprovalTypes, status: Types.ApprovalStatus, referenceType: string, referenceName: string, referenceParentName: string, requestedBy: { __typename?: 'User', id: string }, requestedChanges: Array<{ __typename?: 'ApprovalRequestChange', field: string, fieldId: string, to: string, from: string } | null> }> | null }> };


export const RemoveFileFromObjectDocument = gql`
    mutation RemoveFileFromObject($input: UpdateModularObjectInput!) {
  updateModularObject(input: $input) {
    id
    files {
      id
      name
      location
      externalId
      externalType
      createdAt
      revision
      approved
      tags
    }
    approvals {
      ...ApprovalsOnObjectCardFragment
    }
  }
}
    ${ApprovalsOnObjectCardFragmentFragmentDoc}`;
export type RemoveFileFromObjectMutationFn = Apollo.MutationFunction<RemoveFileFromObjectMutation, RemoveFileFromObjectMutationVariables>;

/**
 * __useRemoveFileFromObjectMutation__
 *
 * To run a mutation, you first call `useRemoveFileFromObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFileFromObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFileFromObjectMutation, { data, loading, error }] = useRemoveFileFromObjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFileFromObjectMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFileFromObjectMutation, RemoveFileFromObjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFileFromObjectMutation, RemoveFileFromObjectMutationVariables>(RemoveFileFromObjectDocument, options);
      }
export type RemoveFileFromObjectMutationHookResult = ReturnType<typeof useRemoveFileFromObjectMutation>;
export type RemoveFileFromObjectMutationResult = Apollo.MutationResult<RemoveFileFromObjectMutation>;
export type RemoveFileFromObjectMutationOptions = Apollo.BaseMutationOptions<RemoveFileFromObjectMutation, RemoveFileFromObjectMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRemoveFileFromObjectMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updateModularObject })
 *   )
 * })
 */
export const mockRemoveFileFromObjectMutation = (resolver: Parameters<typeof graphql.mutation<RemoveFileFromObjectMutation, RemoveFileFromObjectMutationVariables>>[1]) =>
  graphql.mutation<RemoveFileFromObjectMutation, RemoveFileFromObjectMutationVariables>(
    'RemoveFileFromObject',
    resolver
  )
