import type { GetUserCountAndModularObjectCountQuery } from '@/graphql/sideNavigation/getUserCountAndModularObjectCount.generated';
import type { Subscription } from '@/state/queries/subscription.api';
import { HUMAN_FRIENDLY_SUBSCRIPTION_PLAN_NAMES } from '@/util/constants';
import dayjs from 'dayjs';

export default function useSubscriptionInfo (
  subscription: Subscription,
  userAndModularObjectCountData: GetUserCountAndModularObjectCountQuery,
  isAdminOrSuper,
) {
  const shouldHidePricingInfo = subscription?.isPro || !isAdminOrSuper;

  const userAndModularObjectCount = userAndModularObjectCountData?.getUserCountAndModularObjectCount;

  const planName = HUMAN_FRIENDLY_SUBSCRIPTION_PLAN_NAMES[subscription?.subscriptionType] ?? null;

  const objectCountDisplay = `${userAndModularObjectCount?.modularObjectCount ?? 0} / ${
    subscription?.featureLimits?.maxObjects ?? 0
  }`;

  const userCountDisplay = `${userAndModularObjectCount?.userCount ?? 0} / ${
    subscription?.featureLimits?.maxUsers ?? 0
  }`;

  const remainingTrialDays = subscription?.trialDidExpire
    ? 0
    : dayjs(subscription?.trialEndDate).diff(dayjs(), 'day');

  return {
    planName,
    objectCountDisplay,
    userCountDisplay,
    shouldHidePricingInfo,
    remainingTrialDays,
  };
}
