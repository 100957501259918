import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type TeamsForTabsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TeamsForTabsQuery = { __typename?: 'Query', teams: { __typename?: 'TeamConnection', edges: Array<{ __typename?: 'Team', id: string, name: string } | null> | null } };


export const TeamsForTabsDocument = gql`
    query TeamsForTabs {
  teams {
    edges {
      id
      name
    }
  }
}
    `;

/**
 * __useTeamsForTabsQuery__
 *
 * To run a query within a React component, call `useTeamsForTabsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsForTabsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsForTabsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamsForTabsQuery(baseOptions?: Apollo.QueryHookOptions<TeamsForTabsQuery, TeamsForTabsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamsForTabsQuery, TeamsForTabsQueryVariables>(TeamsForTabsDocument, options);
      }
export function useTeamsForTabsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsForTabsQuery, TeamsForTabsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamsForTabsQuery, TeamsForTabsQueryVariables>(TeamsForTabsDocument, options);
        }
export function useTeamsForTabsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TeamsForTabsQuery, TeamsForTabsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamsForTabsQuery, TeamsForTabsQueryVariables>(TeamsForTabsDocument, options);
        }
export type TeamsForTabsQueryHookResult = ReturnType<typeof useTeamsForTabsQuery>;
export type TeamsForTabsLazyQueryHookResult = ReturnType<typeof useTeamsForTabsLazyQuery>;
export type TeamsForTabsSuspenseQueryHookResult = ReturnType<typeof useTeamsForTabsSuspenseQuery>;
export type TeamsForTabsQueryResult = Apollo.QueryResult<TeamsForTabsQuery, TeamsForTabsQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockTeamsForTabsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ teams })
 *   )
 * })
 */
export const mockTeamsForTabsQuery = (resolver: Parameters<typeof graphql.query<TeamsForTabsQuery, TeamsForTabsQueryVariables>>[1]) =>
  graphql.query<TeamsForTabsQuery, TeamsForTabsQueryVariables>(
    'TeamsForTabs',
    resolver
  )
