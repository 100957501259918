import { Label, useEditContext } from '@/components';
import classnames from 'classnames';
import Image from 'next/legacy/image';
import React, { type ChangeEvent, type KeyboardEvent, useMemo } from 'react';
import StatusTooltip from '../tooltip/StatusTooltip';
interface InputProps {
  className?: string;
  dataTestId?: string;
  wrapperClass?: string;
  disabled?: boolean;
  id: string;
  isChecked?: boolean;
  large?: boolean;
  small?: boolean;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
  opaque?: boolean;
  placeholder?: string;
  prefix?: string;
  suffix?: string;
  type: string;
  value?: string | number;
  white?: boolean;
  full?: boolean;
  min?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  label?: any;
  defaultValue?: string | number;
  defaultChecked?: boolean;
  autoFocus?: boolean;
  autoComplete?: string;
  pattern?: string;
  required?: boolean;
  error?: boolean;
  checkboxTop?: boolean;
  name?: string;
  isDirty?: boolean;
  title?: string;
}

/**
 * @deprecated use `src/components/form/Input.tsx` instead.  If you need to have it change based on edit perms, compose that functionality over top of this
 */

export const Input = React.forwardRef<HTMLInputElement, InputProps>(({
  className,
  dataTestId,
  wrapperClass,
  disabled,
  id,
  isChecked,
  large,
  title,
  small,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  onKeyUp,
  opaque = true,
  placeholder,
  prefix,
  suffix,
  type,
  value,
  white,
  full,
  min,
  label,
  defaultValue,
  defaultChecked,
  autoFocus,
  autoComplete,
  pattern,
  required,
  error,
  checkboxTop,
  name,
  isDirty,
}, ref) => {
  const { canUserEdit, changes, requestedChanges } = useEditContext();
  const isCheckbox = type === 'checkbox';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const defaultAttr = {} as any;
  if (defaultValue) {
    defaultAttr.defaultValue = defaultValue;
  }
  if (defaultChecked) {
    defaultAttr.defaultChecked = defaultChecked;
  }

  const _value = useMemo(() => value ?? defaultValue ?? '', [value, defaultValue]);
  const _checked = useMemo(() => isChecked ?? defaultChecked ?? false, [isChecked, defaultChecked]);

  const tooltipStatus = useMemo(() => {
    if (Object.prototype.hasOwnProperty.call(changes ?? {}, name) || isDirty) {
      return 'staged';
    }
    if (Object.prototype.hasOwnProperty.call(requestedChanges ?? {}, name)) {
      return 'waiting for approval';
    }
  }, [changes, requestedChanges]);

  return (
    <>
      {canUserEdit && (
        <div className={wrapperClass}>
          {Boolean(label) && !isCheckbox && (
            <div className='flex'>
              <StatusTooltip status={tooltipStatus} />
              <Label htmlFor={id}>
                {label} {required && <span className='text-secondary'>(Required)</span>}
              </Label>
            </div>
          )}
          <div
            className={classnames(
              'flex items-end space-x-2',
              {
                'whitespace-nowrap': !isCheckbox,
                'font-light text-xxs': small,
                'text-lg': large,
                'opacity-60': !opaque || disabled,
                'text-black': !white,
                'text-white': white,
                'before:content-[attr(data-content-prefix)] before:mr-3 before:text-xs before:font-effra before:opacity-60 before:tracking-[2.4px] before:uppercase':
                  !!prefix,
                'after:content-[attr(data-content-suffix)] after:ml-1 after:text-xs after:font-effra after:opacity-60 after:tracking-[2.4px] after:uppercase':
                  !!suffix,
              },
              full ? 'flex-1' : '',
            )}
            data-content-prefix={prefix}
            data-content-suffix={suffix}
          >
            <input
              id={id}
              ref={ref}
              data-testid={dataTestId}
              data-private='lipsum'
              className={classnames(className, 'bg-transparent', {
                'border-b outline-none px-2 w-full ring-0 transition-all': !isCheckbox,
                'focus:border-primary': !isCheckbox && !error,
                'border-secondary': !isCheckbox && error,
                'font-light text-xxs': small,
                'px-4 pt-5 pb-2 sculpin-2xl font-black': large,
                // 'effra-16': !large && !small && (type === 'text' || !type),
                'sculpin-base pt-2 pb-1 px-3': !large && !small,
                'border-opacity-40': !opaque || disabled,
                'border-black text-black shadow-normal border-1': !white && !isCheckbox,
                'border-white text-white placeholder-white bg-transparent': white,
                'self-start': checkboxTop,
                'self-center': !checkboxTop,
                'custom-checkbox h-6 w-6 shadow-normal border-none rounded-none checked:bg-primary accent-primary shrink-0 relative':
                  isCheckbox,
              })}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              title={title}
              type={type}
              disabled={disabled}
              value={_value}
              checked={_checked}
              placeholder={placeholder}
              min={min}
              autoFocus={autoFocus}
              onBlur={onBlur}
              onFocus={onFocus}
              autoComplete={autoComplete}
              pattern={pattern}
              required={required}
            />
            {label && isCheckbox && (
              <label
                htmlFor={id}
                className={classnames(
                  'flex items-end space-x-2 h-input',
                  {
                    'whitespace-nowrap': !isCheckbox,
                    'font-light text-xxs': small,
                    'text-lg h-input-lg': large,
                    'opacity-60': !opaque || disabled,
                    'text-black': !white,
                    'text-white': white,
                    'before:content-[attr(data-content-prefix)] before:mr-3 before:text-xs before:font-effra before:opacity-60 before:tracking-[2.4px] before:uppercase':
                      !!prefix,
                    'after:content-[attr(data-content-suffix)] after:ml-1 after:text-xs after:font-effra after:opacity-60 after:tracking-[2.4px] after:uppercase':
                      !!suffix,
                    'sculpin-lg font-medium text-lg text-black normal-case tracking-[-0.18px]': !className,
                  },
                  'ml-3',
                  className,
                )}
              >
                {label}
              </label>
            )}
          </div>
        </div>
      )}
      {!canUserEdit && (
        <div className={wrapperClass}>
          {label && !isCheckbox && <Label>{label}</Label>}
          {!isCheckbox && (
            <div
              className={classnames(
                'flex items-end space-x-2 whitespace-nowrap',
                {
                  'font-light text-xxs': small,
                  'text-lg': large,
                  'opacity-60': !opaque || disabled,
                  'text-black': !white,
                  'text-white': white,
                  'before:content-[attr(data-content-prefix)] before:mr-1 before:text-xs before:font-effra before:opacity-60 before:tracking-[2.4px] before:uppercase':
                    !!prefix,
                  'after:content-[attr(data-content-suffix)] after:ml-1 after:text-xs after:font-effra after:opacity-60 after:tracking-[2.4px] after:uppercase':
                    !!suffix,
                },
                full ? 'flex-1' : '',
              )}
              data-content-prefix={prefix}
              data-content-suffix={suffix}
            >
              <div
                id={id}
                data-private='lipsum'
                data-testid={dataTestId}
                className={classnames(className, 'px-0 py-0 leading-none', {
                  'font-light text-xxs': small,
                  'pt-1 pb-2 sculpin-2xl font-black': large,
                  'sculpin-base font-medium': !large && !small,
                  'border-opacity-40': !opaque || disabled,
                  'text-black self-end': !white && !isCheckbox,
                  'text-white placeholder-white bg-transparent': white,
                })}
              >
                {defaultValue || value || '—'}
              </div>
            </div>
          )}
          {isCheckbox && (
            <div className='inline-block'>
              {(isChecked || defaultChecked) && (
                <Image src='/images/check-primary.svg' alt='Check Mark' width={18} height={12.8} />
              )}
              {!isChecked && !defaultChecked && <div>—</div>}
            </div>
          )}
          {label && isCheckbox && (
            <label
              htmlFor={id}
              className={classnames(
                className,
                {
                  'sculpin-base font-medium text-black normal-case': !className,
                },
                'ml-3',
              )}
            >
              {label}
            </label>
          )}
        </div>
      )}
    </>
  );
});

Input.displayName = 'Input';
