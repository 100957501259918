import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type SendAnonymousUserExternalLinkEmailsMutationVariables = Types.Exact<{
  input: Array<Types.CreateExternalLinkInput> | Types.CreateExternalLinkInput;
}>;


export type SendAnonymousUserExternalLinkEmailsMutation = { __typename?: 'Mutation', createExternalLink: Array<string | null> | null };


export const SendAnonymousUserExternalLinkEmailsDocument = gql`
    mutation SendAnonymousUserExternalLinkEmails($input: [CreateExternalLinkInput!]!) {
  createExternalLink(input: $input)
}
    `;
export type SendAnonymousUserExternalLinkEmailsMutationFn = Apollo.MutationFunction<SendAnonymousUserExternalLinkEmailsMutation, SendAnonymousUserExternalLinkEmailsMutationVariables>;

/**
 * __useSendAnonymousUserExternalLinkEmailsMutation__
 *
 * To run a mutation, you first call `useSendAnonymousUserExternalLinkEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAnonymousUserExternalLinkEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAnonymousUserExternalLinkEmailsMutation, { data, loading, error }] = useSendAnonymousUserExternalLinkEmailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendAnonymousUserExternalLinkEmailsMutation(baseOptions?: Apollo.MutationHookOptions<SendAnonymousUserExternalLinkEmailsMutation, SendAnonymousUserExternalLinkEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAnonymousUserExternalLinkEmailsMutation, SendAnonymousUserExternalLinkEmailsMutationVariables>(SendAnonymousUserExternalLinkEmailsDocument, options);
      }
export type SendAnonymousUserExternalLinkEmailsMutationHookResult = ReturnType<typeof useSendAnonymousUserExternalLinkEmailsMutation>;
export type SendAnonymousUserExternalLinkEmailsMutationResult = Apollo.MutationResult<SendAnonymousUserExternalLinkEmailsMutation>;
export type SendAnonymousUserExternalLinkEmailsMutationOptions = Apollo.BaseMutationOptions<SendAnonymousUserExternalLinkEmailsMutation, SendAnonymousUserExternalLinkEmailsMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSendAnonymousUserExternalLinkEmailsMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ createExternalLink })
 *   )
 * })
 */
export const mockSendAnonymousUserExternalLinkEmailsMutation = (resolver: Parameters<typeof graphql.mutation<SendAnonymousUserExternalLinkEmailsMutation, SendAnonymousUserExternalLinkEmailsMutationVariables>>[1]) =>
  graphql.mutation<SendAnonymousUserExternalLinkEmailsMutation, SendAnonymousUserExternalLinkEmailsMutationVariables>(
    'SendAnonymousUserExternalLinkEmails',
    resolver
  )
