import { useCallback, useState } from 'react';

import { Dialog } from '@headlessui/react';

import ModalBG from '@/components/Portal/ModalBG';
import Stepper, { Step } from '@/components/Stepper/Stepper';

import ConfirmHeaderRow from './steps/ConfirmHeaderRow';
import DragDropFileForm from './steps/DragDropFileForm/DragDropFileForm';
import MapHeader from './steps/MapHeader/MapHeader';

import type { ParsedFileData } from './steps/DragDropFileForm/types';
import FieldErrorDisplayForm from './steps/FieldErrorDisplayForm/FieldErrorDisplayForm';

import type { TaskMap } from '@/components/flows/UploadCsvUserFlow/steps/MapHeader/constants';
import CancelCsvUpload from './CancelCsvUpload';

interface UploadCsvUserFlowProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hardware: any;
  hardwareType: string;
}

export default function UploadCsvUserFlow ({
  open = false,
  setOpen,
  hardware,
  hardwareType,
}: UploadCsvUserFlowProps): JSX.Element {
  const [lastStepTaken, setLastStepTaken] = useState(1);
  const [didConfirmUploadDismissal, setDidConfirmUploadDismissal] = useState(false);
  const [
    { header, rows, mappedTasks },
    setFileData,
  ] = useState<ParsedFileData>({ header: [], rows: [], mappedTasks: [] });

  const onMapCallback = useCallback((mappedTasks: TaskMap[]) => {
    setFileData((prev) => ({ ...prev, mappedTasks }));
  }, [setFileData]);

  const onContinue = useCallback(() => {
    setDidConfirmUploadDismissal(false);
  }, [setDidConfirmUploadDismissal]);

  const onConfirmDismiss = useCallback(() => {
    setLastStepTaken(1);
    setDidConfirmUploadDismissal(false);
    setOpen(false);
  }, [setOpen]);

  const onDismiss = useCallback((force: boolean = false) => {
    force
      ? onConfirmDismiss()
      : setDidConfirmUploadDismissal(true);
  }, [onConfirmDismiss]);

  // FIXME: Tuck conditional rendering for CSV modal flow into a separate components
  return (
    <Dialog open={open} onClose={onDismiss}>
      <ModalBG open={open} setOpen={onDismiss}>
        <div className='w-[600px]'>
          {didConfirmUploadDismissal
            ? (
              <CancelCsvUpload
                onClose={onConfirmDismiss}
                onContinue={onContinue}
              />
            ) :
            (
              <Stepper initialStep={lastStepTaken}>
                <Step
                  stepNumber={1}
                  onClose={onDismiss}
                  onDropCallback={setFileData}
                  onStepChange={setLastStepTaken}
                  stepContent={DragDropFileForm}
                />
                <Step
                  stepNumber={2}
                  header={header}
                  onClose={onDismiss}
                  onStepChange={setLastStepTaken}
                  stepContent={ConfirmHeaderRow}
                />
                <Step
                  stepNumber={3}
                  header={header}
                  onClose={onDismiss}
                  onStepChange={setLastStepTaken}
                  onMapCallback={onMapCallback}
                  numTasks={rows.length}
                  stepContent={MapHeader}
                />
                <Step
                  stepNumber={4}
                  onClose={onDismiss}
                  tasks={mappedTasks}
                  onStepChange={setLastStepTaken}
                  rows={rows}
                  hardware={hardware}
                  hardwareType={hardwareType}
                  stepContent={FieldErrorDisplayForm}
                />
              </Stepper>
            )}
        </div>
      </ModalBG>
    </Dialog>
  );
}
