import type { Dependency, FromTo, ModularObjectHistoryUpdate } from '@/__generated__/types';
import TimelineChange from '@/components/history/TimelineEvent/TimelineChange';
import TimelineEventHeader from '@/components/history/TimelineEvent/TimelineEventHeader';
import TimelinePoint from '@/components/history/TimelineEvent/TimelinePoint';
import { TimelineUndo } from '@/components/history/TimelineEvent/TimelineUndo';
import { GetModularObjectDependenciesByIdDocument } from '@/components/modals/DependencyModal/getModularObjectDependenciesById.generated';
import { GetTopLevelModularObjectsDocument } from '@/components/Schedule/queries/getTopLevelModularObjects.generated';
import { useAppSelector } from '@/state/hooks';
import { selectOpenModularObject } from '@/state/selectors';
import { camelCaseToDisplayText } from '@/util/camelCaseToDisplayText';
import { GetHistoryUpdatesForObjectIdDocument } from './getHistoryUpdatesForObjectId.generated';
import { GetModularObjectHistoryByIdDocument } from './getModularObjectDiffs.generated';
import { useRevertHistoryUpdateMutation } from './revertModularObjectHistoryUpdate.generated';

interface HistoryUpdateProps {
  update: ModularObjectHistoryUpdate;
  isLatestChange?: boolean;
}

export function HistoryUpdate ({ update, isLatestChange }: HistoryUpdateProps): JSX.Element {
  const [revertHistoryUpdate] = useRevertHistoryUpdateMutation();
  const shouldShowUndoButton = isLatestChange && update.canUndo;

  const undoHistoryUpdate = () => {
    return revertHistoryUpdate({
      variables: { id: update.id },
      refetchQueries: [
        GetModularObjectHistoryByIdDocument,
        GetHistoryUpdatesForObjectIdDocument,
        GetModularObjectDependenciesByIdDocument,
        GetTopLevelModularObjectsDocument,
      ],
    });
  };

  return (
    <div className='flex justify-between'>
      <div className='flex flex-col text-black/40'>
        <HistoryUpdateContent update={update} />
      </div>
      {shouldShowUndoButton && <TimelineUndo undo={undoHistoryUpdate} />}
    </div>
  );
}

export function HistoryUpdateContent ({ update }: HistoryUpdateProps): JSX.Element {
  switch (update.updateType) {
    case 'dependency_created':
      return <DependencyCreatedUpdate update={update} />;
    case 'dependency_updated':
      return <DependencyUpdatedUpdate update={update} />;
    case 'dependency_reverted':
      return <DependencyUpdatedUpdate update={update} />;
    case 'dependency_deleted':
      return <DependencyDeletedUpdate update={update} />;
  }
}

function DependencyCreatedUpdate ({ update }: HistoryUpdateProps): JSX.Element {
  const dependencyIds = update.data.dependencyIDs;
  const headerText = dependencyIds?.length > 1 ? 'Dependencies created' : 'Dependency created';
  const byUser = update.createdBy;
  return (
    <div>
      <TimelineEventHeader
        icon={<i className='text-white fa-kit fa-slack-shift text-[10px]' />}
        headerText={headerText}
        color='bg-black'
        onDate={update.createdAt}
        byUser={byUser}
      />
      {update.data.dependencies.map((dependency) => (
        <DependencyInfo
          key={dependency.id}
          dependency={dependency}
          noDot
        />
      ))}
    </div>
  );
}

function DependencyDeletedUpdate ({ update }: HistoryUpdateProps): JSX.Element {
  const dependencyIds = update.data.dependencyIDs;
  const headerText = dependencyIds?.length > 1 ? 'Dependencies deleted' : 'Dependency deleted';
  const byUser = update.createdBy;
  return (
    <div>
      <TimelineEventHeader
        icon={<i className='text-white fa-sharp fa-solid fa-times text-[16px]' />}
        headerText={headerText}
        color='bg-black'
        onDate={update.createdAt}
        byUser={byUser}
      />
      {update.data.dependencies.map((dependency) => (
        <DependencyInfo
          key={dependency.id}
          dependency={dependency}
          noDot
        />
      ))}
    </div>
  );
}

function DependencyUpdatedUpdate ({ update }: HistoryUpdateProps): JSX.Element {
  const dependencyIds = update.data.dependencyIDs;
  const dependencyChanges: Record<string, FromTo> = update.data.changes ?? {};
  const dependencyMap = update.data.dependencies.reduce((acc, dependency) => {
    acc[dependency.id] = dependency;
    return acc;
  }, {});
  const updateType = update.updateType === 'dependency_reverted' ? 'reverted' : 'updated';
  const iconClass = update.updateType === 'dependency_reverted' ? 'fa-rotate-left' : 'fa-check';
  const headerText = dependencyIds?.length > 1 ? `Dependencies ${updateType}` : `Dependency ${updateType}`;
  const byUser = update.createdBy;

  return (
    <div>
      <TimelineEventHeader
        icon={<i className={`text-white fa-sharp fa-solid text-[16px] ${iconClass}`} />}
        headerText={headerText}
        color='bg-black'
        onDate={update.createdAt}
        byUser={byUser}
      />
      {Object.entries(dependencyChanges).map(([dependencyId, changes]) => {
        return (
          <DependencyChanges
            key={dependencyId}
            dependency={dependencyMap[dependencyId]}
            changes={changes}
          />
        );
      })}
    </div>
  );
}

function DependencyInfo ({ dependency, noDot }: { dependency: Dependency; noDot?: boolean }): JSX.Element {
  const fromModularObjectId = useAppSelector((state) => selectOpenModularObject(state));
  const modularObjectName = dependency.modularObject?.name ?? 'Unknown Object';
  const blockedByObjectName = dependency.blockedBy?.name ?? 'Unknown Object';
  const [fromModularObjectName, otherModularObjectName, blockPhrase] =
    fromModularObjectId === dependency.modularObject?.id
      ? [modularObjectName, blockedByObjectName, 'is blocking']
      : [blockedByObjectName, modularObjectName, 'is blocked by'];

  return (
    <TimelinePoint noPadding noDot={noDot}>
      <div className='text-black'>
        <div className='flex gap-2 items-center text-lg font-medium'>
          <i className='fa-kit fa-slack-shift text-[10px]' />
          <span>{fromModularObjectName}</span>
          <span className='font-normal text-black/40'>{` ${blockPhrase} `}</span>
          <span>{otherModularObjectName}</span>
        </div>
      </div>
    </TimelinePoint>
  );
}

function DependencyChanges ({ changes, dependency }: { changes: FromTo; dependency: Dependency }): JSX.Element {
  return (
    <>
      <DependencyInfo dependency={dependency} />
      {Object.entries(changes?.from ?? {}).map(([fromKey, fromValue]) => (
        <DependencyChange
          key={`${dependency.id}-${fromKey}`}
          field={fromKey}
          from={fromValue}
          to={changes.to[fromKey]}
        />
      ))}
    </>
  );
}

interface DependencyChangeProps {
  field: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  from: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  to: any;
}
function DependencyChange ({ field = '', from, to }: DependencyChangeProps): JSX.Element {
  const displayName = camelCaseToDisplayText(field);
  if (typeof from === 'number') {
    from = Math.abs(from);
  }
  if (typeof to === 'number') {
    to = Math.abs(to);
  }

  return <TimelineChange change={{ from, to, type: displayName }} noDot />;
}
