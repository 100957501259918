import { namespaceActionWith } from '@/state/namespaceHandlers';

function createCamelCasedActionName (str: string): string {
  const [first, ...rest] = str.toLowerCase().split('_');
  return [first, ...rest.map(word => word.charAt(0).toUpperCase() + word.slice(1))].join('');
}

export default function generateNamespacedActions (namespace, handlers) {
  // ! have to restart the app when a new handler is exported??
  const namespaceAction = namespaceActionWith(namespace);
  const createActionMap = (acc, handlerName) => ({
    ...acc,
    [createCamelCasedActionName(handlerName)]: payload => ({ type: namespaceAction(handlerName), payload }),
  });

  return Object.keys(handlers || {}).reduce(createActionMap, {});
}
