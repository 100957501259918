import { useImpersonationContext } from '@/components/common/ImpersonationContext';
import { Button } from '@/designSystemComponents/Button';
import { getUserName } from '@/util/functions';
import { useRouter } from 'next/router';

interface ImpersonationConfirmModalProps {
  closeModal: () => void;
}

export default function ImpersonationConfirmModal ({ closeModal }: ImpersonationConfirmModalProps): JSX.Element {
  const { impersonationUser, setImpersonationUser } = useImpersonationContext();
  const router = useRouter();

  const handleSubmit = (): void => {
    localStorage.setItem('impersonation', impersonationUser?.email);
    setImpersonationUser(null);
    closeModal();
    router.push('/settings/profile').finally(router.reload);
  };

  return (
    <div className='flex flex-col flex-1 gap-5'>
      <h3 className='mb-0 text-3xl font-black capitalize font-sculpin'>Are you sure?</h3>
      <div className='pt-6 text-gray-400'>
        You are about to impersonate <b>{getUserName(impersonationUser)}</b>.
        <br />
        You will have complete control over their account and any changes made will be <i>irreversible</i>.
        <br />
        <br />
        Do you want to proceed?
      </div>
      <div className='flex gap-5'>
        <Button
          figmaProps={{ style: 'outline', size: 'large' }}
          className='flex-1 w-auto'
          onClick={() => {
            setImpersonationUser(null);
            closeModal();
          }}
        >
          No, Cancel
        </Button>

        <Button figmaProps={{ style: 'fill', size: 'large' }} className='flex-1 w-auto' onClick={handleSubmit}>
          Yes, Proceed
        </Button>
      </div>
    </div>
  );
}
