import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetUserByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetUserByIdQuery = { __typename?: 'Query', getUserByID: { __typename?: 'User', id: string, email: string | null, title: string | null, firstName: string | null, lastName: string | null, fullName: string | null, organizationId: string | null, profileImage: string | null, workCapacity: number | null, deletedAt: any | null, organization: { __typename?: 'Organization', id: string, name: string | null, resourceCostUnit: Types.ResourceCostUnit | null } | null, teams: Array<{ __typename?: 'Team', id: string, name: string } | null> | null } };

export type UserByIdFragment = { __typename?: 'User', id: string, email: string | null, title: string | null, firstName: string | null, lastName: string | null, fullName: string | null, organizationId: string | null, profileImage: string | null, workCapacity: number | null, deletedAt: any | null, organization: { __typename?: 'Organization', id: string, name: string | null, resourceCostUnit: Types.ResourceCostUnit | null } | null, teams: Array<{ __typename?: 'Team', id: string, name: string } | null> | null };

export const UserByIdFragmentDoc = gql`
    fragment UserById on User {
  id
  email
  title
  firstName
  lastName
  fullName
  organizationId
  organization {
    id
    name
    resourceCostUnit
  }
  profileImage
  workCapacity
  teams {
    id
    name
  }
  deletedAt
}
    `;
export const GetUserByIdDocument = gql`
    query GetUserByID($id: UUID!) {
  getUserByID(id: $id) {
    ...UserById
  }
}
    ${UserByIdFragmentDoc}`;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
      }
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export function useGetUserByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdSuspenseQueryHookResult = ReturnType<typeof useGetUserByIdSuspenseQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<GetUserByIdQuery, GetUserByIdQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUserByIdQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ getUserByID })
 *   )
 * })
 */
export const mockGetUserByIdQuery = (resolver: Parameters<typeof graphql.query<GetUserByIdQuery, GetUserByIdQueryVariables>>[1]) =>
  graphql.query<GetUserByIdQuery, GetUserByIdQueryVariables>(
    'GetUserByID',
    resolver
  )
