import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/sharp-regular-svg-icons';

import { Loader } from '@/components';
import { Button } from '@/components/Button/Button';
import InfoTooltip from '@/components/tooltip/InfoTooltip';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface MegaModalListMessageMessageProps {
  message: string;
  showButton?: boolean;
  buttonDisabled?: boolean;
  buttonText?: string;
  buttonToolTip?: string;
  isLoading?: boolean;
  onClick?: (event) => void;
}

export default function MegaModalListMessage ({
  message,
  showButton = true,
  buttonDisabled = false,
  buttonText,
  buttonToolTip,
  isLoading,
  onClick,
}: MegaModalListMessageMessageProps): JSX.Element {
  const { requestObjectButton } = useFlags();

  const button = (
    <Button className='py-3 btn-ghost h-[unset] w-[unset]' onClick={onClick} disabled={buttonDisabled}>
      {buttonText}
    </Button>
  );

  return (
    <div className='flex flex-col gap-2 justify-center items-center grow min-h-[20vh]'>
      {isLoading ?
        <Loader className='self-center text-lg shrink-0' /> :
        <FontAwesomeIcon icon={faRobot} className='self-center text-lg shrink-0' />}

      <div className='text-center text-lg max-w-[50%]'>
        {message.split('\n').map((line, idx) => <div key={`message-line-${idx}`}>{line}</div>)}
      </div>
      {requestObjectButton && showButton && (
        !buttonToolTip ? button : <InfoTooltip message={buttonToolTip}>{button}</InfoTooltip>
      )}
    </div>
  );
}
