import cx from 'classnames';
import { invertObj } from 'ramda';
import { useCallback, useMemo } from 'react';

import FormContainer from '@/components/FormContainer/FormContainer';
import FieldErrorDisplay from './FieldErrorDisplay';

import { Loader } from '@/components/loader';
import { useAppDispatch } from '@/state/hooks';

import type { ModularObject } from '@/__generated__/types';
import {
  useGetUsersByEmailQuery,
} from '@/components/common/Tasks/TaskSection/TaskListActions/AddCollaborators/GetUsersByEmail.query.generated';
import type { StepContentProps } from '@/components/Stepper/Stepper';
import { addToastError } from '@/components/Toast/utils';
import { addIntegrations } from '@/state/slices/integration.slice';
import { addModularObjects } from '@/state/slices/modularObjects.slice';
import { addShares } from '@/state/slices/shares.slice';
import { STATUS_OPTIONS } from '@/util/constants';
import dayjs from 'dayjs';
import { type MappableTaskField, type TaskMap, VISIBILITY_OPTIONS } from '../MapHeader/constants';
import { mapToValidTaskType } from '../MapHeader/utils';
import { useBulkTaskCreateMutation } from './bulkTaskCreate.generated';
import getRowErrors from './lib/getRowErrors';

interface FieldErrorDisplayFormProps extends StepContentProps {
  onClose: (error?: boolean) => void;
  onStepChange?: (step: number) => void;
  tasks: TaskMap;
  rows: TaskMap[];
  hardware: ModularObject;
}

export default function FieldErrorDisplayForm ({
  onClose,
  onStepChange,
  hardware,
  resetSteps,
  tasks,
  rows,
}: Readonly<FieldErrorDisplayFormProps>): JSX.Element {
  const dispatch = useAppDispatch();

  const [bulkTaskCreateMutation, { loading }] = useBulkTaskCreateMutation();

  const taskKeyMap = useMemo(
    () => invertObj(tasks) as Record<MappableTaskField, string>,
    [tasks],
  );

  const _emails = useMemo(() =>
    rows.reduce((acc, row) => {
      const collaboratorEmails = row[taskKeyMap.collaboratorEmail]?.split(',') || [];
      const assignee = row[taskKeyMap.assignee];
      if (assignee) {
        collaboratorEmails.push(assignee);
      }
      collaboratorEmails.forEach(email => acc.add(email.toLowerCase().trim()));
      return acc;
    }, new Set<string>()), [rows, taskKeyMap.assignee, taskKeyMap.collaboratorEmail]);

  const { data: userData } = useGetUsersByEmailQuery({
    variables: { emails: [..._emails.values()] },
    skip: !_emails.size,
  });
  const emails = useMemo(() => userData?.getUsersByEmail.map(user => user.email) || [], [userData]);

  const { body, errors } = useMemo(
    () => {
      const bulkTaskCreateInput = [];

      let unnamedTaskCount = 0;
      const errors = {};

      let lastParentTaskIdx = 0;
      rows.forEach((row, idx) => {
        const rowErrors = getRowErrors(row, taskKeyMap, emails);

        if (rowErrors[taskKeyMap.name]) {
          errors[`Unnamed Task ${++unnamedTaskCount}`] = [rowErrors[taskKeyMap.name]];
          // Set lastParentTaskIdx to null until a valid task is found, so we don't create subtasks under the wrong task
          lastParentTaskIdx = null;
          return;
        } else if (Object.keys(rowErrors).length) {
          errors[row[taskKeyMap.name]] = Object.values(rowErrors);
        }

        // Combine collaborators into an array
        const collaboratorRoles = row[taskKeyMap.collaboratorRole]?.split(',').map((role) => role.trim()) || [];
        let cdx = 0;
        const collaborators = (row[taskKeyMap.collaboratorEmail]?.split(',') || []).reduce(
          (acc, collaboratorEmail) => {
            const email = collaboratorEmail.toLowerCase().trim();

            // Removes emails that don't exist
            if (emails.includes(email)) {
              acc.push({
                email,
                role: collaboratorRoles[cdx]?.toUpperCase() || 'VIEWER',
              });
            }

            cdx++;
            return acc;
          },
          [],
        );

        // Remove assignee if they don't exist
        const assignee = emails.includes(row[taskKeyMap.assignee]?.toLowerCase().trim())
          ? row[taskKeyMap.assignee]
          : null;

        // Add assignee as a collaborator if they're not already
        if (assignee && !collaborators.find((collaborator) => collaborator.email === assignee)) {
          collaborators.push({ email: assignee, role: 'VIEWER' });
        }

        const task = {
          name: row[taskKeyMap.name],
          description: row[taskKeyMap.description],
          startDate: row[taskKeyMap.startDate] ? dayjs(row[taskKeyMap.startDate])?.toISOString() : null,
          targetDate: row[taskKeyMap.targetDate] ? dayjs(row[taskKeyMap.targetDate])?.toISOString() : null,
          status: (row[taskKeyMap.status] ?? 'To Do')?.toUpperCase().replace(' ', '_'),
          visibility: row[taskKeyMap.visibility]?.toUpperCase() || 'PRIVATE',
          resourceCost: row[taskKeyMap.resourceCost],
          assignee,
          collaborators,
          subTasks: [],
          type: '',
        };

        // Error correcting
        // Check for incorrect visibility options
        if (
          !VISIBILITY_OPTIONS.map(visibility => visibility.toUpperCase()).includes(
            row[taskKeyMap.visibility]?.toUpperCase(),
          )
        ) {
          task.visibility = 'PRIVATE';
        }

        // Check for incorrect task statuses
        if (!STATUS_OPTIONS.map(status => status.toUpperCase()).includes(row[taskKeyMap.status]?.toUpperCase())) {
          task.status = 'TO_DO';
        }

        const isSubtask = Boolean(row[taskKeyMap.subtaskType]);
        // Use the task type if not a subtask
        if (!isSubtask) {
          task.type = mapToValidTaskType(row[taskKeyMap.type]).toUpperCase();
        }

        // Check for incorrect subtask statuses
        if (isSubtask) {
          task.type = mapToValidTaskType(row[taskKeyMap.subtaskType]).toUpperCase();

          // Insert subtask into parent task
          if (lastParentTaskIdx !== null) {
            bulkTaskCreateInput[lastParentTaskIdx]?.subTasks.push(task);
          }
          return;
        }

        bulkTaskCreateInput.push(task);
        // Update lastParentTaskIdx to the last task that was created
        lastParentTaskIdx = bulkTaskCreateInput.length - 1;
      });

      return { body: bulkTaskCreateInput, errors };
    },
    [rows, taskKeyMap, emails],
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        await bulkTaskCreateMutation({
          variables: {
            input: {
              objectId: hardware.id,
              tasks: body,
            },
          },
          onCompleted: async (data) => {
            const integrations = [];
            const shares = [];
            if (data?.bulkTaskCreate) {
              const modularObjects = data.bulkTaskCreate.map(modularObject => {
                integrations.push(...modularObject.integrations);
                if (modularObject.shares.length) {
                  shares.push(...modularObject.shares);
                }

                delete modularObject.integrations;
                delete modularObject.shares;
                return modularObject;
              });

              dispatch(addModularObjects(modularObjects));
              dispatch(addIntegrations(integrations));
              dispatch(addShares(shares));
            }
            onClose(true);
          },
          refetchQueries: ['GetObjectCardObject'],
        });
      } catch (error) {
        console.error(error);
        addToastError('Error uploading tasks');
        addToastError(error.message);
      }
    },
    [body, dispatch],
  );

  const headerText = useMemo(() => {
    if (Object.keys(errors)?.length) {
      if (!body.length) {
        return `Error! No tasks can be created. Please fix the errors and try again.`;
      }
      return `Success! ${body.length} tasks can be created, but a few still need attention.`;
    }

    return `Success! ${body.length} tasks can be created.`;
  }, [errors, body]);

  return (
    <article className='relative'>
      <FormContainer
        handleSubmit={handleSubmit}
        headerText={headerText}
        onClose={onClose}
        content={<FieldErrorDisplay errors={errors} taskKeyMap={taskKeyMap} />}
        buttons={
          <>
            <button
              type='button'
              className='btn-ghost'
              onClick={() => {
                onStepChange(1);
                resetSteps();
              }}
            >
              start over
            </button>
            <button
              disabled={loading}
              className={cx('flex items-center justify-center ml-2 btn-primary', {
                'cursor-not-allowed bg-gray-400 text-gray-600 hover:bg-gray-400': loading,
              })}
            >
              <Loader
                className={cx('w-[30px] h-[30px]', {
                  hidden: !loading,
                })}
              />
              <span>create {body.length} tasks</span>
            </button>
          </>
        }
      />
    </article>
  );
}
