export default function SortIcon ({ sortKey, className }: { sortKey: string; className?: string }): JSX.Element {
  return sortKey === 'dsc' ?
    (
      <svg
        className={`fill-current stroke-current ${className}`}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 18.66 15.11'
      >
        <g id='a' />
        <g id='b'>
          <g id='c'>
            <g id='d'>
              <g>
                <path
                  className='e'
                  d='M5.16,4.31l1.46,1.58,1.52-1.39L4.07,0,0,4.48l1.53,1.4,1.5-1.62V14.58h2.08V4.33l.05-.02Z'
                />
                <path className='e' d='M9.32,13.06v2.05h9.34v-2.05H9.32Z' />
                <path className='e' d='M9.32,8.89v2.05h7.26v-2.05h-7.26Z' />
                <path className='e' d='M14.49,6.76v-2.05h-5.17v2.05h5.17Z' />
                <path className='e' d='M12.4,.53h-3.08V2.58h3.08V.53Z' />
              </g>
            </g>
          </g>
        </g>
      </svg>
    ) :
    (
      <svg
        className={`fill-current stroke-current ${className}`}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 18.66 15.11'
      >
        <g id='a' />
        <g id='b'>
          <g id='c'>
            <g id='d'>
              <g>
                <path
                  className='e'
                  d='M5.16,10.8l1.46-1.58,1.52,1.39-4.07,4.5L0,10.62l1.53-1.4,1.5,1.62V.52h2.08V10.78l.05,.02Z'
                />
                <path className='e' d='M9.32,2.05V0h9.34V2.05H9.32Z' />
                <path className='e' d='M9.32,6.22v-2.05h7.26v2.05h-7.26Z' />
                <path className='e' d='M14.49,8.35v2.05h-5.17v-2.05h5.17Z' />
                <path className='e' d='M12.4,14.58h-3.08v-2.05h3.08v2.05Z' />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
}
