import { Loader } from '@/components';
import MegaModalHeader from '@/components/modals/MegaModal/MegaModalHeader/MegaModalHeader';
import { MegaModalSearchInputV2 } from '@/components/modals/MegaModal/MegaModalSearchInput/MegaModalSearchInput';
import CustomCheckbox from '@/components/modules/CustomCheckbox';
import type { StepContentProps } from '@/components/Stepper/Stepper';
import { getParsedDateFormat } from '@/util/time.functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSpreadsheet } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';
import { createRef, type Ref, useMemo, useState } from 'react';

interface SelectExcelFileScreenProps extends StepContentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSelectedFile: (file: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filesList: any[];
  isFetchingData: boolean;
}

export default function SelectExcelFileScreen (
  { handleSelectedFile, filesList, isFetchingData, incrementStep }: SelectExcelFileScreenProps,
): JSX.Element {
  const inputRef: Ref<HTMLInputElement> = createRef();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFileIdx, setSelectedFileIdx] = useState<number | null>(null);

  const filteredFilesList = useMemo(() => {
    if (!searchTerm) return filesList ?? [];
    setSelectedFileIdx(null);
    return filesList?.filter((file) => file.name.toLowerCase().includes(searchTerm.toLowerCase())) ?? [];
  }, [filesList, searchTerm]);

  return (
    <>
      <MegaModalHeader
        title='Link data from an Excel file'
        subtext='Select an Excel file.'
        subTextBlack
      />
      <div className='flex flex-col pt-7 mb-5 min-h-0 gap-[24px] grow'>
        <MegaModalSearchInputV2
          ref={inputRef}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
      </div>
      <div className='flex flex-col gap-5 h-80'>
        <div className='flex flex-row justify-between font-bold effra-12'>
          <span>Name</span>
          <span>Last Edited</span>
        </div>
        <div
          className={cx('flex align-middle justify-center transform-gpu transition-all h-80', {
            'hidden': !isFetchingData,
          })}
        >
          <Loader className='w-10 h-10' />
        </div>
        <div
          className={cx('overflow-y-auto h-80 transition-all transform-gpu', {
            'hidden': isFetchingData,
          })}
        >
          {!isFetchingData && filteredFilesList.map((file, idx) => (
            <div
              className='flex flex-row justify-between align-middle hover:cursor-pointer'
              key={`${file.name}-${idx}`}
              onClick={() => {
                setSelectedFileIdx(selectedFileIdx === idx ? null : idx);
              }}
            >
              <span className='flex flex-row gap-4 items-center sculpin-14'>
                <CustomCheckbox
                  className='opacity-100 h-[14px] w-[14px]'
                  selected={selectedFileIdx === idx}
                  onCheck={() => {
                    setSelectedFileIdx(selectedFileIdx === idx ? null : idx);
                  }}
                  borderClassName='border rounded bg-white border-gray-70'
                />
                <FontAwesomeIcon icon={faFileSpreadsheet} className='self-center text-[14px]' />
                {file.name}
              </span>
              <span className='effra-12'>
                {getParsedDateFormat(file.lastModifiedDateTime, 'MMM d, yyyy')}
              </span>
            </div>
          ))}
          {!isFetchingData && filteredFilesList.length === 0 && !searchTerm && (
            <div className='flex flex-col gap-2 justify-center items-center align-middle effra-12'>
              <FontAwesomeIcon icon={faFileSpreadsheet} className='self-center text-[14px]' />
              <div className='text-center max-w-80'>
                No excel files can be found. Try reconfiguring your integration or contact{' '}
                <a href='mailto:support@integrate.co'>support@integrate.co</a> for help.
              </div>
            </div>
          )}
          {!isFetchingData && filteredFilesList.length === 0 && searchTerm && (
            <div className='flex flex-col gap-2 justify-center items-center align-middle effra-12'>
              <FontAwesomeIcon icon={faFileSpreadsheet} className='self-center text-[14px]' />
              <div className='text-center max-w-80'>
                No titles of excel files match your search.
              </div>
            </div>
          )}
        </div>
      </div>
      <button
        disabled={selectedFileIdx === null}
        onClick={() => {
          handleSelectedFile(filteredFilesList[selectedFileIdx]);
          incrementStep();
        }}
        className='w-full btn-primary h-[36px]'
      >
        continue
      </button>
    </>
  );
}
