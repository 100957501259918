import { TaskPillColor, type TaskStatus as OldTaskStatus } from '@/models/task.model';
import { THEME_COLORS } from '@/util/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, type IconDefinition } from '@fortawesome/sharp-regular-svg-icons';
import { faCircleCheck, faCircleHalfStroke } from '@fortawesome/sharp-solid-svg-icons';
import { type TaskStatus } from '../../../__generated__/types';

export interface TaskStatusIconProps {
  className?: string;
  color: TaskPillColor;
  status: TaskStatus | OldTaskStatus;
}

export default function TaskStatusIcon ({ className = '!h-[16px]', status, color }: TaskStatusIconProps): JSX.Element {
  const TASK_STATUS_COLORS = {
    [TaskPillColor.Black]: 'black',
    [TaskPillColor.White]: 'white',
    [TaskPillColor.Purple]: THEME_COLORS.primary.DEFAULT,
    [TaskPillColor.Teal]: THEME_COLORS.tertiary.DEFAULT,
    [TaskPillColor.Gold]: THEME_COLORS.secondary.DEFAULT,
  };

  // This is a workaround for the fact that the status is not the same as the TaskStatus enum and keeps it backwards compatible
  const statusText = status?.split(' ').join('_').toUpperCase();

  const TASK_STATUS_ICON: { [key in TaskStatus]: IconDefinition } = {
    IN_PROGRESS: faCircleHalfStroke,
    COMPLETE: faCircleCheck,
    WAIVED: faCircleCheck,
    TO_DO: faCircle,
  };

  return (
    <FontAwesomeIcon
      icon={TASK_STATUS_ICON[statusText] ?? faCircle}
      color={TASK_STATUS_COLORS[color]}
      className={className}
    />
  );
}
