import type { ModularObject } from '@/__generated__/types';
import { type PermissionLevel } from '../constants';

interface DeleteMessageProps {
  permissionMap: Record<PermissionLevel, ModularObject[]>;
}

export default function DeleteMessage ({ permissionMap }: Readonly<DeleteMessageProps>): JSX.Element {
  const { isOwner, needsApproval } = permissionMap;
  const isOwnerOfAllSelected = Boolean(isOwner.length) && !needsApproval.length;
  const isOwnerOfNoSelected = !isOwner.length;
  return (
    <div data-testid='delete-message' className='mt-3'>
      {isOwnerOfAllSelected && (
        <div className=''>
          {isOwner.length} {isOwner.length > 1 ? 'objects/tasks' : 'object/task'}{' '}
          and all associated tasks and subtasks will be deleted.
        </div>
      )}
      {needsApproval.length > 0 && (
        <div className='flex flex-col gap-3'>
          <div className='font-medium'>
            {isOwnerOfNoSelected ? 'None' : isOwner.length}{' '}
            of the selected tasks can be deleted. All associated tasks/subtasks will also be deleted.
          </div>
          <div>
            <div>Only objects and tasks you own may be deleted.</div>
            <div>Contact the owner to delete {isOwnerOfNoSelected ? 'them' : 'the others'}.</div>
          </div>
        </div>
      )}
    </div>
  );
}
