import type { RotateProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronUp } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';
import { Button } from './Button';

export interface ToggleChevronButtonProps {
  className?: string;
  expanded: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  textColor?: string;
  fullRotation?: boolean;
  vertical?: boolean;
}

export default function ToggleChevronButton (
  { className, expanded, onClick, textColor = 'text-primary', fullRotation, vertical }: Readonly<
    ToggleChevronButtonProps
  >,
): JSX.Element {
  const iconProps: { rotation?: RotateProp } = {};
  if (expanded && !fullRotation) {
    iconProps.rotation = 90;
  } else if (expanded && fullRotation) {
    iconProps.rotation = 180;
  }

  return (
    <div className={cx('flex', className)}>
      <Button
        onClick={onClick}
      >
        <FontAwesomeIcon
          icon={vertical ? faChevronUp : faChevronRight}
          fixedWidth
          className={cx('transition-all', textColor)}
          {...iconProps}
        />
      </Button>
    </div>
  );
}
