import { type Placement } from '@popperjs/core';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';

interface PopoverProps {
  children: React.ReactNode;
  popoverContent: React.ReactNode;
  className?: string;
  tooltipClassName?: string;
  delay?: number;
  placement?: Placement;
  xOffset?: number;
  yOffset?: number;
}

const Popover = (
  { children, popoverContent, className, tooltipClassName, delay = 0, placement = 'right', xOffset = 0, yOffset = 0 }:
    PopoverProps,
): JSX.Element => {
  const [shouldShowPopper, setShouldShowPopper] = useState(false);
  const [popperRefElement, setPopperRefElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles: popperStyles, attributes } = usePopper(popperRefElement, popperElement, {
    placement,
    modifiers: [
      { name: 'offset', options: { offset: [yOffset, xOffset] } },
    ],
  });

  const updateShowPopper = (shouldShowPopper: boolean): void => {
    setShouldShowPopper(shouldShowPopper);
  };

  const debouncedShowPopper = debounce(updateShowPopper, delay);

  const handleMouseOver = (): void => {
    debouncedShowPopper(true);
  };

  const handleMouseLeave = (): void => {
    debouncedShowPopper(false);
    setShouldShowPopper(false);
  };

  return (
    <div
      className={className}
      ref={setPopperRefElement}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {shouldShowPopper ?
        (
          <div
            data-testid='info-tooltip'
            ref={setPopperElement}
            style={{ zIndex: '250', ...popperStyles.popper }}
            {...attributes.popper}
            className={tooltipClassName}
          >
            {popoverContent}
          </div>
        ) :
        null}
    </div>
  );
};

export default Popover;
