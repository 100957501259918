import type { User } from '@/__generated__/types';
import { ProfileImage } from '@/components/ProfileImage/ProfileImage';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { copyToClipboard } from '@/util/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faSquareArrowUpRight } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';
import { debounce } from 'lodash';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import { useGetUserByIdQuery } from '../getUserById.generated';
import UserProfileDrawerTrigger from './UserProfileDrawerTrigger';

export interface UserProfileHoverProps {
  children: React.ReactNode;
  userId: string;
}
export default function UserProfileHover ({ children, userId }: Readonly<UserProfileHoverProps>): JSX.Element {
  const [isShowingPopper, setIsShowingPopper] = useState(false);
  const [popperRefElement, setPopperRefElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  // const [user, setUser] = useState(null);

  const { data: userData } = useGetUserByIdQuery({
    skip: !userId || !isShowingPopper,
    variables: { id: userId },
  });
  const user = userData?.getUserByID;
  const loggedInUser = useLoggedInUser();
  const isInternal = user?.organization?.id === loggedInUser?.organizationId;
  const isDeleted = Boolean(user?.deletedAt);

  const { styles: popperStyles, attributes } = usePopper(popperRefElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'computeStyles', options: { adaptive: false } },
      { name: 'offset', options: { offset: [-4, 10] } },
    ],
  });

  const debouncedShowPopper = debounce((shouldShowPopper: boolean) => {
    setIsShowingPopper(shouldShowPopper);
  }, 300);

  const copyEmail = async (e): Promise<void> => {
    e.stopPropagation();
    e.preventDefault();
    try {
      await copyToClipboard(user?.email);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 1500);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div
        role='none'
        className='flex truncate'
        ref={setPopperRefElement}
        onMouseOver={() => {
          setIsShowingPopper(true);
        }}
        onFocus={() => { // onFocus event handler for accessibility
          setIsShowingPopper(true);
        }}
        onMouseLeave={() => {
          setIsShowingPopper(false);
        }}
        onBlur={() => {
          setIsShowingPopper(false);
        }}
      >
        {children}
      </div>
      <div
        role='none'
        data-testid='user-profile-hover'
        className={cx(
          'info-tooltip-popper text-left flex flex-col bg-black text-white gap-[16px] p-[16px] w-[349px] absolute transition-[opacity,scale] duration-150 rounded-[2px] shadow-lg-dark-bg cursor-default truncate',
          {
            'opacity-100 scale-100': isShowingPopper,
            'opacity-0 scale-85': !isShowingPopper,
            'pointer-events-none': !isShowingPopper,
          },
        )}
        ref={setPopperElement}
        style={{ zIndex: '250', ...popperStyles.popper }}
        {...attributes.popper}
        onMouseOver={() => {
          debouncedShowPopper(true);
        }}
        onFocus={() => { // onFocus event handler for accessibility
          debouncedShowPopper(true);
        }}
        onMouseLeave={() => {
          debouncedShowPopper(false);
        }}
        onBlur={() => {
          debouncedShowPopper(false);
        }}
      >
        <div className='flex gap-4 items-center text-left'>
          <ProfileImage user={user as User} size='medium' testId='profile-hover-profile-image' />
          <div className='truncate'>
            <UserProfileDrawerTrigger userId={user?.id} className='w-full'>
              <div
                className={cx('sculpin-16 truncate group/name flex items-baseline gap-2', {
                  'text-primary': isInternal,
                  'text-tertiary': !isInternal,
                })}
              >
                <div className='truncate'>{user?.firstName} {user?.lastName}</div>
                {isDeleted && <div className='text-gray-60 effra-12'>(Removed User)</div>}
                <FontAwesomeIcon
                  icon={faSquareArrowUpRight}
                  className='opacity-0 transition-opacity text-[12px] text-white/80 shrink-0 group-hover/name:opacity-100'
                />
              </div>
            </UserProfileDrawerTrigger>
            {Boolean(user?.title) && <p className='truncate effra-12'>{user?.title}</p>}
          </div>
        </div>
        <div className='flex flex-col gap-[8px] truncate'>
          <div className='effra-10 text-gray-80'>Company</div>
          <div className='truncate effra-12'>{user?.organization?.name}</div>
        </div>
        <div className='flex flex-col gap-[8px] truncate'>
          <div className='effra-10 text-gray-80'>Email</div>
          <button
            onClick={copyEmail}
            className='flex items-center text-left cursor-pointer effra-12 group/email gap-[8px] truncate'
          >
            <div className='truncate'>{user?.email}</div>
            <FontAwesomeIcon
              icon={faCopy}
              className='opacity-0 transition-opacity text-[14px] group-hover/email:opacity-100'
            />
            <span
              className={cx('effra-10 text-white transition-opacity duration-500', {
                'opacity-100': copySuccess,
                'opacity-0': !copySuccess,
              })}
            >
              Copied!
            </span>
          </button>
        </div>
        <div
          ref={setArrowElement}
          style={popperStyles.arrow}
          className='w-0 h-0 border-black info-tooltip-arrow border-l-[6px] border-r-[6px] border-l-transparent border-r-transparent'
        />
      </div>
    </>
  );
}
