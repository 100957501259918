import DeployerIcon from '@/components/Icons/DeployerIcon';
import HardwareIcon from '@/components/Icons/HardwareIcon';
import LaunchVehicleIcon from '@/components/Icons/LaunchVehicleIcon';
import MissionIcon from '@/components/Icons/MissionIcon';
import ProgramIcon from '@/components/Icons/ProgramIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBarsProgress,
  faBlock,
  faBorderOuter,
  faBox,
  faCamera,
  faCar,
  faCircleDashed,
  faCode,
  faConveyorBeltArm,
  faDisplay,
  faEngine,
  faFlagSwallowtail,
  faGamepad,
  faGaugeHigh,
  faHandsBound,
  faHardDrive,
  faJoystick,
  faLightbulbGear,
  faMemory,
  faMicrochip,
  faPlaneDeparture,
  faRhombus,
  faRobot,
  faRocketLaunch,
  faRouter,
  faShield,
  faShip,
  faSkeleton,
  faStarfighterTwinIonEngine,
  faTankWater,
  faTemperatureSnow,
  faTire,
  faTowerBroadcast,
  faTransformerBolt,
  faUserPlus,
  faWalkieTalkie,
} from '@fortawesome/sharp-solid-svg-icons';
import pluralize from 'pluralize';

export default function getIcon (name = '', className?: string): JSX.Element {
  const formattedName = pluralize.singular(name.toLowerCase());
  const TEMPLATE_ICONS = {
    adduser: <FontAwesomeIcon data-testid='add-user-icon' icon={faUserPlus} className={className} />,
    antenna: <FontAwesomeIcon data-testid='antenna-icon' icon={faTowerBroadcast} className={className} />,
    assembly: <HardwareIcon className={className} />,
    'avionics system': <FontAwesomeIcon data-testid='avionic-icon' icon={faGaugeHigh} className={className} />,
    'control interface': (
      <FontAwesomeIcon data-testid='control-interface-icon' icon={faJoystick} className={className} />
    ),
    'cooling system': <FontAwesomeIcon data-testid='cryocooler-icon' icon={faTemperatureSnow} className={className} />,
    deployer: <DeployerIcon height='1em' width='1em' className={className} />,
    display: <FontAwesomeIcon data-testid='display-icon' icon={faDisplay} className={className} />,
    effector: <FontAwesomeIcon icon={faHandsBound} className={className} />,
    'electrical power system': (
      <FontAwesomeIcon data-testid='electrical-power-system-icon' icon={faTransformerBolt} className={className} />
    ),
    'fuel tank': <FontAwesomeIcon data-testid='fuel-tank-icon' icon={faTankWater} className={className} />,
    'generic hardware': <FontAwesomeIcon data-testid='generic-hardware-icon' icon={faBlock} className={className} />,
    'ground contact': <FontAwesomeIcon data-testid='ground-contact-icon' icon={faTire} className={className} />,
    'launch vehicle': <LaunchVehicleIcon className={className} />,
    memory: <FontAwesomeIcon data-testid='memory-icon' icon={faMemory} className={className} />,
    microprocessor: <FontAwesomeIcon data-testid='microprocessor-icon' icon={faMicrochip} className={className} />,
    milestone: <FontAwesomeIcon data-testid='milestone-icon' icon={faRhombus} className={className} />,
    mission: <MissionIcon height='1.5em' className={className} />,
    motor: <FontAwesomeIcon data-testid='motor-icon' icon={faEngine} className={className} />,
    'motor controller': <FontAwesomeIcon data-testid='motor-controller-icon' icon={faGamepad} className={className} />,
    payload: <FontAwesomeIcon icon={faHardDrive} className={className} />,
    'printed circuit board': <FontAwesomeIcon icon={faMicrochip} className={className} />,
    program: <ProgramIcon className={className} />,
    'propulsion': <FontAwesomeIcon data-testid='propulsion-icon' icon={faRocketLaunch} className={className} />,
    receiver: <FontAwesomeIcon data-testid='receiver-icon' icon={faRouter} className={className} />,
    requirement: <FontAwesomeIcon data-testid='requirement-icon' icon={faShield} className={className} />,
    'robotic arm': <FontAwesomeIcon data-testid='robotic-arm-icon' icon={faConveyorBeltArm} className={className} />,
    sensor: <FontAwesomeIcon icon={faCamera} className={className} />,
    'separation mechanism': (
      <FontAwesomeIcon data-testid='separation-mechanism-icon' icon={faCircleDashed} className={className} />
    ),
    'communications system': (
      <FontAwesomeIcon data-testid='walkie-talkie-icon' icon={faWalkieTalkie} className={className} />
    ),
    'generic system': (
      <FontAwesomeIcon
        data-testid='lightbulb-gear-icon'
        icon={faLightbulbGear}
        className={className}
      />
    ),
    'ship': <FontAwesomeIcon data-testid='fa-ship' icon={faShip} className={className} />,
    'robot': <FontAwesomeIcon data-testid='roboticicon' icon={faRobot} className={className} />,
    'auto': <FontAwesomeIcon data-testid='car-icon' icon={faCar} className={className} />,
    'chassis': <FontAwesomeIcon data-testid='skeleton-icon' icon={faSkeleton} className={className} />,
    'plane': <FontAwesomeIcon data-testid='plane-departure-icon' icon={faPlaneDeparture} className={className} />,
    'structure': <FontAwesomeIcon data-testid='border-outer-icon' icon={faBorderOuter} className={className} />,
    'project': <FontAwesomeIcon data-testid='brs-progress-icon' icon={faBarsProgress} className={className} />,
    software: <FontAwesomeIcon data-testid='software-icon' icon={faCode} className={className} />,
    'space station accommodation': <FontAwesomeIcon icon={faStarfighterTwinIonEngine} className={className} />,
    spacecraft: <HardwareIcon className={className} />,
    task: <FontAwesomeIcon data-testid='task-icon' icon={faFlagSwallowtail} className={className} />,
    widget: <FontAwesomeIcon data-testid='widget-icon' icon={faBox} className={className} />,
  };

  return TEMPLATE_ICONS[formattedName] || <HardwareIcon data-testid='default-icon' className={className} />;
}
