import { type Module } from '@/models/template.model';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { getModuleComponent } from './module.utils';

interface ModuleWrapperProps {
  module?: Module;
  path?: string;
  index?: number;
  indexLabel?: boolean;
  isCustomField?: boolean;
}
export default function ModuleWrapper ({ module, path, index, indexLabel }: ModuleWrapperProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getProperties = (): Record<string, any> => {
    if (!index && index !== 0) {
      return module.properties;
    }
    const moduleName = `${path}.value`;

    return {
      ...module?.properties,
      name: moduleName,
      path,
      inputProps: {
        ...module?.properties?.inputProps,
        name: moduleName,
      },
      labelProps: {
        ...module?.properties?.labelProps,
        label: indexLabel
          ? `${module?.properties?.labelProps?.label} ${index + 1}`
          : module?.properties?.labelProps?.label,
        name: path,
      },
      modules: module?.modules,
    };
  };

  const moduleFieldName = module?.name?.toLowerCase();
  // TODO: Remove this after fields are pulled out of data and templates are updated
  const DO_NOT_DISPLAY_FIELDS = ['assignee', 'company', 'name', 'schedule', 'startdate', 'targetdate'];

  const { objectCardData } = useObjectCardContext();
  const exceptionForTaskDates = objectCardData?.template?.name?.includes('L-Minus') &&
    ['startdate', 'targetdate', 'schedule'].includes(moduleFieldName);
  if (DO_NOT_DISPLAY_FIELDS.includes(moduleFieldName) && !exceptionForTaskDates) {
    return null;
  }

  const properties = getProperties();
  const ModuleComponent = getModuleComponent(module?.type);
  if (module?.type === undefined || module?.type === null) {
    console.error('Missing module type for module: ', module);
  }

  return <>{ModuleComponent ? <ModuleComponent {...properties} /> : null}</>;
}
