import metrics from '@/util/metrics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/sharp-regular-svg-icons';
import { useRouter } from 'next/router';
import LogoMark from '../../svg/LogoMark';

export default function SidenavFooter (): JSX.Element {
  const router = useRouter();

  const gotoIntegrateSite = () => {
    window.open('https://integrate.co', '_blank', 'noopener');
    metrics.track('sidenav - nav to integrate site via footer logo mark');
  };

  const gotoSupportPage = () => {
    window.open('https://support.integrate.co', '_blank', 'noopener');
    metrics.track('sidenav - nav to support page via footer help link', {
      currentPath: router.pathname,
    });
  };

  const gotoTermsOfUsePage = () => {
    window.open('https://integrate.co/terms-of-use', '_blank', 'noopener');
    metrics.track('sidenav - nav to terms of use page via footer link');
  };

  return (
    <div className='flex items-center shrink-0 gap-[16px] flex-col-reverse @expanded/sidenav:flex-row'>
      <button
        onClick={gotoIntegrateSite}
        className='transition-colors hover:text-white text-white/50'
      >
        <LogoMark height={18} width={18} />
      </button>
      <div className='flex text-[16px] @expanded/sidenav:effra-12 text-white/75 gap-[8px] flex-col @expanded/sidenav:flex-row'>
        <button
          onClick={gotoSupportPage}
          className='flex items-center transition-colors hover:text-white gap-[4px]'
        >
          <FontAwesomeIcon icon={faCircleQuestion} className='' />
          <span className='hidden @expanded/sidenav:block'>Help</span>
        </button>
        <button
          onClick={gotoTermsOfUsePage}
          className='hidden @expanded/sidenav:flex items-center transition-colors hover:text-white'
        >
          Terms of Use
        </button>
      </div>
    </div>
  );
}
