import { type ChangeEvent } from 'react';

type ChangeEventHandler = (e: ChangeEvent<HTMLInputElement>) => void;
type ChangeCallback = (value: string) => void;

export default function handleChange (fn: ChangeCallback): ChangeEventHandler {
  return (e) => {
    fn(e.target.value);
  };
}
