import { getModulePath } from '@/util/lookup.functions';
import { assocPath } from 'ramda';

// rehydrates the modular object with the data from the database
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getTheBandBackTogether (modules = {}, data = {}, customFields = {}): Record<string, any> {
  const templateModulesById = {};

  const combinedModules = { ...modules, ...customFields };

  Object.keys(combinedModules).forEach((moduleId) => {
    const currentModule = combinedModules[moduleId];

    const value = data[moduleId]?.value || '';
    const hydratedModule = { ...currentModule, value };

    templateModulesById[moduleId] = hydratedModule;

    if (currentModule?.type === 'repeater') {
      templateModulesById[moduleId].modules = Object.entries(data[moduleId]?.values || {}).reduce((acc, [id, obj]) => {
        const repeaterModules = { ...currentModule.properties.inputProps.module };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Object.entries((obj as any)?.value || {}).forEach(([key, value]) => {
          const modulePath = getModulePath(repeaterModules.modules, key);
          repeaterModules.modules = assocPath([...modulePath, 'value'], value, repeaterModules.modules);
        });

        return {
          ...acc,
          [id]: {
            ...repeaterModules,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            order: (obj as any)?.order || 0,
          },
        };
      }, {});

      return;
    }

    if (currentModule?.modules) {
      templateModulesById[moduleId].modules = getTheBandBackTogether(
        currentModule.modules,
        data,
      );
    }
  });

  return templateModulesById;
}
