import MegaModalListMessage from '../MegaModalListMessage/MegaModalListMessage';

interface MegaModalEmptyListProps {
  message: string;
  showButton?: boolean;
  buttonDisabled?: boolean;
  buttonText?: string;
  buttonToolTip?: string;
  isLoading?: boolean;
  onClick?: (event) => void;
}

export default function MegaModalEmptyList ({
  message,
  showButton = true,
  buttonDisabled = false,
  buttonText,
  buttonToolTip,
  isLoading,
  onClick,
}: MegaModalEmptyListProps): JSX.Element {
  return (
    <MegaModalListMessage
      message={message}
      showButton={showButton}
      buttonDisabled={buttonDisabled}
      buttonText={buttonText}
      buttonToolTip={buttonToolTip}
      isLoading={isLoading}
      onClick={onClick}
    />
  );
}
