import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type ApproveApprovalMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
  type: Types.ApprovalTypes;
}>;


export type ApproveApprovalMutation = { __typename?: 'Mutation', approveApproval: { __typename?: 'Approval', id: string } | null };


export const ApproveApprovalDocument = gql`
    mutation ApproveApproval($id: UUID!, $type: ApprovalTypes!) {
  approveApproval(id: $id, type: $type) {
    id
  }
}
    `;
export type ApproveApprovalMutationFn = Apollo.MutationFunction<ApproveApprovalMutation, ApproveApprovalMutationVariables>;

/**
 * __useApproveApprovalMutation__
 *
 * To run a mutation, you first call `useApproveApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveApprovalMutation, { data, loading, error }] = useApproveApprovalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useApproveApprovalMutation(baseOptions?: Apollo.MutationHookOptions<ApproveApprovalMutation, ApproveApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveApprovalMutation, ApproveApprovalMutationVariables>(ApproveApprovalDocument, options);
      }
export type ApproveApprovalMutationHookResult = ReturnType<typeof useApproveApprovalMutation>;
export type ApproveApprovalMutationResult = Apollo.MutationResult<ApproveApprovalMutation>;
export type ApproveApprovalMutationOptions = Apollo.BaseMutationOptions<ApproveApprovalMutation, ApproveApprovalMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockApproveApprovalMutation((req, res, ctx) => {
 *   const { id, type } = req.variables;
 *   return res(
 *     ctx.data({ approveApproval })
 *   )
 * })
 */
export const mockApproveApprovalMutation = (resolver: Parameters<typeof graphql.mutation<ApproveApprovalMutation, ApproveApprovalMutationVariables>>[1]) =>
  graphql.mutation<ApproveApprovalMutation, ApproveApprovalMutationVariables>(
    'ApproveApproval',
    resolver
  )
