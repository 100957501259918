import executeWorker from './executeWorker';

import type { DropCallback, ErrorHandler, FileData } from '../types';

export default function fileSelectionHandler (cb: DropCallback, errorHandler: ErrorHandler, fileData: FileData): void {
  const { file, error } = fileData;

  errorHandler(error);

  if (!error) executeWorker(file, cb);
}
