import { useEditContext } from '@/components';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import useStatusConfig from './hooks/useStatusConfig';

export type ApprovalStatus =
  | 'staged'
  | 'staged-non-approve'
  | 'waiting for approval'
  | 'for review'
  | 'change denied'
  | 'change approved'
  | 'recently changed';
interface StatusTooltipProps {
  status: ApprovalStatus;
}

export default function StatusTooltip ({ status }: StatusTooltipProps): JSX.Element {
  const { isOwner } = useEditContext();
  const [{ color: statusColor, text: statusText }] = useStatusConfig(
    status,
    isOwner,
  );

  const [showPopper, setShowPopper] = useState(false);
  const [popperRefElement, setPopperRefElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(popperRefElement, popperElement, {
    placement: 'top-start',
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }, {
      name: 'offset',
      options: { offset: [-4, 10] },
    }],
  });

  return status &&
    (
      <section data-testid='status-dot'>
        <div
          className={`w-2 h-2 ${statusColor} rounded-full m-1 max-w-[250px]`}
          ref={setPopperRefElement}
          onMouseOver={() => {
            setShowPopper(true);
          }}
          onMouseLeave={() => {
            setShowPopper(false);
          }}
        />

        {showPopper &&
          (
            <div ref={setPopperElement} style={{ zIndex: '250', ...styles.popper }} {...attributes.popper}>
              <div className='flex gap-2 p-4 bg-black'>
                <div className={`w-2 h-2 ${statusColor} rounded-full translate-y-[4px]`} />
                <div className='flex-1 text-xs font-normal text-white normal-case tracking-[0.5px] max-w-[35ch] text-wrap'>
                  {statusText}
                </div>
              </div>

              <div
                ref={setArrowElement}
                className='w-0 h-0 border-black border-l-[6px] border-l-transparent border-t-[6px] border-r-[6px] border-r-transparent'
              />
            </div>
          )}
      </section>
    );
}
