import * as amplitude from '@amplitude/analytics-browser';
import {
  type AmplitudeReturn,
  type BaseEvent,
  type EventOptions,
  type Identify,
  type Result,
} from '@amplitude/analytics-types';
import { env } from './env.utils';

class Metrics {
  init() {
    if (env('NEXT_PUBLIC_STAGE') !== 'development' && env('NEXT_PUBLIC_AMPLITUDE_API_KEY')) {
      amplitude.init(env('NEXT_PUBLIC_AMPLITUDE_API_KEY'));
    }
  }

  identify(identify: Identify) {
    if (env('NEXT_PUBLIC_STAGE') !== 'development' && env('NEXT_PUBLIC_AMPLITUDE_API_KEY')) {
      amplitude.identify(identify);
    }
  }

  setUserId(userId: string) {
    if (env('NEXT_PUBLIC_STAGE') !== 'development' && env('NEXT_PUBLIC_AMPLITUDE_API_KEY')) {
      amplitude.setUserId(userId);
    }
  }

  track(
    event: string | BaseEvent,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventProperties?: Record<string, any> | undefined,
    eventOptions?: EventOptions | undefined,
  ): AmplitudeReturn<Result> | void {
    if (env('NEXT_PUBLIC_STAGE') !== 'development' && env('NEXT_PUBLIC_AMPLITUDE_API_KEY')) {
      return amplitude.track(event, eventProperties, eventOptions);
    }
  }
}

export default new Metrics();
