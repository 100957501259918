import { Comment } from '@/components/CommentSection/Comment';
import { useGetCommentsForObjectIdQuery } from '@/components/CommentSection/getCommentsForObjectId.generated';
import { useObjectContext } from '@/components/common/ModularObject/Card/Object.context';
import TimelineEvent from '@/components/history/TimelineEvent/TimelineEvent';
import { useAppSelector } from '@/state/hooks';
import { useGetSubscriptionQuery } from '@/state/queries/subscription.api';
import { selectLoggedInUser } from '@/state/selectors';
import { sortBy } from 'lodash';
import BlockedUpdatesColumn from './BlockedUpdatesColumn';
import { cleanupDiffsForUpdatesColumn } from './cleanupDiffsForUpdatesColumn';
import { useGetHistoryUpdatesForObjectIdQuery } from './getHistoryUpdatesForObjectId.generated';
import { useGetModularObjectHistoryByIdQuery } from './getModularObjectDiffs.generated';
import { HistoryUpdate } from './HistoryUpdate';
import UpdatesColumnProvider from './UpdatesColumn.context';

interface UpdatesColumnProps {
  objectType: string;
  objectID: string;
}

export default function UpdatesColumn ({ objectType, objectID }: UpdatesColumnProps): JSX.Element {
  const { data: currentSubscription } = useGetSubscriptionQuery(false);
  const canUseUpdatesColumn = !currentSubscription?.featureLimits?.isObjectUpdateColumnDisabled;

  const loggedInUser = useAppSelector(selectLoggedInUser);
  const { activeModularObject } = useObjectContext();
  const isObjectEditor = (activeModularObject?.shares ?? [])?.some((share) => {
    return share?.userId === loggedInUser?.id && share?.role?.toUpperCase() === 'EDITOR';
  });
  const isObjectOwner = activeModularObject?.ownerId === loggedInUser?.id;
  const shouldShowCommentSection = isObjectEditor || isObjectOwner;

  const { data } = useGetCommentsForObjectIdQuery({
    variables: { modularObjectId: objectID },
    skip: !shouldShowCommentSection || !objectID,
  });

  const { data: objectHistoryData } = useGetHistoryUpdatesForObjectIdQuery({
    variables: { modularObjectId: objectID },
    skip: !objectID,
    fetchPolicy: 'cache-and-network',
  });

  // Get diffs
  const { data: objectDiffData } = useGetModularObjectHistoryByIdQuery({
    variables: { id: objectID },
    skip: !objectID,
    fetchPolicy: 'cache-and-network',
  });

  const objectHistory = cleanupDiffsForUpdatesColumn(objectDiffData?.getModularObjectHistoryByID || []);

  const comments = data?.getModularObjectByID?.comments || [];
  const historyUpdates = objectHistoryData?.getModularObjectByID?.historicalUpdates || [];
  const historyObjects = sortBy([...comments, ...historyUpdates, ...objectHistory], (event) => {
    if (event.__typename === 'Comment' || event.__typename === 'ModularObjectHistoryUpdate') return event.createdAt;
    return event.date;
  });

  // Finds the last undo-able event and marks it as such
  // Note: Yes, this is a little hacky, until we unify all the history updates into one stream
  let lastUndoEventFound = false;
  let idx = historyObjects.length - 1;
  while (idx >= 0) {
    if (lastUndoEventFound) {
      historyObjects[idx].isLastUndoableEvent = false;
    } else {
      if (historyObjects[idx].__typename === 'ModularObjectHistoryUpdate' && historyObjects[idx].canUndo) {
        historyObjects[idx].isLastUndoableEvent = true;
        lastUndoEventFound = true;
      } else if (!historyObjects[idx].__typename && historyObjects[idx].action !== 'dependencyUpdate') {
        historyObjects[idx].isLastUndoableEvent = true;
        lastUndoEventFound = true;
      }
    }

    idx--;
  }

  if (!canUseUpdatesColumn) return <BlockedUpdatesColumn />;

  return (
    <UpdatesColumnProvider objectType={objectType} objectID={objectID} owner={{}} objectCollaborators={{}}>
      <div className='flex flex-col gap-3 p-7'>
        {historyObjects?.map(
          (historyObject, idx) => {
            // Hey! I hate it too!  But until we merge these concepts together at the database level, this is the cards we are dealt
            if (historyObject.__typename === 'Comment') {
              return <Comment key={historyObject.id} comment={historyObject} />;
            }
            if (historyObject.__typename === 'ModularObjectHistoryUpdate') {
              return (
                <HistoryUpdate
                  key={historyObject.id}
                  update={historyObject}
                  isLatestChange={(idx === historyObjects.length - 1 && historyObject.isLastUndoableEvent) ||
                    (idx !== historyObjects.length - 1 && historyObject.isLastUndoableEvent)}
                />
              );
            }
            return (
              <TimelineEvent
                key={historyObject.id}
                historyObject={historyObject}
                isCurrent={(idx === historyObjects.length - 1 && historyObject.isLastUndoableEvent) ||
                  (idx !== historyObjects.length - 1 && historyObject.isLastUndoableEvent)}
              />
            );
          },
        )}
      </div>
    </UpdatesColumnProvider>
  );
}
