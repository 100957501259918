import HookFormLabel from '@/components/modules/HookFormLabel';
import { type ReactNode } from 'react';
import cx from 'classnames';

interface DetailItemProps {
  name?: string;
  label: string;
  children: ReactNode;
  contentClassName?: string;
}

export default function DetailItem ({ label, name, children, contentClassName }: DetailItemProps): JSX.Element {
  return (
    <div className='flex items-center w-full gap-[8px]' data-testid='detail-item'>
      <HookFormLabel label={label} name={name} className='basis-[40%] max-w-[100px] effra-xs !mb-0' />
      <div className={cx('flex-1', contentClassName)}>
        {children}
      </div>
    </div>
  );
}
