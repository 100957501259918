import Image from 'next/image';

interface DenyButtonProps {
  isSendingApproval: boolean;
  handleSubmitApproval: (approve: boolean) => Promise<void>;
}

export default function DenyButton (
  { isSendingApproval, handleSubmitApproval }: DenyButtonProps,
): JSX.Element {
  return (
    <div>
      <button
        disabled={Boolean(isSendingApproval)}
        onClick={async () => {
          await handleSubmitApproval(false);
        }}
        className='flex gap-2 items-center font-bold group text-secondary'
      >
        {!isSendingApproval ?
          (
            <div className='flex justify-center items-center shrink-0 w-[20px]'>
              <Image src='/images/x-secondary.svg' alt='X' width={11} height={11} />
            </div>
          ) :
          (
            <svg
              className='h-3 animate-spin w-[20px] text-secondary group-hover:text-secondary-hover'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle
                className='opacity-25'
                cx='12'
                cy='12'
                r='10'
                stroke='currentColor'
                strokeWidth='4'
              />
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              />
            </svg>
          )}
        <div>Deny</div>
      </button>
    </div>
  );
}
