import { apolloClientSideClient } from '@/apollo/apolloClient';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { gql } from '@apollo/client';
import pluralize from 'pluralize';
import { useCollabDiscoveryContext } from '../CollabDiscovery.context';
import { useUpdateModularObjectMutation } from '../mutations/updateModularObject.generated';
import { GetCollaboratorListDocument } from '../queries/getCollaboratorList.generated';
import ToggleOption from './ToggleOption';

export default function VisibilitySetting ({ objectId }: { objectId: string }) {
  const { currentUserIsOwner } = useCollabDiscoveryContext();

  const currentObject = apolloClientSideClient.readFragment({
    id: `ModularObject:${objectId}`,
    fragment: gql`
      fragment currentObject on ModularObject {
        visibility
        template {
          name
        }
      }
    `,
  }) ?? {};

  const {
    visibility: previousVis = 'Private',
    template = { name: 'object' },
  } = currentObject;

  const [updateModularObject] = useUpdateModularObjectMutation({
    update: (cache, { data }) => {
      const [updatedObject] = data.updateModularObject;
      cache.writeQuery({
        query: GetCollaboratorListDocument,
        variables: {
          ids: [objectId],
        },
        data: {
          getModularObjectByIDs: [updatedObject],
        },
      });
    },
  });

  const handleVisibilityChange = async (e) => {
    if (!currentUserIsOwner) return;
    const newVis = e.target.checked ? 'Company' : 'Private';
    try {
      await updateModularObject({
        variables: {
          input: {
            diffs: [{
              diffType: 'updated',
              externalID: objectId,
              externalType: 'modular_object',
              diff: {
                displayNames: { ownerId: 'visibility' },
                to: { visibility: newVis },
              },
              fromMigration: false,
            }],
          },
        },
      });
      addToastSuccess(`Visibility set to ${newVis}`);
    } catch (err) {
      console.error(err);
      addToastError('Failed to update visibility');
    }
  };

  return (
    <ToggleOption
      disabled={!currentUserIsOwner}
      icon='fa-sharp fa-regular fa-users'
      value={previousVis === 'Company'}
      onChange={handleVisibilityChange}
      secondaryIcon='fa-sharp fa-regular fa-users-slash'
      title='Visible to your company'
      description={`Anyone at your company will automatically be able to view this ${
        pluralize.singular(template?.name)
      }.`}
    />
  );
}
