import { Loader } from '@/components/loader';
import Color from '@/components/types/Color.enum';

interface SaveButtonProps {
  onClick: () => void;
  disabled?: boolean;
  isSaving?: boolean;
}

export default function SaveButton ({ onClick, disabled, isSaving }: SaveButtonProps): JSX.Element {
  return (
    <button
      type='button'
      onClick={onClick}
      disabled={disabled || isSaving}
      className='button-primary effra-xs w-[90px] h-[36px]'
    >
      {isSaving && <Loader color={Color.WHITE} className='w-[24px]' />}
      {isSaving ? 'Saving' : 'Save'}
    </button>
  );
}
