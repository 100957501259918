import type { HardwareIntegration } from '@/__generated__/types';
import { createSlice } from '@reduxjs/toolkit';

type IntegrationState = Record<string, HardwareIntegration>;

export const initialState: IntegrationState = {};

export const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {
    addIntegrations(state, action) {
      action.payload?.forEach((integration) => {
        if (integration?.id) {
          state[integration.id] = integration;
        }
      });
    },
    removeIntegrations(state, action) {
      action.payload?.forEach((integration) => {
        if (state[integration.id]) {
          delete state[integration.id];
        }
      });
    },
    createIntegrationError(state, { payload }) {
      return {
        ...state,
        error: payload,
      };
    },
  },
});

export const { addIntegrations, removeIntegrations, createIntegrationError } = integrationSlice.actions;
export const { reducer: integrationReducer } = integrationSlice;
