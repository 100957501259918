import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useAppDispatch } from '@/state/hooks';
import { setPrismaticUser } from '@/state/slices/session.slice';
import type { AppThunkDispatch } from '@/state/store';
import { env } from '@/util/env.utils';
import { useGetPrismaticJwtTokenQuery } from '@/util/getPrismaticJWToken.generated';
import prismatic, { PrismaticMessageEvent } from '@prismatic-io/embedded';
import { hasCookie } from 'cookies-next';
import { useCallback, useEffect, useMemo } from 'react';

export interface PrismaticUser {
  instances: {
    nodes: Array<{
      id: string;
      name: string;
      configState: string;
      integration: {
        id: string;
        name: string;
      };
      userLevelConfigVariables: {
        nodes: Array<{
          config: {
            flowConfigs: {
              nodes: Array<{
                id: string;
                webhookUrl: string;
                instanceFlowConfig: {
                  flow: {
                    name: string;
                  };
                };
              }>;
            };
          };
        }>;
      };
    }>;
  };
}

export function usePrismatic () {
  const dispatch = useAppDispatch();
  const currentUser = useLoggedInUser();
  const { data, error } = useGetPrismaticJwtTokenQuery({
    skip: !hasCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME')),
    pollInterval: 1_000 * 60 * 15,
    onCompleted: (data) => {
      if (data?.getPrismaticJwtToken) {
        getPrismaticUser(data.getPrismaticJwtToken, dispatch);
      }
    },
  });

  const token = useMemo(() => {
    if (error || !data?.getPrismaticJwtToken) {
      return;
    }
    return data.getPrismaticJwtToken;
  }, [data, error]);

  const listener = useCallback((message: MessageEvent) => {
    if (currentUser?.id && token) {
      const { event } = message.data;
      switch (event) {
        case PrismaticMessageEvent.USER_CONFIGURATION_DEPLOYED: {
          getPrismaticUser(token, dispatch);
        }
      }
    }
  }, [currentUser?.id, dispatch, token]);

  useEffect(() => {
    window.removeEventListener('message', listener);
    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [listener]);
}

function getPrismaticUser (token: string, dispatch: AppThunkDispatch) {
  prismatic.authenticate({ token })
    .then(() => {
      prismatic.graphqlRequest({
        query: `query getEmbeddedMarketplaceUserInstances {
  authenticatedUser {
    customer {
      name
      externalId
      instances(isSystem: false, enabled: true, needsDeploy: false) {
        nodes {
          id
          name
          needsDeploy
          configState
          inFailedState
          integration {
            id
            name
          }
          userLevelConfigVariables {
            nodes {
              config {
                flowConfigs {
                  nodes {
                    id
                    webhookUrl
                    instanceFlowConfig {
                      flow {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`,
      }).then((res) => {
        dispatch(setPrismaticUser(res.data.authenticatedUser.customer));
      })
        .catch(console.error);
    })
    .catch(console.error);
}
