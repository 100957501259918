import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { NestingColumnItemFragmentDoc } from './NestingColumnItemFragment.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetNestedChildrenOnExpandQueryVariables = Types.Exact<{
  childrenIds: Types.InputMaybe<Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input']>;
}>;


export type GetNestedChildrenOnExpandQuery = { __typename?: 'Query', getModularObjectByIDs: Array<{ __typename?: 'ModularObject', id: string, name: string | null, template: { __typename?: 'Template', id: string | null, name: string | null, type: Types.TemplateTypes | null } | null, children: Array<{ __typename?: 'ModularObject', id: string, template: { __typename?: 'Template', id: string | null, type: Types.TemplateTypes | null } | null }> | null, owner: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null, profileImage: string | null, organization: { __typename?: 'Organization', id: string, name: string | null } | null } | null }> | null };


export const GetNestedChildrenOnExpandDocument = gql`
    query GetNestedChildrenOnExpand($childrenIds: [UUID!]) {
  getModularObjectByIDs(ids: $childrenIds) {
    ...NestingColumnItem
  }
}
    ${NestingColumnItemFragmentDoc}`;

/**
 * __useGetNestedChildrenOnExpandQuery__
 *
 * To run a query within a React component, call `useGetNestedChildrenOnExpandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNestedChildrenOnExpandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNestedChildrenOnExpandQuery({
 *   variables: {
 *      childrenIds: // value for 'childrenIds'
 *   },
 * });
 */
export function useGetNestedChildrenOnExpandQuery(baseOptions?: Apollo.QueryHookOptions<GetNestedChildrenOnExpandQuery, GetNestedChildrenOnExpandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNestedChildrenOnExpandQuery, GetNestedChildrenOnExpandQueryVariables>(GetNestedChildrenOnExpandDocument, options);
      }
export function useGetNestedChildrenOnExpandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNestedChildrenOnExpandQuery, GetNestedChildrenOnExpandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNestedChildrenOnExpandQuery, GetNestedChildrenOnExpandQueryVariables>(GetNestedChildrenOnExpandDocument, options);
        }
export function useGetNestedChildrenOnExpandSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetNestedChildrenOnExpandQuery, GetNestedChildrenOnExpandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNestedChildrenOnExpandQuery, GetNestedChildrenOnExpandQueryVariables>(GetNestedChildrenOnExpandDocument, options);
        }
export type GetNestedChildrenOnExpandQueryHookResult = ReturnType<typeof useGetNestedChildrenOnExpandQuery>;
export type GetNestedChildrenOnExpandLazyQueryHookResult = ReturnType<typeof useGetNestedChildrenOnExpandLazyQuery>;
export type GetNestedChildrenOnExpandSuspenseQueryHookResult = ReturnType<typeof useGetNestedChildrenOnExpandSuspenseQuery>;
export type GetNestedChildrenOnExpandQueryResult = Apollo.QueryResult<GetNestedChildrenOnExpandQuery, GetNestedChildrenOnExpandQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetNestedChildrenOnExpandQuery((req, res, ctx) => {
 *   const { childrenIds } = req.variables;
 *   return res(
 *     ctx.data({ getModularObjectByIDs })
 *   )
 * })
 */
export const mockGetNestedChildrenOnExpandQuery = (resolver: Parameters<typeof graphql.query<GetNestedChildrenOnExpandQuery, GetNestedChildrenOnExpandQueryVariables>>[1]) =>
  graphql.query<GetNestedChildrenOnExpandQuery, GetNestedChildrenOnExpandQueryVariables>(
    'GetNestedChildrenOnExpand',
    resolver
  )
