import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type AddTeamMembersMutationVariables = Types.Exact<{
  userIds: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input'];
  teamId: Types.Scalars['UUID']['input'];
}>;


export type AddTeamMembersMutation = { __typename?: 'Mutation', addTeamMembers: Array<{ __typename?: 'TeamMember', id: string, createdAt: any, user: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null, email: string | null, organization: { __typename?: 'Organization', id: string, name: string | null } | null } } | null> };


export const AddTeamMembersDocument = gql`
    mutation AddTeamMembers($userIds: [UUID!]!, $teamId: UUID!) {
  addTeamMembers(userIds: $userIds, teamId: $teamId) {
    id
    createdAt
    user {
      id
      firstName
      lastName
      email
      organization {
        id
        name
      }
    }
  }
}
    `;
export type AddTeamMembersMutationFn = Apollo.MutationFunction<AddTeamMembersMutation, AddTeamMembersMutationVariables>;

/**
 * __useAddTeamMembersMutation__
 *
 * To run a mutation, you first call `useAddTeamMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeamMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeamMembersMutation, { data, loading, error }] = useAddTeamMembersMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useAddTeamMembersMutation(baseOptions?: Apollo.MutationHookOptions<AddTeamMembersMutation, AddTeamMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTeamMembersMutation, AddTeamMembersMutationVariables>(AddTeamMembersDocument, options);
      }
export type AddTeamMembersMutationHookResult = ReturnType<typeof useAddTeamMembersMutation>;
export type AddTeamMembersMutationResult = Apollo.MutationResult<AddTeamMembersMutation>;
export type AddTeamMembersMutationOptions = Apollo.BaseMutationOptions<AddTeamMembersMutation, AddTeamMembersMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddTeamMembersMutation((req, res, ctx) => {
 *   const { userIds, teamId } = req.variables;
 *   return res(
 *     ctx.data({ addTeamMembers })
 *   )
 * })
 */
export const mockAddTeamMembersMutation = (resolver: Parameters<typeof graphql.mutation<AddTeamMembersMutation, AddTeamMembersMutationVariables>>[1]) =>
  graphql.mutation<AddTeamMembersMutation, AddTeamMembersMutationVariables>(
    'AddTeamMembers',
    resolver
  )
