import Image from 'next/image';
import { type Dispatch, type SetStateAction, useCallback, useMemo } from 'react';
import { type MappableTaskField, TASK_FIELD_OPTIONS, type TaskMap } from './constants';
import TaskFieldDropdown from './TaskFieldDropdown';

interface MapHeaderFormProps {
  header: string[];
  setTaskMap: Dispatch<SetStateAction<TaskMap>>;
  taskMap: TaskMap;
}

export default function MapHeaderForm ({ header, setTaskMap, taskMap }: MapHeaderFormProps): JSX.Element {
  const handleMapChange = useCallback((name, value): void => {
    setTaskMap((prev) => ({ ...prev, [name]: value }));
  }, [setTaskMap]);

  const availableOptions = useMemo(() => {
    const selectedOptions = Object.values(taskMap).filter(Boolean);
    return Object.keys(TASK_FIELD_OPTIONS).filter((option: MappableTaskField) =>
      !selectedOptions.includes(option)
    ) as MappableTaskField[];
  }, [taskMap]);

  const willNotImport = useCallback((column: string) => (
    (Object.hasOwn(taskMap, column) && !taskMap[column]) ||
    (availableOptions?.length === 1 && !taskMap[column])
  ), [taskMap, availableOptions]);

  return (
    <div className='flex flex-col gap-4 pt-5'>
      <p>
        For each of your column headers, select the best match from our existing field options. If there is no good
        match, please select “do not import” from the drop down list. Our “task name” field must be mapped before you
        can upload.
      </p>
      <div className='flex relative flex-col bg-white'>
        <div className='absolute w-full h-2 bg-gradient-to-b to-transparent pointer-events-none z-1 from-black/5' />
        <div className='absolute bottom-0 w-full h-2 bg-gradient-to-t to-transparent pointer-events-none z-1 from-black/5' />
        <div className='flex gap-2 p-3 font-bold border-b font-effra'>
          <div className='w-5/12'>Your column headers</div>
          <div className='w-6/12'>Field options</div>
          <div className='w-1/12' />
        </div>
        <div className='divide-y'>
          {header.map((column) => (
            <div key={column} className='flex gap-4 items-center p-3'>
              <div className='flex justify-between w-5/12 font-effra'>
                <div className='truncate'>{column}</div>
                <Image
                  src='/images/arrow-right-gray.svg'
                  height={12}
                  width={14}
                  alt='arrow icon'
                  className='shrink-0'
                />
              </div>
              <div className='w-6/12'>
                <TaskFieldDropdown
                  onChange={handleMapChange}
                  name={column}
                  options={availableOptions}
                  value={taskMap[column] ?? (willNotImport(column) && '')}
                />
              </div>
              <div className='flex justify-end pr-2 w-1/12'>
                {(Boolean(taskMap[column]) && taskMap[column]) && (
                  <Image
                    src={'/images/complete-tertiary.svg'}
                    height={18}
                    width={18}
                    alt='complete icon'
                    className='shrink-0'
                  />
                )}
                {willNotImport(column) && (
                  <Image
                    src={'/images/null-gray.svg'}
                    height={18}
                    width={18}
                    alt='complete icon'
                    className='shrink-0'
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
