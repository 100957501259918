import { type ComponentProps } from 'react';

export default function DeployerIcon ({ ...otherProps }: ComponentProps<'svg'>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24.429'
      viewBox='0 0 25 24.429'
      {...otherProps}
      fill='currentColor'
    >
      <path
        data-name='Path 16575'
        d='M11.656 6.74h4.456l1.621 4.46h-4.455zm5.688 0H21.8l1.621 4.46h-4.454zM9.626 1.087h4.456L15.7 5.543h-4.452zm5.689 0h4.455l1.622 4.456h-4.455zM8.015 0 0 11.975l4.533 12.454h12.454L25 12.454 20.47 0z'
        transform='translate(-.001)'
        fill='currentColor'
      />
    </svg>
  );
}
