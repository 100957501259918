import Dropdown from '@/designSystemComponents/Dropdown';
import { useCallback, useMemo } from 'react';
import { DEFAULT_PAGE_SIZE_OPTIONS } from './useOffSetPagination';

export interface PageSizeSelectionDropdownProps {
  pageSizeOptions?: number[];
  onPageSizeChange: (page: number) => void;
  initialPageSize?: number;
}

export const PageSizeSelectionDropdown = (
  { onPageSizeChange, pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS, initialPageSize = DEFAULT_PAGE_SIZE_OPTIONS[0] }:
    PageSizeSelectionDropdownProps,
) => {
  const dropdownOptions = useMemo(() => {
    return pageSizeOptions.map((option) => ({
      value: option.toString(),
      displayText: option.toString(),
    }));
  }, [pageSizeOptions]);

  const handlePageSizeSelection = useCallback((selection: string) => {
    const newPageSize = parseInt(selection, 10);
    onPageSizeChange(newPageSize);
  }, [onPageSizeChange]);

  const initialValue = useMemo(() => {
    return {
      value: initialPageSize.toString(),
      displayText: initialPageSize.toString(),
    };
  }, [initialPageSize]);

  return (
    <Dropdown
      hideSelectionHint
      initialValue={initialValue}
      onChange={handlePageSizeSelection}
      options={dropdownOptions}
    />
  );
};
