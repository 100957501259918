import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type UpdateAssigneeMutationVariables = Types.Exact<{
  input: Types.UpdateModularObjectInput;
}>;


export type UpdateAssigneeMutation = { __typename?: 'Mutation', updateModularObject: Array<{ __typename?: 'ModularObject', id: string, assigneeId: string | null, assignee: { __typename?: 'User', id: string, fullName: string | null, organizationId: string | null, organization: { __typename?: 'Organization', id: string, name: string | null } | null } | null }> };


export const UpdateAssigneeDocument = gql`
    mutation UpdateAssignee($input: UpdateModularObjectInput!) {
  updateModularObject(input: $input) {
    id
    assigneeId
    assignee(includeDeleted: true) {
      id
      fullName
      organizationId
      organization {
        id
        name
      }
    }
  }
}
    `;
export type UpdateAssigneeMutationFn = Apollo.MutationFunction<UpdateAssigneeMutation, UpdateAssigneeMutationVariables>;

/**
 * __useUpdateAssigneeMutation__
 *
 * To run a mutation, you first call `useUpdateAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssigneeMutation, { data, loading, error }] = useUpdateAssigneeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssigneeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssigneeMutation, UpdateAssigneeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssigneeMutation, UpdateAssigneeMutationVariables>(UpdateAssigneeDocument, options);
      }
export type UpdateAssigneeMutationHookResult = ReturnType<typeof useUpdateAssigneeMutation>;
export type UpdateAssigneeMutationResult = Apollo.MutationResult<UpdateAssigneeMutation>;
export type UpdateAssigneeMutationOptions = Apollo.BaseMutationOptions<UpdateAssigneeMutation, UpdateAssigneeMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateAssigneeMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updateModularObject })
 *   )
 * })
 */
export const mockUpdateAssigneeMutation = (resolver: Parameters<typeof graphql.mutation<UpdateAssigneeMutation, UpdateAssigneeMutationVariables>>[1]) =>
  graphql.mutation<UpdateAssigneeMutation, UpdateAssigneeMutationVariables>(
    'UpdateAssignee',
    resolver
  )
