import { type UserModel } from '@/models';
import { createContext, type ReactNode, useContext, useMemo } from 'react';

interface UpdatesColumnContextValue {
  objectType: string;
  objectID: string;
  owner: UserModel;
  objectCollaborators: Record<string, UserModel>;
}

interface UpdatesColumnProviderProps {
  objectType: string;
  objectID: string;
  owner: UserModel;
  objectCollaborators: Record<string, UserModel>;
  children: ReactNode;
}

const UpdatesColumnContext = createContext<UpdatesColumnContextValue | null>(null);

export const useUpdatesColumnContext = (): UpdatesColumnContextValue => {
  const context = useContext(UpdatesColumnContext);

  if (!context) {
    throw new Error('UpdatesColumnContext must be used within a UpdatesColumnContextProvider');
  }

  return context;
};

export default function UpdatesColumnProvider ({
  objectType,
  objectID,
  owner,
  objectCollaborators,
  children,
}: UpdatesColumnProviderProps): JSX.Element {
  const value = useMemo(
    () => ({
      objectType,
      objectID,
      owner,
      objectCollaborators,
    }),
    [objectType, objectID, owner, objectCollaborators],
  );

  return <UpdatesColumnContext.Provider value={value}>{children}</UpdatesColumnContext.Provider>;
}
