import { Button } from '@/designSystemComponents/Button';

export interface ConfirmRemoveNestedItemModalProps {
  closeModal: () => void;
  handleConfirm: () => Promise<void>;
  nestedItemName: string;
}

export const ConfirmRemoveNestedItemModal = (
  { closeModal, handleConfirm, nestedItemName }: ConfirmRemoveNestedItemModalProps,
) => {
  return (
    <div className='flex flex-col flex-1 gap-5'>
      <h3 className='mb-0 text-3xl font-black capitalize font-sculpin text-secondary'>Are you sure?</h3>
      <p>
        <span className='font-bold'>{nestedItemName}</span> will be de-nested.
      </p>
      <p>
        Note: If changes to this object require approval by the owner and you are not the owner, then an approval
        request will be sent to the owner
      </p>
      <div className='flex gap-5'>
        <Button figmaProps={{ style: 'outline', size: 'large' }} className='flex-1' onClick={closeModal}>
          Cancel
        </Button>

        <Button figmaProps={{ style: 'fill', size: 'large' }} className='flex-1' onClick={handleConfirm}>
          Remove
        </Button>
      </div>
    </div>
  );
};
