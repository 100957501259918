import { useAppSelector } from '@/state/hooks';
import { selectRootState } from '@/state/selectors';
import { getUserName } from '@/util/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGhost, faXmark } from '@fortawesome/sharp-regular-svg-icons';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

export default function ImpersonationBanner (): JSX.Element {
  const { session: { user } } = useAppSelector(selectRootState);
  const router = useRouter();
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  const userName = useMemo(() => getUserName(user), [user]);

  useEffect(() => {
    if (localStorage.getItem('impersonation')) {
      setIsBannerVisible(true);
    }
  }, []);

  const handleCloseBanner = (): void => {
    localStorage.removeItem('impersonation');
    router.push('/settings/profile').finally(router.reload);
  };

  return (
    isBannerVisible && (
      <section className='flex justify-center items-center p-6 text-white bg-warning'>
        <FontAwesomeIcon icon={faGhost} className='pr-2.5' />
        You’re impersonating&nbsp;<b>{userName}</b>. All changes made will appear to others as if&nbsp;<b>
          {userName}
        </b>&nbsp;made them. Be very careful.
        <span onClick={handleCloseBanner} className='cursor-pointer'>
          <FontAwesomeIcon icon={faXmark} className='pr-1 pl-2.5' />
          Stop Impersonation.
        </span>
      </section>
    )
  );
}
