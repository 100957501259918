import InfoTooltip from '@/components/tooltip/InfoTooltip';
import type { PropsWithChildren } from 'react';

interface PrismaticExcelFieldMessageProps {
  hasDataMapping: boolean;
}

export default function PrismaticExcelFieldMessage (
  { hasDataMapping, children }: PropsWithChildren<PrismaticExcelFieldMessageProps>,
): JSX.Element {
  return (
    <InfoTooltip
      className='flex items-center'
      message={hasDataMapping
        ? 'This field is being populated\n by data from a linked excel file.\n Click to view the mapping.'
        : 'This field is not being populated\n by data from a linked excel file.\n Click to link an excel file.'}
      delay={0.25}
    >
      {children}
    </InfoTooltip>
  );
}
