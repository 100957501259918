import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import { UserByIdFragmentDoc } from './getUserById.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetUsersByIdQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input'];
}>;


export type GetUsersByIdQuery = { __typename?: 'Query', getUsersByID: Array<{ __typename?: 'User', id: string, email: string | null, title: string | null, firstName: string | null, lastName: string | null, fullName: string | null, organizationId: string | null, profileImage: string | null, workCapacity: number | null, deletedAt: any | null, organization: { __typename?: 'Organization', id: string, name: string | null, resourceCostUnit: Types.ResourceCostUnit | null } | null, teams: Array<{ __typename?: 'Team', id: string, name: string } | null> | null }> };


export const GetUsersByIdDocument = gql`
    query GetUsersByID($ids: [UUID!]!) {
  getUsersByID(ids: $ids) {
    ...UserById
  }
}
    ${UserByIdFragmentDoc}`;

/**
 * __useGetUsersByIdQuery__
 *
 * To run a query within a React component, call `useGetUsersByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetUsersByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUsersByIdQuery, GetUsersByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByIdQuery, GetUsersByIdQueryVariables>(GetUsersByIdDocument, options);
      }
export function useGetUsersByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByIdQuery, GetUsersByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByIdQuery, GetUsersByIdQueryVariables>(GetUsersByIdDocument, options);
        }
export function useGetUsersByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersByIdQuery, GetUsersByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersByIdQuery, GetUsersByIdQueryVariables>(GetUsersByIdDocument, options);
        }
export type GetUsersByIdQueryHookResult = ReturnType<typeof useGetUsersByIdQuery>;
export type GetUsersByIdLazyQueryHookResult = ReturnType<typeof useGetUsersByIdLazyQuery>;
export type GetUsersByIdSuspenseQueryHookResult = ReturnType<typeof useGetUsersByIdSuspenseQuery>;
export type GetUsersByIdQueryResult = Apollo.QueryResult<GetUsersByIdQuery, GetUsersByIdQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUsersByIdQuery((req, res, ctx) => {
 *   const { ids } = req.variables;
 *   return res(
 *     ctx.data({ getUsersByID })
 *   )
 * })
 */
export const mockGetUsersByIdQuery = (resolver: Parameters<typeof graphql.query<GetUsersByIdQuery, GetUsersByIdQueryVariables>>[1]) =>
  graphql.query<GetUsersByIdQuery, GetUsersByIdQueryVariables>(
    'GetUsersByID',
    resolver
  )
