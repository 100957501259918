import { type Module } from '@/models/template.model';
import { getOrderedModuleEntries } from '../common/ModularObject/Page/utils';
import HookFormLabel from './HookFormLabel';
import useToggleGroup from './useToggleGroup';
import ModuleWrapper from './utils/ModuleWrapper';

interface GroupProps {
  className: string;
  labelProps?: Record<string, unknown>;
  modules: Record<string, Module>;
  path: string;
  fieldToWatch?: string;
  toggleValue?: string;
  enableSubscription?: boolean;
}

export default function Group (
  { className, labelProps = {}, modules, path, enableSubscription, toggleValue = '', fieldToWatch = '' }: GroupProps,
): JSX.Element {
  const shouldShow = useToggleGroup({
    fieldToWatch,
    toggleValue,
    enableSubscription,
  });

  return shouldShow ?
    (
      <>
        <div data-testid='module-group' className={className}>
          {labelProps ? <HookFormLabel {...labelProps} /> : null}
          {getOrderedModuleEntries(modules)
            .map(([key, module], idx) => (
              <ModuleWrapper
                key={`${path}.modules.${key}`}
                module={module}
                index={idx}
                path={`${path}.modules.${key}`}
              />
            ))}
        </div>
      </>
    ) :
    null;
}
