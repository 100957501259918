import { type UserModel } from '@/models';
import cx from 'classnames';

interface UserNameProps {
  user?: UserModel;
  currentUser: UserModel;
  suffix?: string;
}

export default function UserName ({ user, currentUser, suffix }: UserNameProps): JSX.Element {
  const isInternal = user?.organizationId === currentUser?.organizationId;
  const username = user?.id === currentUser?.id ? 'You' : `${user?.firstName} ${user?.lastName}`;
  const assignedUser = user ? username : 'Unknown user';

  return (
    <span
      data-testid='user-name'
      className={cx('whitespace-nowrap overflow-hidden text-ellipsis', {
        'text-zinc-500': !user,
        'text-primary': isInternal,
        'text-tertiary': !isInternal,
      })}
    >
      {assignedUser}
      {suffix}
    </span>
  );
}
