import { useWatch } from 'react-hook-form';
import useGetTemplateModuleName from './useGetTemplateModuleName';

interface AutoValueHookProps {
  fieldToWatch: string;
  mapOfAutoValues: Record<string, string>;
  enableSubscription?: boolean;
}

export default function useAutoValue ({
  fieldToWatch = '',
  mapOfAutoValues = {},
  enableSubscription = false,
}: AutoValueHookProps): string {
  const templateId = useWatch({ name: 'templateId' });

  const name = useGetTemplateModuleName(templateId, fieldToWatch);

  const disabled = !enableSubscription || !fieldToWatch;

  const value = useWatch({ name, disabled });

  return mapOfAutoValues[value];
}
