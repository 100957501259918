import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetModularObjectByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetModularObjectByIdQuery = { __typename?: 'Query', getModularObjectByID: { __typename?: 'ModularObject', id: string, name: string | null, visibility: string | null, template: { __typename?: 'Template', id: string | null, name: string | null } | null } };


export const GetModularObjectByIdDocument = gql`
    query GetModularObjectByID($id: UUID!) {
  getModularObjectByID(id: $id) {
    id
    name
    visibility
    template {
      id
      name
    }
  }
}
    `;

/**
 * __useGetModularObjectByIdQuery__
 *
 * To run a query within a React component, call `useGetModularObjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModularObjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModularObjectByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetModularObjectByIdQuery(baseOptions: Apollo.QueryHookOptions<GetModularObjectByIdQuery, GetModularObjectByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModularObjectByIdQuery, GetModularObjectByIdQueryVariables>(GetModularObjectByIdDocument, options);
      }
export function useGetModularObjectByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModularObjectByIdQuery, GetModularObjectByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModularObjectByIdQuery, GetModularObjectByIdQueryVariables>(GetModularObjectByIdDocument, options);
        }
export function useGetModularObjectByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetModularObjectByIdQuery, GetModularObjectByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModularObjectByIdQuery, GetModularObjectByIdQueryVariables>(GetModularObjectByIdDocument, options);
        }
export type GetModularObjectByIdQueryHookResult = ReturnType<typeof useGetModularObjectByIdQuery>;
export type GetModularObjectByIdLazyQueryHookResult = ReturnType<typeof useGetModularObjectByIdLazyQuery>;
export type GetModularObjectByIdSuspenseQueryHookResult = ReturnType<typeof useGetModularObjectByIdSuspenseQuery>;
export type GetModularObjectByIdQueryResult = Apollo.QueryResult<GetModularObjectByIdQuery, GetModularObjectByIdQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetModularObjectByIdQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ getModularObjectByID })
 *   )
 * })
 */
export const mockGetModularObjectByIdQuery = (resolver: Parameters<typeof graphql.query<GetModularObjectByIdQuery, GetModularObjectByIdQueryVariables>>[1]) =>
  graphql.query<GetModularObjectByIdQuery, GetModularObjectByIdQueryVariables>(
    'GetModularObjectByID',
    resolver
  )
