import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type RevertHistoryUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type RevertHistoryUpdateMutation = { __typename?: 'Mutation', revertHistoryUpdate: { __typename?: 'RevertedChanges', diffs: Array<{ __typename?: 'Diff', id: string, externalID: string, externalType: string, diffType: Types.DiffType | null, createdAt: any, createdBy: string, fromMigration: boolean, triggeredByID: string | null, diff: { __typename?: 'FromTo', from: any | null, to: any | null, displayNames: any | null } }>, history: { __typename?: 'ModularObjectHistoryUpdate', id: string, modularObjectId: string, updateType: string, createdAt: any, createdById: string, canUndo: boolean, data: { __typename?: 'HistoryUpdateData', changes: any | null, dependencyIDs: Array<string> | null }, createdBy: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null, organizationId: string | null } } } };


export const RevertHistoryUpdateDocument = gql`
    mutation RevertHistoryUpdate($id: UUID!) {
  revertHistoryUpdate(id: $id) {
    diffs {
      id
      externalID
      externalType
      diffType
      diff {
        from
        to
        displayNames
      }
      createdAt
      createdBy
      fromMigration
      triggeredByID
    }
    history {
      id
      modularObjectId
      updateType
      data {
        changes
        dependencyIDs
      }
      createdAt
      createdById
      createdBy {
        id
        firstName
        lastName
        organizationId
      }
      canUndo
    }
  }
}
    `;
export type RevertHistoryUpdateMutationFn = Apollo.MutationFunction<RevertHistoryUpdateMutation, RevertHistoryUpdateMutationVariables>;

/**
 * __useRevertHistoryUpdateMutation__
 *
 * To run a mutation, you first call `useRevertHistoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertHistoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertHistoryUpdateMutation, { data, loading, error }] = useRevertHistoryUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevertHistoryUpdateMutation(baseOptions?: Apollo.MutationHookOptions<RevertHistoryUpdateMutation, RevertHistoryUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevertHistoryUpdateMutation, RevertHistoryUpdateMutationVariables>(RevertHistoryUpdateDocument, options);
      }
export type RevertHistoryUpdateMutationHookResult = ReturnType<typeof useRevertHistoryUpdateMutation>;
export type RevertHistoryUpdateMutationResult = Apollo.MutationResult<RevertHistoryUpdateMutation>;
export type RevertHistoryUpdateMutationOptions = Apollo.BaseMutationOptions<RevertHistoryUpdateMutation, RevertHistoryUpdateMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRevertHistoryUpdateMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ revertHistoryUpdate })
 *   )
 * })
 */
export const mockRevertHistoryUpdateMutation = (resolver: Parameters<typeof graphql.mutation<RevertHistoryUpdateMutation, RevertHistoryUpdateMutationVariables>>[1]) =>
  graphql.mutation<RevertHistoryUpdateMutation, RevertHistoryUpdateMutationVariables>(
    'RevertHistoryUpdate',
    resolver
  )
