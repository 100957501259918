import { useEditContext } from '@/components';
import cx from 'classnames';
import HookFormInput from './HookFormInput';
import HookFormLabel from './HookFormLabel';

export interface RadioInputProps {
  inputProps: React.ComponentProps<typeof HookFormInput>;
  labelProps: React.ComponentProps<typeof HookFormLabel>;
  className?: HTMLDivElement['className'];
}

export default function RadioInput ({
  className,
  labelProps,
  inputProps,
}: RadioInputProps): JSX.Element {
  const { className: inputClassName = '', ...rest } = inputProps;
  const { canUserEdit } = useEditContext();

  return (
    <div data-testid='radio-option' className={`flex gap-1 ${className ?? ''}`}>
      <HookFormLabel className='mr-2 input-label' {...labelProps} />
      <HookFormInput {...rest} className={cx('!grow-0', inputClassName)} type='radio' disabled={!canUserEdit} />
    </div>
  );
}
