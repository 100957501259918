import { usePrismaticContext } from '@/components/Contexts/prismatic.context';
import InputCellMappingScreen from '@/components/modals/ExcelIntegrationMappingModal/screens/InputCellMappingScreen';
import SelectExcelFileScreen from '@/components/modals/ExcelIntegrationMappingModal/screens/SelectExcelFileScreen';
import SelectExcelSheetScreen from '@/components/modals/ExcelIntegrationMappingModal/screens/SelectExcelSheetScreen';
import ShowCurrentMappingScreen from '@/components/modals/ExcelIntegrationMappingModal/screens/ShowCurrentMappingScreen';
import Stepper, { Step } from '@/components/Stepper/Stepper';
import { addToast, addToastError } from '@/components/Toast/utils';
import { useModal } from '@/hooks/useModal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import MegaModal from '../MegaModal/MegaModal';

interface ExcelIntegrationMappingModalProps {
  moduleId: string;
}

export default function ExcelIntegrationMappingModal ({
  moduleId,
}: ExcelIntegrationMappingModalProps): JSX.Element {
  const {
    excelMappingData,
    getExcelFilesEndpoint,
    getExcelSheetsEndpoint,
    submitExcelMapping,
    refreshExcelSubscription,
  } = usePrismaticContext();
  const { closeModal } = useModal();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [excelFilesList, setExcelFilesList] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sheetsList, setSheetsList] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedFile, setSelectedFile] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedSheet, setSelectedSheet] = useState<any>(null);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);

  useEffect(() => {
    if (excelMappingData?.sharepointFileId && excelFilesList?.length) {
      setSelectedFile(excelFilesList.find((file) => file.id === excelMappingData.sharepointFileId));
    }
  }, [excelFilesList, excelMappingData?.sharepointFileId]);

  useEffect(() => {
    if (excelMappingData?.sharepointFileId && sheetsList?.length) {
      setSelectedSheet(sheetsList.find((sheet) => sheet.name === excelMappingData.sheetName));
    }
  }, [excelMappingData?.sharepointFileId, excelMappingData?.sheetName, sheetsList]);

  const initialStep = useMemo(() => {
    const cellMappingList = Object.keys(excelMappingData?.cellMapping ?? {});
    return cellMappingList.length
      ? excelMappingData?.cellMapping?.[moduleId]
        ? 4
        : 3
      : 1;
  }, [excelMappingData?.cellMapping, moduleId]);

  useEffect(() => {
    if (getExcelFilesEndpoint) {
      setIsFetchingData(true);
      fetch(getExcelFilesEndpoint)
        .then((response) => response.json())
        .then((data) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setExcelFilesList(data.filter((file: any) => file.name.endsWith('.xlsx')));
          setIsFetchingData(false);
        })
        .catch((err) => {
          setIsFetchingData(false);
          console.error(err);
        });
    }
  }, [getExcelFilesEndpoint]);

  useEffect(() => {
    if (getExcelSheetsEndpoint && selectedFile) {
      setIsFetchingData(true);
      fetch(getExcelSheetsEndpoint, { headers: { 'file-id': selectedFile.id } })
        .then((response) => response.json())
        .then((data) => {
          setSheetsList(Array.isArray(data) ? data : []);
          setIsFetchingData(false);
        })
        .catch((err) => {
          setIsFetchingData(false);
          console.error(err);
        });
    }
  }, [getExcelSheetsEndpoint, selectedFile]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmitMapping = useCallback(async (mapping: any) => {
    setIsFetchingData(true);
    try {
      await submitExcelMapping(selectedFile?.id, selectedSheet?.name, mapping);
      setIsFetchingData(false);
      closeModal();
      addToast('Excel mapping saved successfully');
    } catch (err) {
      setIsFetchingData(false);
      console.error(err);
      addToastError('Failed to save Excel mapping');
    }
  }, [closeModal, selectedFile?.id, selectedSheet?.name, submitExcelMapping]);

  const deleteMapping = useCallback(async () => {
    setIsFetchingData(true);
    try {
      delete excelMappingData?.cellMapping?.[moduleId];
      await submitExcelMapping(selectedFile?.id, selectedSheet?.name, excelMappingData?.cellMapping);
      setIsFetchingData(false);
      closeModal();
      addToast('Excel mapping deleted successfully');
    } catch (err) {
      setIsFetchingData(false);
      console.error(err);
      addToastError('Failed to delete Excel mapping');
    }
  }, [closeModal, excelMappingData?.cellMapping, moduleId, selectedFile?.id, selectedSheet?.name, submitExcelMapping]);

  return (
    <MegaModal className='w-screen'>
      <Stepper initialStep={initialStep}>
        <Step
          stepNumber={1}
          stepContent={SelectExcelFileScreen}
          handleSelectedFile={setSelectedFile}
          filesList={excelFilesList}
          isFetchingData={isFetchingData && !excelFilesList?.length}
        />
        <Step
          stepNumber={2}
          stepContent={SelectExcelSheetScreen}
          selectedFile={selectedFile}
          handleSelectedSheet={setSelectedSheet}
          sheetsList={sheetsList}
          isFetchingData={isFetchingData}
        />
        <Step
          stepNumber={3}
          stepContent={InputCellMappingScreen}
          selectedFile={selectedFile}
          selectedSheet={selectedSheet}
          isFetchingData={isFetchingData}
          mapping={excelMappingData?.cellMapping}
          submitMapping={handleSubmitMapping}
          moduleId={moduleId}
        />
        <Step
          stepContent={ShowCurrentMappingScreen}
          stepNumber={4}
          selectedFile={selectedFile}
          selectedSheet={selectedSheet}
          mapping={excelMappingData}
          moduleId={moduleId}
          isFetchingData={isFetchingData}
          deleteMapping={deleteMapping}
          refreshSubscription={refreshExcelSubscription}
        />
      </Stepper>
    </MegaModal>
  );
}
