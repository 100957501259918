import { useObjectCardContext } from '@/state/ObjectCard.context';
import cx from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';

export interface ResizeableDrawerProps {
  children: React.ReactNode;
  defaultWidth?: number;
  open: boolean;
}

export default function ResizeableDrawer (
  { children, defaultWidth = 800, open }: ResizeableDrawerProps,
): JSX.Element {
  const { isObjectCardFullWidth } = useObjectCardContext();
  const drawerRef = useRef<HTMLDivElement>(null);
  const hasBeenOpened = useRef<boolean>(false);
  const lastCardWidth = useRef<number>(defaultWidth);

  const [isRendered, setIsRendered] = useState<boolean>(open);
  const [isResizing, setIsResizing] = useState(false);
  const [cardWidth, setCardWidth] = useState(0);

  const startResizing = useCallback((e) => {
    // These should keep the mouse down event from selecting text while resizing
    e.preventDefault();
    e.stopPropagation();

    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent) => {
      if (isResizing && open) {
        const newWidth = drawerRef.current.getBoundingClientRect().right - mouseMoveEvent.clientX;
        lastCardWidth.current = newWidth;
        setCardWidth(newWidth);
      }
    },
    [isResizing],
  );

  useEffect(() => {
    const handleTransitionEnd = () => {
      if (!open) {
        setIsRendered(false);
      } else {
        setIsRendered(true);
      }
    };

    // Add event listeners for resizing
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);

    // Add event listeners for width transition
    drawerRef.current.addEventListener('transitionend', handleTransitionEnd);

    return () => {
      // Remove event listeners for resizing
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);

      // Remove event listeners for width transition
      drawerRef?.current?.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [resize, stopResizing, open, drawerRef]);

  useEffect(() => {
    if (open) {
      hasBeenOpened.current = true;
      setCardWidth(lastCardWidth.current);
    } else {
      // If we don't track if this has been opened, we can accidentally set the lastCardWidth to 0 and the card will never open
      if (hasBeenOpened.current) {
        lastCardWidth.current = cardWidth;
      }
      setCardWidth(0);
    }
  }, [open]);

  return (
    <div
      data-testid='resizeable-drawer'
      ref={drawerRef}
      className={cx(
        'flex shrink h-full relative',
        {
          'max-w-[850px] shadow-lg': open,
          'min-w-[600px]': isRendered,
          'transition-all duration-300': !isResizing,
          'mx-auto': isObjectCardFullWidth,
        },
      )}
      style={{
        width: cardWidth,
      }}
    >
      {!isObjectCardFullWidth && (
        <div
          className={cx(
            'absolute top-0 justify-self-end w-1 h-full resize-x left-[-3px] grow-0 shrink-0 basis-1 hover:bg-neutral-200',
            {
              'cursor-col-resize': open,
            },
          )}
          onMouseDown={startResizing}
          data-testid='object-card-resize-handle'
        />
      )}
      {isRendered && (
        <div className='flex overflow-hidden w-full h-full animate-fade-in'>
          {children}
        </div>
      )}
    </div>
  );
}
