import dayjs from 'dayjs';
import type { ObjectCardSubTaskFragment } from '../getObjectCardSubTasks.generated';

export default function useFilteredTasks (
  tasks: ObjectCardSubTaskFragment[],
  startDateFilter: string,
  targetDateFilter: string,
  parentFilter: string,
) {
  const taskArray = tasks.filter(Boolean);

  if (parentFilter) {
    return taskArray.sort((a, b) => {
      if (parentFilter === 'asc') {
        return a?.parent?.name?.localeCompare(b?.parent?.name) ||
          dayjs(a.targetDate).diff(dayjs(b.targetDate));
      } else {
        return b?.parent?.name?.localeCompare(a?.parent?.name) ||
          dayjs(a.targetDate).diff(dayjs(b.targetDate));
      }
    });
  }

  if (startDateFilter) {
    return taskArray.sort((a, b) => {
      const aDate = new Date(a.startDate).getTime();
      const bDate = new Date(b.startDate).getTime();

      return startDateFilter === 'asc' ? aDate - bDate : bDate - aDate;
    });
  }

  if (targetDateFilter) {
    return taskArray.sort((a, b) => {
      const aDate = new Date(a.targetDate).getTime();
      const bDate = new Date(b.targetDate).getTime();

      return targetDateFilter === 'asc' ? aDate - bDate : bDate - aDate;
    });
  }

  return taskArray;
}
