import { useEffect, useState } from 'react';
import { useObjectContext } from '../Card/Object.context';

export default function useShouldAnimate (animate = false) {
  const { activeModularObject } = useObjectContext();
  const [shouldAnimate, setShouldAnimate] = useState(animate);

  useEffect(() => {
    setShouldAnimate(true);

    // eslint-disable-next-line -- not sure why it thinks this isn't reassigned
    let timeout;

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setShouldAnimate(false);
    }, 1000);
  }, [activeModularObject?.id]);

  return shouldAnimate;
}
