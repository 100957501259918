import type { HardwareIntegration, ModularObject, Share, User } from '@/__generated__/types';
import { getTheBandBackTogether } from '@/components/common/ModularObject/Card/ObjectCard.util';
import { getFlattenedModules, getFlattenedModulesWithIds } from '@/components/common/ModularObject/Page/utils';
import { type ManifestableObject } from '@/models/manifestableObject.model';
import { type RootState } from '@/state/store';
import { OBJECT_TYPES } from '@/util/constants';
import type { HardwareIntegrationWithIntegrations } from '@/util/typeHelpers';
import { get } from 'lodash';
import { dropLast, last } from 'ramda';
import { getModularObjects, getTemplates } from './requests.functions';
import type { HydratedModule } from './typeHelpers';

export function getObject (externalType: string, externalID: string, state: RootState) {
  const typeMap = {
    modular_object: state.modularObjects,
    template: state.templates,
  };

  const foundObj = typeMap[externalType] ?? {};

  return foundObj[externalID];
}

export function getObjectType (id: string, state: RootState): string {
  for (const type of OBJECT_TYPES) {
    const object = getObject(type, id, state);
    if (object) return type;
  }

  return '';
}

export function getObjectName (externalType: string, externalID: string, state: RootState): string {
  const object = getObject(externalType, externalID, state);
  if (externalType === 'modular_object') {
    return getModularObjectName(object);
  }
  return object?.name ?? 'Unknown';
}

export function findUserById (userID: string, state: RootState): User {
  const { users } = state;
  return users?.[userID];
}

export function findShareById (externalID: string, shareID: string, state: RootState): Share {
  const { shares } = state;
  const collaborator = shares[externalID]?.[shareID];
  if (collaborator) return collaborator;
}

interface StateObject {
  id?: string;
  name?: string;
}

export function getNameById<T extends StateObject> (id: string, list: T[], fallback?: string): string {
  if (!id) return null;
  const image = list?.find((thing) => thing?.id === id);
  return image?.name ?? fallback ?? 'Unknown';
}

export function getUserFullName (user: Partial<User>) {
  if (user?.firstName && !user?.lastName) {
    return user?.firstName;
  }
  if (!user?.firstName && !user?.lastName) {
    return 'Unknown';
  }
  return `${user?.firstName} ${user?.lastName}`;
}

export function getUserRole (externalType: string, externalID: string, userID: string, state: RootState): string {
  // Check if user is the owner
  const objectOwnerId = getObject(externalType, externalID, state)?.ownerId;
  if (objectOwnerId === userID) return 'Owner';

  // Check if user is a collaborator
  const objectShares = state.shares[externalID];
  const collaborator = Object.values(objectShares ?? {})?.find((share) => share.userId === userID);
  if (collaborator) return collaborator.role;

  // User is not a collaborator or owner
  return 'Viewer';
}

export function getObjectMass (
  object: ManifestableObject,
  state: RootState,
  parent?: ManifestableObject,
  getOwnMass?: boolean,
): number {
  if (object?.children?.length && !getOwnMass) {
    return getObjectMass(object, state, parent, true) + object?.children?.reduce((acc, curr) => {
      return acc + getObjectMass(curr, state, object);
    }, 0);
  }
}

export function isObjectOwner (externalType: string, externalId: string, userId: string, state: RootState): boolean {
  return getUserRole(externalType, externalId, userId, state) === 'Owner';
}

export function getModularObjectName (modularObject: ModularObject): string {
  return modularObject?.name ?? null;
}

export function getModuleByName (modules: Record<string, HydratedModule>, name: string): HydratedModule {
  const flattenedModules = getFlattenedModules(modules);
  return flattenedModules?.find((module) => module?.name?.toLowerCase() === name?.toLowerCase());
}

export function getModuleIdByName (modules: Record<string, HydratedModule>, name: string): string {
  const flattenedModules = getFlattenedModulesWithIds(modules);
  const foundModule = flattenedModules?.find((module) => module?.name?.toLowerCase() === name?.toLowerCase());
  return foundModule?.id;
}
export function getAllModulesWithName (modules: Record<string, HydratedModule>, name: string): HydratedModule[] {
  const flattenedModules = getFlattenedModules(modules);
  return flattenedModules?.filter((module) => name?.toLowerCase()?.includes(module?.name?.toLowerCase()));
}

export function getModulePath (modules: Record<string, HydratedModule>, name: string) {
  const paths = [];
  Object.entries(modules ?? {})?.some(([key, module]) => {
    if (module?.name?.toLowerCase() === name?.toLowerCase()) {
      paths.push(key);
      return true;
    }
    if (Object.values(module?.modules ?? {})?.length) {
      const foundPaths = getModulePath(module.modules, name);
      if (foundPaths?.length) {
        paths.push(key, 'modules', ...foundPaths);
        return true;
      }
    }
    return false;
  });

  return paths;
}

export function getClosestParentModuleByType (modules: unknown, type: string, path: string[]): HydratedModule {
  const parentPath = dropLast(1, path);
  if (!parentPath?.length) return null;

  const parentModule = get(modules, parentPath);
  if (parentModule?.type === type) {
    return parentModule;
  }

  return getClosestParentModuleByType(modules, type, parentPath);
}

export function getModuleSectionDisplayName (values: unknown, modulePath: string[]): string {
  const parentCollapsibleSection = getClosestParentModuleByType(values, 'collapsibleSection', modulePath);
  const parentCollapsibleSectionLabel: string = parentCollapsibleSection?.properties?.labelProps?.label;
  const parentCollapsibleSectionName = parentCollapsibleSection?.name;

  const parentRepeaterModule = getClosestParentModuleByType(values, 'repeater', modulePath);
  if (parentRepeaterModule) {
    // If the module is part of a repeater module, also include the order
    const parentCollapsibleSectionNumber = parentCollapsibleSection?.order + 1;
    return `${parentCollapsibleSectionLabel || parentCollapsibleSectionName} ${parentCollapsibleSectionNumber}`;
  }

  return parentCollapsibleSectionLabel || parentCollapsibleSectionName;
}

export function getModuleDisplayName (values: unknown, modulePath: string[]): string {
  if (!modulePath?.length) return '';
  const fieldModule: HydratedModule = get(values, modulePath);
  const moduleLabel: string = fieldModule?.properties?.labelProps?.label;
  const moduleName = fieldModule?.name;
  const moduleSuffix = fieldModule?.properties?.suffix;

  // Include section name in field name to describe which section the field is in
  const sectionDisplayName = getModuleSectionDisplayName(values, modulePath);
  if (sectionDisplayName) {
    return sectionDisplayName + ' - ' + String(moduleLabel || moduleName || last(modulePath)) +
      (moduleSuffix ? ' (' + String(moduleSuffix) + ')' : '');
  }
  return (moduleLabel || moduleName || last(modulePath)) + (moduleSuffix ? ` (${moduleSuffix})` : '');
}

export function getModularObjectTemplateName (objectId: string, state: RootState): string {
  const object = getObject('modular_object', objectId, state);
  const template = getObject('template', object?.templateId, state);
  return template?.name ?? 'Unknown';
}

export function getAllParentObjectIds (objectId: string, integrations: Record<string, HardwareIntegration>): string[] {
  // Recursively get all parent integrations for a given object
  const parentIntegrations = Object.values(integrations).filter((integration) => integration?.objectId === objectId);
  const parentIntegrationIds = parentIntegrations.map(
    (integration) => integration?.parentId,
  );
  const grandparentIntegrations = parentIntegrations.map(
    (integration) => getAllParentObjectIds(integration?.parentId, integrations),
  ).flat();
  return [...parentIntegrationIds, ...grandparentIntegrations];
}

export function getObjectNestedIntegrationsTree (
  objectId: string,
  integrations: Record<string, HardwareIntegration>,
  limitDepth: number = 0, // Default limitDepth of 0 doesn't limit depth
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback?: (integration: Partial<HardwareIntegrationWithIntegrations>) => any,
  objectIntegrations: Record<
    string,
    HardwareIntegrationWithIntegrations
  > = {},
  depth: number = 0,
): Record<string, HardwareIntegrationWithIntegrations> {
  if (limitDepth !== 0) {
    depth++;
  }

  // Recursively get all nested integrations for a given object
  Object.values(integrations || {}).forEach((integration) => {
    // Check if integration is a nested integration of the object
    if (objectId && integration?.parentId === objectId) {
      let _integration = { ...integration, integrations: {} };

      // If callback call it to mutate the integration
      if (callback) {
        _integration = callback(_integration);
      }

      // Check if the callback removed the integration
      if (!_integration) return;

      // Add integration to objectIntegrations
      objectIntegrations[_integration?.objectId] = _integration;
      // Recursively get all nested integrations for the integration
      if (depth < limitDepth || limitDepth === 0) {
        objectIntegrations[integration?.objectId].integrations = getObjectNestedIntegrationsTree(
          integration?.objectId,
          integrations,
          limitDepth,
          callback,
          objectIntegrations[integration?.objectId].integrations,
          depth,
        );
      }
    }
  });

  return objectIntegrations;
}

export function getAllChildIntegrations (
  objectId: string,
  integrations: Record<string, HardwareIntegration>,
): HardwareIntegration[] {
  // Recursively get all child integrations for a given object
  const childIntegrations = Object.values(integrations || {}).filter((integration) =>
    integration?.parentId === objectId
  );

  const grandchildIntegrations = childIntegrations.map(
    (integration) => getAllChildIntegrations(integration?.objectId, integrations),
  ).flat();

  return [...childIntegrations, ...grandchildIntegrations];
}

export function getAllChildObjects (
  objectId: string,
  integrations: Record<string, HardwareIntegration>,
  modularObjects: Record<string, ModularObject>,
): ModularObject[] {
  const childIntegrations = getAllChildIntegrations(objectId, integrations);
  const childObjects = childIntegrations.map((integration) => modularObjects[integration?.objectId]);
  return childObjects;
}

export function getChildObjectIds (objectId: string, integrations: Record<string, HardwareIntegration>): string[] {
  return Object.values(integrations).filter((i) => i.parentId === objectId).map((i) => i.objectId);
}

const update = (t, k, f) => t.set(k, f(t.get(k)));

const append = (t, k, v) => update(t, k, (a = []) => [...a, v]);

const indexChildrenByParentId = (a = [], f) => a.reduce((t, v) => append(t, f(v), v), new Map());

const mapIndexedChildrenToParent = (i, f, root = null) => {
  const many = (a = []) => a.map(v => one(v));
  const one = (v) => f(v, next => many(i.get(next)));

  return many(i.get(root));
};

export default function getTreeFromObjectArray (
  arr,
  indexFn = ({ parentId }) => parentId,
) {
  return mapIndexedChildrenToParent(
    indexChildrenByParentId(arr, indexFn),
    (node, findChildrenBy) => ({ ...node, rows: findChildrenBy(node?.id) }),
  );
}

export function getParentObject (
  objectType: string,
  objectId: string,
  state: RootState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [parent: any, parentType: string] {
  // Note: Missions and Deployers don't have parents
  // Get the parent object of a given object
  if (objectType === 'spacecraft') {
    const spacecraft = getObject(objectType, objectId, state);
    const parent = getObject(spacecraft?.parentType, spacecraft?.parentID, state);
    const mission = getObject('mission', spacecraft?.missionId, state);
    return parent ? [parent, spacecraft?.parentType] : [mission, 'mission'];
  }

  if (objectType === 'milestone') {
    const milestone = getObject(objectType, objectId, state);
    if (milestone.hardwareId && milestone.hardwareType) {
      const parent = getObject(milestone?.hardwareType, milestone?.hardwareId, state);
      return parent ? [parent, milestone?.hardwareType] : [null, null];
    } else if (milestone.nonHardwareId && milestone.nonHardwareType) {
      const parent = getObject(milestone?.nonHardwareType, milestone?.nonHardwareId, state);
      return parent ? [parent, milestone?.nonHardwareType] : [null, null];
    }
  }

  // Check integrations if payload or modular object
  if (objectType === 'payload' || objectType === 'modular_object') {
    const integration = Object.values(state.integrations || {})
      .find((integration) => integration?.objectId === objectId);

    if (integration) {
      const parent = getObject(integration?.parentType, integration?.parentId, state);
      return [parent, integration?.parentType];
    }
  }

  return [null, null];
}

export function getParentObjectName (objectType: string, objectId: string, state: RootState) {
  const [parent, parentType] = getParentObject(objectType, objectId, state);
  return getObjectName(parentType, parent?.id, state);
}

export function getSameOrg (object, user, userState): boolean {
  return userState[object?.ownerId]?.organizationId === user?.organizationId;
}

export function getIsOwner (object, user): boolean {
  return object?.ownerId === user?.id;
}

export async function getHydratedModularObjectsAndTemplates () {
  try {
    const [templates, modularObjects] = await Promise.all([
      getTemplates(),
      getModularObjects(),
    ]);

    const hydratedModularObjects = modularObjects.map((modularObject) => {
      const template = templates.find((template) => template.id === modularObject.templateId);
      modularObject.data = getTheBandBackTogether(
        template.modules,
        modularObject.data,
        modularObject?.customFields ?? {},
      );
      return modularObject;
    });

    return [templates, hydratedModularObjects];
  } catch (error) {
    console.error(error);
  }
}
