import { Button } from '@/components/Button/Button';
import { dehydrateModularObject } from '@/components/common/ModularObject/utils';
import type { TaskFragment } from '@/components/Schedule/queries/taskFragment.generated';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useAppSelector } from '@/state/hooks';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { selectRootState } from '@/state/selectors';
import getIcon from '@/util/getIcon';
import { getModularObjectTemplateName } from '@/util/lookup.functions';
import cx from 'classnames';

interface TaskParentProps {
  task: TaskFragment;
  className?: string;
  rootClassName?: string;
}

export default function TaskParent ({ task, className, rootClassName }: Readonly<TaskParentProps>): JSX.Element {
  const currentUser = useLoggedInUser();
  const state = useAppSelector(selectRootState);
  const currentParent = {
    ...task?.parent,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...dehydrateModularObject((task?.parent as any)?.data, true),
  };

  const parentTemplateName = getModularObjectTemplateName(currentParent?.id, state) || null;
  const { openViewObjectCardDrawer } = useObjectCardContext();

  const openParent = (): void => {
    openViewObjectCardDrawer({ modularObjectId: currentParent?.id });
  };
  const hasAssignee = Boolean(task?.assigneeId);
  const isExternalOwner = currentUser?.organizationId !== task?.owner?.organizationId;
  const isExternalAssignee = currentUser?.organizationId !== task?.assignee?.organizationId && hasAssignee;
  const isExternal = isExternalOwner || isExternalAssignee;

  return (
    <Button
      className={cx('flex items-center font-normal font-sculpin text-left', rootClassName)}
      innerClassName='flex gap-3 items-center'
      onClick={openParent}
    >
      {currentParent?.name && (
        <div className='flex items-center w-[12px] h-[12px] aspect-square'>
          {getIcon(parentTemplateName.toLowerCase())}
        </div>
      )}
      <span
        className={cx('truncate font-sculpin font-medium', className, {
          'text-black': !isExternal,
          'text-tertiary': isExternal,
        })}
      >
        {!currentParent?.name ? '–' : currentParent?.name}
      </span>
    </Button>
  );
}
