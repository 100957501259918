import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetModularObjectNameQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetModularObjectNameQuery = { __typename?: 'Query', getModularObjectByID: { __typename?: 'ModularObject', id: string, name: string | null } };


export const GetModularObjectNameDocument = gql`
    query GetModularObjectName($id: UUID!) {
  getModularObjectByID(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetModularObjectNameQuery__
 *
 * To run a query within a React component, call `useGetModularObjectNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModularObjectNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModularObjectNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetModularObjectNameQuery(baseOptions: Apollo.QueryHookOptions<GetModularObjectNameQuery, GetModularObjectNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModularObjectNameQuery, GetModularObjectNameQueryVariables>(GetModularObjectNameDocument, options);
      }
export function useGetModularObjectNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModularObjectNameQuery, GetModularObjectNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModularObjectNameQuery, GetModularObjectNameQueryVariables>(GetModularObjectNameDocument, options);
        }
export function useGetModularObjectNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetModularObjectNameQuery, GetModularObjectNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModularObjectNameQuery, GetModularObjectNameQueryVariables>(GetModularObjectNameDocument, options);
        }
export type GetModularObjectNameQueryHookResult = ReturnType<typeof useGetModularObjectNameQuery>;
export type GetModularObjectNameLazyQueryHookResult = ReturnType<typeof useGetModularObjectNameLazyQuery>;
export type GetModularObjectNameSuspenseQueryHookResult = ReturnType<typeof useGetModularObjectNameSuspenseQuery>;
export type GetModularObjectNameQueryResult = Apollo.QueryResult<GetModularObjectNameQuery, GetModularObjectNameQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetModularObjectNameQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ getModularObjectByID })
 *   )
 * })
 */
export const mockGetModularObjectNameQuery = (resolver: Parameters<typeof graphql.query<GetModularObjectNameQuery, GetModularObjectNameQueryVariables>>[1]) =>
  graphql.query<GetModularObjectNameQuery, GetModularObjectNameQueryVariables>(
    'GetModularObjectName',
    resolver
  )
