import { useEffect, useState } from 'react';

export const useIsChromeBrowser = (): boolean => {
  const [isChromeBrowser, setIsChromeBrowser] = useState(false);

  useEffect(() => {
    const isChromium = window.chrome !== null && window.navigator.vendor === 'Google Inc.';
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = typeof window.opr !== 'undefined';
    const isIEedge = winNav.userAgent.includes('Edg');
    const isIOSChrome = winNav.userAgent.includes('CriOS');

    if (isIOSChrome) {
      setIsChromeBrowser(true);
    } else if (
      isChromium !== null && typeof isChromium !== 'undefined' && vendorName === 'Google Inc.' && !isOpera && !isIEedge
    ) {
      setIsChromeBrowser(true);
    } else {
      setIsChromeBrowser(false);
    }
  }, []);

  return isChromeBrowser;
};
