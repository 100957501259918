import { Button } from '@/designSystemComponents/Button';

export interface ConfirmDeleteDependencyModalProps {
  closeModal: () => void;
  handleDelete: () => Promise<void>;
}

export const ConfirmDeleteDependencyModal = ({ closeModal, handleDelete }: ConfirmDeleteDependencyModalProps) => {
  return (
    <div className='flex flex-col flex-1 gap-5'>
      <h3 className='mb-0 text-3xl font-black capitalize font-sculpin text-secondary'>Are you sure?</h3>
      <p>This dependency will be removed.</p>
      <div className='flex gap-5'>
        <Button figmaProps={{ style: 'outline', size: 'large' }} className='flex-1' onClick={closeModal}>
          Cancel
        </Button>

        <Button figmaProps={{ style: 'fill', size: 'large' }} className='flex-1' onClick={handleDelete}>
          Remove
        </Button>
      </div>
    </div>
  );
};
