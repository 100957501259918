import type * as Types from '../../../../../../__generated__/types';

import { gql } from '@apollo/client';
export type UpdatedModularObjectFragment = { __typename?: 'ModularObject', id: string, status: Types.TaskStatus | null };

export const UpdatedModularObjectFragmentDoc = gql`
    fragment UpdatedModularObject on ModularObject {
  id
  status
}
    `;