import cx from 'classnames';
import { type ComponentProps } from 'react';

interface LabelProps extends ComponentProps<'label'> {}

export function Label (props: LabelProps) {
  const { className = '', children, ...otherProps } = props;

  return (
    <label className={cx('effra-xs text-black/60 block', className)} {...otherProps}>
      {children}
    </label>
  );
}
