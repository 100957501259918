import { type File } from '@/__generated__/types';
import { addToastError } from '@/components/Toast/utils';
import { uploadFile } from '@/util/requests.functions';
import { type ChangeEvent, useEffect, useRef, useState } from 'react';

export type FileOrString = File | string;
export type HandleFileDownload = (FileOrString, number, boolean?) => Promise<void>;
export type HandleFileUpload = () => void;
export type HandleFileRemove = (number) => void;
export type HandleFileRef = (HTMLElement, number) => void;
export type GetHREF = (number) => string;

function isURL (str: string): boolean {
  try {
    // eslint-disable-next-line no-new
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
}

export interface FileUploaderRenderProps {
  handleFileDownload: HandleFileDownload;
  handleFileUpload: HandleFileUpload;
  handleFileRemove: HandleFileRemove;
  handleFileRef: HandleFileRef;
  isUploading: boolean;
  objectFiles: FileOrString[];
  objectType: string;
  getHREF: GetHREF;
}

interface RenderWhenEmptyProps {
  handleFileUpload: HandleFileUpload;
  isUploading: boolean;
}

interface FileUploaderProps {
  fileAccept?: string;
  objectType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  objectEdit: any;
  objectFileKey?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleObjectEdit: (key: string, value: any) => void;
  render: (
    props: FileUploaderRenderProps,
  ) => JSX.Element;
  renderWhenEmpty?: ({ handleFileUpload, isUploading }: RenderWhenEmptyProps) => JSX.Element;
  setIsDirty?: (isDirty: boolean) => void;
  tags?: string[];
}

export function FileUploader (props: FileUploaderProps): JSX.Element {
  const {
    fileAccept = '*',
    objectType,
    objectEdit,
    objectFileKey = 'files',
    handleObjectEdit,
    render,
    renderWhenEmpty,
    setIsDirty,
    tags = ['file'],
  } = props;
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [objectFiles, setObjectFiles] = useState<FileOrString[]>([]);
  const downloaders = useRef([]);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    const filesOrString = objectEdit?.[objectFileKey];
    if (Array.isArray(filesOrString)) {
      setObjectFiles(filesOrString);
    } else {
      setObjectFiles([filesOrString]);
    }
  }, []);

  const handleFileRemove = (idToRemove: string): void => {
    const filteredFiles = objectFiles.filter(
      (file) => typeof file !== 'string' && file.id !== idToRemove,
    );
    setObjectFiles(filteredFiles);
    handleObjectEdit('files', filteredFiles);
    if (idToRemove === objectEdit?.imageId) {
      handleObjectEdit('imageId', '');
    }
  };

  useEffect(() => {
    const objects = objectEdit?.[objectFileKey];
    if (objects) {
      setObjectFiles(Array.isArray(objects) ? objects : [objects]);
    }
  }, [objectEdit]);

  const handleFileUpload = (): void => hiddenFileInput?.current?.click();
  const handleFileDownload = async (
    fileObject: FileOrString,
    index: number,
    click: boolean = true,
  ): Promise<void> => {
    if (!fileObject || !downloaders.current[index]) {
      return;
    }

    // If the fileObject is a string that is also a URL, then use it directly as an HREF
    if (typeof fileObject === 'string' && isURL(fileObject)) {
      downloaders.current[index].href = fileObject;
    } else {
      // If the fileObject is a string but not a URL, then it is an ID. Find the correlated file.
      if (typeof fileObject === 'string') {
        const files: File[] = objectEdit?.files;
        fileObject = files?.find((file) => file.id === fileObject);
        // If the file is not found, return
        if (!fileObject) {
          return;
        }
      } else if (fileObject.location.includes('X-Amz-Algorithm')) {
        downloaders.current[index].href = fileObject.location;
      } else {
        const filename = fileObject.name;

        const result = await fetch(`/api/file-proxy?filename=${filename}&id=${fileObject.id}`, {
          credentials: 'include',
        });

        if (result.status <= 299) {
          try {
            const blob = await result.blob();
            const href = window.URL.createObjectURL(blob);
            downloaders.current[index].download = filename;
            downloaders.current[index].href = href;
          } catch (e) {
          }
        } else {
          console.error(result.statusText);
        }
      }
    }

    if (click) {
      downloaders.current[index]?.click();
    }
  };

  const handleSetFileRef = (el: HTMLElement, idx: number): void => {
    downloaders.current[idx] = el;
  };

  const handleOnFileSelect = (event: ChangeEvent<HTMLInputElement>): void => {
    setIsUploadingFile(true);
    setIsDirty(true);
    Array.from(event.target.files).forEach(async (file) => {
      try {
        const uploadedFile = await uploadFile(file, tags);
        const data = objectType === 'user' || objectType === 'organization'
          ? uploadedFile.file.location
          : uploadedFile;
        handleObjectEdit(objectFileKey, data);
        setIsUploadingFile(false);
      } catch (err) {
        addToastError('There was an issue trying to upload the file. Please try again later.');
        console.error(err);
        setIsUploadingFile(false);
      }
    });
  };

  const getHREF = (idx: number): string => {
    return downloaders.current[idx]?.href;
  };

  return (
    <>
      {objectFiles?.length || !renderWhenEmpty
        ? render(
          {
            handleFileDownload,
            handleFileUpload,
            handleFileRemove,
            handleFileRef: handleSetFileRef,
            isUploading: isUploadingFile,
            objectFiles,
            objectType,
            getHREF,
          },
        )
        : renderWhenEmpty({ handleFileUpload, isUploading: isUploadingFile })}
      <input
        accept={fileAccept}
        ref={hiddenFileInput}
        onChange={handleOnFileSelect}
        type='file'
        className='hidden'
      />
    </>
  );
}
