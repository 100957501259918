import SidenavSection from '@/components/SideNavigation/components/SidenavSection';
import { useLoggedInUserRole } from '@/hooks/useLoggedInUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingMagnifyingGlass, faTicket, faUserMagnifyingGlass } from '@fortawesome/sharp-solid-svg-icons';
import { useRouter } from 'next/router';
import SidenavTab from '../components/SidenavTab';

export default function OpsTabs (): JSX.Element {
  const router = useRouter();
  const {
    isSuper: shouldShowOpsTabs,
  } = useLoggedInUserRole();

  if (!shouldShowOpsTabs) {
    return null;
  }

  return (
    <SidenavSection title='Ops'>
      <SidenavTab
        name='Organizations'
        href='/ops/organizations'
        icon={<FontAwesomeIcon icon={faBuildingMagnifyingGlass} />}
        isActive={router.pathname.includes('/ops/organizations')}
        className='py-1'
      />
      <SidenavTab
        name='Users'
        href='/ops/users'
        isActive={router.pathname.includes('/ops/users')}
        icon={<FontAwesomeIcon icon={faUserMagnifyingGlass} />}
        className='py-1'
      />
      <SidenavTab
        name='Coupons'
        href='/ops/coupons'
        isActive={router.pathname.includes('/ops/coupons')}
        icon={<FontAwesomeIcon icon={faTicket} />}
        className='py-1'
      />
    </SidenavSection>
  );
}
