import InfoTooltip from '@/components/tooltip/InfoTooltip';
import metrics from '@/util/metrics';
import { faArrowUpRightFromSquare } from '@fortawesome/sharp-regular-svg-icons';
import { HeaderButton } from './HeaderButton';

interface ExternalLinkButtonProps {
  objectId: string;
}
export default function ExternalLinkButton ({ objectId }: ExternalLinkButtonProps): JSX.Element {
  const openObjectInNewTab = () => {
    metrics.track('Object Card - Open in New Tab', { objectId });
    window.open(`/object?modularObjectId=${objectId}&isObjectCardOpen=true&fullWidth=true`, '_blank');
  };

  return (
    <InfoTooltip message='Open in new tab' tooltipClassName='effra-8' tooltipContentClassName='px-[6px] py-[4px]'>
      <HeaderButton icon={faArrowUpRightFromSquare} onClick={openObjectInNewTab} testId='object-card-external-link' />
    </InfoTooltip>
  );
}
