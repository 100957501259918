import type { TemplateSubTypes } from '@/__generated__/types';
import { TaskPillColor } from '@/models/task.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagSwallowtail, faRhombus, faShield } from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';
import Pill from '../Pill';

export interface SubTaskPillProps {
  color: TaskPillColor;
  type: TemplateSubTypes;
}

export default function SubTaskPill ({ color, type }: Readonly<SubTaskPillProps>): JSX.Element {
  const iconClassName = color === TaskPillColor.Black ? 'text-black' : 'text-white';

  const renderIcon = () => {
    switch (type) {
      case 'task':
        return <FontAwesomeIcon icon={faFlagSwallowtail} className={iconClassName} transform='shrink-6' />;
      case 'reqver':
        return <FontAwesomeIcon icon={faShield} className={iconClassName} transform='shrink-6' />;
      case 'milestone':
        return <FontAwesomeIcon icon={faRhombus} className={iconClassName} transform='shrink-6' />;
      default:
        return null;
    }
  };

  return (
    <Pill
      data-testid='sub-task-pill'
      className={cx(
        'flex border items-center justify-center w-[18px] h-[18px] text-[16px]',
        {
          'text-black border-black': color === TaskPillColor.Black,
          'border-white': color === TaskPillColor.White,
        },
      )}
    >
      {renderIcon()}
    </Pill>
  );
}
