import type { User } from '@/__generated__/types';
import TaskPill from '@/components/Pill/TaskPill/TaskPill';
import TaskParent from '@/components/Schedule/AllTasks/taskItems/TaskParent';
import TaskName from '@/components/Schedule/List/ScheduleListObject/scheduleItems/TaskName';
import PermissionDeprecated from '@/components/Schedule/List/ScheduleListObject/scheduleItems/UserPermissionOnObject';
import type { TaskFragment } from '@/components/Schedule/queries/taskFragment.generated';
import { useGetUserByIdQuery } from '@/components/User/getUserById.generated';
import UserComponent from '@/components/User/User';
import cx from 'classnames';

interface SelectTaskProps {
  selectedTasks: string[];
  task: TaskFragment & { Status?: string } & { selected?: boolean };
  onChange: (e) => void;
}
export default function SelectTask ({ selectedTasks, task, onChange }: Readonly<SelectTaskProps>): JSX.Element {
  // TODO: This really needs to not be on a per-row basis.  We should be getting this via the task
  const { data: ownerInfo } = useGetUserByIdQuery({ variables: { id: task?.ownerId }, skip: !task?.ownerId });
  const owner = ownerInfo?.getUserByID;
  const company = owner?.organization?.name;

  return (
    <div className='flex items-center py-2'>
      <div className='flex flex-row items-center min-w-0 flex-[2] gap-[8px]'>
        <div className='flex min-w-0 flex-[2] gap-[8px]'>
          <input
            name='selectedTasks'
            onChange={onChange}
            value={task?.id}
            className={`input-checkbox custom-checkbox checked:bg-primary`}
            type='checkbox'
            checked={selectedTasks?.includes(task.id)}
          />
          <TaskName object={task} className='flex-1 min-w-0 font-medium truncate' />
        </div>
        <div className='flex flex-1'>
          <TaskPill
            type={task?.template?.name}
            data={{
              assignee: task?.assigneeId,
            }}
          />
        </div>
        <div className='flex min-w-0 flex-[2]'>
          <TaskParent task={task} />
        </div>
      </div>
      <div className='flex flex-1 min-w-0 gap-[8px]'>
        <div className='flex flex-1'>
          <PermissionDeprecated data={task} isBlackFont />
        </div>
        {/* Owner */}
        <div
          className={cx('flex flex-[2] gap-2  min-w-0', {
            'opacity-50': task?.Status === 'Complete' && task?.assigneeId,
          })}
        >
          <UserComponent user={owner as Partial<User>} noRole={true} size='small' />
        </div>
        {/* Company */}
        <div className='flex flex-1'>{company}</div>
      </div>
    </div>
  );
}
