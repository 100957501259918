import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetGapDataQueryVariables = Types.Exact<{
  input: Types.DependencyGapCalcInput;
}>;


export type GetGapDataQuery = { __typename?: 'Query', getDependencyGapCalcs: Array<{ __typename?: 'DependencyGapCalc', modularObjectId: string, blockedById: string, noOverlap: { __typename?: 'DependencyGap', gapMonths: number | null, gapDays: number | null, gapYears: number | null }, strict: { __typename?: 'DependencyGap', gapMonths: number | null, gapDays: number | null, gapYears: number | null }, none: { __typename?: 'DependencyGap', gapMonths: number | null, gapDays: number | null, gapYears: number | null } }> };


export const GetGapDataDocument = gql`
    query getGapData($input: DependencyGapCalcInput!) {
  getDependencyGapCalcs(input: $input) {
    modularObjectId
    blockedById
    noOverlap {
      gapMonths
      gapDays
      gapYears
    }
    strict {
      gapMonths
      gapDays
      gapYears
    }
    none {
      gapMonths
      gapDays
      gapYears
    }
  }
}
    `;

/**
 * __useGetGapDataQuery__
 *
 * To run a query within a React component, call `useGetGapDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGapDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGapDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetGapDataQuery(baseOptions: Apollo.QueryHookOptions<GetGapDataQuery, GetGapDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGapDataQuery, GetGapDataQueryVariables>(GetGapDataDocument, options);
      }
export function useGetGapDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGapDataQuery, GetGapDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGapDataQuery, GetGapDataQueryVariables>(GetGapDataDocument, options);
        }
export function useGetGapDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGapDataQuery, GetGapDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGapDataQuery, GetGapDataQueryVariables>(GetGapDataDocument, options);
        }
export type GetGapDataQueryHookResult = ReturnType<typeof useGetGapDataQuery>;
export type GetGapDataLazyQueryHookResult = ReturnType<typeof useGetGapDataLazyQuery>;
export type GetGapDataSuspenseQueryHookResult = ReturnType<typeof useGetGapDataSuspenseQuery>;
export type GetGapDataQueryResult = Apollo.QueryResult<GetGapDataQuery, GetGapDataQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetGapDataQuery((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ getDependencyGapCalcs })
 *   )
 * })
 */
export const mockGetGapDataQuery = (resolver: Parameters<typeof graphql.query<GetGapDataQuery, GetGapDataQueryVariables>>[1]) =>
  graphql.query<GetGapDataQuery, GetGapDataQueryVariables>(
    'getGapData',
    resolver
  )
