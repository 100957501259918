import { Button } from '@/designSystemComponents/Button';
import { useCallback, useState } from 'react';

export interface ConfirmDeleteFileModalProps {
  closeModal: () => void;
  handleConfirm: () => Promise<void>;
  fileName: string;
}

export const ConfirmDeleteFileModal = ({ closeModal, handleConfirm, fileName }: ConfirmDeleteFileModalProps) => {
  const [isRemoving, setIsRemoving] = useState(false);

  const handleRemoveClick = useCallback(async () => {
    setIsRemoving(true);
    await handleConfirm();
    setIsRemoving(false);
    closeModal();
  }, [closeModal, handleConfirm]);

  return (
    <div className='flex flex-col flex-1 gap-5'>
      <h3 className='mb-0 text-3xl font-black capitalize font-sculpin text-secondary'>Are you sure?</h3>
      <p>
        <span className='font-bold'>{fileName}</span> will be deleted.
      </p>
      <p>
        Note: If changes to this object require approval by the owner and you are not the owner, then an approval
        request will be sent to the owner
      </p>
      <div className='flex gap-5'>
        <Button figmaProps={{ style: 'outline', size: 'large' }} className='flex-1' onClick={closeModal}>
          Cancel
        </Button>

        <Button
          isLoading={isRemoving}
          figmaProps={{ style: 'fill', size: 'large' }}
          className='flex-1'
          onClick={handleRemoveClick}
        >
          Remove
        </Button>
      </div>
    </div>
  );
};
