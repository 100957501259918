import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

dayjs.extend(calendar);

export function getDateDisplay (date: string | Date): string {
  return dayjs(date).calendar(null, {
    sameDay: '[Today at] h:mma', // The same day ( Today at 2:30 AM )
    lastDay: '[Yesterday at] h:mma', // The day before ( Yesterday at 2:30 AM )
    lastWeek: 'M/D/YY [at] h:mma', //
    sameElse: 'M/D/YY [at] h:mma', // Everything else ( 7/10/2011 )
  });
}
