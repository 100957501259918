import { BetaBadge } from '@/components/BetaBadge';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import type { ComponentProps } from 'react';
import { useObjectCardScrollContext } from '../../../Card/ObjectCardScroll.context';

interface HeaderTabProps {
  icon?: IconProp;
  name: string;
  disabled?: boolean;
  proFeature?: boolean;
  isBeta?: boolean;
  /**
   * Use this property when you want to use a custom icon. (Non Font Awesome)
   */
  RenderIcon?: (props: ComponentProps<'svg'>) => JSX.Element;
}

export default function HeaderTab (
  { icon, name, disabled = false, proFeature = false, RenderIcon, isBeta }: HeaderTabProps,
): JSX.Element {
  const { scrollToId } = useObjectCardScrollContext();

  const scrollToSection = (e): void => {
    e.preventDefault();
    e.stopPropagation();

    const stickyHeaderHeightInPx = 70;

    scrollToId(`${name.toLowerCase()}-section`, stickyHeaderHeightInPx);
  };

  return (
    <div className='flex items-center'>
      <button
        type='button'
        onClick={scrollToSection}
        disabled={disabled}
        className={cx(
          'rounded-[4px] px-[12px] py-[8px] flex items-center gap-[4px] transition-all',
          {
            'hover:bg-neutral-200': !disabled,
          },
        )}
      >
        {RenderIcon ? <RenderIcon /> : <FontAwesomeIcon icon={icon} />}
        {name} {isBeta && <BetaBadge />}
      </button>
      {proFeature && disabled && (
        <div className='text-white shadow-sm bg-warning px-[4px] rounded-[4px]'>
          Pro
        </div>
      )}
    </div>
  );
}
