import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetTemplatesUsedByModularObjectsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTemplatesUsedByModularObjectsQuery = { __typename?: 'Query', getTemplates: Array<{ __typename?: 'Template', id: string | null, version: number | null, name: string | null, description: string | null, imagePath: string | null, pageColumns: Array<string | null> | null, isIntegratable: boolean | null, modules: any | null, type: Types.TemplateTypes | null, subType: Types.TemplateSubTypes | null, visibility: string | null }> };


export const GetTemplatesUsedByModularObjectsDocument = gql`
    query GetTemplatesUsedByModularObjects {
  getTemplates(input: {getOnlyUsedByModularObjects: true}) {
    id
    version
    name
    description
    imagePath
    pageColumns
    isIntegratable
    modules
    type
    subType
    visibility
  }
}
    `;

/**
 * __useGetTemplatesUsedByModularObjectsQuery__
 *
 * To run a query within a React component, call `useGetTemplatesUsedByModularObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesUsedByModularObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesUsedByModularObjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTemplatesUsedByModularObjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetTemplatesUsedByModularObjectsQuery, GetTemplatesUsedByModularObjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplatesUsedByModularObjectsQuery, GetTemplatesUsedByModularObjectsQueryVariables>(GetTemplatesUsedByModularObjectsDocument, options);
      }
export function useGetTemplatesUsedByModularObjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplatesUsedByModularObjectsQuery, GetTemplatesUsedByModularObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplatesUsedByModularObjectsQuery, GetTemplatesUsedByModularObjectsQueryVariables>(GetTemplatesUsedByModularObjectsDocument, options);
        }
export function useGetTemplatesUsedByModularObjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTemplatesUsedByModularObjectsQuery, GetTemplatesUsedByModularObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTemplatesUsedByModularObjectsQuery, GetTemplatesUsedByModularObjectsQueryVariables>(GetTemplatesUsedByModularObjectsDocument, options);
        }
export type GetTemplatesUsedByModularObjectsQueryHookResult = ReturnType<typeof useGetTemplatesUsedByModularObjectsQuery>;
export type GetTemplatesUsedByModularObjectsLazyQueryHookResult = ReturnType<typeof useGetTemplatesUsedByModularObjectsLazyQuery>;
export type GetTemplatesUsedByModularObjectsSuspenseQueryHookResult = ReturnType<typeof useGetTemplatesUsedByModularObjectsSuspenseQuery>;
export type GetTemplatesUsedByModularObjectsQueryResult = Apollo.QueryResult<GetTemplatesUsedByModularObjectsQuery, GetTemplatesUsedByModularObjectsQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetTemplatesUsedByModularObjectsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ getTemplates })
 *   )
 * })
 */
export const mockGetTemplatesUsedByModularObjectsQuery = (resolver: Parameters<typeof graphql.query<GetTemplatesUsedByModularObjectsQuery, GetTemplatesUsedByModularObjectsQueryVariables>>[1]) =>
  graphql.query<GetTemplatesUsedByModularObjectsQuery, GetTemplatesUsedByModularObjectsQueryVariables>(
    'GetTemplatesUsedByModularObjects',
    resolver
  )
