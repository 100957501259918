import type { ApprovalRequestChange } from '@/__generated__/types';
import { RichText } from '@/components/RichText/RichText';
import { addToastError } from '@/components/Toast/utils';
import { useGetUserByIdLazyQuery } from '@/components/User/getUserById.generated';
import { camelCaseToDisplayText } from '@/util/camelCaseToDisplayText';
import { downloadFile } from '@/util/downloadFile';
import { isIsoDate } from '@/util/time.functions';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';

interface ApprovalChangeProps {
  change: ApprovalRequestChange;
}

const getContentComponent = (content) => {
  try {
    // Numbers are valid JSON so need to explicitly check for them
    if (!isNaN(parseInt(content))) throw new Error('Number, not json');

    const parsedContent = JSON.parse(content);

    return <RichText content={parsedContent} isEditable={false} />;
  } catch (e) {
    if (isIsoDate(content)) {
      return dayjs(content).format('MM/DD/YYYY');
    }

    return content || '-';
  }
};

export default function ApprovalChange ({ change }: ApprovalChangeProps) {
  const downloaderRef = useRef<HTMLAnchorElement>();
  const [title, setTitle] = useState(camelCaseToDisplayText(change.field));
  const [fromChange, setFromChange] = useState(getContentComponent(change.from));
  const [toChange, setToChange] = useState(getContentComponent(change.to));
  const [isFileChange, setIsFileChange] = useState(false);
  const [lazyGetUserById] = useGetUserByIdLazyQuery();

  useEffect(() => {
    if (title === 'Assignee Id') {
      setTitle('Assignee');

      if (change.to && change.to !== '-') {
        lazyGetUserById({ variables: { id: change.to } })
          .then(({ data }) => {
            setToChange(data?.getUserByID?.fullName || 'Unknown User');
          })
          .catch(console.error);
      }

      if (change.from && change.from !== '-') {
        lazyGetUserById({ variables: { id: change.from } })
          .then(({ data }) => {
            setFromChange(data?.getUserByID?.fullName || 'Unknown User');
          })
          .catch(console.error);
      }
    }

    if (title.toLowerCase().includes('file')) {
      setIsFileChange(true);
    }
  }, [change.from, change.to, lazyGetUserById, title]);

  const handleFileDownload = useCallback(async (name: string) => {
    try {
      await downloadFile({
        id: change.fieldId,
        name,
        location: change.fieldLocation,
      }, downloaderRef);
    } catch (err) {
      addToastError(`Error downloading file: ${err.message}`);
    }
  }, [change.fieldId, change.fieldLocation]);

  return (
    <div key={title} className={cx('flex gap-4')} data-testid='approval-changes'>
      <div className='opacity-50 lg:w-5/12 truncate grow' title={title}>{title}</div>
      <div className='flex gap-5 lg:w-7/12'>
        <div data-testid='approval-from' className='w-[35%] opacity-50 truncate'>
          {isFileChange && fromChange ?
            (
              <button
                type='button'
                onClick={() => handleFileDownload(fromChange)}
                className='text-primary'
              >
                {fromChange}
              </button>
            ) :
            fromChange}
        </div>
        <div className='w-[30%] opacity-50 truncate grow-0 shrink-0'>➔</div>
        <div data-testid='approval-to' className='w-[35%] truncate'>
          {isFileChange && toChange ?
            (
              <button
                type='button'
                onClick={() => handleFileDownload(toChange)}
                className='text-primary'
              >
                {toChange}
              </button>
            ) :
            toChange}
        </div>
      </div>
      <a ref={downloaderRef} className='hidden' />
    </div>
  );
}
