import { type User } from '@/__generated__/types';
import { Modal } from '@/components/modals/Modal';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { useGetOrganizationUsersQuery } from '@/state/queries/organizations.api';
import { selectLoggedInUser, selectRootState } from '@/state/selectors';
import { setUser } from '@/state/slices/session.slice';
import { putUser } from '@/util/requests.functions';
import { useEffect, useMemo, useState } from 'react';

interface TransferAdminModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export function TransferAdminModal ({
  isModalOpen,
  onClose,
  onSubmit,
}: TransferAdminModalProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { session: sessionState } = useAppSelector(selectRootState);
  const [user, setUserState] = useState<User>({} as User);
  const [selectedUser, setSelectedUser] = useState<User>({} as User);
  const loggedInUser = useAppSelector(selectLoggedInUser);

  const { data: orgUsers } = useGetOrganizationUsersQuery(
    loggedInUser?.organizationId,
  );
  const userOptions = useMemo(() => {
    if (!orgUsers) return [];
    return orgUsers
      .filter((thisUser) => thisUser.id !== user.id)
      .map((user) => ({
        id: user.id,
        label: `${user.firstName} ${user.lastName} (${user.email})`,
      }));
  }, [orgUsers]);

  useEffect(() => {
    setUserState(sessionState.user);
  }, [sessionState]);

  const handleSelectNewAdmin = (event): void => {
    const selectedUserId = event.target.value?.id;
    const newAdmin = {
      ...orgUsers.find((thisUser) => thisUser.id === selectedUserId),
    };
    setSelectedUser(newAdmin);
  };

  const handleSubmitTransfer = async (): Promise<void> => {
    try {
      // Set selected user to admin role
      await putUser({ ...selectedUser, role: 'admin' });
      // Set self to editor role
      const response = await putUser({ ...user, role: 'user' });
      dispatch(setUser(response));
      onSubmit();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      header='Transfer Admin'
      isOpen={isModalOpen}
      onClose={onClose}
      onSubmit={handleSubmitTransfer}
      submitText='Transfer'
      columns={[
        [
          {
            label: 'Team Members',
            id: 'modal-transfer-Admin',
            width: '100',
            placeholder: 'Select New Admin',
            inputType: 'dropdown',
            options: userOptions,
            optionKey: 'label',
            // required: true,
            onChange: handleSelectNewAdmin,
          },
        ],
      ]}
    />
  );
}
