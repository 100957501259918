import { type ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: ReactNode;
  mount?: boolean;
}

export default function Portal ({ children }: PortalProps): JSX.Element {
  const [ref, setRef] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setRef(document.getElementById('portal-root'));

    return () => {
      setRef(null);
    };
  }, []);

  return ref ? createPortal(children, ref) : null;
}
