import type { StepperState } from './Stepper.reducer';

type StepperActionTypes =
  | typeof INCREMENT_STEP
  | typeof DECREMENT_STEP
  | typeof REGISTER_STEP
  | typeof SET_CURRENT_STEP;

interface StepAction {
  type: StepperActionTypes;
  payload: {
    step: number;
  };
}

export const INCREMENT_STEP = 'INCREMENT_STEP';
export const DECREMENT_STEP = 'DECREMENT_STEP';
export const REGISTER_STEP = 'REGISTER_STEP';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';

export function setCurrentStep (step: number): StepAction {
  return {
    type: SET_CURRENT_STEP,
    payload: { step },
  };
}

export function incrementStep (state: StepperState): StepAction {
  return {
    type: INCREMENT_STEP,
    payload: { step: state.step + 1 },
  };
}

export function decrementStep (state: StepperState): StepAction {
  return {
    type: DECREMENT_STEP,
    payload: { step: state.step - 1 },
  };
}

export function registerStep (step: number): StepAction {
  return {
    type: REGISTER_STEP,
    payload: { step },
  };
}
