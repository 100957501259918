import type { User } from '@/__generated__/types';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useLogout } from '@/hooks/useLogout';
import { useUserProfileDrawer } from '@/hooks/useUserProfileDrawer';
import metrics from '@/util/metrics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faCog, faUser } from '@fortawesome/sharp-solid-svg-icons';
import { Menu, Transition } from '@headlessui/react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { ProfileImage } from '../../ProfileImage/ProfileImage';
interface UserMenuProps {
  onClick: () => void;
}

export default function UserMenu ({ onClick }: Readonly<UserMenuProps>): JSX.Element {
  const { openUserProfileDrawer } = useUserProfileDrawer();

  const currentUser = useLoggedInUser();
  const { logout } = useLogout();

  const logoutUser = (e) => {
    e.preventDefault();
    metrics.track('user logout');
    void logout();
  };

  return (
    <Menu as='div' className='relative h-[24px]'>
      {({ open }) => (
        <>
          <Menu.Button
            data-testid='user-menu-button'
            onClick={onClick}
            className={cx(
              'inline-flex justify-center hover:ring-2 hover:ring-white/10 rounded-full transition-all',
              {
                'ring-2 !ring-white/20': open,
              },
            )}
          >
            <ProfileImage
              user={currentUser as User}
              size='small'
              className='!border-0'
            />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute left-0 z-50 mt-2 bg-white divide-y divide-gray-100 ring-1 shadow-lg origin-top-right focus:outline-none w-[144px] rounded-[2px] ring-black/5 overflow-clip'>
              {
                /* <UserMenuItem disabled>
                👋 Hi, {currentUser?.firstName}
              </UserMenuItem> */
              }
              <UserMenuItem onClick={() => openUserProfileDrawer(currentUser.id)}>
                <FontAwesomeIcon icon={faUser} />
                Profile
              </UserMenuItem>
              <UserMenuItem href='/settings/company'>
                <FontAwesomeIcon icon={faCog} />
                Settings
              </UserMenuItem>
              <UserMenuItem onClick={logoutUser}>
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
                Logout
              </UserMenuItem>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

interface UserMenuItemProps {
  children: React.ReactNode;
  href?: string;
  onClick?: (e) => void;
}
function UserMenuItem ({ children, href, onClick, ...otherProps }: Readonly<UserMenuItemProps>): JSX.Element {
  const router = useRouter();
  const handleOnClick = async (e) => {
    if (onClick) {
      onClick(e);
    } else if (href) {
      await router.push(href);
    }
  };

  return (
    <Menu.Item {...otherProps}>
      {({ active }) => (
        <button
          onClick={handleOnClick}
          className={cx(
            'group flex w-full items-center p-[8px] effra-12 gap-[8px] transition-all disabled:opacity-100 disabled:text-primary',
            {
              'bg-gray-90': active,
            },
          )}
          {...otherProps}
        >
          {children}
        </button>
      )}
    </Menu.Item>
  );
}
