import { Button } from '@/designSystemComponents/Button';

export interface ConfirmDeleteCommentModalProps {
  closeModal: () => void;
  handleDelete: () => void;
}

export const ConfirmDeleteCommentModal = ({ closeModal, handleDelete }: ConfirmDeleteCommentModalProps) => {
  return (
    <div className='flex flex-col flex-1 gap-5'>
      <h3 className='mb-0 text-3xl font-black capitalize font-sculpin'>Confirm Delete Comment</h3>
      <div className='flex gap-5'>
        <Button figmaProps={{ style: 'outline', size: 'large' }} className='flex-1 w-auto' onClick={closeModal}>
          Cancel
        </Button>

        <Button figmaProps={{ style: 'fill', size: 'large' }} className='flex-1 w-auto' onClick={handleDelete}>
          Delete
        </Button>
      </div>
    </div>
  );
};
