import useScrollDirection from '@/hooks/useScrollDirection';
import { useEffect, useRef } from 'react';
import { useObjectCardScrollContext } from './ObjectCardScroll.context';

interface ObjectCardScrollingContainerProps {
  children: React.ReactNode;
}

/**
 * This component was created so we can memoize ObjectCardContent to keep it from updating when we collapse the header.
 */
export default function ObjectCardScrollingContainer ({ children }: ObjectCardScrollingContainerProps): JSX.Element {
  const scrollingElement = useRef<HTMLDivElement>(null);

  const { setScrollDirection, setScrollElement } = useObjectCardScrollContext();

  const scrollDirection = useScrollDirection({ el: scrollingElement });

  useEffect(() => {
    setScrollElement(scrollingElement);
  }, [scrollingElement]);

  useEffect(() => {
    setScrollDirection(scrollDirection);
  }, [scrollDirection]);

  return (
    // The relative below is necessary for the scroll to section functionality to work (the scrollTo function in the ObjectCardScrollContext)
    // if you remove it, it will break the scrolling
    <div className='overflow-y-auto overflow-x-hidden relative grow' ref={scrollingElement}>
      {children}
    </div>
  );
}
