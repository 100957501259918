import { v4 as uuidv4 } from 'uuid';
import FormContainer from '../../../FormContainer/FormContainer';

import type { StepContentProps } from '../../../Stepper/Stepper';

interface ConfirmHeaderRowProps extends StepContentProps {
  header: string[];
  onClose: () => void;
  onStepChange: (step: number) => void;
  step: number;
}

const HEADER_DISPLAY_LIMIT = 4;
const NUM_EMPTY_ROWS = 2;

function HeaderRowPreview ({ header }: { header: string[] }): JSX.Element {
  const headerSize = Math.min(header.length, HEADER_DISPLAY_LIMIT);
  const sizedHeader = header.slice(0, headerSize);
  return (
    <div className='flex flex-col gap-4 pt-4'>
      <p>
        This row was pulled from the top of your template, and will be used to map your fields to ours in the next step.
        If these column headers look wrong, please go back and adjust your file to include a header row, then try again.
      </p>
      <div className='relative'>
        {/* Fade Overlays */}
        <div className='absolute z-[2] bottom-0 right-0 h-full w-[6%] pointer-events-none bg-gradient-to-r from-transparent to-neutral-50' />
        <div className='absolute bottom-0 left-0 w-full h-10 bg-gradient-to-b from-transparent pointer-events-none z-[1] to-neutral-50' />
        <table className='w-full text-left border-collapse table-fixed'>
          <thead className=''>
            <tr>
              {sizedHeader.map((column) => (
                <th
                  key={column}
                  className='col-span-1 p-3 font-normal border border-neutral-500/20 bg-neutral-500/20 effra-base'
                >
                  <div className='overflow-hidden text-ellipsis'>{column}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array(NUM_EMPTY_ROWS).fill('').map((_, i) => (
              <tr key={`row-${uuidv4()}`}>
                {Array(headerSize).fill('').map((_, i) => (
                  <td key={uuidv4()} className='h-10 bg-white border'>{'\t'}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default function ConfirmHeaderRow ({
  header,
  onClose,
  onStepChange,
  step,
  incrementStep,
  decrementStep,
}: ConfirmHeaderRowProps): JSX.Element {
  return (
    <FormContainer
      headerText={'Is this your Template\'s header row?'}
      content={<HeaderRowPreview header={header} />}
      buttons={
        <div className='flex gap-6 justify-between w-full'>
          <button
            type='button'
            className='w-1/2 font-medium btn-primary-hollow effra-xs'
            onClick={() => {
              onStepChange(step - 1);
              decrementStep();
            }}
          >
            No, Go Back
          </button>
          <button
            type='button'
            className='w-1/2 text-xs font-medium btn-primary effra-xs'
            onClick={() => {
              onStepChange(step + 1);
              incrementStep();
            }}
          >
            Yes, Continue
          </button>
        </div>
      }
      onClose={onClose}
    />
  );
}
