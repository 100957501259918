import { faArrowRightToLine } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import cx from 'classnames';
import type { PropsWithChildren } from 'react';
import { Fragment } from 'react';

export interface DrawerProps {
  anchor?: string;
  isOpen: boolean;
  handleClose: () => void;
  position?: string;
}
export function Drawer ({
  anchor = 'bottom',
  children,
  isOpen,
  handleClose = () => {},
  position = 'bottom',
}: PropsWithChildren<DrawerProps>): JSX.Element {
  return (
    <Transition.Root as={Fragment} show={isOpen}>
      <div
        className={cx({
          'fixed inset-y-0 right-0 z-[100]': position === 'right',
          'fixed inset-y-0 left-0 z-[100]': position === 'left',
          'fixed inset-y-0 top-0 z-[100]': position === 'top',
          'fixed inset-y-0 bottom-0 z-[100]': position === 'bottom',
        })}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom={cx({
            'translate-x-full': anchor === 'right',
            '-translate-x-full': anchor === 'left',
            '-translate-y-full': anchor === 'top',
            'translate-y-full': anchor === 'bottom',
          })}
          enterTo={cx({
            'translate-x-0': anchor === 'right',
            '-translate-x-0': anchor === 'left',
            '-translate-y-0': anchor === 'top',
            'translate-y-0': anchor === 'bottom',
          })}
          leave='ease-in duration-200'
          leaveFrom={cx({
            'translate-x-0': anchor === 'right',
            '-translate-x-0': anchor === 'left',
            '-translate-y-0': anchor === 'top',
            'translate-y-0': anchor === 'bottom',
          })}
          leaveTo={cx({
            'translate-x-full': anchor === 'right',
            '-translate-x-full': anchor === 'left',
            '-translate-y-full': anchor === 'top',
            'translate-y-full': anchor === 'bottom',
          })}
        >
          <div id='drawer'>
            {children}
            <FontAwesomeIcon
              icon={faArrowRightToLine}
              className='absolute right-4 font-bold text-white cursor-pointer md:right-8 text-[14px] font-effra top-[33px] z-[250]'
              onClick={handleClose}
            />
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
}
