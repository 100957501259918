export default function SelectIcon (): JSX.Element {
  return (
    <span className='flex absolute inset-y-0 right-0 items-center pr-2 pointer-events-none'>
      <svg
        className='w-5 h-5 text-gray-400'
        viewBox='0 0 20 20'
        fill='none'
        stroke='currentColor'
      >
        <path
          d='M7 7l3-3 3 3m0 6l-3 3-3-3'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </span>
  );
}
