import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { ObjectListRowPropsFragmentDoc } from '../AddNewObjectModal/ObjectListRow/ObjectListRowProps.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetBulkAddToParentModalResultsQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input'];
}>;


export type GetBulkAddToParentModalResultsQuery = { __typename?: 'Query', getPossibleParentsForModularObjects: Array<{ __typename?: 'ModularObject', id: string, name: string | null, deactivated: boolean | null, ownerId: string | null, template: { __typename?: 'Template', id: string | null, name: string | null } | null, parent: { __typename?: 'ModularObject', id: string, name: string | null, owner: { __typename?: 'User', id: string, organizationId: string | null } | null, template: { __typename?: 'Template', id: string | null, name: string | null } | null } | null, owner: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null, profileImage: string | null, organizationId: string | null, organization: { __typename?: 'Organization', id: string, name: string | null } | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, createdAt: any | null, externalId: string | null, externalType: string | null, modifiedAt: any | null, role: string | null, userId: string | null }> | null }> | null };


export const GetBulkAddToParentModalResultsDocument = gql`
    query GetBulkAddToParentModalResults($ids: [UUID!]!) {
  getPossibleParentsForModularObjects(ids: $ids) {
    ...ObjectListRowProps
  }
}
    ${ObjectListRowPropsFragmentDoc}`;

/**
 * __useGetBulkAddToParentModalResultsQuery__
 *
 * To run a query within a React component, call `useGetBulkAddToParentModalResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBulkAddToParentModalResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBulkAddToParentModalResultsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetBulkAddToParentModalResultsQuery(baseOptions: Apollo.QueryHookOptions<GetBulkAddToParentModalResultsQuery, GetBulkAddToParentModalResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBulkAddToParentModalResultsQuery, GetBulkAddToParentModalResultsQueryVariables>(GetBulkAddToParentModalResultsDocument, options);
      }
export function useGetBulkAddToParentModalResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBulkAddToParentModalResultsQuery, GetBulkAddToParentModalResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBulkAddToParentModalResultsQuery, GetBulkAddToParentModalResultsQueryVariables>(GetBulkAddToParentModalResultsDocument, options);
        }
export function useGetBulkAddToParentModalResultsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBulkAddToParentModalResultsQuery, GetBulkAddToParentModalResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBulkAddToParentModalResultsQuery, GetBulkAddToParentModalResultsQueryVariables>(GetBulkAddToParentModalResultsDocument, options);
        }
export type GetBulkAddToParentModalResultsQueryHookResult = ReturnType<typeof useGetBulkAddToParentModalResultsQuery>;
export type GetBulkAddToParentModalResultsLazyQueryHookResult = ReturnType<typeof useGetBulkAddToParentModalResultsLazyQuery>;
export type GetBulkAddToParentModalResultsSuspenseQueryHookResult = ReturnType<typeof useGetBulkAddToParentModalResultsSuspenseQuery>;
export type GetBulkAddToParentModalResultsQueryResult = Apollo.QueryResult<GetBulkAddToParentModalResultsQuery, GetBulkAddToParentModalResultsQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetBulkAddToParentModalResultsQuery((req, res, ctx) => {
 *   const { ids } = req.variables;
 *   return res(
 *     ctx.data({ getPossibleParentsForModularObjects })
 *   )
 * })
 */
export const mockGetBulkAddToParentModalResultsQuery = (resolver: Parameters<typeof graphql.query<GetBulkAddToParentModalResultsQuery, GetBulkAddToParentModalResultsQueryVariables>>[1]) =>
  graphql.query<GetBulkAddToParentModalResultsQuery, GetBulkAddToParentModalResultsQueryVariables>(
    'GetBulkAddToParentModalResults',
    resolver
  )
