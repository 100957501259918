import { append, concat, evolve, filter, pipe, uniq } from 'ramda';
import initialState from './drafts.initialState';

// ! *********************
// ! Adding a handler to this file for some reason requires a restart of the app
// ! in order to be picked up and attached to the actions object, not sure why, will fix
// ! *********************

export const ADD_HARDWARE_DRAFT = (state, { payload }) =>
  evolve({
    hardware: {
      spacecraft: pipe(concat(payload.draft.spacecraft ?? []), uniq),
      deployers: append(payload.draft.deployer),
    },
  }, state);

export const REMOVE_HARDWARE_DRAFT = (state, { payload }) =>
  evolve({
    hardware: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [payload.hardware]: filter(draft => (draft as any).id !== payload.id),
    },
  }, state);

export const REMOVE_SAVED_HARDWARE_DRAFTS = (state, { payload }) =>
  evolve({
    hardware: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      spacecraft: filter(d => (d as any).missionID !== payload.missionId),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      deployers: filter(d => (d as any).missionID !== payload.missionId),
    },
  }, state);

export const RESET_HARDWARE_DRAFTS = () => initialState;
