import cx from 'classnames';
import { useRef } from 'react';

interface OnOffToggleProps {
  enabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  identifier: string;
  disabled?: boolean;
}

const noop = () => {};

function OnOffToggle ({ enabled = false, disabled = false, onChange = noop, identifier }: OnOffToggleProps) {
  const checkboxRef = useRef<HTMLInputElement>(null);

  return (
    <div>
      <label
        htmlFor={`checkbox-toggle-${identifier}`}
        className={cx(
          'w-[40px] h-[20px] rounded-3xl bg-black cursor-pointer flex justify-around items-center relative',
          {
            '!cursor-not-allowed': disabled,
            '!bg-gray-80': disabled,
            'bg-gray-70': !enabled,
            'bg-black': enabled,
          },
        )}
      >
        <div
          className={cx('text-white h-[12px] font-effra text-[9px] text-gray-80 uppercase pl-[5px]', {
            'opacity-0': !enabled,
          })}
        >
          On
        </div>
        <div
          className={cx('text-white h-[12px] font-effra text-[9px] text-gray-80 pr-[4px] uppercase', {
            'opacity-0': enabled,
          })}
        >
          Off
        </div>
        <div
          className={cx(
            'rounded-3xl bg-white top-[3px] left-[3px] h-[14px] w-[14px] absolute transition-all ',
            {
              'transform translate-x-[20px]': enabled,
            },
          )}
          onClick={(e) => {
            if (disabled) return;
            e.preventDefault();
            e.stopPropagation();
            checkboxRef.current?.click();
          }}
        />
      </label>
      <input
        onChange={onChange}
        disabled={disabled}
        checked={enabled}
        ref={checkboxRef}
        className='sr-only'
        type='checkbox'
        id={`checkbox-toggle-${identifier}`}
      />
    </div>
  );
}

interface ToggleOptionProps {
  value?: boolean;
  title: string;
  description: string;
  icon: string;
  secondaryIcon: string;
  onChange?: (e: React.ChangeEvent) => void;
  disabled?: boolean;
}

export default function ToggleOption (
  { value = false, title, description, icon, secondaryIcon, disabled = false, onChange = noop }: ToggleOptionProps,
) {
  return (
    <article className='flex justify-between items-center w-full z-1'>
      <div className='effra-12'>
        <div className='flex gap-2'>
          <i
            className={cx(`${icon} w-[18px] text-center`, {
              'hidden': !value,
            })}
          />
          <i
            className={cx(`${secondaryIcon} w-[18px] text-center`, {
              'hidden': value,
            })}
          />
          <div className='flex flex-col gap-1'>
            <p className='text-black'>
              {title}
            </p>
            <p className='text-gray-60 max-w-[272px]'>{description}</p>
          </div>
        </div>
      </div>
      <OnOffToggle enabled={value} disabled={disabled} identifier={title} onChange={onChange} />
    </article>
  );
}
