import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type DeletePrismaticJiraMappingMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type DeletePrismaticJiraMappingMutation = { __typename?: 'Mutation', deletePrismaticJiraMapping: { __typename?: 'JiraMapping', id: string } | null };


export const DeletePrismaticJiraMappingDocument = gql`
    mutation DeletePrismaticJiraMapping($id: UUID!) {
  deletePrismaticJiraMapping(id: $id) {
    id
  }
}
    `;
export type DeletePrismaticJiraMappingMutationFn = Apollo.MutationFunction<DeletePrismaticJiraMappingMutation, DeletePrismaticJiraMappingMutationVariables>;

/**
 * __useDeletePrismaticJiraMappingMutation__
 *
 * To run a mutation, you first call `useDeletePrismaticJiraMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePrismaticJiraMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePrismaticJiraMappingMutation, { data, loading, error }] = useDeletePrismaticJiraMappingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePrismaticJiraMappingMutation(baseOptions?: Apollo.MutationHookOptions<DeletePrismaticJiraMappingMutation, DeletePrismaticJiraMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePrismaticJiraMappingMutation, DeletePrismaticJiraMappingMutationVariables>(DeletePrismaticJiraMappingDocument, options);
      }
export type DeletePrismaticJiraMappingMutationHookResult = ReturnType<typeof useDeletePrismaticJiraMappingMutation>;
export type DeletePrismaticJiraMappingMutationResult = Apollo.MutationResult<DeletePrismaticJiraMappingMutation>;
export type DeletePrismaticJiraMappingMutationOptions = Apollo.BaseMutationOptions<DeletePrismaticJiraMappingMutation, DeletePrismaticJiraMappingMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeletePrismaticJiraMappingMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ deletePrismaticJiraMapping })
 *   )
 * })
 */
export const mockDeletePrismaticJiraMappingMutation = (resolver: Parameters<typeof graphql.mutation<DeletePrismaticJiraMappingMutation, DeletePrismaticJiraMappingMutationVariables>>[1]) =>
  graphql.mutation<DeletePrismaticJiraMappingMutation, DeletePrismaticJiraMappingMutationVariables>(
    'DeletePrismaticJiraMapping',
    resolver
  )
