import type { PropsWithChildren } from 'react';
import InfoTooltip from '../../tooltip/InfoTooltip';

interface UserMenuItemProps extends PropsWithChildren {
  name: string;
  isExpanded: boolean;
  count: number;
  overflowLimit?: number;
}
export default function IconWithCounter (
  { children, name, isExpanded, count, overflowLimit = 99 }: Readonly<UserMenuItemProps>,
): JSX.Element {
  return (
    <InfoTooltip
      message={name}
      tooltipClassName='effra-8'
      tooltipContentClassName='px-[6px] py-[4px]'
      placement={isExpanded ? 'bottom' : 'left'}
    >
      <div className='flex relative justify-center items-center rounded-[2px] h-[24px] w-[24px] hover:bg-white/10'>
        {children}
        {(count > 0) && (
          <>
            <div className='absolute bg-pink left-[83%] top-[83%] h-2 w-2 @expanded/sidenav:hidden rounded-[8px] z-10' />
            <div className='hidden @expanded/sidenav:inline-flex absolute bg-pink text-white text-[8px] left-[66%] top-[50%] rounded-[8px] h-[16px] items-center justify-center px-[4px] z-10'>
              {count > overflowLimit ? `${overflowLimit}+` : count}
            </div>
          </>
        )}
      </div>
    </InfoTooltip>
  );
}
