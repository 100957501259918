import ObjectIcon from '../ObjectIcon';

interface ObjectNameCellProps {
  name: string;
  objectType: string;
  payloads?: number;
}

function PayloadIcon ({ className }: { readonly className: string }): JSX.Element {
  return (
    <svg
      className={`fill-current stroke-current ${className}`}
      id='Layer_2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 28.13 23.51'
    >
      <g id='Layer_1-2'>
        <g>
          <path d='m13.34,5.05c-1.5-.15-3.16,1.04-3.7,2.65-.55,1.61.22,3.04,1.72,3.18,1.5.15,3.16-1.04,3.7-2.65h0c.55-1.61-.22-3.04-1.72-3.18Z' />
          <path d='m28.13,9.43v-2h-2.28l.68-1.87-3.73-5.56H7.37l-1.96,5.4h-2.78v2h2.05l-2.17,5.95H0v2h1.79l-.03.08h0s2.96,4.41,2.96,4.41l-.96,2.6,1.88.69.79-2.15h8.94l-.68,1.83,1.88.69.93-2.52h3.43l1.65-4.53h2.76v-2h-2.03l1.83-5.04h3ZM8.46,1.55h12.69s-4.68,12.85-4.68,12.85H3.77L8.46,1.55Z' />
        </g>
      </g>
    </svg>
  );
}

export function ObjectNameCell ({ name, objectType, payloads }: Readonly<ObjectNameCellProps>): JSX.Element {
  return (
    <div data-testid='object-name' className='flex items-center flex-[2]'>
      <span className='flex items-center shrink-0'>
        <ObjectIcon objectType={objectType} width={30} height={30} />
      </span>
      <span className='block ml-4 font-bold text-primary sculpin-base shrink truncate sm:w-[3ch] md:w-[5ch] lg:w-[10ch] xl:w-[12ch] 2xl:w-[20ch]'>
        {name}
      </span>
      {Boolean(payloads) && (
        <span className='flex gap-1 items-center py-1 px-2 ml-4 rounded-3xl bg-slate-200'>
          <PayloadIcon className='w-4 h-4' />
          <span>{payloads}</span>
        </span>
      )}
    </div>
  );
}
