import { useCallback, useMemo, useState } from 'react';

export const DEFAULT_PAGE_SIZE_OPTIONS = [5, 10, 25, 100, 1000];

interface OffSetPaginationArgs {
  initialSelectedPage?: number;
  initialSelectedPageSize?: number;
}
export const useOffSetPagination = (
  { initialSelectedPage = 1, initialSelectedPageSize = DEFAULT_PAGE_SIZE_OPTIONS[0] }: OffSetPaginationArgs,
) => {
  const [selectedPage, setSelectedPage] = useState(initialSelectedPage);
  const [selectedPageSize, setSelectedPageSize] = useState(initialSelectedPageSize);

  const onPageChange = useCallback((page: number) => {
    setSelectedPage(page);
  }, []);

  const onPageSizeChange = useCallback((pageSize: number) => {
    // Need to set page to 1 when changing page size so that you don't end up on a page that doesn't exist
    setSelectedPage(1);
    setSelectedPageSize(pageSize);
  }, []);

  const api = useMemo(() => {
    return {
      selectedPage,
      onPageChange,
      selectedPageSize,
      onPageSizeChange,
    };
  }, [onPageChange, onPageSizeChange, selectedPage, selectedPageSize]);

  return api;
};
