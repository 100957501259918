interface DeleteCustomFieldConfirmationProps {
  handleDeleteCustomField: () => void;
  closeModal: () => void;
}

export default function DeleteCustomFieldConfirmation (
  { handleDeleteCustomField, closeModal }: DeleteCustomFieldConfirmationProps,
) {
  return (
    <div className='flex-1'>
      <p className='mb-4 -mt-2 font-bold font-sculpin text-[18px] text-secondary'>Are you sure?</p>
      <p className='mb-8 text-gray-800'>All data associated with this input will be lost.</p>
      <button
        onClick={() => {
          handleDeleteCustomField();
          closeModal();
        }}
        className='w-full uppercase btn-primary'
      >
        remove custom field
      </button>
    </div>
  );
}
