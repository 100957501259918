import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { NestingColumnItemFragmentDoc } from './NestingColumnItemFragment.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetChildrenForNestingColumnQueryVariables = Types.Exact<{
  modularObjectId: Types.Scalars['UUID']['input'];
}>;


export type GetChildrenForNestingColumnQuery = { __typename?: 'Query', getModularObjectByIDs: Array<{ __typename?: 'ModularObject', id: string, children: Array<{ __typename?: 'ModularObject', id: string, name: string | null, template: { __typename?: 'Template', id: string | null, name: string | null, type: Types.TemplateTypes | null } | null, children: Array<{ __typename?: 'ModularObject', id: string, template: { __typename?: 'Template', id: string | null, type: Types.TemplateTypes | null } | null }> | null, owner: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null, profileImage: string | null, organization: { __typename?: 'Organization', id: string, name: string | null } | null } | null }> | null, childrenPendingAddApproval: Array<{ __typename?: 'ModularObject', id: string, name: string | null, template: { __typename?: 'Template', id: string | null, name: string | null, type: Types.TemplateTypes | null } | null, children: Array<{ __typename?: 'ModularObject', id: string, template: { __typename?: 'Template', id: string | null, type: Types.TemplateTypes | null } | null }> | null, owner: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null, profileImage: string | null, organization: { __typename?: 'Organization', id: string, name: string | null } | null } | null }> | null, childrenPendingRemoveApproval: Array<{ __typename?: 'ModularObject', id: string, name: string | null, template: { __typename?: 'Template', id: string | null, name: string | null, type: Types.TemplateTypes | null } | null, children: Array<{ __typename?: 'ModularObject', id: string, template: { __typename?: 'Template', id: string | null, type: Types.TemplateTypes | null } | null }> | null, owner: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null, profileImage: string | null, organization: { __typename?: 'Organization', id: string, name: string | null } | null } | null }> | null }> | null };


export const GetChildrenForNestingColumnDocument = gql`
    query GetChildrenForNestingColumn($modularObjectId: UUID!) {
  getModularObjectByIDs(ids: [$modularObjectId]) {
    id
    children {
      ...NestingColumnItem
    }
    childrenPendingAddApproval {
      ...NestingColumnItem
    }
    childrenPendingRemoveApproval {
      ...NestingColumnItem
    }
  }
}
    ${NestingColumnItemFragmentDoc}`;

/**
 * __useGetChildrenForNestingColumnQuery__
 *
 * To run a query within a React component, call `useGetChildrenForNestingColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChildrenForNestingColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChildrenForNestingColumnQuery({
 *   variables: {
 *      modularObjectId: // value for 'modularObjectId'
 *   },
 * });
 */
export function useGetChildrenForNestingColumnQuery(baseOptions: Apollo.QueryHookOptions<GetChildrenForNestingColumnQuery, GetChildrenForNestingColumnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChildrenForNestingColumnQuery, GetChildrenForNestingColumnQueryVariables>(GetChildrenForNestingColumnDocument, options);
      }
export function useGetChildrenForNestingColumnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChildrenForNestingColumnQuery, GetChildrenForNestingColumnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChildrenForNestingColumnQuery, GetChildrenForNestingColumnQueryVariables>(GetChildrenForNestingColumnDocument, options);
        }
export function useGetChildrenForNestingColumnSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetChildrenForNestingColumnQuery, GetChildrenForNestingColumnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetChildrenForNestingColumnQuery, GetChildrenForNestingColumnQueryVariables>(GetChildrenForNestingColumnDocument, options);
        }
export type GetChildrenForNestingColumnQueryHookResult = ReturnType<typeof useGetChildrenForNestingColumnQuery>;
export type GetChildrenForNestingColumnLazyQueryHookResult = ReturnType<typeof useGetChildrenForNestingColumnLazyQuery>;
export type GetChildrenForNestingColumnSuspenseQueryHookResult = ReturnType<typeof useGetChildrenForNestingColumnSuspenseQuery>;
export type GetChildrenForNestingColumnQueryResult = Apollo.QueryResult<GetChildrenForNestingColumnQuery, GetChildrenForNestingColumnQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetChildrenForNestingColumnQuery((req, res, ctx) => {
 *   const { modularObjectId } = req.variables;
 *   return res(
 *     ctx.data({ getModularObjectByIDs })
 *   )
 * })
 */
export const mockGetChildrenForNestingColumnQuery = (resolver: Parameters<typeof graphql.query<GetChildrenForNestingColumnQuery, GetChildrenForNestingColumnQueryVariables>>[1]) =>
  graphql.query<GetChildrenForNestingColumnQuery, GetChildrenForNestingColumnQueryVariables>(
    'GetChildrenForNestingColumn',
    resolver
  )
