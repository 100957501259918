import { createContext, useContext, useMemo, useState } from 'react';
import type { GetObjectCardObjectQuery } from './GetObjectCardObject.generated';

interface ObjectContextType {
  activeModularObject: GetObjectCardObjectQuery['getModularObjectByIDs'][0];
  activeTemplate: GetObjectCardObjectQuery['getModularObjectByIDs'][0]['template'];
  isEdit: boolean;
  isExternalLink: boolean;
  isCompressedHeader: boolean;
  setIsCompressedHeader: (isCompressedHeader: boolean) => void;
}

interface ObjectProviderProps {
  activeModularObject: GetObjectCardObjectQuery['getModularObjectByIDs'][0];
  activeTemplate: GetObjectCardObjectQuery['getModularObjectByIDs'][0]['template'];
  isExternalLink: boolean;
  children: React.ReactNode;
}

const ObjectContext = createContext<ObjectContextType>(null);

/**
 * @deprecated use `src/state/ObjectCard.context.tsx` instead
 */
export function useObjectContext (): ObjectContextType {
  const context = useContext(ObjectContext);

  if (context === undefined) {
    throw new Error('useObjectContext must be used within a ObjectProvider');
  }

  return context;
}

/**
 * @deprecated use `src/state/ObjectCard.context.tsx` instead
 */
export default function ObjectProvider ({
  activeModularObject,
  activeTemplate,
  isExternalLink,
  children,
}: ObjectProviderProps): JSX.Element {
  const [isCompressedHeader, setIsCompressedHeader] = useState(false);
  const isEdit = useMemo(() => Boolean(activeModularObject?.id), [activeModularObject]);

  const value = useMemo(
    () => ({
      activeModularObject,
      activeTemplate,
      isEdit,
      isExternalLink,
      isCompressedHeader,
      setIsCompressedHeader,
    }),
    [
      activeModularObject,
      activeTemplate,
      isEdit,
      isExternalLink,
      isCompressedHeader,
      setIsCompressedHeader,
    ],
  );

  return <ObjectContext.Provider value={value}>{children}</ObjectContext.Provider>;
}
