import { useObjectContext } from '@/components/common/ModularObject/Card/Object.context';
import { Loader } from '@/components/loader';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import getIcon from '@/util/getIcon';
import cx from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import ImageDisplay from './ImageDisplay';
import ImageUpload from './ImageUpload';

interface ImageProps {
  isCompressedHeader: boolean;
}

enum ImageStatus {
  LOADING = 'loading',
  PROCESSING = 'processing',
  DONE = 'done',
}

export default function HeaderImage ({ isCompressedHeader }: Readonly<ImageProps>): JSX.Element {
  const { objectCardData } = useObjectCardContext();
  const { isEdit } = useObjectContext();
  const { template, imageId } = objectCardData;

  const imageFile = useMemo(() => {
    const files = objectCardData.files ?? [];
    const imageFile = files.find((file) => file.id === objectCardData.imageId);
    return imageFile;
  }, [objectCardData.files, objectCardData.imageId]);
  // @ts-expect-error this is a valid type
  const { signedURL, location } = imageFile || {};

  const [fileURL, setFileURL] = useState<string>(location);
  const [imageStatus, setImageStatus] = useState<ImageStatus>(ImageStatus.DONE);

  useEffect(() => {
    setFileURL('');
    if (imageFile) {
      void getSignedUrl();
    }
  }, [imageId]);

  const getSignedUrl = async (): Promise<void> => {
    setImageStatus(ImageStatus.LOADING);

    if (signedURL) {
      setFileURL(signedURL);
    } else if (location?.includes('X-Amz-Algorithm')) {
      setFileURL(location);
    } else if (imageFile) {
      const url = encodeURI(`/api/file-proxy?filename=${imageFile.name}&id=${imageFile.id}`);
      const result = await fetch(url, {
        credentials: 'include',
      });

      if (result.ok) {
        try {
          setFileURL(encodeURI(result.url));
        } catch (e) {
          console.error('Failed to get signed URL for image', e);
        }
      } else {
        const body = await result.json();
        if (body?.error === 'file is still being processed') {
          setImageStatus(ImageStatus.PROCESSING);
          return;
        }

        console.error(result.statusText);

        setImageStatus(ImageStatus.DONE);
      }
    }

    setImageStatus(ImageStatus.DONE);
  };

  return (
    <div
      className={cx(' shrink-0 border relative transition-all duration-500', {
        'w-[40px] h-[40px]': isCompressedHeader,
        'w-[88px] h-[88px]': !isCompressedHeader,
      })}
    >
      <ImageDisplay image={fileURL} placeholderImage={getIcon(template?.name, 'w-[60%] h-[60% text-[6rem]')} />
      {isEdit && <ImageUpload />}
      {imageStatus === ImageStatus.PROCESSING && (
        <div className='flex absolute top-0 left-0 justify-center items-center w-full h-full transition-all bg-white/90'>
          Processing...
        </div>
      )}
      {imageStatus === ImageStatus.LOADING &&
        (
          <div className='flex absolute top-0 left-0 justify-center items-center w-full h-full transition-all bg-white/70'>
            <Loader className='w-[505] h-[50%] items-center' />
          </div>
        )}
    </div>
  );
}
