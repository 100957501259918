import { type Module } from '@/models/template.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/sharp-solid-svg-icons';
import { useFormContext, useWatch } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { getOrderedModuleEntries } from '../common/ModularObject/Page/utils';
import HookFormLabel from './HookFormLabel';
import ModuleWrapper from './utils/ModuleWrapper';

interface RepeaterButtonProps extends React.ComponentProps<'button'> {
  text?: string;
}
interface RepeaterProps {
  name?: string;
  path?: string;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputProps?: Record<string, any>;
  labelProps?: Record<string, unknown>;
  buttonProps?: RepeaterButtonProps;
}

interface RepeaterModule extends Module {
  added?: boolean;
  removed?: boolean;
}
export default function Repeater (
  { name, path, className, inputProps, labelProps = {}, buttonProps }: RepeaterProps,
): JSX.Element {
  const { setValue } = useFormContext();
  const repeaterName = `${path}.modules`;

  const repeaterModules: Record<string, RepeaterModule> = useWatch({ name: repeaterName, defaultValue: {} });

  const removeInstance = (key: string): void => {
    setValue(`${path}.modules.${key}`, null, { shouldDirty: true });
    setValue(`${path}.modules.${key}.removed`, true, { shouldDirty: true });
    // Update remaining modules' order
    const orderedModules = getOrderedModuleEntries(repeaterModules);
    orderedModules
      .filter(([_key, module]) => _key !== key && Boolean(key) && Boolean(module) && !module.removed)
      .forEach(([key, module], idx) => {
        setValue(`${path}.modules.${key}.order`, idx, { shouldDirty: true });
      });
  };

  const repeatingModule: Module = {
    ...inputProps.module,
    properties: {
      ...inputProps.module?.properties,
      onRemove: removeInstance,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const injectRemoveFunction = (module: Record<string, any>, key: string): Record<string, any> => {
    if (!module || Object.keys(module).length === 0) return;

    return {
      ...module,
      properties: {
        ...module?.properties,
        onRemove: removeInstance,
        repeaterKey: key,
      },
    };
  };

  const preppedValue = Object.entries(repeaterModules ?? {})?.filter(([, module]) => module && !module.removed)
    .reduce((acc, [key, module]) => {
      if (!module || Object.keys(module).length === 0) return acc;
      return {
        ...acc,
        [key]: injectRemoveFunction(module, key),
      };
    }, {});

  const addInstance = (): void => {
    const nextKey: string = uuid();
    const nextModule = {
      ...repeatingModule,
      order: Object.keys(preppedValue)?.length,
    };
    setValue(`${path}.modules.${nextKey}`, nextModule, { shouldDirty: true });
    setValue(`${path}.modules.${nextKey}.added`, true, { shouldDirty: true });
  };

  return (
    <>
      {Boolean(labelProps) && <HookFormLabel {...labelProps} />}
      <div className={className}>
        {getOrderedModuleEntries(preppedValue)
          .map(([key, module], idx) => (
            Object.keys(module ?? {}).length > 0 && (
              <ModuleWrapper
                key={`${path}.modules.${key}`}
                module={module}
                index={idx}
                indexLabel
                path={`${path}.modules.${key}`}
              />
            )
          ))}
      </div>
      <button
        type='button'
        onClick={addInstance}
        className={'justify-center button-secondary mt-3 w-full py-3'}
        {...buttonProps}
      >
        <FontAwesomeIcon icon={faPlus} className='mr-2' />
        {buttonProps?.text || 'Add'}
      </button>
    </>
  );
}
