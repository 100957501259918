import cx from 'classnames';
import { useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Label, useEditContext } from '@/components';
import DatePickerInput from '@/components/form/datePicker/datePickerInput';
import StatusTooltip from '@/components/tooltip/StatusTooltip';
import dayjs from 'dayjs';

interface DatePickerProps {
  className?: string;
  date: Date;
  onDateChange: (date: Date) => void;
  id: string;
  label?: string;
  placeholder?: string;
  name?: string;
}

/**
 * @deprecated do not use this. use src/components/form/DatePickerInput/DatePickerInput.tsx instead
 */
export default function DatePicker ({
  className = '',
  date,
  onDateChange,
  id,
  label,
  name,
}: DatePickerProps): JSX.Element {
  const { canUserEdit, changes, requestedChanges } = useEditContext();

  const tooltipStatus = useMemo(() => {
    if (Object.prototype.hasOwnProperty.call(changes ?? {}, name)) {
      return 'staged';
    }
    if (Object.prototype.hasOwnProperty.call(requestedChanges ?? {}, name)) {
      return 'waiting for approval';
    }
  }, [changes, requestedChanges]);

  return (
    <>
      {canUserEdit && (
        <div className={cx(className)}>
          {Boolean(label) && (
            <div className='flex'>
              <StatusTooltip status={tooltipStatus} />
              <Label>{label}</Label>
            </div>
          )}
          {canUserEdit && (
            <>
              <ReactDatePicker
                id={id}
                selected={date}
                onChange={onDateChange}
                dateFormat='yyyy-MM-dd'
                customInput={<DatePickerInput />}
                fixedHeight
              />
            </>
          )}
          {!canUserEdit && <p className='mt-4 sculpin-base'>{date ? dayjs(date).format('MM/DD/YYYY') : '—'}</p>}
        </div>
      )}
    </>
  );
}
