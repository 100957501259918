import { type User } from '@/__generated__/types';
import DisplayValue from '@/components/modules/DisplayValue';
import type HookFormLabel from '@/components/modules/HookFormLabel';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectRootState } from '@/state/selectors';
import { addUsers } from '@/state/slices/user.slice';
import { getUserName } from '@/util/functions';
import { getUserById } from '@/util/requests.functions';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';

export interface DisplayValueControllerProps {
  className?: HTMLDivElement['className'];
  value?: string;
  preformat?: boolean;
  labelProps?: Partial<React.ComponentProps<typeof HookFormLabel>>;
}

export default function DisplayValueController ({
  className = '',
  value = '',
  preformat = false,
  labelProps = {},
}: DisplayValueControllerProps): JSX.Element {
  const { users: userState } = useAppSelector(selectRootState);
  const dispatch = useAppDispatch();
  const ownerId = useWatch({ name: 'ownerId' });
  const [displayValue, setDisplayValue] = useState(null);

  useEffect(() => {
    void (async () => {
      let user: User;
      if (userState[ownerId]) {
        user = userState[ownerId];
      } else if (ownerId) {
        try {
          user = await getUserById(ownerId);
          dispatch(addUsers([user]));
        } catch (e) {}
      }

      switch (value) {
        case 'owner':
          setDisplayValue(getUserName(user));
          break;
        case 'ownerOrganization':
          setDisplayValue(user?.organization?.name || '—');
          break;
        default:
          setDisplayValue(value);
      }
    })();
  }, [value, ownerId]);

  return (
    <DisplayValue
      className={className}
      value={displayValue}
      labelProps={labelProps}
      preformat={preformat}
    />
  );
}
