import cx from 'classnames';
import { useEffect } from 'react';
import StepperProvider, { useStepperContext } from './Stepper.context';

interface StepProps {
  stepContent: React.FC<StepContentProps>;
  stepNumber: number;
  [key: string]: unknown;
  className?: string;
}

export interface StepContentProps {
  incrementStep?: () => void;
  decrementStep?: () => void;
  resetSteps?: () => void;
  step?: number;
}

interface StepperProps {
  children: React.ReactNode;
  initialStep?: number;
  className?: string;
}

export function Step (
  { className, stepContent: StepContent, stepNumber = 1, ...props }: Readonly<StepProps>,
): JSX.Element {
  const { step, register, increment, decrement, reset } = useStepperContext();

  useEffect(() => {
    register(stepNumber);
  }, [register, stepNumber]);

  const stepContentProps = {
    ...props,
    incrementStep: increment,
    decrementStep: decrement,
    resetSteps: reset,
    // TODO: find a way to allow breaking out of steps, without exposing the step number to the step content
    step,
  };

  return (
    <div
      className={cx('flex flex-col z-10 animate-fade-in', className, {
        // ! cannot get animation to fade out without flash of content in initial load
        'w-full': step === stepNumber,
        'z-[-1]': step !== stepNumber,
      })}
      data-testid='step'
    >
      {stepNumber === step && <StepContent {...stepContentProps} />}
    </div>
  );
}

export default function Stepper ({ children, className, initialStep = 1 }: Readonly<StepperProps>): JSX.Element {
  return (
    <div className={cx('relative flex flex-1 min-h-0', className)} data-testid='stepper'>
      <StepperProvider initialStep={initialStep}>{children}</StepperProvider>
    </div>
  );
}
