import { type FileModel } from '@/models';
import { downloadFile } from '@/util/downloadFile';
import { useRef } from 'react';

interface FileProps {
  file: FileModel;
}

export default function File ({ file }: FileProps): JSX.Element {
  const downloaderRef = useRef<HTMLAnchorElement>();

  return (
    <div key={file?.id} className=''>
      <button
        type='button'
        className='text-primary'
        onClick={async () => {
          await downloadFile(file, downloaderRef);
        }}
      >
        {file?.name}
      </button>
      <a ref={downloaderRef} className='hidden' />
    </div>
  );
}
