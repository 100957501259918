import { useCallback, useMemo } from 'react';
import { useQueryParams, type UserProfileQueryParams } from './useQueryParams';

export const useUserProfileDrawer = () => {
  const { updateUrlParams, queryParams } = useQueryParams<UserProfileQueryParams>();

  const isUserProfileDrawerOpen = Boolean(queryParams.activeProfileId);

  const closeUserProfileDrawer = useCallback(
    () => {
      const { activeProfileId, ...rest } = queryParams;
      updateUrlParams(rest);
    },
    [queryParams, updateUrlParams],
  );

  const openUserProfileDrawer = useCallback((userId: string) => {
    updateUrlParams({ ...queryParams, activeProfileId: userId });
  }, [queryParams, updateUrlParams]);

  const api = useMemo(() => {
    return { openUserProfileDrawer, closeUserProfileDrawer, isUserProfileDrawerOpen };
  }, [closeUserProfileDrawer, openUserProfileDrawer, isUserProfileDrawerOpen]);

  return api;
};
