import InfoTooltip from '@/components/tooltip/InfoTooltip';
import { faArrowRightToLine } from '@fortawesome/sharp-regular-svg-icons';
import { HeaderButton } from './HeaderButton';

export default function CloseButton ({ onClick }: { onClick: () => void }): JSX.Element {
  return (
    <InfoTooltip message='Close' tooltipClassName='effra-8' tooltipContentClassName='px-[6px] py-[4px]'>
      <HeaderButton icon={faArrowRightToLine} onClick={onClick} testId='object-card-header-close-button' />
    </InfoTooltip>
  );
}
