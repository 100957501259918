import { defaultEnv } from './constants';

export function env (key: string): string {
  let envValue;
  if (typeof window !== 'undefined' && window.__ENV) {
    envValue = window.__ENV[key] || window.__ENV[key.replace('NEXT_PUBLIC_', '')];
  } else {
    envValue = process.env[key] || process.env[key.replace('NEXT_PUBLIC_', '')];
  }

  return envValue || defaultEnv[key] || defaultEnv[key.replace('NEXT_PUBLIC_', '')];
}
