import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type DenyApprovalMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
  type: Types.ApprovalTypes;
}>;


export type DenyApprovalMutation = { __typename?: 'Mutation', denyApproval: { __typename?: 'Approval', id: string } | null };


export const DenyApprovalDocument = gql`
    mutation DenyApproval($id: UUID!, $type: ApprovalTypes!) {
  denyApproval(id: $id, type: $type) {
    id
  }
}
    `;
export type DenyApprovalMutationFn = Apollo.MutationFunction<DenyApprovalMutation, DenyApprovalMutationVariables>;

/**
 * __useDenyApprovalMutation__
 *
 * To run a mutation, you first call `useDenyApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDenyApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [denyApprovalMutation, { data, loading, error }] = useDenyApprovalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useDenyApprovalMutation(baseOptions?: Apollo.MutationHookOptions<DenyApprovalMutation, DenyApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DenyApprovalMutation, DenyApprovalMutationVariables>(DenyApprovalDocument, options);
      }
export type DenyApprovalMutationHookResult = ReturnType<typeof useDenyApprovalMutation>;
export type DenyApprovalMutationResult = Apollo.MutationResult<DenyApprovalMutation>;
export type DenyApprovalMutationOptions = Apollo.BaseMutationOptions<DenyApprovalMutation, DenyApprovalMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDenyApprovalMutation((req, res, ctx) => {
 *   const { id, type } = req.variables;
 *   return res(
 *     ctx.data({ denyApproval })
 *   )
 * })
 */
export const mockDenyApprovalMutation = (resolver: Parameters<typeof graphql.mutation<DenyApprovalMutation, DenyApprovalMutationVariables>>[1]) =>
  graphql.mutation<DenyApprovalMutation, DenyApprovalMutationVariables>(
    'DenyApproval',
    resolver
  )
