import classNames from 'classnames';
import React from 'react';

export interface MegaModalProps {
  readonly children: React.ReactNode;
  readonly className?: string;
}

export default function MegaModal ({ children, className = '' }: MegaModalProps): JSX.Element {
  return (
    <div
      className={classNames('bg-white flex-1 flex flex-col py-5 px-5 gap-6 min-w-0', className)}
      data-testid='mega-modal'
    >
      {children}
    </div>
  );
}
