import type * as Types from '../__generated__/types';

import { gql } from '@apollo/client';
export type ObjectParentPropsFragment = { __typename?: 'ModularObject', id: string, name: string | null, owner: { __typename?: 'User', id: string, organizationId: string | null } | null, template: { __typename?: 'Template', id: string | null, name: string | null } | null };

export const ObjectParentPropsFragmentDoc = gql`
    fragment ObjectParentProps on ModularObject {
  id
  name
  owner(includeDeleted: true) {
    id
    organizationId
  }
  template {
    id
    name
  }
}
    `;