import Image from 'next/legacy/image';

export function MobileUnsupported () {
  return (
    <section className='flex absolute top-0 left-0 z-10 justify-center w-screen h-screen bg-primary-dark'>
      <div className='flex flex-col px-7 pt-16 max-w-[30rem]'>
        <div className='flex justify-center'>
          <Image src='/images/astro-lizzy-on-my-way.gif' alt='Astro Lizzy' height={136} width={136} />
        </div>
        <h1 className='pt-16 text-center text-tertiary'>Please view on desktop</h1>
        <p className='pt-7 text-center text-white font-effra'>
          Integrate is unavailable on mobile phones and tablets. Please access the application via a desktop browser,
          preferably Google Chrome.
        </p>
        <div className='flex justify-center pt-7'>
          {/* <a className='text-secondary font-effra' href={`www.${env('NEXT_PUBLIC_APP_DOMAIN')}`}> */}
          {/*  ← Return home */}
          {/* </a> */}
        </div>
      </div>
    </section>
  );
}
