import { useWatch } from 'react-hook-form';
import useGetTemplateModuleName from './useGetTemplateModuleName';

interface ToggleHookProps {
  fieldToWatch: string | string[];
  toggleValue: string;
  enableSubscription?: boolean;
}

export default function useToggleGroup ({
  fieldToWatch = '',
  toggleValue = '',
  enableSubscription = false,
}: ToggleHookProps): boolean {
  const templateId = useWatch({ name: 'templateId' });

  const name = useGetTemplateModuleName(templateId, fieldToWatch);
  const disabled = !enableSubscription || !fieldToWatch;

  const value = useWatch({ name, disabled, defaultValue: '' });

  return toggleValue.includes(value);
}
