import { Transition } from '@headlessui/react';
import Image from 'next/legacy/image';

interface ColumnHeaderProps {
  title: string;
  actions?: JSX.Element;
  className?: string;
  hide?: boolean;
}

export default function ColumnHeader ({
  title,
  actions,
  className,
  hide,
}: ColumnHeaderProps): JSX.Element {
  const COLUMN_ICONS = {
    Details: {
      src: '/images/details-black.svg',
      alt: 'Details',
      height: 9.6,
      width: 12,
    },
    Collaboration: {
      src: '/images/collaboration-black.svg',
      alt: 'Collaboration',
      height: 12.8,
      width: 20,
    },
    Configuration: {
      src: '/images/configuration-black.svg',
      alt: 'Configuration',
      height: 12.8,
      width: 20,
    },
    'Serial Spacecraft': {
      src: '/images/serial-spacecraft-black.svg',
      alt: 'Serial Spacecraft',
      height: 14.4,
      width: 18.16,
    },
  };

  return (
    <div className={`${className} shrink-0 grow-0 border-r bg-zinc-50`}>
      <Transition
        className={`${className} flex justify-between py-3.5 pl-10 pr-5 bg-zinc-50`}
        show={!hide}
        enter='transition-opacity duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-150'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <div className='flex justify-between w-full'>
          <div className='flex'>
            <h2 className='mr-2 font-bold'>{title}</h2>
            {Boolean(title) && <Image {...COLUMN_ICONS[title]} />}
          </div>
          <div>{actions}</div>
        </div>
      </Transition>
    </div>
  );
}
