import { TASK_TYPES } from './constants';

export function isValidTaskType (taskType: string): boolean {
  if (!taskType) return false;
  return TASK_TYPES.some(validType => validType.toLowerCase() === taskType.toLowerCase());
}

export function mapToValidTaskType (taskType?: string): string {
  if (!taskType) return 'Task';
  return TASK_TYPES.find(validType => validType.toLowerCase() === taskType.toLowerCase()) ?? 'Task';
}
