import type { ModularObject } from '@/__generated__/types';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import type { ObjectCardSubTaskFragment } from './getObjectCardSubTasks.generated';
import useFilteredTasks from './hooks/useFilteredTasks';
import TaskListRow from './TaskListRow/TaskListRow';

interface TaskListProps {
  filterTerm: string;
  startDateFilter: string;
  targetDateFilter: string;
  parentFilter: string;
  tasks: ObjectCardSubTaskFragment[];
  setOpenTask: (open: boolean) => void;
  setCurrentTask: (task: ModularObject) => void;
}

export default function TaskList ({
  filterTerm,
  tasks,
  startDateFilter,
  targetDateFilter,
  parentFilter,
}: Readonly<TaskListProps>): JSX.Element {
  const { openViewObjectCardDrawer } = useObjectCardContext();
  const openTask = (task: ModularObject): void => {
    openViewObjectCardDrawer({ modularObjectId: task.id });
  };

  const filteredTasks = useFilteredTasks(
    tasks,
    startDateFilter,
    targetDateFilter,
    parentFilter,
  );

  return (
    <div className='flex overflow-auto flex-col py-4 no-scrollbar grow' data-testid='task-list'>
      {filteredTasks
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((task: ModularObject & { parent: Record<string, any> }, idx) => (
          <TaskListRow key={`${task.id}-${idx}`} task={task} openTask={openTask} />
        ))}
    </div>
  );
}
