import { type ComponentProps } from 'react';

export default function OTVIcon ({ ...otherProps }: ComponentProps<'svg'>): JSX.Element {
  return (
    <svg width='160' height='167' viewBox='0 0 160 167' fill='none' xmlns='http://www.w3.org/2000/svg' {...otherProps}>
      <g clipPath='url(#clip0_372_8478)'>
        <path
          d='M21.7575 24.8778H112.159L141.169 104.933L119.412 134.723H59.9284C61.5825 132.809 63.173 130.832 64.6362 128.918C65.336 127.961 65.9086 127.132 66.5447 126.239H110.251L86.3936 60.4721H11.1332L25.9563 101.361C23.9205 104.104 21.9483 107.038 20.0398 110.1L0 54.6673L21.7575 24.8778Z'
          fill='currentColor'
        />
        <path
          d='M61.0735 87.6463C60.2465 87.6463 59.3559 87.7101 58.2743 88.029C53.7574 89.1135 46.1232 93.3235 35.8171 107.038C22.6481 124.453 12.0238 150.479 5.9801 167.064C20.2942 156.794 42.4334 139.443 55.5387 122.029C70.8708 101.744 68.7714 91.6012 65.0815 88.7945C64.7634 88.5394 63.4274 87.6463 61.0099 87.6463H61.0735Z'
          fill='currentColor'
        />
        <path d='M82.004 0H40.5885L27.8012 16.2662L27.2922 16.9679L68.9622 16.9041L82.004 0Z' fill='currentColor' />
        <path
          d='M78.6322 16.9679H112.095H117.63L119.539 22.1986L130.99 53.7743L144.223 36.5512L130.99 0H91.7376L78.6322 16.9679Z'
          fill='currentColor'
        />
        <path d='M146.704 97.0871L160 80.0554L147.467 45.5455L134.235 62.7685L146.704 97.0871Z' fill='currentColor' />
      </g>
      <defs>
        <clipPath id='clip0_372_8478'>
          <rect width='160' height='167' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
