import { ProfileImage } from '@/components/ProfileImage/ProfileImage';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useModal } from '@/hooks/useModal';
import { getUserFullName } from '@/util/lookup.functions';
import { getPrettyDateString } from '@/util/time.functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/sharp-regular-svg-icons';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import cx from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useObjectContext } from '../common/ModularObject/Card/Object.context';
import { ConfirmDeleteCommentModal } from './CommentForm/ConfirmDeleteCommentModal';
import { useDeleteCommentMutation } from './deleteComment.generated';
import { GetCommentsForObjectIdDocument, type GetCommentsForObjectIdQuery } from './getCommentsForObjectId.generated';
dayjs.extend(utc);

export interface CommentProps {
  comment: GetCommentsForObjectIdQuery['getModularObjectByID']['comments'][0];
}

const extensions = [
  StarterKit,
];

export const Comment = ({ comment }: CommentProps) => {
  const { body, createdBy } = comment;
  const editor = useEditor({
    extensions,
    content: body,
    editable: false,
  });

  const loggedInUser = useLoggedInUser();
  const fullName = getUserFullName(createdBy);
  const { activeModularObject } = useObjectContext();

  const isInternal = loggedInUser?.organizationId === createdBy?.organizationId;

  const { showModal, closeModal } = useModal();
  const isObjectEditor = (activeModularObject?.shares ?? [])?.some((share) => {
    return share?.userId === loggedInUser?.id && share?.role?.toUpperCase() === 'EDITOR';
  });

  const canDeleteComment = () => {
    if (activeModularObject.ownerId === loggedInUser.id) return true;
    return isObjectEditor;
  };

  const [deleteComment] = useDeleteCommentMutation();

  const handleDeleteComment = () => {
    void deleteComment({ variables: { id: comment.id }, refetchQueries: [GetCommentsForObjectIdDocument] });
    closeModal();
  };

  const handleConfirmDeleteComment = () => {
    showModal(
      <ConfirmDeleteCommentModal closeModal={closeModal} handleDelete={handleDeleteComment} />,
      { className: 'w-[450px]' },
    );
  };

  return (
    <div className='flex flex-1 group' data-testid='comment'>
      <div className='mr-4'>
        <ProfileImage user={createdBy} />
      </div>
      <div className={cx('flex flex-col p-4 flex-1', { 'group-hover:bg-[#fafafa]': canDeleteComment })}>
        <div className='flex'>
          <span className={cx('leading-[14px] mr-2', { 'text-primary': isInternal, 'text-tertiary': !isInternal })}>
            {fullName}
          </span>
          <span className='mr-2 text-sm text-gray-ghost' data-chromatic='ignore'>
            {getPrettyDateString(comment?.createdAt)}
          </span>
          <FontAwesomeIcon
            data-testid='delete-comment'
            onClick={handleConfirmDeleteComment}
            className={cx('text-gray-ghost cursor-pointer ml-auto invisible', {
              'group-hover:visible': canDeleteComment,
            })}
            icon={faTrash}
          />
        </div>
        <EditorContent editor={editor} />
      </div>
    </div>
  );
};
