import getIcon from '@/util/getIcon';
import { type ReactNode } from 'react';

interface ImageDisplayProps {
  image?: string;
  placeholderImage?: ReactNode;
}

export default function ImageDisplay ({ image, placeholderImage }: ImageDisplayProps): JSX.Element {
  const defaultPlaceholderImage = getIcon();
  return (
    <>
      {image ?
        (
          <div
            data-testid='image-display'
            className='w-full before:pt-[100%] before:block grow bg-center bg-no-repeat bg-contain text-center'
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
        ) :
        (
          <div className='flex justify-center items-center w-full h-full text-center bg-center bg-no-repeat bg-contain before:block grow'>
            {placeholderImage ?? defaultPlaceholderImage}
          </div>
        )}
    </>
  );
}
