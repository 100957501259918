import { type User } from '@/__generated__/types';
import { Button, Modal, User as UserComponent } from '@/components';
import { Fragment, useState } from 'react';
import { NIL as NIL_UUID } from 'uuid';
import type { UserByIdFragment } from '../User/getUserById.generated';

interface SelectAssigneeModalProps {
  collaborators: Array<Partial<User | UserByIdFragment>>;
  currentAssigneeId: string;
  isModalOpen: boolean;
  onClose: () => void;
  onSubmit: (assigneeId: string) => void;
}

export function SelectAssigneeModal ({
  collaborators,
  currentAssigneeId,
  isModalOpen,
  onSubmit,
  onClose,
}: SelectAssigneeModalProps): JSX.Element {
  const [selectedUserId, setSelectedUserId] = useState<string>(currentAssigneeId);

  const _onSubmit = (): void => {
    onSubmit(selectedUserId);
  };

  const onChangeUser = (event): void => {
    setSelectedUserId(event.target.value || null);
  };

  const tableRow = (user: Partial<User | UserByIdFragment>): JSX.Element => {
    if (!user) return;
    return (
      <Fragment key={user.id}>
        <div className='overflow-hidden col-span-9 pl-4 w-full whitespace-nowrap text-ellipsis'>
          <label htmlFor={`select-user-${user.id}`}>
            <UserComponent
              user={user}
              display='large'
              disableUserProfileDrawer={true}
              disableUserProfileHover={true}
              disableUserProfile={true}
            />
          </label>
        </div>
        <div className='flex col-span-3 justify-end items-center pr-4 w-full text-right'>
          <label htmlFor={`select-user-${user.id}`}>
            <input
              id={`select-user-${user.id}`}
              name='new-owner'
              type='radio'
              value={user.id}
              checked={selectedUserId === user.id}
              onChange={onChangeUser}
            />
          </label>
        </div>
      </Fragment>
    );
  };

  return (
    <Modal
      header='Select Assignee'
      isOpen={isModalOpen}
      onClose={onClose}
      width='w-[40rem]'
      columns={[
        [
          {
            custom: (
              <div key='add-launch-service-modal-content' data-testid='select-assignee-modal'>
                <div className='grid grid-cols-12 font-medium text-primary'>
                  <div className='col-span-9 pl-4'>Collaborators</div>
                  <div className='col-span-3 text-right'>Select</div>
                </div>
                <div
                  className='grid overflow-scroll grid-cols-12 gap-x-2 gap-y-4 py-4 my-2 bg-white max-h-[14rem] no-scrollbar'
                  style={{
                    boxShadow: 'inset 0px 0px 8px 1px rgb(0 0 0 / 10%)',
                  }}
                >
                  {Boolean(collaborators.length) && (
                    <>
                      <div className='overflow-hidden col-span-9 pl-4 w-full whitespace-nowrap text-ellipsis'>
                        <label htmlFor='unassign-user'>
                          <UserComponent
                            user={null}
                            display='large'
                            disableUserProfileDrawer={true}
                            disableUserProfileHover={true}
                            disableUserProfile={true}
                          />
                        </label>
                      </div>
                      <div className='flex col-span-3 justify-end items-center pr-4 w-full text-right'>
                        <label htmlFor={'unassign-user'}>
                          <input
                            id={'unassign-user'}
                            name='new-owner'
                            type='radio'
                            value={NIL_UUID}
                            onChange={onChangeUser}
                          />
                        </label>
                      </div>
                    </>
                  )}
                  {collaborators?.map((user) => tableRow(user))}
                  {!collaborators?.length && (
                    <div className='col-span-12 p-5 text-center opacity-60'>
                      No collaborators to transfer to.
                    </div>
                  )}
                </div>
                <div className='flex flex-row gap-y-4 justify-between mt-8'>
                  <Button
                    className='flex justify-center items-center py-6 w-full text-white border border-solid cursor-pointer border-primary bg-primary effra-xs'
                    onClick={_onSubmit}
                  >
                    {'Assign'}
                  </Button>
                </div>
              </div>
            ),
          },
        ],
      ]}
    />
  );
}
