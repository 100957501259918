import { getCommaSeparated } from '@/util/math.functions';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

interface SliderProps {
  marks?: number;
  min?: number;
  max?: number;
  defaultValue?: number;
  value?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void;
  className?: string;
  timeRange?: boolean;
  disabled?: boolean;
}

export const Slider = (props: SliderProps): JSX.Element => {
  const [value, setValue] = useState(props.defaultValue || props.value);
  const [inputValue, setInputValue] = useState(props.defaultValue || props.value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const _inputChange = (value: number): void => {
    const correctValue = value > props.max ? props.max : value < props.min ? props.min : value;
    setInputValue(correctValue);
    setValue(correctValue);
    props.onChange(correctValue);
  };
  const debouncedInputChange = useCallback(debounce(_inputChange, 500), [props.value]);

  const handleSliderChange = (event): void => {
    setValue(event.target.value);
    props.onChange(event.target.value);
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value === '' ? null : Number(event.target.value);
    debouncedInputChange(newValue);
    setInputValue(newValue);
  };

  const fraction = 100 * ((value - props.min) / (props.max - props.min));

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <div className={classnames('flex items-center gap-2', props.className)}>
      <span
        className={classnames('font-light text-sm mt-[-1rem]', {
          'opacity-60': props.disabled,
        })}
      >
        {props.min}
      </span>
      <div className='flex flex-col grow'>
        <div style={{ transform: `translate(${fraction}%, 0.5rem)` }}>
          <span
            className={classnames('text-center font-medium text-sm', {
              'ml-[-2rem]': fraction > 50,
              'ml-[-1rem]': fraction < 50,
              'opacity-60': props.disabled,
            })}
          >
            Target
          </span>
        </div>
        <div>
          <input
            type='range'
            className={classnames(
              'w-full accent-primary bg-black appearance-none cursor-pointer h-[1px]',
              {
                'bg-opacity-60': props.disabled,
                'cursor-not-allowed': props.disabled,
              },
            )}
            min={props.min}
            max={props.max}
            value={value}
            onChange={handleSliderChange}
            disabled={props.disabled}
          />
        </div>
        <div style={{ transform: `translateX(${fraction}%)` }}>
          <input
            type='number'
            className={classnames(
              'text-center shadow-normal pl-2 pt-1 mt-2 border-b-2 border-black focus:border-primary focus:outline-none w-[5rem]',
              {
                'ml-[-3rem]': fraction > 50,
                'ml-[-2rem]': fraction < 50,
                'opacity-60': props.disabled,
                'border-opacity-60': props.disabled,
              },
            )}
            min={props.min}
            max={props.max}
            value={inputValue}
            onChange={handleInputChange}
            disabled={props.disabled}
          />
        </div>
      </div>
      <span
        className={classnames('font-light text-sm mt-[-1rem]', {
          'opacity-60': props.disabled,
        })}
      >
        {getCommaSeparated(props.max)}
      </span>
    </div>
  );
};
