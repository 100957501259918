import cx from 'classnames';

interface TableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rows?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  dataObjectName: string;
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (columnData: any) => void;
  location?: string;
}

const TABLE_LOCATION_PRESETS = {
  list: {
    tableHeight: '70vh',
    rowHeight: '7rem',
    hoverColor: 'bg-gray-50',
  },
  home: {
    tableHeight: '33vh',
    minHeight: '5.625rem',
    rowHeight: '5.625rem',
    lightShadow: true,
    hoverColor: 'bg-zinc-200',
  },
};

const CELL_PADDING = 'py-3.5 px-2';

export function Table (props: TableProps) {
  const { columns, rows, data, dataObjectName, id, onClick, location = 'list' } = props;
  const { tableHeight, rowHeight, lightShadow, minHeight, hoverColor } = TABLE_LOCATION_PRESETS[location];

  return (
    <div id={id} className='flex flex-col'>
      <div className='overflow-x-auto'>
        <div className='inline-block min-w-full align-middle'>
          <table className='min-w-full' data-testid='records-table'>
            <thead className='bg-zinc-50'>
              <tr className='font-bold text-left'>
                {columns?.map((v, idx) => (
                  <th
                    className={cx(v.class, CELL_PADDING, 'py-3.5 px-2')}
                    key={`${dataObjectName}-table-header-${idx}`}
                  >
                    {v.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody
              className={cx({
                'shadow-normal': !lightShadow,
                'shadow-light': lightShadow,
              })}
            >
              {false && !lightShadow && (
                <tr className='h-0'>
                  <td className='p-0 bg-none' colSpan={columns?.length}>
                    <div className='relative w-full'>
                      <div className='fixed w-full bg-gradient-to-t from-transparent h-[11px] to-black/[0.1]' />
                    </div>
                  </td>
                </tr>
              )}
              {!data?.length && (
                <tr
                  style={{ height: minHeight || tableHeight }}
                  className={cx({ 'bg-white': !lightShadow })}
                >
                  <td className='text-center' colSpan={columns?.length}>
                    No {dataObjectName !== 'spacecraft' ? `${dataObjectName}s` : dataObjectName} yet.{' '}
                    {onClick && (
                      <button
                        id={`placeholder-add-new-${dataObjectName}`}
                        className='text-primary bold'
                        onClick={onClick}
                      >
                        Add one.
                      </button>
                    )}
                  </td>
                </tr>
              )}

              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Object.values(data || {})?.map((data: any, k: number) => (
                  <tr
                    id={`${dataObjectName}-${data.id}`}
                    key={k}
                    style={{ height: rowHeight }}
                    className={cx(
                      `${dataObjectName}-row hover:${hoverColor} transition-all bg-white`,
                      {
                        'cursor-pointer ': Boolean(onClick),
                        'first:shadow-normal': !lightShadow,
                      },
                    )}
                    onClick={() => {
                      onClick && onClick(data);
                    }}
                  >
                    {rows(data).map((column, idx) => (
                      <td key={`${data.id}-${idx}`} className={cx(CELL_PADDING, column.class)}>
                        {column.content || (!column.noNullDash && '–')}
                      </td>
                    ))}
                  </tr>
                ))
              }
              {false && !lightShadow && (
                <tr className='h-0'>
                  <td className='p-0 bg-none' colSpan={columns?.length}>
                    <div className='w-full bg-gradient-to-b from-transparent -translate-y-[11px] h-[11px] to-black/[0.1]' />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
