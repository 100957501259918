const ShowSVG = () => (
  <svg
    className=''
    width='14'
    height='14'
    viewBox='0 0 28.56 21.36'
    fill='black'
    xmlns='http://www.w3.org/2000/svg'
    style={{ transform: 'scale(1.19) translate(1px, 1.5px)' }}
  >
    <path
      data-name='Path 16630'
      d='M12.5 15.612C5.831 15.612.6 8.623.383 8.326L0 7.806l.383-.52C.6 6.988 5.831 0 12.5 0s11.9 6.988 12.117 7.286l.383.52-.383.52c-.219.3-5.448 7.286-12.117 7.286M2.209 7.806c1.3 1.554 5.486 6.055 10.291 6.055s8.994-4.5 10.292-6.054c-1.3-1.554-5.485-6.056-10.292-6.056s-8.99 4.5-10.291 6.055'
    />
    <path
      data-name='Path 16631'
      d='M13.756 13.414A5.244 5.244 0 1 1 19 8.171a5.249 5.249 0 0 1-5.244 5.244m0-8.736a3.493 3.493 0 1 0 3.493 3.493 3.5 3.5 0 0 0-3.493-3.493'
      transform='translate(-1.061 -.365)'
    />
  </svg>
);

export default ShowSVG;
