import ModuleWrapper from '@/components/modules/utils/ModuleWrapper';
import { useWatch } from 'react-hook-form';
import { getOrderedModuleEntries } from '../../Page/utils';

export default function AttributesSection (): JSX.Element {
  const modules = useWatch({ name: 'data', defaultValue: [] });
  const custom = useWatch({ name: 'customFields', defaultValue: {} });

  const filteredModules = Object.entries(modules).filter(([key]) => !Object.keys(custom ?? {}).includes(key)).reduce(
    (obj, [key, val]) => {
      obj[key] = val;
      return obj;
    },
    {},
  );

  return (
    <div className='flex flex-col'>
      {getOrderedModuleEntries(filteredModules)
        .map(([key, module], idx) => (
          <div key={`data.${key}`} className='px-[24px]'>
            <ModuleWrapper module={module} index={idx} path={`data.${key}`} />
          </div>
        ))}
    </div>
  );
}
