import cx from 'classnames';
import Link from 'next/link';
import { type PropsWithChildren, type ReactNode } from 'react';
import InfoTooltip from '../../tooltip/InfoTooltip';
import { useSidenavContext } from '../Sidenav.context';

interface SidenavTabProps {
  name: ReactNode;
  className?: string;
  keyName?: string;
  icon?: ReactNode;
  subLabel?: string;
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isActive?: boolean;
  newTab?: boolean;
  actionIcon?: ReactNode;
  actionTitle?: string;
  actionOnClick?: () => void;
}

export default function SidenavTab ({
  name,
  keyName,
  className,
  icon,
  subLabel = '',
  href = '',
  onClick = () => {},
  isActive,
  newTab,
  actionIcon,
  actionTitle,
  actionOnClick,
}: PropsWithChildren<SidenavTabProps>): JSX.Element {
  const { isExpanded } = useSidenavContext();
  const stringName = typeof name === 'string' ? name : '';

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Keep the event from bubbling up to the parent and triggering the sideNav navigation
    event.stopPropagation();
    event.preventDefault();

    if (actionOnClick) {
      actionOnClick();
    }
  };

  return (
    <InfoTooltip
      message={keyName || stringName}
      className='w-[24px] @expanded/sidenav:w-full'
      tooltipClassName={cx('effra-8', {
        'hidden': isExpanded, // Do not show tooltips when not expanded
      })}
      tooltipContentClassName='px-[6px] py-[4px]'
      placement='right'
    >
      <Link
        id={`nav-${(keyName || stringName).toLowerCase()}`}
        data-testid='sidenav-items'
        href={href}
        onClick={onClick}
        target={newTab ? '_blank' : undefined}
        className={cx(
          className,
          'group/sidenav-link flex items-center gap-[12px] effra-14 @expanded/sidenav:px-[8px] hover:bg-white/20 h-[24px] text-white rounded-[2px] transition-all justify-center @expanded/sidenav:justify-start shrink-0',
          {
            '!bg-white/30 !opacity-100': isActive,
          },
        )}
      >
        {Boolean(icon) && (
          <div
            className={cx(
              'w-[14px] @expanded/sidenav:text-[12px] text-[14px]',
              'shrink-0 flex items-center',
              'justify-center',
            )}
          >
            {icon}
          </div>
        )}
        <span className='items-center gap-5 truncate hidden @expanded/sidenav:flex flex-1'>
          <span className={cx('truncate grow')}>{name}</span>
          {subLabel && <span className='effra-10'>{subLabel}</span>}
          {actionIcon && (
            <button
              title={actionTitle}
              className={cx('invisible opacity-40 hover:opacity-100 group-hover/sidenav-link:visible text-[12px]', {
                'hidden': !isExpanded, // Do not show action icons when not expanded
              })}
              onClick={handleActionClick}
            >
              {actionIcon}
            </button>
          )}
        </span>
      </Link>
    </InfoTooltip>
  );
}
