import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type RevertDiffMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type RevertDiffMutation = { __typename?: 'Mutation', revertDiff: Array<{ __typename?: 'Diff', id: string, externalID: string, externalType: string, diffType: Types.DiffType | null, createdAt: any, createdBy: string, fromMigration: boolean, triggeredByID: string | null, diff: { __typename?: 'FromTo', from: any | null, to: any | null, displayNames: any | null } }> };


export const RevertDiffDocument = gql`
    mutation RevertDiff($id: UUID!) {
  revertDiff(id: $id) {
    id
    externalID
    externalType
    diffType
    diff {
      from
      to
      displayNames
    }
    createdAt
    createdBy
    fromMigration
    triggeredByID
  }
}
    `;
export type RevertDiffMutationFn = Apollo.MutationFunction<RevertDiffMutation, RevertDiffMutationVariables>;

/**
 * __useRevertDiffMutation__
 *
 * To run a mutation, you first call `useRevertDiffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertDiffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertDiffMutation, { data, loading, error }] = useRevertDiffMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevertDiffMutation(baseOptions?: Apollo.MutationHookOptions<RevertDiffMutation, RevertDiffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevertDiffMutation, RevertDiffMutationVariables>(RevertDiffDocument, options);
      }
export type RevertDiffMutationHookResult = ReturnType<typeof useRevertDiffMutation>;
export type RevertDiffMutationResult = Apollo.MutationResult<RevertDiffMutation>;
export type RevertDiffMutationOptions = Apollo.BaseMutationOptions<RevertDiffMutation, RevertDiffMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRevertDiffMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ revertDiff })
 *   )
 * })
 */
export const mockRevertDiffMutation = (resolver: Parameters<typeof graphql.mutation<RevertDiffMutation, RevertDiffMutationVariables>>[1]) =>
  graphql.mutation<RevertDiffMutation, RevertDiffMutationVariables>(
    'RevertDiff',
    resolver
  )
