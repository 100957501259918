import type * as Types from '../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetRecentActivityCountByUserIdQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetRecentActivityCountByUserIdQuery = { __typename?: 'Query', getRecentActivityCountByUserId: number };


export const GetRecentActivityCountByUserIdDocument = gql`
    query GetRecentActivityCountByUserId($id: UUID!) {
  getRecentActivityCountByUserId(userId: $id)
}
    `;

/**
 * __useGetRecentActivityCountByUserIdQuery__
 *
 * To run a query within a React component, call `useGetRecentActivityCountByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentActivityCountByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentActivityCountByUserIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRecentActivityCountByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetRecentActivityCountByUserIdQuery, GetRecentActivityCountByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecentActivityCountByUserIdQuery, GetRecentActivityCountByUserIdQueryVariables>(GetRecentActivityCountByUserIdDocument, options);
      }
export function useGetRecentActivityCountByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentActivityCountByUserIdQuery, GetRecentActivityCountByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecentActivityCountByUserIdQuery, GetRecentActivityCountByUserIdQueryVariables>(GetRecentActivityCountByUserIdDocument, options);
        }
export function useGetRecentActivityCountByUserIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetRecentActivityCountByUserIdQuery, GetRecentActivityCountByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRecentActivityCountByUserIdQuery, GetRecentActivityCountByUserIdQueryVariables>(GetRecentActivityCountByUserIdDocument, options);
        }
export type GetRecentActivityCountByUserIdQueryHookResult = ReturnType<typeof useGetRecentActivityCountByUserIdQuery>;
export type GetRecentActivityCountByUserIdLazyQueryHookResult = ReturnType<typeof useGetRecentActivityCountByUserIdLazyQuery>;
export type GetRecentActivityCountByUserIdSuspenseQueryHookResult = ReturnType<typeof useGetRecentActivityCountByUserIdSuspenseQuery>;
export type GetRecentActivityCountByUserIdQueryResult = Apollo.QueryResult<GetRecentActivityCountByUserIdQuery, GetRecentActivityCountByUserIdQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetRecentActivityCountByUserIdQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ getRecentActivityCountByUserId })
 *   )
 * })
 */
export const mockGetRecentActivityCountByUserIdQuery = (resolver: Parameters<typeof graphql.query<GetRecentActivityCountByUserIdQuery, GetRecentActivityCountByUserIdQueryVariables>>[1]) =>
  graphql.query<GetRecentActivityCountByUserIdQuery, GetRecentActivityCountByUserIdQueryVariables>(
    'GetRecentActivityCountByUserId',
    resolver
  )
