import Stepper, { Step } from '@/components/Stepper/Stepper';
import { type UserModel } from '@/models';
import { useState } from 'react';
import EmailInputStep from './steps/EmailInputStep';
import UserLookupStep from './steps/UserLookupStep/UserLookupStep';
import UserSelectionContextProvider from './UserSelection.context';

interface UserSelectionProps {
  onClose: () => void;
  selectedAction: string;
}

export default function UserSelection ({ onClose, selectedAction }: Readonly<UserSelectionProps>): JSX.Element {
  const [userEmail, setUserEmail] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<UserModel>(null);

  return (
    <Stepper>
      <UserSelectionContextProvider
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        onClose={onClose}
      >
        <Step
          stepContent={EmailInputStep}
          stepNumber={1}
          selectedAction={selectedAction}
        />
        <Step
          stepContent={UserLookupStep}
          stepNumber={2}
        />
      </UserSelectionContextProvider>
    </Stepper>
  );
}
