import { CollapsibleSection, EditContext, Loader } from '@/components';
import { FileUploader, type FileUploaderRenderProps } from '@/components/form/fileUploader';
import { type FileModel } from '@/models';
import { useAppSelector } from '@/state/hooks';
import { selectRootState } from '@/state/selectors';
import { getObjectEditNewFiles } from '@/util/object-edit.utils';
import { type FileAndSigned } from '@/util/requests.functions';
import cx from 'classnames';
import Image from 'next/legacy/image';
import { useEffect, useState } from 'react';
import { v4, v5 } from 'uuid';

function renderWrapped (className: string, placeholderImage: string) {
  return function render ({
    handleFileDownload,
    handleFileRef,
    handleFileUpload,
    isUploading,
    objectFiles,
    getHREF,
  }: FileUploaderRenderProps) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [href, setHREF] = useState<string>(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      void (async () => {
        try {
          const file = objectFiles as FileModel;
          if (file[0]?.location) {
            await handleFileDownload(file[0], 0, false);
            setHREF(getHREF(0));
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }, [JSON.stringify(objectFiles)]);

    return (
      <EditContext.Consumer>
        {({ canUserEdit }): JSX.Element => (
          <div className={cx(className, 'self-start')}>
            <CollapsibleSection
              title='Image'
              lightHeader
              noPadding
              className='mb-6'
              actionButton={canUserEdit ?
                (
                  <button
                    onClick={handleFileUpload}
                    className='flex gap-2 items-center font-bold font-effra text-primary'
                  >
                    <span>Change Image</span>
                    <Image src='/images/image-primary.svg' width={16} height={12} />
                  </button>
                ) :
                null}
            >
              <div className='text-center border relative w-[100%] before:content-[""] before:pt-[100%] before:block overflow-hidden mt-2'>
                <div
                  className={cx('absolute top-0 left-0 bottom-0 right-0 bg-no-repeat bg-center', {
                    'bg-[length:50%_50%]': !href || href === placeholderImage,
                    'bg-contain': Boolean(href) && href !== placeholderImage,
                  })}
                  style={{
                    backgroundImage: `url(${href || placeholderImage})`,
                  }}
                >
                  {isUploading && (
                    <div className='flex items-center w-full h-full bg-white/90'>
                      <Loader className='m-auto max-h-[4rem] max-w-[4rem]' />
                    </div>
                  )}
                </div>
              </div>
              <a
                ref={(el) => {
                  handleFileRef(el, 0);
                }}
                className='hidden'
              />
            </CollapsibleSection>
          </div>
        )}
      </EditContext.Consumer>
    );
  };
}

interface ImageUploaderProps {
  className?: string;
  objectType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  objectEdit: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setObjectEdit: (object: any) => void;
  imageKey?: string;
  placeholderImage: string;
}

export function CardImageUploader (props: ImageUploaderProps): JSX.Element {
  const {
    className,
    objectType,
    objectEdit,
    setObjectEdit,
    imageKey = 'imageId',
    placeholderImage = '/images/spacecraft-black.svg',
  } = props;

  const { session: { user } } = useAppSelector(selectRootState);

  const [realLocation, setRealLocation] = useState<string>(null);
  const [location, setLocation] = useState<string>(null);

  useEffect(() => {
    setRealLocation(objectEdit?.files?.find((file) => file.id === objectEdit?.[imageKey])?.location);
  }, []);

  useEffect(() => {
    if (objectEdit?.files?.length && !realLocation) {
      setLocation(objectEdit?.files?.find((file) => file.id === objectEdit?.[imageKey])?.location);
    }
  }, [realLocation, objectEdit]);

  const handleObjectEdit = (_, fileAndSigned: FileAndSigned): void => {
    setLocation(fileAndSigned.signed);
    const image = fileAndSigned.file;
    image.id = v5(`${image.name}-${v4()}`, user?.id);
    const newFiles = getObjectEditNewFiles(objectEdit, image);
    setRealLocation(image.location);
    setObjectEdit({
      ...objectEdit,
      [imageKey]: image.id,
      files: newFiles,
    });
  };

  const renderFunc = renderWrapped(className, placeholderImage);

  return (
    <FileUploader
      fileAccept='image/*'
      objectType={objectType}
      objectEdit={{
        ...objectEdit,
        image: { ...objectEdit?.files?.find((file) => file.id === objectEdit?.[imageKey]), location },
      }}
      objectFileKey='image'
      handleObjectEdit={handleObjectEdit}
      render={renderFunc}
      tags={['file', 'image']}
    />
  );
}
