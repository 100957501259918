import cx from 'classnames';
import { type Dispatch, type ReactNode, type SetStateAction } from 'react';

interface ModalBGProps {
  children: ReactNode;
  open: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  disableBackgroundDismissal?: boolean;
}

export default function ModalBG ({
  children,
  open,
  setOpen,
  disableBackgroundDismissal = false,
}: ModalBGProps): JSX.Element {
  return (
    <div
      className={cx(
        'flex fixed justify-center items-center z-[9999] inset-0 flex-col overflow-hidden',
        { 'opacity-100': open, 'opacity-0 pointer-events-none delay-200 ease-out': !open },
      )}
    >
      <div
        className={cx('z-[9999] fixed inset-0 w-screen h-screen bg-black/60', {
          'opacity-100 ease-in duration-300': open,
          'opacity-0 ease-out duration-100': !open,
        })}
        data-testid='modal-bg'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!disableBackgroundDismissal) setOpen(false);
        }}
      />
      <div
        className={cx('z-[9999] fixed flex flex-col justify-center items-center', {
          'delay-100 ease-in duration-200 opacity-100 translate-y-0 sm:scale-100': open,
          'ease-out duration-100 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95': !open,
        })}
      >
        {open && children}
      </div>
    </div>
  );
}
