import { Breadcrumb, type BreadCrumbModel } from '@/components/breadcrumb';
import cx from 'classnames';
import Image from 'next/legacy/image';

interface CardHeaderControlsProps {
  className?: string;
  cancelAction?: string;
  onCancelAction: () => void;
  breadcrumbs: BreadCrumbModel[];
}

export function CardHeaderControls (props: CardHeaderControlsProps): JSX.Element {
  const { className = '', cancelAction = 'Close', onCancelAction, breadcrumbs } = props;

  return (
    <div
      className={cx(
        'flex justify-between items-center bg-zinc-50 w-full h-header shrink-0 px-10 border-b shadow-md',
        className,
      )}
    >
      <Breadcrumb crumbs={breadcrumbs} />
      <button
        data-testid={`${cancelAction.toLowerCase()}-button`}
        onClick={onCancelAction}
        className='flex items-center font-bold font-effra text-black/60'
      >
        <span className='mr-1'>{cancelAction}</span>
        <Image src='/images/x-gray.svg' alt='X' height={11.2} width={11.2} />
      </button>
    </div>
  );
}

export default CardHeaderControls;
