import { createSelector } from '@reduxjs/toolkit';

import type { HardwareIntegration, ModularObject } from '@/__generated__/types';
import type { Diff } from '@/models/diff.model';
import { needsToApproveIntegration } from '@/models/integration.model';
import { ADMIN_ROLE, SUPER_ROLE } from '@/util/constants';
import { getObject } from '@/util/lookup.functions';
import dayjs from 'dayjs';
import generateChangeLog from './epics/diffObjectsForTimeline/diffObjects';
import type { RootState } from './store';

export const selectRootState = (state: RootState): RootState => state;
export const selectUsers = (state: RootState) => state.users;
export const selectSession = (state: RootState) => state.session;
export const selectIntegrations = (state: RootState) => state.integrations;
export const selectModularObjects = (state: RootState) => state.modularObjects;
export const selectOpenModularObject = (state: RootState) => state.session.modularObjectModal.modularObjectId;
export const selectTemplates = (state: RootState) => state.templates;
export const selectShares = (state: RootState) => state.shares;
export const selectApprovals = (state: RootState) => state.approvals;
export const selectLoggedInUser = createSelector(selectSession, (session) => session.user);
export const selectIsAdminOrSuper = createSelector(
  selectLoggedInUser,
  (user) => [ADMIN_ROLE, SUPER_ROLE].includes(user?.role),
);
export const selectUser = createSelector(
  [
    selectUsers,
    (state, userId) => userId,
  ],
  (users, userId) => users[userId],
);
export const selectUsersSelector = createSelector(
  selectUsers,
  (users) => users,
);
export const selectIsSameOrg = createSelector(
  [
    selectLoggedInUser,
    (state, userId) => selectUser(state, userId),
  ],
  (loggedInUser, user) => loggedInUser?.organizationId === user?.organizationId,
);
export const selectModularObjectById = createSelector(
  [
    selectModularObjects,
    (state, objectId) => objectId,
  ],
  (modularObjects, objectId) => modularObjects[objectId],
);
export const selectTemplateById = createSelector(
  [
    selectTemplates,
    (state, templateId) => templateId,
  ],
  (templates, templateId) => templates[templateId],
);
export const selectSharesByObjectId = createSelector(
  [
    selectShares,
    (state, objectId) => objectId,
  ],
  (shares, objectId) => {
    return shares[objectId];
  },
);
export const selectIntegrationsMappedToObjectId = createSelector(
  selectIntegrations,
  (integrations) => {
    return Object.values(integrations ?? {}).reduce((acc: Record<string, HardwareIntegration>, integration) => {
      return {
        ...acc,
        [integration?.objectId]: integration,
      };
    }, {});
  },
);
export const selectUserPermissionForObjectId = createSelector(
  [
    selectLoggedInUser,
    (state, objectId) => selectModularObjectById(state, objectId),
    (state, objectId) => selectSharesByObjectId(state, objectId),
  ],
  (loggedInUser, object, shares) => {
    // If logged in user is the owner of the object
    if (object?.ownerId === loggedInUser?.id) {
      return 'Owner';
    }
    // If logged in user is shared on the object
    const userShare = Object.values(shares ?? {}).find((share) => share?.userId === loggedInUser?.id);
    if (userShare) {
      return userShare?.role;
    }
    return 'Viewer';
  },
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getFilteredSchedule (items: any[], filterText: string) {
  if (filterText?.length) {
    if (!items?.length) return [];
    return items
      .map((item) => {
        switch (item.rowType) {
          case 'milestone': {
            return item.name.toLowerCase().includes(filterText.toLowerCase()) ? item : null;
          }
          case 'modular_object': {
            if (item.name.toLowerCase().includes(filterText.toLowerCase())) {
              return item;
            }
            const filteredRows = getFilteredSchedule(item.rows, filterText);
            const hasFilteredRows = filteredRows?.some((row) => row.rowType !== 'milestone');
            const filteredMilestones = getFilteredSchedule(item.milestones, filterText);
            const hasFilteredMilestones = Boolean(filteredMilestones?.length);
            return (hasFilteredRows || hasFilteredMilestones)
              ? {
                ...item,
                rows: filteredRows,
                hasFilteredRows,
                milestones: filteredMilestones,
                hasFilteredMilestones,
              }
              : null;
          }
          default:
            return items;
        }
      })
      .filter(Boolean);
  }

  return items;
}

export const makeSelectModularObjectById = () =>
  createSelector([
    selectModularObjects,
    selectIntegrations,
    (_, id) => id,
  ], (modularObjects, _integrations, id): ModularObject & { integrations: HardwareIntegration[] } => {
    const modularObject = modularObjects[id];

    const integrations = Object.values(_integrations).filter((i) =>
      !i.isPending &&
      id &&
      i.parentId === id &&
      modularObjects[i.objectId]
    );

    return { ...modularObject, integrations };
  });

export const selectAllApprovals = createSelector(
  selectRootState,
  (state) => {
    const { approvals, diffs, session: { user } } = state;

    if (!approvals || !user || !diffs) {
      return {
        pendingApprovals: [],
        pendingDiffs: [],
      };
    }

    const isOwnerOfObject = (diff: Diff): boolean => {
      const object = getObject(diff.externalType, diff.externalID, state);
      return object?.ownerId === user?.id;
    };

    // ! Temporary fix, ignore requested diffs from migrations
    const isValidDiff = (diff: Diff): boolean => {
      // Migrated diffs will not have from value
      return !diff.fromMigration;
    };

    const pendingDiffs = (() => {
      const flatDiffs = Object.values(
        Object.values(diffs || {}).reduce((acc, curr) => {
          return { ...acc, ...curr };
        }, {}),
      );

      const resolvedDiffs = flatDiffs.filter((diff) => (diff.diffType === 'denied' || diff.diffType === 'approved'))
        .map((
          diff,
        ) => diff?.diff?.to?.requestedDiffId).filter(Boolean);
      const pending = flatDiffs.filter((diff) => {
        const pending = diff.diffType === 'requested' && !resolvedDiffs.includes(diff?.id) && isOwnerOfObject(diff) &&
          isValidDiff(diff);
        // Filter out diffs that do not have a changeLog
        const changeLog = generateChangeLog(diff.diff.from, diff.diff.to, diff.diff.displayNames, diff.externalType);
        const hasChangeLog = changeLog.length > 0;

        return pending && hasChangeLog;
      });

      return pending.sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix());
    })();

    const { organizations, integration, organizationJoinRequests } = approvals;

    const pendingApprovals = [];

    const orgApprovals = Object.values(organizations || {});
    const integrationApprovals = Object.values(integration || {});
    const organizationJoinRequestsApprovals = Object.values(organizationJoinRequests || {});

    pendingApprovals.push(...orgApprovals.map((org) => ({
      ...org,
      approvalType: 'organizations',
    })));
    pendingApprovals.push(
      ...organizationJoinRequestsApprovals.map((approval) => ({
        ...approval,
        approvalType: 'organizationJoinRequests',
      })),
    );
    pendingApprovals.push(
      ...integrationApprovals
        .filter((approval) => {
          return (
            !approval.deletedAt && needsToApproveIntegration(approval, user?.id, state)
          );
        })
        .map((approval) => ({
          ...approval,
          approvalType: 'integration',
        })),
    );

    pendingApprovals.sort((a, b) => (dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1));

    return {
      pendingApprovals,
      pendingDiffs,
    };
  },
);
