'use client';
import type {
  CreateModularObjectCardFragmentFragment,
} from '@/components/common/CreateNewObjectCard/CreateModularObject.generated';
import {
  type GetObjectCardObjectQuery,
  useGetObjectCardObjectQuery,
} from '@/components/common/ModularObject/Card/GetObjectCardObject.generated';
import { addToastError } from '@/components/Toast/utils';
import { useQueryParams } from '@/hooks/useQueryParams';
import React, {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ObjectCardQueryParamValues = {
  modularObjectId?: string | 'new';
  templateId?: string;
  tid?: string; // Need to name this separately because of the templateId in the path for the object listing pages (e.g. /templates/:name/:templateId)
  isObjectCardOpen?: 'true';
  fullWidth?: 'true';
};

interface OpenViewObjectCardDrawerArgs {
  modularObjectId: string;
}

interface NewObjectConfig {
  createdFromId?: string;
  createdFromTemplateId?: string;
  asParent?: boolean;
  asTask?: boolean;
  isDependency?: boolean;
  templateId: string;
  isBlockingDependency?: boolean;
  afterObjectCreationSave?: (modularObject: CreateModularObjectCardFragmentFragment) => void;
}

interface ObjectCardContextValue {
  isObjectCardDrawerOpen: boolean;
  isObjectCardFullWidth: boolean;
  openViewObjectCardDrawer: (objectCardDrawerArgs: OpenViewObjectCardDrawerArgs) => void;
  openCreateNewObjectCardDrawer: (newObjectConfig: NewObjectConfig) => void;
  closeObjectCardDrawer: () => void;
  activeModularObjectId?: string;
  newObjectConfig?: NewObjectConfig;
  isLoadingObjectCardData: boolean;
  objectCardData?: GetObjectCardObjectQuery['getModularObjectByIDs'][0];
  handleSetExternalObjectViewer?: (
    isExternalObjectViewer: boolean,
    object: GetObjectCardObjectQuery['getModularObjectByIDs'][0],
  ) => void;
}

const ObjectCardContext = createContext<ObjectCardContextValue>(null);

export const useObjectCardContext = (): ObjectCardContextValue => {
  const context = useContext(ObjectCardContext);
  if (!context) {
    throw new Error('useObjectCardContext must be used within an ObjectCardContextProvider');
  }
  return context;
};

const safeDefault = {
  id: null,
  name: '',
  ownerId: null,
  templateId: null,
  parent: null,
  children: [],
  tasks: { edges: [] },
  approvals: [],
  diffs: [],
  integrations: [],
  approvalsEnabled: false,
} as GetObjectCardObjectQuery['getModularObjectByIDs'][0];

const getDefaultNewObjectConfig = (): NewObjectConfig => ({
  templateId: null,
  createdFromId: undefined,
  createdFromTemplateId: undefined,
  asParent: false,
  asTask: false,
  isDependency: false,
  isBlockingDependency: false,
  afterObjectCreationSave: null,
});

export const ObjectCardContextProvider = ({ children }: PropsWithChildren) => {
  const { updateUrlParams, queryParams } = useQueryParams<ObjectCardQueryParamValues>();
  const [newObjectConfig, setNewObjectConfig] = useState<NewObjectConfig>(
    getDefaultNewObjectConfig(),
  );
  const [isExternalObjectViewer, setIsExternalObjectViewer] = useState<boolean>();
  const [externalObject, setExternalObject] = useState<GetObjectCardObjectQuery['getModularObjectByIDs'][0]>();

  // Didn't want to use useEffect but the fact that the templateId is set via a useState kind of forces our hand without refactoring
  useEffect(() => {
    if (queryParams?.templateId) {
      setNewObjectConfig((prev) => ({ ...prev, templateId: queryParams.templateId }));
    }
  }, [queryParams?.templateId]);

  // todo: get rid of this.  we need to be managing external links more succinctly
  const handleSetExternalObjectViewer = useCallback(
    (isExternalObjectViewer: boolean, object: GetObjectCardObjectQuery['getModularObjectByIDs'][0]) => {
      setIsExternalObjectViewer(isExternalObjectViewer);
      setExternalObject(object);
    },
    [],
  );

  // todo: get rid of this.  we need to be managing external links more succinctly
  const resetExternalObjectCrap = useCallback(() => {
    setIsExternalObjectViewer(false);
    setExternalObject(null);
  }, []);

  const isObjectCardDrawerOpen = Boolean(queryParams?.isObjectCardOpen);
  const isObjectCardFullWidth = Boolean(queryParams?.fullWidth);

  const openViewObjectCardDrawer = useCallback(
    ({ modularObjectId }: OpenViewObjectCardDrawerArgs) => {
      const { modularObjectId: prevModularObjectId, ...rest } = queryParams;

      // Resetting new object config when opening view object card drawer since don't want new object config to be persisted
      setNewObjectConfig(getDefaultNewObjectConfig());

      updateUrlParams({ ...rest, modularObjectId, isObjectCardOpen: 'true' });
    },
    [queryParams, updateUrlParams],
  );

  const openCreateNewObjectCardDrawer = useCallback((newObjectConfig: NewObjectConfig) => {
    const { modularObjectId: prevModularObjectId, ...rest } = queryParams;

    setNewObjectConfig(newObjectConfig);
    updateUrlParams({ ...rest, isObjectCardOpen: 'true', tid: newObjectConfig.templateId });
    resetExternalObjectCrap();
  }, [queryParams, resetExternalObjectCrap, updateUrlParams]);

  const closeObjectCardDrawer = useCallback(
    () => {
      const { modularObjectId, tid, isObjectCardOpen, fullWidth, ...rest } = queryParams;
      setNewObjectConfig(getDefaultNewObjectConfig());
      updateUrlParams(rest);
    },
    [queryParams, updateUrlParams],
  );

  const activeModularObjectId = queryParams?.modularObjectId;

  const { data, loading: isLoadingObjectCardData, previousData } = useGetObjectCardObjectQuery({
    variables: { modularObjectId: activeModularObjectId },
    fetchPolicy: 'network-only',
    skip: !activeModularObjectId,
    onCompleted: (data) => {
      if (!data.getModularObjectByIDs) {
        console.error('No object returned from fetching card data', activeModularObjectId);
        addToastError('Unable to access object.');
        closeObjectCardDrawer();
      }
    },
    onError: (error) => {
      console.error('Error fetching object card data', error);
      addToastError(`Unable to access object. ${error.message}`);
      closeObjectCardDrawer();
    },
  });

  const _data = isLoadingObjectCardData ? previousData : data;
  const objectCardData = useMemo(() => {
    if (!_data?.getModularObjectByIDs && !externalObject) {
      return safeDefault;
    }

    if (isExternalObjectViewer) {
      if (!externalObject) {
        return safeDefault;
      }
      return externalObject;
    }

    return _data.getModularObjectByIDs[0];
  }, [_data?.getModularObjectByIDs, externalObject, isExternalObjectViewer]);

  const api = useMemo(() => {
    return {
      isObjectCardDrawerOpen,
      isObjectCardFullWidth,
      closeObjectCardDrawer,
      activeModularObjectId,
      openViewObjectCardDrawer,
      openCreateNewObjectCardDrawer,
      newObjectConfig,
      isLoadingObjectCardData,
      objectCardData,
      handleSetExternalObjectViewer,
    };
  }, [
    isObjectCardDrawerOpen,
    isObjectCardFullWidth,
    closeObjectCardDrawer,
    activeModularObjectId,
    openViewObjectCardDrawer,
    openCreateNewObjectCardDrawer,
    newObjectConfig,
    isLoadingObjectCardData,
    objectCardData,
    handleSetExternalObjectViewer,
  ]);

  return (
    <ObjectCardContext.Provider value={api}>
      {children}
    </ObjectCardContext.Provider>
  );
};
