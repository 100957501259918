import Image from 'next/legacy/image';
import { type ChangeEventHandler, type ForwardedRef, forwardRef } from 'react';

interface DatePickerInputProps {
  id?: string;
  onClick?: () => void;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const DatePickerInput = forwardRef(
  ({ id, value, onClick, onChange }: DatePickerInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const _onClick = (event): void => {
      event.preventDefault();
      onClick();
    };

    return (
      <div ref={ref} className='relative'>
        <input
          id={id}
          onClick={_onClick}
          type='date'
          value={value}
          placeholder={'MM/DD/YYYY'}
          onChange={onChange}
          className='w-full input-text'
        />
        <button type='button' onClick={onClick} className='absolute top-3 right-3'>
          <Image src='/images/calendar.svg' alt='Calendar' height={16} width={16} />
        </button>
      </div>
    );
  },
);

DatePickerInput.displayName = 'DatePickerInput';

export default DatePickerInput;
