import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
dayjs.extend(calendar);

export function toCalendarDate (date: string | Dayjs) {
  return dayjs(date).calendar(null, {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: '[Next] dddd',
    lastWeek: '[Last] dddd',
    sameElse: '[on] MM/DD/YYYY',
  });
}
