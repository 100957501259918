import type { Diff } from '@/__generated__/types';
import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

type DiffRecord = Record<string, Diff>;
type DiffObjectRecord = Record<string, DiffRecord>;

const initialState: DiffObjectRecord = {};

export const diffSlice = createSlice({
  name: 'diffs',
  initialState,
  reducers: {
    addDiffs(state, action) {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((diff) => {
          set(state, [diff.externalID, diff.id], diff);
        });
      } else {
        set(state, [action.payload.externalID, action.payload.id], action.payload);
      }
    },
  },
});

export const { addDiffs } = diffSlice.actions;
export const { reducer: diffsReducer } = diffSlice;
