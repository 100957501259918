import { createContext, type ReactNode, useContext, useMemo } from 'react';

import { type UserModel } from '@/models';

interface UserContextValue {
  user: UserModel;
  currentUser: UserModel;
  isInternal: boolean;
  isDeleted: boolean;
  noUserText: string;
  config: {
    profileDimensions: number;
    showRoleDesc: boolean;
    showIcon: boolean;
  };
}

interface UserProviderProps {
  user: UserModel;
  currentUser: UserModel;
  display?: 'single' | 'large' | 'medium' | 'default' | 'small';
  noUserText?: string;
  children: ReactNode;
  config?: {
    profileDimensions?: number;
    showRoleDesc?: boolean;
    showIcon?: boolean;
  };
}

const UserContext = createContext<UserContextValue | null>(null);

export const useUserContext = (): UserContextValue => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('UserContext must be used within a UserContextProvider');
  }

  return context;
};

const configMap = new Map();

configMap.set('single', { profileDimensions: 24, showRoleDesc: false, showIcon: true });
configMap.set('large', { profileDimensions: 52, showRoleDesc: true, showIcon: true });
configMap.set('medium', { profileDimensions: 48, showRoleDesc: true, showIcon: true });
configMap.set('default', { profileDimensions: 32, showRoleDesc: true, showIcon: true });

export default function UserProvider ({
  user,
  currentUser,
  display = 'default',
  noUserText,
  config = {},
  children,
}: UserProviderProps): JSX.Element {
  const value = useMemo(
    () => ({
      user,
      currentUser,
      noUserText,
      isInternal: user?.organization?.id === currentUser?.organizationId
        || user?.organizationId === currentUser?.organizationId,
      isDeleted: Boolean(user?.deletedAt),
      config: { ...configMap.get(display), ...config },
    }),
    [user, currentUser, display],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
