import { type HardwareIntegration } from '@/__generated__/types';
import { type CalcRelations } from '@/models';
import { postCalcTotalMass } from '@/util/requests.functions';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import HookFormLabel from '../HookFormLabel';
import { getRelation } from './calculators.utils';

const DEFAULT_RELATIONS = {
  self: {
    fieldDependencies: {
      mass: {
        type: 'number',
        fields: ['Mass', 'Wet Mass', 'Dry Mass'],
      },
    },
  },
  children: {
    fieldDependencies: {
      mass: {
        type: 'number',
        fields: ['Mass', 'Wet Mass', 'Dry Mass'],
      },
    },
  },
};

interface TotalMassProps {
  className?: string;
  name: string;
  labelProps?: React.ComponentProps<typeof HookFormLabel>;
  relations?: CalcRelations;
  suffix?: string;
}

export default function TotalMass (
  { className, name, labelProps, relations = DEFAULT_RELATIONS, suffix = 'kg' }: TotalMassProps,
): JSX.Element {
  const [selfMass, setSelfMass] = useState(0);
  const [totalMass, setTotalMass] = useState(0);
  const objectId = useWatch({ name: 'id' });
  const data = useWatch({ name: 'data' });
  const integrations = useWatch({ name: 'integrations' });

  const childFields = relations?.children?.fieldDependencies?.mass?.fields ?? [];

  const getTotalMass = useCallback(
    async (id: string, mass: number, integrations: HardwareIntegration[]): Promise<void> => {
      const pendingIntegrations = integrations.filter(integration => integration.isPending);
      const newTotalMass = await postCalcTotalMass(id, childFields, mass, pendingIntegrations);
      setTotalMass(newTotalMass);
    },
    [selfMass],
  );

  const debouncedGetTotalMass = useCallback(debounce(getTotalMass, 300), []);

  useEffect(() => {
    const mass = parseFloat(getRelation(relations.self, data)?.mass ?? '0');
    setSelfMass(mass);
  }, [data]);

  useEffect(() => {
    void debouncedGetTotalMass(objectId, selfMass, integrations);
  }, [selfMass, integrations, objectId]);

  return (
    <div className={className}>
      <HookFormLabel {...labelProps} />
      <div className='flex gap-1 items-end'>
        <div className='leading-[1.125rem] sculpin-lg'>
          {totalMass}
        </div>
        <div className='align-bottom effra-xs leading-[0.75rem]'>
          {suffix}
        </div>
      </div>
    </div>
  );
}
