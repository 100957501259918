import { GetHistoryUpdatesForObjectIdDocument } from '@/components/cards/UpdatesColumn/getHistoryUpdatesForObjectId.generated';
import { usePrismaticContext } from '@/components/Contexts/prismatic.context';
import DatePickerInput, { ParentRangeColor } from '@/components/form/DatePickerInput/DatePickerInput';
import Assignee from '@/components/modules/Assignee';
import DisplayValueController from '@/components/modules/DisplayValueController';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { GetTemplatesUsedByModularObjectsDocument } from '@/graphql/sideNavigation/getTemplatesUsedByModularObjects.generated';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { buildFieldsPendingApprovalMap } from '@/util/approvals';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { buildUpdateObjectDiffPayload } from '../../utils';
import DetailItem from './DetailItem';
import Estimation from './Estimation';
import { useGetTotalResourceCostQuery } from './getTotalResourceCost.generated';
import ParentSelectButtonWrapper from './ParentSelectButtonWrapper';
import TotalResourceCost from './TotalResourceCost';
import { useUpdateObjectCardStartDateMutation } from './UpdateObjectCardStartDate.generated';
import { useUpdateObjectCardTargetDateMutation } from './UpdateObjectCardTargetDate.generated';

const START_DATE_FIELD_NAME = 'startDate';
const TARGET_DATE_FIELD_NAME = 'targetDate';

export default function DetailsSection (): JSX.Element {
  const loggedInUser = useLoggedInUser();
  const resourceCostUnit: string = loggedInUser?.organization?.resourceCostUnit || 'resource cost';
  const { objectCardData, activeModularObjectId } = useObjectCardContext();
  const { canDeleteJiraTask, updateJiraTicket } = usePrismaticContext();
  const isTask = objectCardData.template?.type?.toLowerCase() === 'task';
  const [lastSavedStartDate, setLastSavedStartDate] = useState<string>(objectCardData.startDate);
  const [lastSavedTargetDate, setLastSavedTargetDate] = useState<string>(objectCardData.targetDate);

  const [updateStartDate] = useUpdateObjectCardStartDateMutation();
  const [updateTargetDate] = useUpdateObjectCardTargetDateMutation();

  const startDate = useMemo(() => {
    return objectCardData.startDate ? new Date(objectCardData.startDate) : null;
  }, [objectCardData?.startDate]);

  const targetDate = useMemo(() => {
    return objectCardData.targetDate ? new Date(objectCardData.targetDate) : null;
  }, [objectCardData?.targetDate]);

  const handleStartDateChange = useCallback(
    async (date: string) => {
      const updateStartDatePayload = buildUpdateObjectDiffPayload({
        objectId: objectCardData?.id,
        fieldName: START_DATE_FIELD_NAME,
        newFieldValue: date,
      });

      const isDateSameAsLastSave = dayjs(lastSavedStartDate).format() === dayjs(date).format();
      if (isDateSameAsLastSave) return;

      setLastSavedStartDate(date);
      await updateStartDate({
        variables: { input: { diffs: [updateStartDatePayload] } },
        refetchQueries: [GetHistoryUpdatesForObjectIdDocument, GetTemplatesUsedByModularObjectsDocument],
        onCompleted: (data) => {
          const fieldsPendingApprovalMap = buildFieldsPendingApprovalMap(data.updateModularObject?.[0]?.approvals);
          const isSaved = !fieldsPendingApprovalMap[START_DATE_FIELD_NAME];

          const SUCCESS_MESSAGE = isSaved
            ? 'Successfully saved start date'
            : 'Successfully requested approval for start date';

          addToastSuccess(SUCCESS_MESSAGE);
        },
        onError: () => addToastError('Failed to update start date, please try again'),
      });

      if (canDeleteJiraTask) {
        await updateJiraTicket();
      }
    },
    [canDeleteJiraTask, lastSavedStartDate, objectCardData?.id, updateJiraTicket, updateStartDate],
  );

  const handleTargetDateChange = useCallback(
    async (date: string) => {
      const updateTargetDatePayload = buildUpdateObjectDiffPayload({
        objectId: objectCardData?.id,
        fieldName: TARGET_DATE_FIELD_NAME,
        newFieldValue: date,
      });
      const isDateSameAsLastSave = dayjs(date).format() === dayjs(lastSavedTargetDate).format();

      if (isDateSameAsLastSave) return;
      setLastSavedTargetDate(date);

      await updateTargetDate({
        variables: { input: { diffs: [updateTargetDatePayload] } },
        refetchQueries: [GetHistoryUpdatesForObjectIdDocument],
        onCompleted: (data) => {
          const fieldsPendingApprovalMap = buildFieldsPendingApprovalMap(data.updateModularObject?.[0]?.approvals);
          const isSaved = !fieldsPendingApprovalMap[TARGET_DATE_FIELD_NAME];

          const SUCCESS_MESSAGE = isSaved
            ? 'Successfully saved target date'
            : 'Successfully requested approval for target date';

          addToastSuccess(SUCCESS_MESSAGE);
        },
        onError: () => addToastError('Failed to update target date, please try again'),
      });
      if (canDeleteJiraTask) {
        await updateJiraTicket();
      }
    },
    [canDeleteJiraTask, lastSavedTargetDate, objectCardData?.id, updateJiraTicket, updateTargetDate],
  );

  const { ownerId: parentOwnerId, owner: parentOwner } = objectCardData?.parent ?? {};

  const parentStartDate = useMemo(() => {
    const { startDate } = objectCardData?.parent ?? {};

    return dayjs(startDate).toDate();
  }, [objectCardData?.parent]);

  const parentTargetDate = useMemo(() => {
    const { targetDate } = objectCardData?.parent ?? {};

    return dayjs(targetDate).toDate();
  }, [objectCardData?.parent]);

  let parentRangeColor = ParentRangeColor.Default;

  const isInternal = loggedInUser?.organizationId === parentOwner?.organizationId;

  if (parentOwnerId && !isInternal) {
    parentRangeColor = ParentRangeColor.External;
  } else if (parentOwnerId && isInternal) {
    parentRangeColor = ParentRangeColor.Internal;
  }

  const { data: modularObjectResourceCostData } = useGetTotalResourceCostQuery({
    skip: !activeModularObjectId || isTask,
    variables: {
      id: activeModularObjectId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const totalResourceCost = modularObjectResourceCostData?.getModularObjectByID?.totalResourceCost || 0;

  return (
    <div className='grid grid-cols-2 gap-y-1 gap-x-8 px-[24px]'>
      <DetailItem label='Start Date' name='startDate'>
        <DatePickerInput
          id={START_DATE_FIELD_NAME}
          endDate={targetDate}
          parentStartDate={parentStartDate}
          parentEndDate={parentTargetDate}
          parentRangeColor={parentRangeColor}
          name={START_DATE_FIELD_NAME}
          value={startDate}
          onChange={handleStartDateChange}
          placeholder='Enter start date'
          className='max-w-[15rem]'
        />
      </DetailItem>
      <DetailItem label='Company'>
        <DisplayValueController value='ownerOrganization' className='text-[14px]' />
      </DetailItem>
      <DetailItem label='Target Date' name='targetDate'>
        <DatePickerInput
          id={TARGET_DATE_FIELD_NAME}
          startDate={startDate}
          parentStartDate={parentStartDate}
          parentEndDate={parentTargetDate}
          parentRangeColor={parentRangeColor}
          value={targetDate}
          onChange={handleTargetDateChange}
          name={TARGET_DATE_FIELD_NAME}
          placeholder='Enter target date'
          className='max-w-[15rem]'
        />
      </DetailItem>
      <DetailItem label='Parent' contentClassName='truncate'>
        <ParentSelectButtonWrapper />
      </DetailItem>
      {!isTask && Boolean(totalResourceCost) && (
        <DetailItem label={`task ${resourceCostUnit}`}>
          <TotalResourceCost cost={totalResourceCost} />
        </DetailItem>
      )}
      {isTask && (
        <>
          <DetailItem label='Estimation' name='resourceCost'>
            <Estimation />
          </DetailItem>
          <DetailItem label='Assignee'>
            <Assignee name='assigneeId' className='font-medium text-[14px] font-sculpin' />
          </DetailItem>
        </>
      )}
    </div>
  );
}
