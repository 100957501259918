import HideSVG from '@/components/svg/HideSVG';
import ShowSVG from '@/components/svg/ShowSVG';
import cx from 'classnames';
import { forwardRef, useState } from 'react';

export interface TextInputProps extends React.ComponentPropsWithRef<'input'> {
  large?: boolean;
  prefix?: string;
  suffix?: string;
  showPasswordButton?: boolean;
  wrapperClassName?: string;
  unstyledInput?: boolean;
  // type?: 'text' | 'password' | 'checkbox' | 'radio' | 'file' | 'submit' | 'reset' | 'button' | 'image' | 'hidden' | 'range' | 'color' | 'date' | 'datetime-local' | 'email' | 'month' | 'number' | 'password' | 'search' | 'tel' | 'time' | 'url' | 'week';
}

const prefixClassList =
  'before:content-[attr(data-content-prefix)] before:mr-1 before:text-xs before:font-effra before:opacity-60 before:tracking-[2.4px] before:uppercase before:align-bottom';

const suffixClassList =
  'after:content-[attr(data-content-suffix)] after:ml-1 after:text-xs after:font-effra after:opacity-60 after:tracking-[2.4px] after:uppercase';

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({
  className,
  disabled,
  large = false,
  name,
  prefix,
  suffix,
  value,
  showPasswordButton = false,
  type = 'text',
  wrapperClassName,
  checked,
  unstyledInput,
  ...otherProps
}, ref): JSX.Element => {
  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  const handleClickShowPassword = (): void => {
    setShouldShowPassword(!shouldShowPassword);
  };

  return (
    <div
      data-testid='text-input-wrapper'
      className={cx(
        'flex',
        'items-center',
        wrapperClassName,
        {
          [prefixClassList]: prefix,
          [suffixClassList]: suffix,
        },
      )}
      data-content-prefix={prefix}
      data-content-suffix={suffix}
    >
      <input
        name={name}
        className={cx({
          'grow': !unstyledInput,
          'input-text': !unstyledInput && !large,
          'input-large': !unstyledInput && large,
        }, className)}
        type={shouldShowPassword ? 'text' : type}
        autoComplete='off'
        id={name}
        value={value}
        disabled={Boolean(disabled)}
        ref={ref}
        checked={checked}
        {...otherProps}
      />
      {type === 'password' && showPasswordButton && (
        <div
          className='flex absolute right-0 z-0 col-span-full row-span-full justify-self-end items-center pr-2.5 cursor-pointer hover:opacity-60'
          data-testid='show-button'
          onClick={handleClickShowPassword}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleClickShowPassword();
            }
          }}
          tabIndex={0}
        >
          {shouldShowPassword ? <HideSVG /> : <ShowSVG />}
        </div>
      )}
    </div>
  );
});

export default TextInput;
TextInput.displayName = 'TextInput';
