import type { CollaboratorDiscoveryState } from './CollabDiscovery.reducer';

export enum CollabDiscoveryActionTypes {
  SET_USERS = 'SET_USERS',
  ADD_USER = 'ADD_USER',
  REMOVE_USER = 'REMOVE_USER',
}

interface CollabDiscoveryAction {
  type: CollabDiscoveryActionTypes;
  payload: {
    id?: string;
    user?: string | Record<string, unknown>;
    users?: Partial<CollaboratorDiscoveryState>;
  };
}

export function addUser (user: string | Record<string, unknown>): CollabDiscoveryAction {
  return {
    type: CollabDiscoveryActionTypes.ADD_USER,
    payload: { user },
  };
}

export function removeUser (id: string): CollabDiscoveryAction {
  return {
    type: CollabDiscoveryActionTypes.REMOVE_USER,
    payload: { id },
  };
}

export function setUsers (users: Partial<CollaboratorDiscoveryState>): CollabDiscoveryAction {
  return {
    type: CollabDiscoveryActionTypes.SET_USERS,
    payload: { users },
  };
}
