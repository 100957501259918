import FormContainer from '@/components/FormContainer/FormContainer';

interface CancelCsvUploadProps {
  onClose: () => void;
  onContinue: () => void;
}

export default function CancelCsvUpload ({ onContinue, onClose }: CancelCsvUploadProps): JSX.Element {
  return (
    <FormContainer
      headerText={<div className='text-secondary'>Are you sure?</div>}
      onClose={() => {}}
      content={
        <div className='mt-3'>Closing this window will discard your template and your progress will not be saved.</div>
      }
      buttons={
        <>
          <button onClick={onClose} className='btn-ghost'>discard &amp; exit</button>
          <button onClick={onContinue} className='ml-2 btn-primary'>continue upload process</button>
        </>
      }
    />
  );
}
