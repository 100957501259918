import { type ModularObject } from '@/__generated__/types';
import cx from 'classnames';
import { type ColumnConfig } from './utils';

interface TableRowProps {
  rowConfig: (object?: ModularObject) => ColumnConfig[];
  object?: ModularObject;
  objectType: string;
  handleOpenObject: (object) => void;
  className?: string;
}

export default function TableRow ({
  rowConfig,
  object,
  objectType,
  handleOpenObject,
  className = 'gap-10 px-6 lg:px-20 hover:bg-gray-50 min-h-[7rem]',
}: TableRowProps): JSX.Element {
  return (
    <div
      id={`${objectType}-${object.id}`}
      className={cx('flex items-center transition-colors cursor-pointer', className)}
      onClick={() => {
        handleOpenObject(object);
      }}
    >
      {rowConfig(object).map((col, idx) => (
        <div
          key={`${object.id}-${col.title}-${idx}`}
          className={cx(
            col.class,
            col.displayClass,
            'whitespace-nowrap text-ellipsis overflow-hidden',
          )}
          style={{ width: col.width }}
        >
          {col.content}
        </div>
      ))}
    </div>
  );
}
