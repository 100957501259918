import { Listbox } from '@headlessui/react';
import cx from 'classnames';
import { useMemo } from 'react';
import { type MappableTaskField, REQUIRED_FIELDS, TASK_FIELD_OPTIONS } from './constants';

interface TaskFieldDropdownProps {
  onChange: (name: string, value: string) => void;
  name: string;
  options: MappableTaskField[];
  value: string;
}

// TODO: [APP-987] make a reusable svg component for creating app icons
function UpDownPrimary (): JSX.Element {
  return (
    <svg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7 9.3125L4 12.3125L1 9.3125'
        stroke='#7B25C6'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 4.3125L4 1.3125L7 4.3125'
        stroke='#7B25C6'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

function UpDownGray (): JSX.Element {
  return (
    <svg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7 9.3125L4 12.3125L1 9.3125'
        stroke='#9E9E9E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 4.3125L4 1.3125L7 4.3125'
        stroke='#9E9E9E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default function TaskFieldDropdown (
  { onChange, name, options, value }: TaskFieldDropdownProps,
): JSX.Element {
  const displayValue = useMemo(() => TASK_FIELD_OPTIONS[value], [value]);

  const handleSelect = (value: string): void => {
    onChange(name, value);
  };

  return (
    <Listbox name={name} value={value} onChange={handleSelect}>
      <div className='relative'>
        <Listbox.Button
          className={cx(
            'relative w-full bg-white border pl-3 pr-10 py-3 text-left cursor-default focus:outline-none focus:border-primary',
            {
              'border-primary text-primary': !displayValue,
              'border-neutral-300': displayValue,
            },
          )}
        >
          <span className='flex items-center truncate effra-xs'>{displayValue ?? 'Select a Field'}</span>
          <span className='flex absolute inset-y-0 right-3 items-center pr-2 pointer-events-none'>
            {displayValue ? <UpDownGray /> : <UpDownPrimary />}
          </span>
        </Listbox.Button>
        <Listbox.Options className='overflow-auto absolute z-10 py-1 mb-10 w-full text-base bg-white shadow-lg focus:outline-none max-h-[12.5rem]'>
          {options?.map((option) => (
            <Listbox.Option
              key={option}
              className={({ active }) =>
                cx(
                  'relative cursor-default select-none py-3 pl-3 pr-4 effra-xs h-10',
                  {
                    'bg-neutral-200': active,
                    'text-warning': REQUIRED_FIELDS.includes(option),
                  },
                )}
              value={option}
            >
              <span className='block truncate'>
                {TASK_FIELD_OPTIONS[option]}
                {REQUIRED_FIELDS.includes(option) && '*'}
              </span>
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
}
