import type { Diff, FromTo } from '@/__generated__/types';
import type { ApprovalsOnObjectCardFragmentFragment } from '@/components/common/ModularObject/Card/ApprovalsOnObjectCardFragment.generated';
import { getTheBandBackTogether } from '@/components/common/ModularObject/Card/ObjectCard.util';
import { handleSubmitFiles } from './functions';
import { patchModularObject } from './requests.functions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const approveModularObjectUpdate = async (diff: Diff, approve: boolean, state): Promise<any> => {
  const resolvedDiff: Diff = {
    externalID: diff.externalID,
    externalType: diff.externalType,
    diff: {
      displayNames: diff.diff.displayNames,
      to: {
        ...diff.diff.to,
        requestedById: diff.createdBy,
        requestedOn: diff.createdAt,
        requestedDiffId: diff.id,
        addedFiles: diff.diff.to?.addedFiles,
        removedFiles: diff.diff.to?.removedFiles,
      },
    } as FromTo,
    diffType: approve ? 'approved' : 'denied',
  } as Diff;

  let requestResult = null;

  // Expand this to handle all external types
  const requestMap = {
    modular_object: async (): Promise<void> => {
      const addedFilesObject = resolvedDiff?.diff?.to?.addedFiles;
      const removedFilesObject = resolvedDiff?.diff?.to?.removedFiles;

      if (approve && (addedFilesObject || removedFilesObject)) {
        await handleSubmitFiles(
          resolvedDiff.externalID,
          resolvedDiff.externalType,
          addedFilesObject,
          removedFilesObject,
        );
      } else if (!approve) {
        await handleSubmitFiles(resolvedDiff.externalID, resolvedDiff.externalType, [], addedFilesObject);
      }
      const modularObjects = await patchModularObject([resolvedDiff]);
      const template = state.templates[modularObjects[0]?.templateId];
      const hydratedResponse = {
        ...modularObjects[0],
        data: getTheBandBackTogether(template?.modules, modularObjects[0]?.data),
      };

      requestResult = hydratedResponse;
    },
  };

  await requestMap[diff.externalType]();

  return requestResult;
};

export const buildFieldsPendingApprovalMap = (
  pendingApprovals: ApprovalsOnObjectCardFragmentFragment[],
) => {
  const pendingDiffApprovals = pendingApprovals?.filter(({ approvalType }) => approvalType === 'diff') ?? [];

  const fieldsPendingApprovalMap: Record<string, boolean> = {};

  pendingDiffApprovals.forEach(({ requestedChanges }) => {
    requestedChanges.forEach(({ fieldId }) => {
      fieldsPendingApprovalMap[fieldId] = true;
    });
  });

  return fieldsPendingApprovalMap;
};
