import { type Handler } from '@/state/createReducer';

export function namespaceActionWith (namespace: string): (name: string) => string {
  return name => `${namespace}/${name}`;
}

export default function namespaceHandlers (
  namespace: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handlers: any,
): Record<string, Handler> {
  const namespaceAction = namespaceActionWith(namespace);

  return Object.keys(handlers || {}).reduce((acc, handlerName) => {
    acc[namespaceAction(handlerName)] = handlers[handlerName];
    return acc;
  }, {});
}
