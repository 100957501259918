import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type AddChildMutationVariables = Types.Exact<{
  input: Types.InputMaybe<Array<Types.CreateIntegrationInput> | Types.CreateIntegrationInput>;
}>;


export type AddChildMutation = { __typename?: 'Mutation', createHardwareIntegration: Array<{ __typename?: 'HardwareIntegration', id: string } | { __typename?: 'HardwareIntegrationApproval', id: string } | null> | null };


export const AddChildDocument = gql`
    mutation AddChild($input: [CreateIntegrationInput!]) {
  createHardwareIntegration(input: $input) {
    ... on HardwareIntegration {
      id
    }
    ... on HardwareIntegrationApproval {
      id
    }
  }
}
    `;
export type AddChildMutationFn = Apollo.MutationFunction<AddChildMutation, AddChildMutationVariables>;

/**
 * __useAddChildMutation__
 *
 * To run a mutation, you first call `useAddChildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChildMutation, { data, loading, error }] = useAddChildMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddChildMutation(baseOptions?: Apollo.MutationHookOptions<AddChildMutation, AddChildMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddChildMutation, AddChildMutationVariables>(AddChildDocument, options);
      }
export type AddChildMutationHookResult = ReturnType<typeof useAddChildMutation>;
export type AddChildMutationResult = Apollo.MutationResult<AddChildMutation>;
export type AddChildMutationOptions = Apollo.BaseMutationOptions<AddChildMutation, AddChildMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddChildMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ createHardwareIntegration })
 *   )
 * })
 */
export const mockAddChildMutation = (resolver: Parameters<typeof graphql.mutation<AddChildMutation, AddChildMutationVariables>>[1]) =>
  graphql.mutation<AddChildMutation, AddChildMutationVariables>(
    'AddChild',
    resolver
  )
