import { createSelector } from '@reduxjs/toolkit';

export const selectIntegratedVehicles = (integrations = []) =>
  createSelector(
    [({ modularObjects, templates }) => ({ modularObjects, templates })],
    ({ modularObjects, templates }) => {
      const integrationArrays = Array.isArray(integrations)
        ? integrations
        : [];

      return integrationArrays.reduce((acc, { objectId }) => [
        ...acc,
        modularObjects[objectId],
      ], [])
        .map(obj => {
          const template = templates[obj?.templateId] || {};
          return { ...obj, templateName: template?.name };
        })
        .filter(obj => {
          return obj?.templateName === 'Launch Vehicles';
        });
    },
  );
