import { type Template } from '@/__generated__/types';
import { TemplateNameToIdMap } from '@/components/common/Tasks/TaskSection/TaskListActions/constants';
import getIcon from '@/util/getIcon';
import CreateObjectListRow from '../CreateNewObjectListRow/CreateNewObjectListRow';

export interface CreateNewObjectListProps {
  results: Template[];
  onCreateClick: (id: string, name: string) => Promise<void>;
}

function getOrderedResults (results: Template[]): Template[] {
  const FIRST_TEMPLATES = [
    TemplateNameToIdMap.PROGRAM,
    TemplateNameToIdMap.GENERIC_HARDWARE,
  ];

  const [first, rest] = results.reduce(([first, rest], template) => {
    FIRST_TEMPLATES.includes(template?.id as TemplateNameToIdMap)
      ? first.unshift(template)
      : rest.push(template);

    return [first, rest];
  }, [[], []]);

  return first.concat(rest.sort((a, b) => a.name.localeCompare(b.name)));
}

export default function CreateNewObjectList (
  { results, onCreateClick }: CreateNewObjectListProps,
): JSX.Element {
  return (
    <>
      {getOrderedResults(results).map((
        { id, name }: { id: string; name: string },
      ) => (
        <CreateObjectListRow
          key={`create-new-object-list-item-${id}`}
          label={name}
          icon={getIcon(name.toLowerCase())}
          onCreateClick={async () => {
            await onCreateClick(id, name);
          }}
        />
      ))}
    </>
  );
}
