import UploadCsvUserFlow from '@/components/flows/UploadCsvUserFlow/UploadCsvUserFlow';
import useMap from './hooks/useMap';
import { useTaskListContext } from './TaskList.context';
import TaskPlaceholder from './TaskPlaceholder';
import TaskSectionContent from './TaskSectionContent';

interface TaskSectionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hardware: any;
  hardwareType: string;
}

export default function TaskSection ({
  hardware,
  hardwareType,
}: Readonly<TaskSectionProps>): JSX.Element {
  const { tasks } = useTaskListContext();

  const shouldShowPlaceholder = !tasks?.length;
  const map = useMap([
    ['openCsvUpload', false],
  ]);

  const openCSVUploader = (open = true) => map.set('openCsvUpload', open);

  return (
    <div className='flex relative flex-col'>
      {!shouldShowPlaceholder ?
        <TaskSectionContent openCSVUploader={openCSVUploader} />
        : (
          <TaskPlaceholder
            openCSVUploader={openCSVUploader}
          />
        )}
      <UploadCsvUserFlow
        open={Boolean(map.get('openCsvUpload'))}
        setOpen={openCSVUploader}
        hardware={hardware}
        hardwareType={hardwareType}
      />
    </div>
  );
}
