import { usePrismaticContext } from '@/components/Contexts/prismatic.context';
import Image from 'next/image';
import { useCallback } from 'react';

interface ApprovalActionsProps {
  handleSubmitApproval: (approve: boolean) => Promise<void>;
  isSendingApproval: boolean;
}

export default function ApproveButton (
  { handleSubmitApproval, isSendingApproval }: ApprovalActionsProps,
): JSX.Element {
  const { canDeleteJiraTask, updateJiraTicket } = usePrismaticContext();

  const _handleSubmitApproval = useCallback(async () => {
    await handleSubmitApproval(true);

    if (canDeleteJiraTask) {
      await updateJiraTicket();
    }
  }, [canDeleteJiraTask, handleSubmitApproval, updateJiraTicket]);

  return (
    <div className='flex justify-end items-center'>
      <button
        disabled={Boolean(isSendingApproval)}
        onClick={_handleSubmitApproval}
        className='flex gap-1 items-center py-3 px-6 border transition-all hover:scale-105 disabled:opacity-40 group border-primary text-primary disabled:hover:scale-100'
      >
        {!isSendingApproval ?
          (
            <div className='flex items-center shrink-0'>
              <Image src='/images/check-primary.svg' alt='Check Mark' width={14} height={11} />
            </div>
          ) :
          (
            <svg
              className='w-4 h-4 animate-spin text-primary group-hover:text-primary-dark'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle
                className='opacity-25'
                cx='12'
                cy='12'
                r='10'
                stroke='currentColor'
                strokeWidth='4'
              />
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              />
            </svg>
          )}
        <div className='font-bold'>Approve</div>
      </button>
    </div>
  );
}
