import { GetObjectCardObjectDocument } from '@/components/common/ModularObject/Card/GetObjectCardObject.generated';
import { useAddChildMutation } from '@/components/common/Nesting/AddChild.generated';
import { GetChildrenForNestingColumnDocument } from '@/components/common/Nesting/GetChildrenForNestingColumn.generated';
import { useRemoveChildMutation } from '@/components/common/Nesting/RemoveChildMutation.generated';
import { GetTopLevelModularObjectsDocument } from '@/components/Schedule/queries/getTopLevelModularObjects.generated';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { useMemo } from 'react';

export const useNestingMutations = () => {
  const [addChild] = useAddChildMutation({
    refetchQueries: [
      GetChildrenForNestingColumnDocument,
      GetTopLevelModularObjectsDocument,
      GetObjectCardObjectDocument,
    ],
    onCompleted: (data) => {
      const isApproval = data?.createHardwareIntegration?.every((integration) =>
        integration.__typename === 'HardwareIntegrationApproval'
      );
      const isIntegration = data?.createHardwareIntegration?.every((integration) =>
        integration.__typename === 'HardwareIntegration'
      );

      if (isApproval) {
        return addToastSuccess('Request to nest object(s) sent for approval.');
      }
      if (isIntegration) {
        return addToastSuccess('Successfully nested object(s)');
      }
      return addToastSuccess('Successfully nested object(s) and requested approval to nest object(s)');
    },
    onError: () => addToastError('Failed to nest object(s), please try again'),
  });

  const [removeChild] = useRemoveChildMutation({
    refetchQueries: [
      GetChildrenForNestingColumnDocument,
      GetTopLevelModularObjectsDocument,
      GetObjectCardObjectDocument,
    ],
  });

  return useMemo(() => ({ addChild, removeChild }), [addChild, removeChild]);
};
