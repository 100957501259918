import type * as Types from '../../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetTotalResourceCostQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetTotalResourceCostQuery = { __typename?: 'Query', getModularObjectByID: { __typename?: 'ModularObject', id: string, totalResourceCost: number | null } };


export const GetTotalResourceCostDocument = gql`
    query GetTotalResourceCost($id: UUID!) {
  getModularObjectByID(id: $id) {
    id
    totalResourceCost
  }
}
    `;

/**
 * __useGetTotalResourceCostQuery__
 *
 * To run a query within a React component, call `useGetTotalResourceCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalResourceCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalResourceCostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTotalResourceCostQuery(baseOptions: Apollo.QueryHookOptions<GetTotalResourceCostQuery, GetTotalResourceCostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTotalResourceCostQuery, GetTotalResourceCostQueryVariables>(GetTotalResourceCostDocument, options);
      }
export function useGetTotalResourceCostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTotalResourceCostQuery, GetTotalResourceCostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTotalResourceCostQuery, GetTotalResourceCostQueryVariables>(GetTotalResourceCostDocument, options);
        }
export function useGetTotalResourceCostSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTotalResourceCostQuery, GetTotalResourceCostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTotalResourceCostQuery, GetTotalResourceCostQueryVariables>(GetTotalResourceCostDocument, options);
        }
export type GetTotalResourceCostQueryHookResult = ReturnType<typeof useGetTotalResourceCostQuery>;
export type GetTotalResourceCostLazyQueryHookResult = ReturnType<typeof useGetTotalResourceCostLazyQuery>;
export type GetTotalResourceCostSuspenseQueryHookResult = ReturnType<typeof useGetTotalResourceCostSuspenseQuery>;
export type GetTotalResourceCostQueryResult = Apollo.QueryResult<GetTotalResourceCostQuery, GetTotalResourceCostQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetTotalResourceCostQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ getModularObjectByID })
 *   )
 * })
 */
export const mockGetTotalResourceCostQuery = (resolver: Parameters<typeof graphql.query<GetTotalResourceCostQuery, GetTotalResourceCostQueryVariables>>[1]) =>
  graphql.query<GetTotalResourceCostQuery, GetTotalResourceCostQueryVariables>(
    'GetTotalResourceCost',
    resolver
  )
