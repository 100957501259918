import type * as Types from '../../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type BulkTaskCreateMutationVariables = Types.Exact<{
  input: Types.BulkTaskCreateInput;
}>;


export type BulkTaskCreateMutation = { __typename?: 'Mutation', bulkTaskCreate: Array<{ __typename?: 'ModularObject', id: string, name: string | null, approvalsEnabled: boolean | null, templateId: string | null, assigneeId: string | null, imageId: string | null, startDate: any | null, targetDate: any | null, resourceCost: string | null, data: any | null, customFields: any | null, ownerId: string | null, createdAt: any | null, createdById: string | null, modifiedAt: any | null, deletedAt: any | null, visibility: string | null, deactivated: boolean | null, integrations: Array<{ __typename?: 'HardwareIntegration', id: string, objectId: string, objectType: string, parentId: string | null, parentType: string | null, createdAt: any | null, createdById: string | null, isPending: boolean | null, modifiedAt: any | null }> | null, shares: Array<{ __typename?: 'Share', id: string | null, externalId: string | null, externalType: string | null, userId: string | null, createdAt: any | null, deletedAt: any | null, organizationId: string | null, modifiedAt: any | null, role: string | null }> | null }> };


export const BulkTaskCreateDocument = gql`
    mutation BulkTaskCreate($input: BulkTaskCreateInput!) {
  bulkTaskCreate(input: $input) {
    id
    name
    approvalsEnabled
    templateId
    assigneeId
    imageId
    startDate
    targetDate
    resourceCost
    data
    customFields
    ownerId
    createdAt
    createdById
    modifiedAt
    deletedAt
    visibility
    deactivated
    integrations {
      id
      objectId
      objectType
      parentId
      parentType
      createdAt
      createdById
      isPending
      modifiedAt
    }
    shares {
      id
      externalId
      externalType
      userId
      createdAt
      deletedAt
      organizationId
      modifiedAt
      role
    }
  }
}
    `;
export type BulkTaskCreateMutationFn = Apollo.MutationFunction<BulkTaskCreateMutation, BulkTaskCreateMutationVariables>;

/**
 * __useBulkTaskCreateMutation__
 *
 * To run a mutation, you first call `useBulkTaskCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkTaskCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkTaskCreateMutation, { data, loading, error }] = useBulkTaskCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkTaskCreateMutation(baseOptions?: Apollo.MutationHookOptions<BulkTaskCreateMutation, BulkTaskCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkTaskCreateMutation, BulkTaskCreateMutationVariables>(BulkTaskCreateDocument, options);
      }
export type BulkTaskCreateMutationHookResult = ReturnType<typeof useBulkTaskCreateMutation>;
export type BulkTaskCreateMutationResult = Apollo.MutationResult<BulkTaskCreateMutation>;
export type BulkTaskCreateMutationOptions = Apollo.BaseMutationOptions<BulkTaskCreateMutation, BulkTaskCreateMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBulkTaskCreateMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ bulkTaskCreate })
 *   )
 * })
 */
export const mockBulkTaskCreateMutation = (resolver: Parameters<typeof graphql.mutation<BulkTaskCreateMutation, BulkTaskCreateMutationVariables>>[1]) =>
  graphql.mutation<BulkTaskCreateMutation, BulkTaskCreateMutationVariables>(
    'BulkTaskCreate',
    resolver
  )
