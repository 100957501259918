import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type RemoveTeamMemberMutationVariables = Types.Exact<{
  id: Types.InputMaybe<Types.Scalars['UUID']['input']>;
  teamId: Types.InputMaybe<Types.Scalars['UUID']['input']>;
  userId: Types.InputMaybe<Types.Scalars['UUID']['input']>;
}>;


export type RemoveTeamMemberMutation = { __typename?: 'Mutation', removeTeamMember: string | null };


export const RemoveTeamMemberDocument = gql`
    mutation RemoveTeamMember($id: UUID, $teamId: UUID, $userId: UUID) {
  removeTeamMember(id: $id, teamId: $teamId, userId: $userId)
}
    `;
export type RemoveTeamMemberMutationFn = Apollo.MutationFunction<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>;

/**
 * __useRemoveTeamMemberMutation__
 *
 * To run a mutation, you first call `useRemoveTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeamMemberMutation, { data, loading, error }] = useRemoveTeamMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      teamId: // value for 'teamId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>(RemoveTeamMemberDocument, options);
      }
export type RemoveTeamMemberMutationHookResult = ReturnType<typeof useRemoveTeamMemberMutation>;
export type RemoveTeamMemberMutationResult = Apollo.MutationResult<RemoveTeamMemberMutation>;
export type RemoveTeamMemberMutationOptions = Apollo.BaseMutationOptions<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRemoveTeamMemberMutation((req, res, ctx) => {
 *   const { id, teamId, userId } = req.variables;
 *   return res(
 *     ctx.data({ removeTeamMember })
 *   )
 * })
 */
export const mockRemoveTeamMemberMutation = (resolver: Parameters<typeof graphql.mutation<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>>[1]) =>
  graphql.mutation<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>(
    'RemoveTeamMember',
    resolver
  )
