import FormContainer from '@/components/FormContainer/FormContainer';
import Spinner from '@/components/Icons/Spinner';
import { useAppDispatch } from '@/state/hooks';
import { removeModularObjects } from '@/state/slices/modularObjects.slice';
import { deleteModularObjects } from '@/util/requests.functions';
import { useApolloClient } from '@apollo/client';
import { useState } from 'react';
import { type ActionMessageProps } from '../constants';
import DeleteMessage from './DeleteMessage';

export default function DeleteConfirmation ({ permissionMap, onClose }: Readonly<ActionMessageProps>): JSX.Element {
  const dispatch = useAppDispatch();
  const { reFetchObservableQueries } = useApolloClient();
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState('');
  const { isOwner } = permissionMap;
  const isNotOwnerOfSelectedTasks = !isOwner.length && !isDeleting;

  const handleSubmit = async (e): Promise<void> => {
    setIsDeleting(true);
    try {
      e.preventDefault();
      // Delete tasks and attached subtasks
      await deleteModularObjects(isOwner, true);
      dispatch(removeModularObjects(isOwner));
      reFetchObservableQueries().catch(console.error);
      onClose();
    } catch (err) {
      console.error(err);
      setError(`Error: ${err.message ?? err.error?.integrations}`);
    }
  };

  return (
    <FormContainer
      handleSubmit={handleSubmit}
      onClose={onClose}
      headerText={
        <span className='text-primary'>
          {isNotOwnerOfSelectedTasks ? 'Oops! You can\'t do that.' : 'Are you sure?'}
        </span>
      }
      width={isNotOwnerOfSelectedTasks ? 'unset' : '500px'}
      content={<DeleteMessage permissionMap={permissionMap} />}
      buttons={
        <div className='flex flex-col gap-1 grow'>
          <div className='flex gap-3'>
            <button onClick={onClose} className='w-1/2 btn btn-primary-hollow'>
              Cancel
            </button>
            {!isNotOwnerOfSelectedTasks && (
              <button className='w-1/2 btn-primary' disabled={isDeleting}>
                {isDeleting
                  ? (
                    <div className='flex flex-col items-center grow'>
                      <div className='flex gap-2 items-center'>
                        <Spinner className='w-6 h-6 animate-spin' /> Deleting
                      </div>
                    </div>
                  )
                  : 'Yes'}
              </button>
            )}
          </div>
          {Boolean(error) && <div className='text-red-500'>{error}</div>}
        </div>
      }
    />
  );
}
