import type { ComponentProps } from 'react';

export default function LogoMark ({ ...otherProps }: ComponentProps<'svg'>): JSX.Element {
  return (
    <svg
      width='227'
      height='230'
      viewBox='0 0 227 230'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...otherProps}
    >
      <path
        d='M199.74 129.5H162.35V164.35C162.35 173.38 155 180.72 145.97 180.72H48.33V66.7H96.96V18.38H0V229.05H145.97C181.65 229.05 210.68 200.03 210.68 164.35V129.5H199.74Z'
        fill='currentColor'
      />
      <path d='M145.29 67.52V81.17H158.94H199.74H226.46V0H145.29V26.72V67.52Z' fill='currentColor' />
    </svg>
  );
}
