import { type ComponentProps } from 'react';

export default function CubesatIcon ({ ...otherProps }: ComponentProps<'svg'>): JSX.Element {
  return (
    <svg id='Layer_2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24.25 23.14' {...otherProps}>
      <g id='Layer_1-2'>
        <path
          d='m23.62,12.22l.63-1.72L17.24,0H4.6L0,12.64l7.03,10.5h1.72l.49-1.35h7.85l.9,1.35h1.72l.63-1.72-.94-1.41,2.83-7.79h1.39Zm-2.31-2.22l-.32.87.25.37-3.38,9.28h-9.08s-.31-.46-.31-.46h-.83l2.99-8.22.26.38h1.72l.63-1.72-.34-.5h8.41Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
