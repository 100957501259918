import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { ObjectParentPropsFragmentDoc } from '../../../ObjectParentProps.generated';
import { UserPermissionOnObjectPropsFragmentDoc } from '../../../Schedule/List/ScheduleListObject/scheduleItems/UserPermissionOnObjectProps.generated';
export type ObjectListRowPropsFragment = { __typename?: 'ModularObject', id: string, name: string | null, deactivated: boolean | null, ownerId: string | null, template: { __typename?: 'Template', id: string | null, name: string | null } | null, parent: { __typename?: 'ModularObject', id: string, name: string | null, owner: { __typename?: 'User', id: string, organizationId: string | null } | null, template: { __typename?: 'Template', id: string | null, name: string | null } | null } | null, owner: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null, profileImage: string | null, organizationId: string | null, organization: { __typename?: 'Organization', id: string, name: string | null } | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, createdAt: any | null, externalId: string | null, externalType: string | null, modifiedAt: any | null, role: string | null, userId: string | null }> | null };

export const ObjectListRowPropsFragmentDoc = gql`
    fragment ObjectListRowProps on ModularObject {
  id
  name
  deactivated
  template {
    id
    name
  }
  parent {
    ...ObjectParentProps
  }
  ownerId
  owner(includeDeleted: true) {
    id
    firstName
    lastName
    profileImage
    organizationId
    organization {
      id
      name
    }
  }
  shares {
    id
    createdAt
    externalId
    externalType
    modifiedAt
    role
    userId
  }
  ...UserPermissionOnObjectProps
}
    ${ObjectParentPropsFragmentDoc}
${UserPermissionOnObjectPropsFragmentDoc}`;