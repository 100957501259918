import { useEditContext } from '@/components';
import { Button } from '@/designSystemComponents/Button';

interface FileUploadButtonProps {
  onClick: () => void;
  isLoading: boolean;
}
export default function FileUploadButton ({ isLoading, onClick }: FileUploadButtonProps): JSX.Element {
  const { canUserEdit } = useEditContext();

  if (!canUserEdit) return null;

  return (
    <Button
      isLoading={isLoading}
      className='w-[100px] !h-[32px] !font-medium text-[0.75rem] !px-4 !py-3'
      figmaProps={{ size: 'small', style: 'outline' }}
      onClick={onClick}
    >
      add file
    </Button>
  );
}
