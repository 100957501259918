import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetPrismaticJiraMappingQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetPrismaticJiraMappingQuery = { __typename?: 'Query', getPrismaticJiraMapping: { __typename?: 'JiraMapping', id: string, modularObjectId: string, jiraIssueId: string, jiraIssueKey: string, fieldMapping: any, jiraWebhookId: string, jiraTicketUrl: string, createdAt: any | null, modifiedAt: any | null, deletedAt: any | null } | null };


export const GetPrismaticJiraMappingDocument = gql`
    query GetPrismaticJiraMapping($id: UUID!) {
  getPrismaticJiraMapping(id: $id) {
    id
    modularObjectId
    jiraIssueId
    jiraIssueKey
    fieldMapping
    jiraWebhookId
    jiraTicketUrl
    createdAt
    modifiedAt
    deletedAt
  }
}
    `;

/**
 * __useGetPrismaticJiraMappingQuery__
 *
 * To run a query within a React component, call `useGetPrismaticJiraMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrismaticJiraMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrismaticJiraMappingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPrismaticJiraMappingQuery(baseOptions: Apollo.QueryHookOptions<GetPrismaticJiraMappingQuery, GetPrismaticJiraMappingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrismaticJiraMappingQuery, GetPrismaticJiraMappingQueryVariables>(GetPrismaticJiraMappingDocument, options);
      }
export function useGetPrismaticJiraMappingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrismaticJiraMappingQuery, GetPrismaticJiraMappingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrismaticJiraMappingQuery, GetPrismaticJiraMappingQueryVariables>(GetPrismaticJiraMappingDocument, options);
        }
export function useGetPrismaticJiraMappingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPrismaticJiraMappingQuery, GetPrismaticJiraMappingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPrismaticJiraMappingQuery, GetPrismaticJiraMappingQueryVariables>(GetPrismaticJiraMappingDocument, options);
        }
export type GetPrismaticJiraMappingQueryHookResult = ReturnType<typeof useGetPrismaticJiraMappingQuery>;
export type GetPrismaticJiraMappingLazyQueryHookResult = ReturnType<typeof useGetPrismaticJiraMappingLazyQuery>;
export type GetPrismaticJiraMappingSuspenseQueryHookResult = ReturnType<typeof useGetPrismaticJiraMappingSuspenseQuery>;
export type GetPrismaticJiraMappingQueryResult = Apollo.QueryResult<GetPrismaticJiraMappingQuery, GetPrismaticJiraMappingQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPrismaticJiraMappingQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ getPrismaticJiraMapping })
 *   )
 * })
 */
export const mockGetPrismaticJiraMappingQuery = (resolver: Parameters<typeof graphql.query<GetPrismaticJiraMappingQuery, GetPrismaticJiraMappingQueryVariables>>[1]) =>
  graphql.query<GetPrismaticJiraMappingQuery, GetPrismaticJiraMappingQueryVariables>(
    'GetPrismaticJiraMapping',
    resolver
  )
