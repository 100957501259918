import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetPrismaticExcelMappingQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetPrismaticExcelMappingQuery = { __typename?: 'Query', getPrismaticExcelMapping: { __typename?: 'ExcelMapping', id: string, modularObjectId: string, sharepointFileId: string, sheetName: string, cellMapping: any } | null };


export const GetPrismaticExcelMappingDocument = gql`
    query GetPrismaticExcelMapping($id: UUID!) {
  getPrismaticExcelMapping(id: $id) {
    id
    modularObjectId
    sharepointFileId
    sheetName
    cellMapping
  }
}
    `;

/**
 * __useGetPrismaticExcelMappingQuery__
 *
 * To run a query within a React component, call `useGetPrismaticExcelMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrismaticExcelMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrismaticExcelMappingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPrismaticExcelMappingQuery(baseOptions: Apollo.QueryHookOptions<GetPrismaticExcelMappingQuery, GetPrismaticExcelMappingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrismaticExcelMappingQuery, GetPrismaticExcelMappingQueryVariables>(GetPrismaticExcelMappingDocument, options);
      }
export function useGetPrismaticExcelMappingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrismaticExcelMappingQuery, GetPrismaticExcelMappingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrismaticExcelMappingQuery, GetPrismaticExcelMappingQueryVariables>(GetPrismaticExcelMappingDocument, options);
        }
export function useGetPrismaticExcelMappingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPrismaticExcelMappingQuery, GetPrismaticExcelMappingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPrismaticExcelMappingQuery, GetPrismaticExcelMappingQueryVariables>(GetPrismaticExcelMappingDocument, options);
        }
export type GetPrismaticExcelMappingQueryHookResult = ReturnType<typeof useGetPrismaticExcelMappingQuery>;
export type GetPrismaticExcelMappingLazyQueryHookResult = ReturnType<typeof useGetPrismaticExcelMappingLazyQuery>;
export type GetPrismaticExcelMappingSuspenseQueryHookResult = ReturnType<typeof useGetPrismaticExcelMappingSuspenseQuery>;
export type GetPrismaticExcelMappingQueryResult = Apollo.QueryResult<GetPrismaticExcelMappingQuery, GetPrismaticExcelMappingQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPrismaticExcelMappingQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ getPrismaticExcelMapping })
 *   )
 * })
 */
export const mockGetPrismaticExcelMappingQuery = (resolver: Parameters<typeof graphql.query<GetPrismaticExcelMappingQuery, GetPrismaticExcelMappingQueryVariables>>[1]) =>
  graphql.query<GetPrismaticExcelMappingQuery, GetPrismaticExcelMappingQueryVariables>(
    'GetPrismaticExcelMapping',
    resolver
  )
