import { type SUBSCRIPTION_TYPES, type USER_SELECTABLE_SUBSCRIPTION_TYPES } from '@/util/constants';

import type { ValueOf } from '@/util/typeHelpers';
import { restApi } from './rest.api';

const SUBSCRIPTION_API_BASE_URL = '/organizations/subscription';

export interface FeatureLimits {
  maxUsers: number;
  maxObjects: number;
  isUserInviteDisabled: boolean;
  isObjectCreationDisabled: boolean;
  isGanttViewDisabled: boolean;
  isObjectUpdateColumnDisabled: boolean;
}

export interface Subscription {
  subscriptionId: string;
  subscriptionType: ValueOf<typeof SUBSCRIPTION_TYPES>;
  isTrial: boolean;
  isBasic: boolean;
  isStarter: boolean;
  isPro: boolean;
  trialEndDate: string;
  featureLimits?: FeatureLimits;
  trialDidExpire: boolean;
}

export interface PaymentMethodInput {
  cardNumber: string | FormDataEntryValue;
  expMonth: number;
  expYear: number;
  cvc: string | FormDataEntryValue;
}

export interface PutSubscriptionInput {
  newSubscription: USER_SELECTABLE_SUBSCRIPTION_TYPES;
}

export const subscriptionApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubscription: builder.query<Subscription, boolean>({
      query: (shouldRefresh) => ({
        url: `${SUBSCRIPTION_API_BASE_URL}?${shouldRefresh && 'refresh'}`,
        method: 'GET',
      }),
      transformResponse: (rawResult: { data: { subscription: Subscription } }) => {
        return rawResult.data.subscription;
      },
    }),
    putSubscription: builder.query<Subscription, PutSubscriptionInput>({
      query: (newSubscription) => ({
        url: `${SUBSCRIPTION_API_BASE_URL}`,
        method: 'PUT',
        body: newSubscription,
      }),
      transformResponse: (rawResult: { data: { subscription: Subscription } }) => {
        return rawResult.data.subscription;
      },
    }),
    postPaymentMethod: builder.query<undefined, PaymentMethodInput>({
      query: (paymentMethod) => ({
        url: '/organizations/paymentmethod',
        method: 'POST',
        body: paymentMethod,
      }),
    }),
  }),
});

export const {
  useGetSubscriptionQuery,
  useLazyPostPaymentMethodQuery,
  usePutSubscriptionQuery,
  useLazyPutSubscriptionQuery,
  endpoints: subscriptionApiEndpoints,
  util,
} = subscriptionApi;
