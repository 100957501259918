import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetHistoryUpdatesForObjectIdQueryVariables = Types.Exact<{
  modularObjectId: Types.Scalars['UUID']['input'];
}>;


export type GetHistoryUpdatesForObjectIdQuery = { __typename?: 'Query', getModularObjectByID: { __typename?: 'ModularObject', id: string, historicalUpdates: Array<{ __typename?: 'ModularObjectHistoryUpdate', id: string, updateType: string, modularObjectId: string, createdAt: any, createdById: string, canUndo: boolean, createdBy: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null, organizationId: string | null }, data: { __typename?: 'HistoryUpdateData', changes: any | null, dependencyIDs: Array<string> | null, dependencies: Array<{ __typename?: 'Dependency', id: string, blockedBy: { __typename?: 'ModularObject', id: string, name: string | null } | null, modularObject: { __typename?: 'ModularObject', id: string, name: string | null } | null }> | null } }> | null } };


export const GetHistoryUpdatesForObjectIdDocument = gql`
    query GetHistoryUpdatesForObjectId($modularObjectId: UUID!) {
  getModularObjectByID(id: $modularObjectId) {
    id
    historicalUpdates {
      id
      updateType
      modularObjectId
      createdAt
      createdById
      createdBy {
        id
        firstName
        lastName
        organizationId
      }
      data {
        changes
        dependencyIDs
        dependencies {
          id
          blockedBy {
            id
            name
          }
          modularObject {
            id
            name
          }
        }
      }
      canUndo
    }
  }
}
    `;

/**
 * __useGetHistoryUpdatesForObjectIdQuery__
 *
 * To run a query within a React component, call `useGetHistoryUpdatesForObjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHistoryUpdatesForObjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHistoryUpdatesForObjectIdQuery({
 *   variables: {
 *      modularObjectId: // value for 'modularObjectId'
 *   },
 * });
 */
export function useGetHistoryUpdatesForObjectIdQuery(baseOptions: Apollo.QueryHookOptions<GetHistoryUpdatesForObjectIdQuery, GetHistoryUpdatesForObjectIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHistoryUpdatesForObjectIdQuery, GetHistoryUpdatesForObjectIdQueryVariables>(GetHistoryUpdatesForObjectIdDocument, options);
      }
export function useGetHistoryUpdatesForObjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHistoryUpdatesForObjectIdQuery, GetHistoryUpdatesForObjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHistoryUpdatesForObjectIdQuery, GetHistoryUpdatesForObjectIdQueryVariables>(GetHistoryUpdatesForObjectIdDocument, options);
        }
export function useGetHistoryUpdatesForObjectIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetHistoryUpdatesForObjectIdQuery, GetHistoryUpdatesForObjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHistoryUpdatesForObjectIdQuery, GetHistoryUpdatesForObjectIdQueryVariables>(GetHistoryUpdatesForObjectIdDocument, options);
        }
export type GetHistoryUpdatesForObjectIdQueryHookResult = ReturnType<typeof useGetHistoryUpdatesForObjectIdQuery>;
export type GetHistoryUpdatesForObjectIdLazyQueryHookResult = ReturnType<typeof useGetHistoryUpdatesForObjectIdLazyQuery>;
export type GetHistoryUpdatesForObjectIdSuspenseQueryHookResult = ReturnType<typeof useGetHistoryUpdatesForObjectIdSuspenseQuery>;
export type GetHistoryUpdatesForObjectIdQueryResult = Apollo.QueryResult<GetHistoryUpdatesForObjectIdQuery, GetHistoryUpdatesForObjectIdQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetHistoryUpdatesForObjectIdQuery((req, res, ctx) => {
 *   const { modularObjectId } = req.variables;
 *   return res(
 *     ctx.data({ getModularObjectByID })
 *   )
 * })
 */
export const mockGetHistoryUpdatesForObjectIdQuery = (resolver: Parameters<typeof graphql.query<GetHistoryUpdatesForObjectIdQuery, GetHistoryUpdatesForObjectIdQueryVariables>>[1]) =>
  graphql.query<GetHistoryUpdatesForObjectIdQuery, GetHistoryUpdatesForObjectIdQueryVariables>(
    'GetHistoryUpdatesForObjectId',
    resolver
  )
