import getInvalidFileTypeError from './getInvalidFileTypeError';
import getMultiFileError from './getMultiFileError';

import type { ChangeEvent, DragEvent, FileData } from '../types';

export default function getFileFromUserInput (e: DragEvent | ChangeEvent): FileData {
  const files = (e as DragEvent)?.dataTransfer?.files ?? (e as ChangeEvent)?.target?.files;
  const [file = null] = files ?? [];
  const error = getMultiFileError(files) ?? getInvalidFileTypeError(file);

  return { file, error };
}
