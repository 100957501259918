import { Button, useEditContext } from '@/components';
import cx from 'classnames';
import Image from 'next/legacy/image';

interface CardFooterProps {
  isEdit: boolean;
  objectType: string;
  onRemove: () => void;
  onSubmit: () => void;
  onViewerOnlyRemove: () => void;
  isDirty: boolean;
}

export function CardFooter ({
  isEdit,
  objectType,
  onRemove,
  onSubmit,
  onViewerOnlyRemove,
  isDirty,
}: CardFooterProps): JSX.Element {
  const { canUserEdit } = useEditContext();
  return (
    <div
      className={cx(
        { 'flex justify-between': isEdit && canUserEdit },
        'p-7 border-t bg-zinc-50 text-right h-footer shrink-0',
      )}
    >
      {canUserEdit && isEdit && (
        <button
          className='flex items-center py-3.5 px-3 font-bold whitespace-normal text-secondary'
          onClick={onRemove}
        >
          <Image src='/images/x-secondary.svg' alt='X' width={11.2} height={11.2} />
          <span className='ml-2 capitalize'>Delete {objectType}</span>
        </button>
      )}
      {canUserEdit
        ? (
          <Button
            id={`submit-${objectType}`}
            className='py-3.5 px-3 font-bold text-gray-200 whitespace-normal effra-xs bg-primary'
            onClick={onSubmit}
            disabled={!isDirty}
          >
            Save {objectType} and Notify Collaborators
          </Button>
        )
        : (
          <Button
            id={`submit-${objectType}`}
            className='py-3.5 px-3 font-bold text-gray-200 whitespace-normal effra-xs bg-primary'
            onClick={onViewerOnlyRemove}
            disabled={!isDirty}
          >
            Save {objectType} and Notify Collaborators
          </Button>
        )}
    </div>
  );
}
