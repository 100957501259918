import type { User } from '@/__generated__/types';
import { useAppSelector } from '@/state/hooks';
import { selectRootState } from '@/state/selectors';
import { mapExternalTypeToDisplay } from '@/util/functions';
import Image from 'next/legacy/image';
import { ACTION_TO_ICON } from '../constants';
import { useTimelineEventContext } from './TimelineEvent.context';
import TimelineEventHeader from './TimelineEventHeader';

export default function DiffEventHeader (): JSX.Element {
  const state = useAppSelector(selectRootState);
  const { historyObject } = useTimelineEventContext();

  const iconMap = {
    ...ACTION_TO_ICON,
    created: {
      src: `/images/modular_object-white.svg`,
      color: 'bg-primary',
    },
  };
  const iconConfig = iconMap[historyObject.action] || iconMap.updated;

  const externalType = mapExternalTypeToDisplay(historyObject.externalID, state);
  const headerTextMap = {
    created: `${externalType} created`,
    updated: `${externalType} updated`,
    reverted: `${externalType} reverted`,
    dependencyUpdate: 'Dependency triggered update',
    requested: 'Update requested',
    approved: 'Request approved',
    denied: 'Request denied',
  };

  const headerText = headerTextMap[historyObject.action];
  const byUser = historyObject.owner as User;

  return (
    <TimelineEventHeader
      icon={<Image src={iconConfig?.src} height={15} width={15} />}
      color={iconConfig?.color}
      shouldShowTopLine={historyObject.action === 'created'}
      headerText={headerText}
      onDate={historyObject.date}
      byUser={byUser}
    />
  );
}
