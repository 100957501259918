import Stepper, { Step } from '@/components/Stepper/Stepper';
import type { ModularObjectsState } from '@/state/slices/modularObjects.slice';
import metrics from '@/util/metrics';
import { useContext, useEffect, useState } from 'react';
import MegaModal from '../MegaModal/MegaModal';
import { ModalContext } from '../Modal';
import ObjectConfigScreen, { type EditableDependency } from './screens/DependencyConfigScreen';
import DependencySelectionScreen from './screens/DependencySelectionScreen';

interface DependencyModalProps {
  readonly currentObjectId: string;
  readonly templateId?: string;
  readonly templateName?: string;
  readonly dependencyIdToEdit?: EditableDependency;
  readonly isBlockingDependency?: boolean;
  readonly preselectedId?: string;
  readonly initialStep?: number;
  readonly modularObjects?: ModularObjectsState;
}

export default function DependencyModal ({
  currentObjectId,
  templateId,
  templateName,
  preselectedId,
  dependencyIdToEdit = null,
  isBlockingDependency = false,
  initialStep = 1,
}: DependencyModalProps): JSX.Element {
  const { showModal, closeModal } = useContext(ModalContext);
  const [selectedIds, setSelectedIds] = useState<string[]>([preselectedId, dependencyIdToEdit?.id].filter(Boolean));

  useEffect(() => {
    metrics.track('dependency modal - open', { templateId, isBlockingDependency, template: templateName });
  }, [isBlockingDependency, templateId, templateName]);

  return (
    <MegaModal>
      <Stepper initialStep={dependencyIdToEdit ? 2 : initialStep}>
        <Step
          stepNumber={1}
          stepContent={DependencySelectionScreen}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          currentObjectId={currentObjectId}
          preselectedId={preselectedId}
          showModal={showModal}
          templateId={templateId}
          templateName={templateName}
          isBlockingDependency={isBlockingDependency}
          className='flex-1 min-h-0 gap-[24px]'
        />
        <Step
          stepNumber={2}
          stepContent={ObjectConfigScreen}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          isBlockingDependency={isBlockingDependency}
          currentObjectId={currentObjectId}
          dependencyIdToEdit={dependencyIdToEdit}
          closeModal={closeModal}
        />
      </Stepper>
    </MegaModal>
  );
}
