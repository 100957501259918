import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type CreatePrismaticExcelMappingMutationVariables = Types.Exact<{
  input: Types.ExcelMappingCreateInput;
}>;


export type CreatePrismaticExcelMappingMutation = { __typename?: 'Mutation', createPrismaticExcelMapping: { __typename?: 'ExcelMapping', id: string, modularObjectId: string, sharepointFileId: string, sheetName: string, cellMapping: any } | null };


export const CreatePrismaticExcelMappingDocument = gql`
    mutation CreatePrismaticExcelMapping($input: ExcelMappingCreateInput!) {
  createPrismaticExcelMapping(input: $input) {
    id
    modularObjectId
    sharepointFileId
    sheetName
    cellMapping
  }
}
    `;
export type CreatePrismaticExcelMappingMutationFn = Apollo.MutationFunction<CreatePrismaticExcelMappingMutation, CreatePrismaticExcelMappingMutationVariables>;

/**
 * __useCreatePrismaticExcelMappingMutation__
 *
 * To run a mutation, you first call `useCreatePrismaticExcelMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrismaticExcelMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrismaticExcelMappingMutation, { data, loading, error }] = useCreatePrismaticExcelMappingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePrismaticExcelMappingMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrismaticExcelMappingMutation, CreatePrismaticExcelMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePrismaticExcelMappingMutation, CreatePrismaticExcelMappingMutationVariables>(CreatePrismaticExcelMappingDocument, options);
      }
export type CreatePrismaticExcelMappingMutationHookResult = ReturnType<typeof useCreatePrismaticExcelMappingMutation>;
export type CreatePrismaticExcelMappingMutationResult = Apollo.MutationResult<CreatePrismaticExcelMappingMutation>;
export type CreatePrismaticExcelMappingMutationOptions = Apollo.BaseMutationOptions<CreatePrismaticExcelMappingMutation, CreatePrismaticExcelMappingMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreatePrismaticExcelMappingMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ createPrismaticExcelMapping })
 *   )
 * })
 */
export const mockCreatePrismaticExcelMappingMutation = (resolver: Parameters<typeof graphql.mutation<CreatePrismaticExcelMappingMutation, CreatePrismaticExcelMappingMutationVariables>>[1]) =>
  graphql.mutation<CreatePrismaticExcelMappingMutation, CreatePrismaticExcelMappingMutationVariables>(
    'CreatePrismaticExcelMapping',
    resolver
  )
