import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type UpdateCollaboratorsMutationVariables = Types.Exact<{
  input: Array<Types.SharesInput> | Types.SharesInput;
}>;


export type UpdateCollaboratorsMutation = { __typename?: 'Mutation', updateShares: Array<{ __typename?: 'Share', id: string | null, externalType: string | null, externalId: string | null, organizationId: string | null, role: string | null, userId: string | null, user: { __typename?: 'User', id: string, email: string | null, firstName: string | null, lastName: string | null, profileImage: string | null } | null } | null> };


export const UpdateCollaboratorsDocument = gql`
    mutation UpdateCollaborators($input: [SharesInput!]!) {
  updateShares(input: $input) {
    id
    externalType
    externalId
    organizationId
    role
    userId
    user {
      id
      email
      firstName
      lastName
      profileImage
    }
  }
}
    `;
export type UpdateCollaboratorsMutationFn = Apollo.MutationFunction<UpdateCollaboratorsMutation, UpdateCollaboratorsMutationVariables>;

/**
 * __useUpdateCollaboratorsMutation__
 *
 * To run a mutation, you first call `useUpdateCollaboratorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollaboratorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollaboratorsMutation, { data, loading, error }] = useUpdateCollaboratorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollaboratorsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCollaboratorsMutation, UpdateCollaboratorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCollaboratorsMutation, UpdateCollaboratorsMutationVariables>(UpdateCollaboratorsDocument, options);
      }
export type UpdateCollaboratorsMutationHookResult = ReturnType<typeof useUpdateCollaboratorsMutation>;
export type UpdateCollaboratorsMutationResult = Apollo.MutationResult<UpdateCollaboratorsMutation>;
export type UpdateCollaboratorsMutationOptions = Apollo.BaseMutationOptions<UpdateCollaboratorsMutation, UpdateCollaboratorsMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateCollaboratorsMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updateShares })
 *   )
 * })
 */
export const mockUpdateCollaboratorsMutation = (resolver: Parameters<typeof graphql.mutation<UpdateCollaboratorsMutation, UpdateCollaboratorsMutationVariables>>[1]) =>
  graphql.mutation<UpdateCollaboratorsMutation, UpdateCollaboratorsMutationVariables>(
    'UpdateCollaborators',
    resolver
  )
