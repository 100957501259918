import { useEditContext } from '@/components';
import DisplayValue from '@/components/modules/DisplayValue';
import { useUpdateAttributeValue } from '@/hooks/useUpdateAttributeValue';
import { Listbox } from '@headlessui/react';
import cx from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { usePopper } from 'react-popper';
import HookFormLabel from './HookFormLabel';
import SelectBoxButton from './SelectBoxInput/SelectBoxButton';
import SelectBoxOptions from './SelectBoxInput/SelectBoxOptions';

export interface Option {
  label: string;
  value: string;
}

const SPLIT_JOIN_CRITERIA = ', ';

export interface SelectBoxOptionsProps {
  className?: HTMLDivElement['className'];
  options: Option[];
  labelProps?: React.ComponentProps<typeof HookFormLabel>;
  buttonProps?: React.ComponentProps<typeof SelectBoxButton>;
  name: string;
  theme?: 'light' | 'dark';
}

export default function MultiSelect (
  {
    className,
    labelProps,
    buttonProps,
    name,
    options,
    theme = 'light',
  }: Readonly<SelectBoxOptionsProps>,
): JSX.Element {
  const { canUserEdit } = useEditContext();
  const { control } = useFormContext() ?? {};

  const value: string = useWatch({ name }) ?? '';
  const initalSelectedItems = useMemo(() => {
    if (!value) return [];
    return value?.split(SPLIT_JOIN_CRITERIA);
  }, [value]);

  const [selectedItems, setSelectedItems] = useState<string[]>(initalSelectedItems);
  const [popperElement, setPopperElement] = useState(null);
  const [popperRefElement, setPopperRefElement] = useState(null);
  const { styles, attributes } = usePopper(popperRefElement, popperElement);
  const { updateValue } = useUpdateAttributeValue();

  const handleChange = useCallback((selectedItems, cb: (selectedItems) => void) => {
    setSelectedItems(selectedItems);
    if (cb) cb(selectedItems.join(SPLIT_JOIN_CRITERIA));

    const newFieldValue = selectedItems.join(SPLIT_JOIN_CRITERIA);
    void updateValue({ newFieldValue, attributeName: name });
  }, [name, updateValue]);

  return (
    <div className={cx('relative', theme, className)}>
      {canUserEdit ?
        (
          <>
            <HookFormLabel className='mb-2 label' {...labelProps} />
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, value } }) => {
                return (
                  <Listbox
                    value={selectedItems}
                    onChange={selectedItems => handleChange(selectedItems, onChange)}
                    multiple={true}
                  >
                    <SelectBoxButton
                      name={labelProps?.label}
                      selectedItem={selectedItems.join(SPLIT_JOIN_CRITERIA)}
                      ref={setPopperRefElement}
                      {...buttonProps}
                    />

                    <SelectBoxOptions
                      options={options}
                      ref={setPopperElement}
                      style={styles.popper}
                      {...attributes.popper}
                      selectedItems={selectedItems}
                    />
                  </Listbox>
                );
              }}
            />
          </>
        ) :
        <DisplayValue className={className} labelProps={labelProps} value={value} />}
    </div>
  );
}
