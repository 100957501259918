import { useGetSubscriptionQuery } from '@/state/queries/subscription.api';
import metrics from '@/util/metrics';

import { Button } from '@/designSystemComponents/Button';
import {
  useGetUserCountAndModularObjectCountQuery,
} from '@/graphql/sideNavigation/getUserCountAndModularObjectCount.generated';
import { useLoggedInUserRole } from '@/hooks/useLoggedInUser';
import { useRouter } from 'next/router';
import Color from '../../types/Color.enum';
import useSubscriptionInfo from './useSubscriptionInfo';

export default function SubscriptionInfo (): JSX.Element {
  const router = useRouter();
  const { isSuper, isAdmin } = useLoggedInUserRole();
  const { data: userAndModularObjectCountData } = useGetUserCountAndModularObjectCountQuery();
  const isAdminOrSuper = isSuper || isAdmin;
  const { data: currentSubscription } = useGetSubscriptionQuery(false);

  const { planName, objectCountDisplay, userCountDisplay, shouldHidePricingInfo, remainingTrialDays } =
    useSubscriptionInfo(currentSubscription, userAndModularObjectCountData, isAdminOrSuper);

  if (shouldHidePricingInfo) {
    return null;
  }

  const gotoPlanPageViaTrialCountdown = async () => {
    metrics.track(
      'sidenav - nav to plan page via trial countdown',
      {
        remainingTrialDays,
      },
    );

    await router.push('/settings/plan');
  };

  const gotoPlanPage = async () => {
    metrics.track('sidenav - nav to plan page via plan name', {
      planName,
    });
    await router.push('/settings/plan');
  };

  return (
    <div className='flex-col gap-[22px] text-neutral-200 effra-10 hidden @expanded/sidenav:flex'>
      <div className='flex flex-col gap-[8px]'>
        <button onClick={gotoPlanPage}>{planName} Plan</button>
        {!currentSubscription?.isTrial && (
          <>
            <div>Objects {objectCountDisplay}</div>
            <div>Users {userCountDisplay}</div>
          </>
        )}
      </div>
      {currentSubscription?.isTrial && (
        <Button
          onClick={gotoPlanPageViaTrialCountdown}
          color={Color.SECONDARY}
          figmaProps={{ style: 'fill', size: 'small' }}
          className='rounded-[2px] !h-[24px]'
        >
          {remainingTrialDays} days left
        </Button>
      )}
    </div>
  );
}
