import InfoTooltip from '@/components/tooltip/InfoTooltip';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import getIcon from '@/util/getIcon';
import { truncateString } from '@/util/truncateString';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';
export interface BreadcrumbConfig {
  parentName?: string;
  parentId?: string;
  parentTemplateId?: string;
  parentTemplateName?: string;
  childId?: string;
}

interface BreadcrumbProps {
  breadcrumb?: BreadcrumbConfig;
}

export default function Breadcrumb ({ breadcrumb = {} }: BreadcrumbProps): JSX.Element {
  const { parentName: name, parentId: id, parentTemplateName: templateName, childId } = breadcrumb;
  const { openViewObjectCardDrawer } = useObjectCardContext();
  const handleClick = (): void => {
    openViewObjectCardDrawer({ modularObjectId: id });
  };

  const canAccessBreadcrumb = !name?.includes('Unnamed');

  const renderButton = (
    <button
      type='button'
      data-testid='name-button'
      className={cx({
        'underline-offset-2 whitespace-nowrap': childId,
        'hover:underline': canAccessBreadcrumb,
      })}
      onClick={handleClick}
      disabled={!canAccessBreadcrumb}
    >
      {truncateString(name, 8, 6)}
    </button>
  );

  return (
    <div data-testid='breadcrumb' className='flex gap-2 items-center text-neutral-500'>
      <div className={cx('flex items-center gap-1', { 'hover:text-neutral-700': childId && canAccessBreadcrumb })}>
        {getIcon(templateName, 'text-[12px]')}
        {!canAccessBreadcrumb ?
          (
            <InfoTooltip message='You do not have access to this object'>
              {renderButton}
            </InfoTooltip>
          ) :
          renderButton}
      </div>
      {Boolean(childId) && (
        <div>
          <FontAwesomeIcon data-testid='child-breadcrumb-arrow' icon={faChevronRight} />
        </div>
      )}
    </div>
  );
}
