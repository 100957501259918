import type { File } from '@/__generated__/types';
import { GetHistoryUpdatesForObjectIdDocument } from '@/components/cards/UpdatesColumn/getHistoryUpdatesForObjectId.generated';
import { MegaModalSearchInputV2 } from '@/components/modals/MegaModal/MegaModalSearchInput/MegaModalSearchInput';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useModal } from '@/hooks/useModal';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { downloadFile } from '@/util/downloadFile';
import { deleteFiles, postFiles } from '@/util/requests.functions';
import cx from 'classnames';
import { type ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { v4, v5 } from 'uuid';
import FileUploadButton from '../../Files/FileUploadButton';
import { uploadNewFile } from '../../Files/utils';
import { buildUpdateObjectDiffPayloadForFile } from '../utils';
import { useAddFileToObjectMutation } from './AddFileToObject.generated';
import { ConfirmDeleteFileModal } from './ConfirmDeleteFileModal';
import { useRemoveFileFromObjectMutation } from './RemoveFileFromObject.generated';

const fileIconMap = {
  csv: 'fa-sharp fa-regular fa-file-excel',
  xlsx: 'fa-sharp fa-regular fa-file-excel',
  jpg: 'fa-sharp fa-regular fa-file-image',
  png: 'fa-sharp fa-regular fa-file-image',
  gif: 'fa-sharp fa-regular fa-file-image',
};

interface FileRowProps {
  file: File;
  isPendingApproval?: boolean;
}

const FileRow = ({ file, isPendingApproval }: FileRowProps) => {
  const downloaderRef = useRef<HTMLAnchorElement>();
  const { objectCardData } = useObjectCardContext();
  const { showModal, closeModal } = useModal();

  const [removeFileFromObject] = useRemoveFileFromObjectMutation();

  const removeFile = useCallback(async () => {
    try {
      await deleteFiles([file]);
    } catch (e) {
      throw new Error(e);
    }

    try {
      await removeFileFromObject({
        variables: {
          input: {
            diffs: [buildUpdateObjectDiffPayloadForFile({
              objectId: objectCardData.id,
              file,
              payloadType: 'removedFiles',
            })],
          },
        },
        refetchQueries: [GetHistoryUpdatesForObjectIdDocument],
        onCompleted: async (data) => {
          const latestFiles = data.updateModularObject[0].files ?? [];

          const isSaved = !latestFiles.some((f) => f.id === file.id);

          const SUCCESS_MESSAGE = isSaved
            ? 'Successfully removed file'
            : 'Successfully requested approval for removing file';

          addToastSuccess(SUCCESS_MESSAGE);
        },
        onError: () => {
          addToastError('Failed to remove file, please try again');
        },
      });
    } catch (err) {
      console.error(err);
      addToastError(err.error ?? err);
    }
  }, [file, objectCardData.id, removeFileFromObject]);

  const handleDownloadClick = useCallback(async () => {
    try {
      await downloadFile(file, downloaderRef);
    } catch (err) {
      addToastError(`Error downloading file: ${err.message}`);
    }
  }, [file]);

  const handleRemoveClick = useCallback(() => {
    showModal(<ConfirmDeleteFileModal closeModal={closeModal} handleConfirm={removeFile} fileName={file.name} />);
  }, [closeModal, file.name, removeFile, showModal]);

  const [fileName, fileExtension] = file.name.split('.');
  const fileIcon = fileIconMap[fileExtension] ?? 'fa-sharp fa-regular fa-file';

  return (
    <div
      data-testid='file-row'
      className={cx(
        'group flex items-center gap-5 px-6 py-2 transition-colors',
        {
          'opacity-40': isPendingApproval,
        },
      )}
    >
      <div className='flex gap-2 w-1/2'>
        <i className={fileIcon} />
        {fileName}
      </div>
      <div className='flex justify-end w-1/2'>
        <div className='flex justify-center w-1/4 uppercase'>
          {fileExtension}
        </div>
        <button
          data-testid='download-file'
          type='button'
          onClick={handleDownloadClick}
          className='justify-end w-1/4'
        >
          {!isPendingApproval &&
            <i className='fa-sharp fa-solid fa-arrow-down-to-bracket' />}
        </button>
        <button
          data-testid='remove-file'
          type='button'
          onClick={handleRemoveClick}
          className='w-4 opacity-0 transition-opacity group-hover:opacity-100 link text-zinc-500'
        >
          <i className='fa-sharp fa-regular fa-xmark' />
        </button>
      </div>
      <a ref={downloaderRef} className='hidden' />
    </div>
  );
};

export const FileUploadSection = () => {
  const user = useLoggedInUser();
  const fileInputRef = useRef(null);
  const { objectCardData } = useObjectCardContext();
  const files = objectCardData.files;

  const openFileSelector = (): void => {
    fileInputRef.current.click();
  };

  const [isLoading, setIsLoading] = useState(false);
  const [addObjectToFile] = useAddFileToObjectMutation();

  const updateObjectWithFile = useCallback(async (newFile: File) => {
    await addObjectToFile({
      variables: {
        input: {
          diffs: [
            buildUpdateObjectDiffPayloadForFile({
              file: newFile,
              objectId: objectCardData.id,
              payloadType: 'addedFiles',
            }),
          ],
        },
      },
      refetchQueries: [GetHistoryUpdatesForObjectIdDocument],
      onCompleted: async (data) => {
        const latestFiles = data.updateModularObject[0].files ?? [];

        const isSaved = latestFiles.some((f) => f.id === newFile.id);

        const SUCCESS_MESSAGE = isSaved
          ? 'Successfully saved file'
          : 'Successfully requested approval for adding file';

        addToastSuccess(SUCCESS_MESSAGE);
      },
      onError: () => addToastError('Failed to add file, please try again'),
    });
  }, [addObjectToFile, objectCardData.id]);

  const onFileSelect = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    setIsLoading(true);
    for await (const file of Array.from(event.target.files)) {
      const response = await uploadNewFile(file);
      const responseFile = response?.file;
      const newFileId = v5(`${responseFile.name}-${v4()}`, user?.id);
      const newFile = {
        ...response.file,
        id: newFileId,
        externalId: objectCardData.id,
        externalType: 'modular_object',
      };
      await postFiles([newFile]);
      await updateObjectWithFile(newFile);
    }
    setIsLoading(false);
    fileInputRef.current.value = null;
  };

  const [filterText, setFilterText] = useState('');

  const filteredFiles = useMemo(() => {
    const fileList = files ?? [];
    return fileList?.filter(
      file => file.name.toLowerCase().includes(filterText.toLowerCase()),
    );
  }, [files, filterText]);

  return (
    <>
      <div className='z-10 px-6 shadow-md'>
        <div className='flex gap-8'>
          <FileUploadButton isLoading={isLoading} onClick={openFileSelector} />
          <MegaModalSearchInputV2
            placeholder='Filter...'
            value={filterText}
            onChange={(e) => {
              setFilterText(e.target.value);
            }}
          />
        </div>
        <div className='flex py-3 font-bold'>
          <div className='w-1/2 capitalize'>
            name
          </div>
          <div className='flex justify-end w-1/2'>
            <div className='px-4 w-1/4 capitalize'>
              file type
            </div>
            <div className='justify-end px-4 w-1/4 capitalize'>
              download
            </div>
          </div>
        </div>
      </div>
      <div className='overflow-auto my-4 h-40'>
        {filteredFiles?.map((file) => <FileRow key={file.id} file={file} />)}
        {!filteredFiles?.length && (
          <div className='flex justify-center items-center h-full'>
            <div className='font-medium opacity-60'>No files uploaded</div>
          </div>
        )}
      </div>
      <input
        className='hidden'
        ref={fileInputRef}
        type='file'
        onChange={onFileSelect}
      />
    </>
  );
};
