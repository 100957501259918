import { INDEX_TO_TIME_MAP, TIME_TO_INDEX_MAP } from '@/util/constants';
import classnames from 'classnames';
import { useEffect, useState } from 'react';

interface TimeSliderProps {
  marks?: number;
  min?: number;
  max?: number;
  defaultValue?: number;
  value?: string;
  onChange?: (value) => void;
  className?: string;
  timeRange?: boolean;
}

const getTimeOffset = (value) => 100 * (TIME_TO_INDEX_MAP[value] / INDEX_TO_TIME_MAP.length);

export const TimeSlider = (props: TimeSliderProps): JSX.Element => {
  const [value, setValue] = useState(props.defaultValue || props.value);
  const [xOffset, setXOffset] = useState(getTimeOffset(props.value));

  const handleSliderChange = (event): void => {
    const value = event.target.value;
    const mappedTime = INDEX_TO_TIME_MAP[value];
    setValue(mappedTime);
    props.onChange(mappedTime);
  };

  const handleInputChange = (event): void => {
    const newValue = event.target.value;
    setValue(newValue);
    props.onChange(newValue);
  };

  useEffect(() => {
    setXOffset(getTimeOffset(props.value));
  }, [props.value]);

  return (
    <div className={classnames('flex items-center gap-2', props.className)}>
      <span className='text-sm font-light mt-[-1rem]'>{'12:00a'}</span>
      <div className='flex flex-col grow'>
        <div className='' style={{ transform: `translate(${xOffset}%, 0.5rem)` }}>
          <span
            className={classnames(
              { 'ml-[-2rem]': xOffset > 50, 'ml-[-1rem]': xOffset <= 50 },
              'text-center font-medium text-sm',
            )}
          >
            Target
          </span>
        </div>
        <div>
          <input
            type='range'
            className={'w-full accent-primary bg-black appearance-none cursor-pointer h-[1px]'}
            min={0}
            max={INDEX_TO_TIME_MAP.length - 1}
            value={TIME_TO_INDEX_MAP[value]}
            onChange={handleSliderChange}
          />
        </div>
        <div style={{ transform: `translateX(${xOffset}%)` }}>
          <input
            type='time'
            className={classnames(
              { 'ml-[-3.5rem]': xOffset > 50, 'ml-[-3rem]': xOffset <= 50 },
              'text-center shadow-normal pl-2 pt-1 mt-2 border-b-2 border-black focus:border-primary focus:outline-none w-[7rem]',
            )}
            value={value}
            step={60}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <span className='text-sm font-light mt-[-1rem]'>{'11:59p'}</span>
    </div>
  );
};
