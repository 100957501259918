import type { ComponentProps } from 'react';

export const FixedGapBehaviorIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_6598_37681)'>
        <path d='M10.6359 4.14006V3.06724C10.6359 1.59655 9.43517 0.395837 7.96448 0.395837C6.49378 0.395837 5.29307 1.59655 5.29307 3.06724V4.14006H3.42451V10.7333H12.5115V4.14006H10.643H10.6359ZM6.57904 4.14006V3.06724C6.57904 2.30703 7.19716 1.68891 7.95737 1.68891C8.71758 1.68891 9.34281 2.30703 9.34281 3.06724V4.14006H6.57904ZM11.2114 5.43314V9.44735H4.71048V5.43314H11.2185H11.2114ZM16 0.125854V11.0033H14.643V0.125854H16ZM0 0.807915V11.0104H1.35702V0.125854H0V0.807915Z' />
      </g>
      <defs>
        <clipPath id='clip0_6598_37681'>
          <rect width='16' height='10.8774' fill='white' transform='translate(0 0.125854)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NoOverlapGapBehaviorIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_6598_37682)'>
        <path d='M16 0V10.415H14.7007V0H16ZM0 0.653061V10.4218H1.29932V0H0V0.653061ZM9.57823 4.59864L11.8912 6.91156C10.1293 7.12245 8.93197 6.95918 8.18367 6.41497C7.57823 5.97279 7.21088 5.23129 7.06803 4.15646C6.59864 0.62585 3.59864 -0.0340136 2.08844 0.115646V1.46939C2.40136 1.44218 5.31292 1.28571 5.72109 4.33333C5.91156 5.77551 6.46939 6.85034 7.38095 7.5102C8.16327 8.08163 9.17687 8.36735 10.5374 8.36735C10.8367 8.36735 11.1565 8.34694 11.4966 8.31973L9.55782 10.2585L10.4218 11.1224L14.1293 7.41497L10.4422 3.72789L9.57823 4.59184V4.59864Z' />
      </g>
      <defs>
        <clipPath id='clip0_6598_37682'>
          <rect width='16' height='11.1293' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NoGapBehavior = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_6598_37680)'>
        <path d='M0 0.357056H1.29932V10.772H0V0.357056ZM14.7007 0.357056V10.772H16V0.357056H14.7007ZM9.80952 0.758416L12.8095 3.75842V7.37746L9.80952 10.3775H6.19048L3.19048 7.37746V3.75842L6.19048 0.758416H9.80952ZM9.30612 9.15978L11.5986 6.86726V4.25501L9.30612 1.9625H6.69388L4.40136 4.25501V6.86726L6.69388 9.15978H9.30612ZM9.15646 3.54753L8 4.70399L6.84354 3.54753L5.98639 4.40467L7.14286 5.56114L5.98639 6.7176L6.84354 7.57474L8 6.41828L9.15646 7.57474L10.0136 6.7176L8.85714 5.56114L10.0136 4.40467L9.15646 3.54753Z' />
      </g>
      <defs>
        <clipPath id='clip0_6598_37680'>
          <rect width='16' height='10.415' fill='white' transform='translate(0 0.357056)' />
        </clipPath>
      </defs>
    </svg>
  );
};
