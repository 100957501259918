import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComputerClassic, faXmark } from '@fortawesome/sharp-solid-svg-icons';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

export default function MaintenanceBanner (): JSX.Element {
  const { siteMaintenance } = useFlags();
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  useEffect(() => {
    const maintenanceBannerDismissed = localStorage.getItem('maintenance-banner-dismissed');
    let maintenanceBannerDismissedObject;
    try {
      maintenanceBannerDismissedObject = maintenanceBannerDismissed && JSON.parse(maintenanceBannerDismissed);
    } catch (e) {
      maintenanceBannerDismissedObject = null;
    }
    const isLastDismissedOutsideWindow = !maintenanceBannerDismissedObject?.date ||
      dayjs(maintenanceBannerDismissedObject?.date).add(1, 'day').isBefore(dayjs());

    if (isLastDismissedOutsideWindow && siteMaintenance) {
      setIsBannerVisible(true);
    }

    if (isLastDismissedOutsideWindow) {
      localStorage.removeItem('maintenance-banner-dismissed');
    }
  }, [siteMaintenance]);

  const handleCloseBanner = (): void => {
    const dismissedObject = {
      date: new Date().toISOString(),
    };
    localStorage.setItem('maintenance-banner-dismissed', JSON.stringify(dismissedObject));
    setIsBannerVisible(false);
  };

  return (
    <>
      {isBannerVisible && (
        <section className='flex justify-center items-center p-6 text-white bg-warning'>
          <FontAwesomeIcon icon={faComputerClassic} className='pr-2.5' />
          Exciting changes ahead! Integrate is undergoing a large update today, you may experience some difficulties as
          we work to improve your experience, thank you for your patience!
          <div className='cursor-pointer' onClick={handleCloseBanner}>
            <FontAwesomeIcon icon={faXmark} className='pr-1 pl-2.5' />
          </div>
        </section>
      )}
    </>
  );
}
