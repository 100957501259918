import cx from 'classnames';
import { type ColumnConfig } from './utils';

interface TableHeaderProps {
  rowConfig: () => ColumnConfig[];
  className?: string;
}

export default function TableHeader (
  { rowConfig, className = 'gap-10 px-6 shadow-md lg:px-20 bg-zinc-50' }: TableHeaderProps,
): JSX.Element {
  return (
    <div className={cx(className, 'z-10 flex items-center py-3.5  font-bold')}>
      {rowConfig().map((col, idx) => (
        <div
          key={`${col.title}-${idx}`}
          className={cx('whitespace-nowrap text-ellipsis overflow-hidden', col.displayClass)}
          style={{ width: col.width }}
        >
          {col.title}
        </div>
      ))}
    </div>
  );
}
