import cx from 'classnames';

interface MegaModalHeaderProps {
  title: string;
  subtext?: string;
  subTextBlack?: boolean;
}

export default function MegaModalHeader ({ title, subtext, subTextBlack }: MegaModalHeaderProps): JSX.Element {
  return (
    <div className='flex flex-col shrink'>
      <div className='flex flex-col gap-2'>
        <h1 className='text-3xl'>{title}</h1>
        {subtext && (
          <div
            className={cx({
              'text-black-400': subTextBlack,
              'text-gray-400': !subTextBlack,
            })}
          >
            {subtext}
          </div>
        )}
      </div>
    </div>
  );
}
