import { Button } from '@/components/Button/Button';
import { useEditContext } from '@/components/cards';
import { AddNewObjectModal } from '@/components/modals';
import InfoTooltip from '@/components/tooltip/InfoTooltip';
import { useModal } from '@/hooks/useModal';
import { useNestingMutations } from '@/hooks/useNestingMutations';
import { type Integration } from '@/models/integration.model';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { useGetSubscriptionQuery } from '@/state/queries/subscription.api';
import { memo, useCallback, useMemo } from 'react';
import { useGetObjectCardObjectQuery } from '../ModularObject/Card/GetObjectCardObject.generated';
import { useObjectCardScrollContext } from '../ModularObject/Card/ObjectCardScroll.context';
import { useGetChildrenForNestingColumnQuery } from './GetChildrenForNestingColumn.generated';
import NestedItem from './NestedItem';

function denormalizeArray<T> (array: T[], keySelector: (item: T) => string): Record<string, T> {
  return array.reduce((result, item) => {
    const key = keySelector(item);
    result[key] = item;
    return result;
  }, {});
}

const NestingSection = memo(function (): JSX.Element {
  const { canUserEdit } = useEditContext();
  const { data: subscription } = useGetSubscriptionQuery(false);
  const { closeModal, showModal } = useModal();
  const { scrollToId } = useObjectCardScrollContext();
  const { activeModularObjectId, openViewObjectCardDrawer } = useObjectCardContext();

  const { data, loading, previousData } = useGetObjectCardObjectQuery({
    variables: { modularObjectId: activeModularObjectId },
    skip: !activeModularObjectId,
  });

  const { data: nestingData, loading: isNestingDataLoading, previousData: nestingPreviousData } =
    useGetChildrenForNestingColumnQuery({
      variables: { modularObjectId: activeModularObjectId },
      skip: !activeModularObjectId,
    });

  const { addChild } = useNestingMutations();

  const _nestingData = isNestingDataLoading ? nestingPreviousData : nestingData;
  const activeObject = useMemo(() => {
    if (!_nestingData?.getModularObjectByIDs?.[0]) return null;

    return _nestingData.getModularObjectByIDs[0];
  }, [_nestingData?.getModularObjectByIDs]);

  const childrenPendingRemoveApprovalMap = useMemo(() => {
    if (!activeObject?.childrenPendingRemoveApproval) return {};
    const childrenPendingRemoveApproval = activeObject.childrenPendingRemoveApproval.filter((child) =>
      child.template.type !== 'task'
    );

    return denormalizeArray(childrenPendingRemoveApproval, (child) => child.id);
  }, [activeObject?.childrenPendingRemoveApproval]);

  const childrenPendingAddApprovalMap = useMemo(() => {
    if (!activeObject?.childrenPendingAddApproval) return {};

    const childrenPendingAddApproval = activeObject.childrenPendingAddApproval.filter((child) =>
      child.template.type !== 'task'
    );

    return denormalizeArray(childrenPendingAddApproval, (child) => child.id);
  }, [activeObject?.childrenPendingAddApproval]);

  const children = useMemo(() => {
    if (!activeObject?.children) return [];

    const currentChildren = activeObject.children.filter((child) =>
      Boolean(child?.template?.type) && child?.template?.type !== 'task'
    );

    // this gets rid of duplicates
    const childrenMap = {
      ...denormalizeArray(currentChildren, (child) => child.id),
      ...childrenPendingAddApprovalMap,
      ...childrenPendingRemoveApprovalMap,
    };

    return Object.values(childrenMap);
  }, [activeObject?.children, childrenPendingAddApprovalMap, childrenPendingRemoveApprovalMap]);

  const _data = loading ? previousData : data;
  const activeModularObject = useMemo(() => _data?.getModularObjectByIDs?.[0], [_data]);

  // Get potential parentId/parentTemplateId to see if we should disable the "Add item" buttons
  // due to the potential for an endless recursion of creating new objects/new items

  const handleAddChild = useCallback(async (integrations: Integration[]) => {
    const addChildPayload = integrations.map((integration) => ({
      objectId: integration.objectId,
      parentId: activeModularObjectId,
    }));

    await addChild({
      variables: { input: addChildPayload },
    });

    scrollToId('nesting-section');
    closeModal();
  }, [activeModularObjectId, addChild, closeModal, scrollToId]);

  const handleAddObjectButtonClick = (): void => {
    const shouldDisableObjectCreation = subscription?.featureLimits?.isObjectCreationDisabled;
    const disabledObjectCreationMessage = shouldDisableObjectCreation
      && 'Cannot create objects while\ncreating a nested object.';

    showModal(
      <AddNewObjectModal
        modularObject={activeModularObject}
        onSubmit={handleAddChild}
        disableCreateObject={shouldDisableObjectCreation}
        disableCreateObjectMessage={disabledObjectCreationMessage}
        afterObjectCreationSave={(newObject) => {
          showModal(
            <AddNewObjectModal
              modularObject={activeModularObject}
              onSubmit={handleAddChild}
              preselectedId={newObject.id}
            />,
            { isMegaModal: true, showCloseIcon: false },
          );
          openViewObjectCardDrawer({ modularObjectId: activeModularObjectId });
        }}
      />,
      {
        showCloseIcon: false,
        isMegaModal: true,
      },
    );
  };

  return (
    <div className='grow'>
      {/* TODO: Component-ize header */}
      <div className='z-10 shadow-md'>
        {canUserEdit && (
          <AddObjectButton
            onClick={handleAddObjectButtonClick}
          />
        )}
        <div className='flex py-3 px-6 ml-20 font-bold'>
          <div className='w-[50%] px-4'>
            Name
          </div>
          <div className='w-[25%] px-4'>
            Owner
          </div>
          <div className='w-[25%] px-4'>
            Company
          </div>
        </div>
        <div className='flex flex-col gap-4 py-4 bg-zinc-100'>
          <div className='flex flex-col ml-20'>
            {children.map((child) => {
              if (childrenPendingRemoveApprovalMap[child.id]) {
                return <NestedItem key={`integrations-${child.id}`} nestedItem={child} isPendingRemovalApproval />;
              }

              if (childrenPendingAddApprovalMap[child.id]) {
                return <NestedItem key={`integrations-${child.id}`} nestedItem={child} isPendingAddApproval />;
              }

              return <NestedItem key={`integrations-${child.id}`} nestedItem={child} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
});

NestingSection.displayName = 'NestingSection';

export default NestingSection;

interface AddObjectButtonProps {
  readonly onClick: () => void;
  readonly disabled?: boolean;
  readonly tooltip?: string;
}

function AddObjectButton ({
  onClick,
  disabled = false,
  tooltip,
}: AddObjectButtonProps): JSX.Element {
  const button = (
    <Button
      onClick={onClick}
      disabled={disabled}
      className='flex gap-3 justify-center items-center py-3 px-4 font-medium btn-primary-hollow effra-xs'
    >
      Add Object
    </Button>
  );

  return (
    <div data-testid='add-item-button' className='p-6'>
      {!tooltip ? button : <InfoTooltip message={tooltip}>{button}</InfoTooltip>}
    </div>
  );
}
