import { type ComponentProps } from 'react';
import CubesatIcon from './Icons/CubesatIcon';
import DeployerIcon from './Icons/DeployerIcon';
import MissionIcon from './Icons/MissionIcon';
import OTVIcon from './Icons/OTVIcon';
import SpacecraftIcon from './Icons/SpacecraftIcon';
import VehicleIcon from './Icons/VehicleIcon';

const iconComponentMap = {
  cubesat: CubesatIcon,
  deployer: DeployerIcon,
  mission: MissionIcon,
  smallsat: SpacecraftIcon,
  spacecraft: SpacecraftIcon,
  otv: OTVIcon,
  vehicle: VehicleIcon,
  modular_object: SpacecraftIcon,
};

interface ObjectIconProps extends ComponentProps<'svg'> {
  objectType: string;
}

export default function ObjectIcon ({ objectType, ...otherProps }: ObjectIconProps): JSX.Element {
  const IconComponent = iconComponentMap[objectType.toLowerCase()] || SpacecraftIcon;
  return <IconComponent {...otherProps} />;
}
