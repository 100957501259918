import { Label, useEditContext } from '@/components';
import { Listbox, Transition } from '@headlessui/react';
import classnames from 'classnames';
import Image from 'next/legacy/image';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import StatusTooltip from '../tooltip/StatusTooltip';

interface DropdownProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionKey?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionValueKey?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (event: any) => void;
  prefix?: string;
  suffix?: string;
  className?: string;
  wrapperClass?: string;
  selectedClass?: string;
  disabled?: boolean;
  disableOptionOn?: (option: string) => boolean;
  placeholder?: string;
  label?: string;
  useIcons?: boolean;
  required?: boolean;
  dark?: boolean;
  name?: string;
  isDirty?: boolean;
}

export function Dropdown ({
  className,
  wrapperClass,
  selectedClass,
  defaultValue,
  value,
  id,
  options,
  optionKey,
  optionValueKey,
  onChange,
  prefix,
  suffix,
  disabled,
  disableOptionOn,
  placeholder,
  label,
  useIcons,
  required,
  dark,
  name,
  isDirty,
}: DropdownProps): JSX.Element {
  const [selectedItem, setSelectedItem] = useState(value ?? defaultValue ?? '');
  const [popperElement, setPopperElement] = useState(null);
  const [popperRefElement, setPopperRefElement] = useState(null);
  const { styles, attributes } = usePopper(popperRefElement, popperElement);
  const _onChange = (event): void => {
    setSelectedItem(event);
    onChange({ target: { value: event } });
  };

  const { canUserEdit, changes, requestedChanges } = useEditContext();

  const backgroundColor = dark ? 'bg-black' : 'bg-white';
  const activeBackgroundColor = dark ? 'bg-white/20' : 'bg-gray-200';

  useEffect(() => {
    if (optionValueKey) {
      const optionValue = options.find((option) => option[optionValueKey] === value);
      setSelectedItem(optionValue ?? value ?? '');
    } else {
      setSelectedItem(value ?? '');
    }
  }, [value]);

  // Set selectedItem to value or defaultValue on mount
  useEffect(() => {
    if (optionValueKey) {
      const optionValue = options.find((option) => option[optionValueKey] === value);
      setSelectedItem(optionValue ?? value ?? defaultValue ?? '');
    } else {
      setSelectedItem(value ?? defaultValue ?? '');
    }
  }, []);

  const tooltipStatus = useMemo(() => {
    if (Object.prototype.hasOwnProperty.call(changes ?? {}, name) || isDirty) {
      return 'staged';
    }
    if (Object.prototype.hasOwnProperty.call(requestedChanges ?? {}, name)) {
      return 'waiting for approval';
    }
  }, [changes, requestedChanges]);

  return (
    <div id={id} className={wrapperClass}>
      {canUserEdit && (
        <>
          {canUserEdit && (
            <>
              {Boolean(label) && (
                <div className='flex'>
                  <StatusTooltip status={tooltipStatus} />
                  <Label>
                    {label} {required && <span className='text-secondary'>(Required)</span>}
                  </Label>
                </div>
              )}
              <div
                className={classnames(className, 'relative effra-xs', {
                  'before:content-[attr(data-content-prefix)] before:mr-1 before:text-xs': !!prefix, // TODO: make text size dynamic here
                  'after:content-[attr(data-content-suffix)] after:ml-1 after:text-xs': !!suffix,
                })}
                data-content-prefix={prefix}
                data-content-suffix={suffix}
              >
                <Listbox value={selectedItem} onChange={_onChange} disabled={disabled}>
                  <Listbox.Button
                    ref={setPopperRefElement}
                    className={classnames(
                      {
                        'opacity-[0.4]': disabled,
                        [selectedClass]: selectedClass,
                      },
                      'w-full border border-gray-dark focus:border-black focus:outline-none tracking-[2.4px] font-effra uppercase text-left py-3 pr-10 pl-3 relative flex items-center h-input',
                    )}
                  >
                    <span className='block uppercase truncate'>
                      {selectedItem && optionKey && Object.hasOwn(selectedItem, optionKey)
                        ? selectedItem[optionKey]
                        : selectedItem || placeholder}
                    </span>
                    <span className='flex absolute inset-y-0 right-0 items-center pr-2 pointer-events-none'>
                      <svg
                        className='w-5 h-5 text-gray-400'
                        viewBox='0 0 20 20'
                        fill='none'
                        stroke='currentColor'
                      >
                        <path
                          d='M7 7l3-3 3 3m0 6l-3 3-3-3'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                  </Listbox.Button>
                  <Transition
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                    className={classnames('absolute w-[100%] mt-1 z-[9999] overflow-auto shadow-lg', backgroundColor)}
                  >
                    <Listbox.Options className='max-h-60 leading-6 sm:leading-5 md:py-2 focus:outline-none'>
                      {Boolean(placeholder) && (
                        <Listbox.Option
                          key={'dropdown-placeholder'}
                          value=''
                          as={Fragment}
                          disabled
                        >
                          <li
                            className={'pr-10 pl-3 py-3 opacity-[0.5] font-effra uppercase tracking-[2.4px]'}
                          >
                            {placeholder}
                          </li>
                        </Listbox.Option>
                      )}
                      {options.map((item, idx) => (
                        <Listbox.Option
                          key={`dropdown-option-${id}-${idx}`}
                          value={item}
                          as={Fragment}
                          disabled={disableOptionOn && disableOptionOn(item?.[optionKey] || item)}
                        >
                          {({ active, selected }) => (
                            <li
                              className={classnames(
                                item?.class,
                                'pr-5 pl-3 py-3 transition-all',
                                {
                                  'font-bold': selected,
                                  [activeBackgroundColor]: active,
                                  'flex justify-between items-center': useIcons,
                                  'opacity-30': disableOptionOn && disableOptionOn(item?.[optionKey] || item),
                                },
                              )}
                            >
                              <div className='content-center'>
                                {useIcons && item?.icon && <Image src={item.icon} alt='Icon' height={14} width={14} />}
                              </div>
                              <div
                                id={`dropdown-option-${
                                  (item?.[optionKey] || item)
                                    .toLowerCase()
                                    .replaceAll(/- |\(|\)|\.|:/g, '')
                                    .replaceAll(' ', '-')
                                }`}
                                className='uppercase font-effra tracking-[2.4px]'
                              >
                                {item?.[optionKey] || item}
                              </div>
                            </li>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </Listbox>
              </div>
            </>
          )}
          {!canUserEdit && (
            <>
              {Boolean(label) && <Label>{label}</Label>}
              <div className='font-medium sculpin-base'>
                {(optionKey
                  ? options.find((option) => option.value === selectedItem)?.[optionKey]
                  : selectedItem) ||
                  defaultValue ||
                  '—'}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
