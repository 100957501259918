import type { User } from '@/__generated__/types';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';

interface DisabledObjectMessageProps {
  objectId?: string;
  owner?: Partial<User>;
}

export default function DisabledObjectMessage ({ objectId, owner }: DisabledObjectMessageProps): JSX.Element {
  const currentUser = useLoggedInUser();
  const { organizationId } = owner ?? {};

  const isInternal = objectId && organizationId === currentUser?.organizationId;

  return (
    <div className='flex gap-2 items-start p-4 bg-white border border-gray-300 shadow-md w-[300px] min-h-[80px] h-fit'>
      <div className='text-center text-black'>
        {isInternal
          ? (
            <>
              <div className='font-sculpin'>Object Limit Reached</div>
              <div>Your plan has used all of the objects available. Please upgrade to create more objects.</div>
            </>
          ) :
          (
            <>
              <div className='font-sculpin'>Object Deactivated</div>
              <div>
                This object has been deactivated because the owner has too many objects for their plan. Ask them to
                upgrade to collaborate.
              </div>
            </>
          )}
      </div>
    </div>
  );
}
