import { last, pathOr } from 'ramda';
import { useFormState } from 'react-hook-form';
import { useCopyToClipboard } from 'usehooks-ts';
import { useEditContext } from '../cards';
import FormLabel from '../FormLabel/FormLabel';
import { type ApprovalStatus } from '../tooltip/StatusTooltip';

export interface HookFormLabelProps {
  name?: string;
  status?: ApprovalStatus;
  label?: string;
  className?: string;
  showStatus?: boolean;
}

export default function HookFormLabel ({
  name = 'default',
  status = null,
  label,
  className = 'mb-2 label',
  showStatus = true,
}: HookFormLabelProps): JSX.Element {
  const { requestedChanges } = useEditContext();
  const { dirtyFields } = useFormState();
  const [, copy] = useCopyToClipboard();
  const namePathArray = name.split('.');

  const isDirty = pathOr(
    false,
    namePathArray,
    dirtyFields,
  );

  const pathInDiff = `data.${last(namePathArray)}`;
  const isPendingApproval = Array.isArray(requestedChanges)
    ? requestedChanges?.find((change) => change === name || change === pathInDiff)
    : false;

  const labelStatus = isPendingApproval ? 'waiting for approval' : null;
  const dirtyState = isDirty ? 'staged' : null;

  return (
    <FormLabel
      className={className}
      label={label}
      name={name}
      onDoubleClick={() => copy(last(namePathArray))}
      status={showStatus ? status ?? dirtyState ?? labelStatus : null}
    />
  );
}
