import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetModularObjectHistoryByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetModularObjectHistoryByIdQuery = { __typename?: 'Query', getModularObjectHistoryByID: Array<{ __typename?: 'Diff', id: string, externalID: string, diffType: Types.DiffType | null, createdAt: any, createdBy: string, fromMigration: boolean, triggeredByID: string | null, diff: { __typename?: 'FromTo', from: any | null, to: any | null, displayNames: any | null } }> };


export const GetModularObjectHistoryByIdDocument = gql`
    query GetModularObjectHistoryByID($id: UUID!) {
  getModularObjectHistoryByID(id: $id) {
    id
    externalID
    diffType
    diff {
      from
      to
      displayNames
    }
    createdAt
    createdBy
    fromMigration
    triggeredByID
  }
}
    `;

/**
 * __useGetModularObjectHistoryByIdQuery__
 *
 * To run a query within a React component, call `useGetModularObjectHistoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModularObjectHistoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModularObjectHistoryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetModularObjectHistoryByIdQuery(baseOptions: Apollo.QueryHookOptions<GetModularObjectHistoryByIdQuery, GetModularObjectHistoryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModularObjectHistoryByIdQuery, GetModularObjectHistoryByIdQueryVariables>(GetModularObjectHistoryByIdDocument, options);
      }
export function useGetModularObjectHistoryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModularObjectHistoryByIdQuery, GetModularObjectHistoryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModularObjectHistoryByIdQuery, GetModularObjectHistoryByIdQueryVariables>(GetModularObjectHistoryByIdDocument, options);
        }
export function useGetModularObjectHistoryByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetModularObjectHistoryByIdQuery, GetModularObjectHistoryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModularObjectHistoryByIdQuery, GetModularObjectHistoryByIdQueryVariables>(GetModularObjectHistoryByIdDocument, options);
        }
export type GetModularObjectHistoryByIdQueryHookResult = ReturnType<typeof useGetModularObjectHistoryByIdQuery>;
export type GetModularObjectHistoryByIdLazyQueryHookResult = ReturnType<typeof useGetModularObjectHistoryByIdLazyQuery>;
export type GetModularObjectHistoryByIdSuspenseQueryHookResult = ReturnType<typeof useGetModularObjectHistoryByIdSuspenseQuery>;
export type GetModularObjectHistoryByIdQueryResult = Apollo.QueryResult<GetModularObjectHistoryByIdQuery, GetModularObjectHistoryByIdQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetModularObjectHistoryByIdQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ getModularObjectHistoryByID })
 *   )
 * })
 */
export const mockGetModularObjectHistoryByIdQuery = (resolver: Parameters<typeof graphql.query<GetModularObjectHistoryByIdQuery, GetModularObjectHistoryByIdQueryVariables>>[1]) =>
  graphql.query<GetModularObjectHistoryByIdQuery, GetModularObjectHistoryByIdQueryVariables>(
    'GetModularObjectHistoryByID',
    resolver
  )
