import { useAppSelector } from '@/state/hooks';
import { selectRootState } from '@/state/selectors';
import Image from 'next/legacy/image';
import { useMemo } from 'react';

const VISIBILITY_STATUS_ICONS = {
  Private: {
    src: '/images/private.svg',
    alt: 'Private',
    width: 20,
    height: 16,
  },
  PrivateShared: {
    src: '/images/private-shared.svg',
    alt: 'Private Shared',
    width: 22,
    height: 16,
  },
  Company: {
    src: '/images/company.svg',
    alt: 'Company',
    width: 16,
    height: 16,
  },
  CompanyShared: {
    src: '/images/company-shared.svg',
    alt: 'Company Shared',
    width: 20,
    height: 16,
  },
  Global: {
    src: '/images/global.svg',
    alt: 'Global',
    width: 16,
    height: 16,
  },
  External: {
    src: '/images/shared-with-you-tertiary.svg',
    alt: 'External',
    width: 20,
    height: 16,
  },
  Partners: {
    src: '/images/partners.svg',
    alt: 'Partners',
    width: 22,
    height: 16,
  },
};

export interface VisibilityProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any;
  noIcon?: boolean;
}

export function Visibility ({ object, noIcon = false }: Readonly<VisibilityProps>): JSX.Element {
  const { session: sessionState, users: usersState } = useAppSelector(selectRootState);
  const currentUser = sessionState.user;
  const owner = object?.owner || usersState[object?.ownerId];

  const status: string = object?.visibility || 'Private';
  const isInternal = owner?.organizationId === currentUser?.organizationId;

  const shares = useMemo(() => {
    return object?.shares ?? [];
  }, [object]);

  const isShared = useMemo(() => {
    return Boolean(shares?.find((share) => share.userId === currentUser?.id));
  }, [object, currentUser]);

  const sharedExternally = useMemo(() => {
    return Boolean(
      shares?.find((share) => usersState[share.userId]?.organizationId !== currentUser?.organizationId),
    );
  }, [shares, usersState, currentUser]);

  if (status === 'Global') {
    return (
      <div className='flex gap-2 items-center'>
        {!noIcon && (
          <div className='flex items-center shrink-0'>
            {Boolean(VISIBILITY_STATUS_ICONS[status]) && <Image {...VISIBILITY_STATUS_ICONS[status]} />}
          </div>
        )}
        <div className='overflow-hidden font-normal whitespace-nowrap text-ellipsis font-effra'>{status}</div>
      </div>
    );
  }

  return (
    <>
      {isInternal && sharedExternally && (
        <div className='flex gap-2 items-center'>
          {!noIcon && (
            <div className='flex items-center shrink-0'>
              <Image {...VISIBILITY_STATUS_ICONS.Partners} />
            </div>
          )}
          <div>Partners</div>
        </div>
      )}
      {isInternal && !sharedExternally && (
        <div className='flex gap-2 items-center'>
          {!noIcon && (
            <div className='flex items-center shrink-0'>
              {Boolean(VISIBILITY_STATUS_ICONS[status]) && (
                <Image {...VISIBILITY_STATUS_ICONS[`${status}${isShared ? 'Shared' : ''}`]} />
              )}
            </div>
          )}
          <div className='overflow-hidden font-normal whitespace-nowrap text-ellipsis font-effra'>{status}</div>
        </div>
      )}
      {!isInternal && isShared && (
        <div className='flex gap-2 items-center'>
          {!noIcon && (
            <div className='flex items-center shrink-0'>
              <Image {...VISIBILITY_STATUS_ICONS.External} />
            </div>
          )}
          <div className='whitespace-nowrap text-tertiary'>Shared with you</div>
        </div>
      )}
    </>
  );
}
