import { useUserProfileDrawer } from '@/hooks/useUserProfileDrawer';
import type { PropsWithChildren } from 'react';

export default function UserProfileDrawerTrigger (
  { userId, children, className }: PropsWithChildren<{ userId: string; className?: string }>,
): JSX.Element {
  const { openUserProfileDrawer } = useUserProfileDrawer();

  const handleUserClick = (e): void => {
    e.stopPropagation();
    openUserProfileDrawer(userId);
  };

  const handleKeyPress = (e): void => {
    if (e.key === 'Enter') {
      handleUserClick(e);
    }
  };

  return (
    <div onClick={handleUserClick} onKeyDown={handleKeyPress} className={className}>
      {children}
    </div>
  );
}
