import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetUsersByOrganizationQueryVariables = Types.Exact<{
  orgID: Types.Scalars['UUID']['input'];
}>;


export type GetUsersByOrganizationQuery = { __typename?: 'Query', getUsersByOrganization: Array<{ __typename?: 'User', id: string, email: string | null, title: string | null, firstName: string | null, lastName: string | null, fullName: string | null, profileImage: string | null, role: Types.UserRoles | null, createdAt: any | null, deletedAt: any | null, lastLoggedIn: any | null, createdObjectCount: number | null, deactivated: boolean | null, acknowledgements: { __typename?: 'Acknowledgements', termsOfUse: boolean } | null, organization: { __typename?: 'Organization', id: string, name: string | null } | null }> };


export const GetUsersByOrganizationDocument = gql`
    query GetUsersByOrganization($orgID: UUID!) {
  getUsersByOrganization(orgID: $orgID) {
    id
    email
    title
    firstName
    email
    lastName
    fullName
    profileImage
    role
    createdAt
    deletedAt
    acknowledgements {
      termsOfUse
    }
    lastLoggedIn
    createdObjectCount
    deactivated
    organization {
      id
      name
    }
  }
}
    `;

/**
 * __useGetUsersByOrganizationQuery__
 *
 * To run a query within a React component, call `useGetUsersByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByOrganizationQuery({
 *   variables: {
 *      orgID: // value for 'orgID'
 *   },
 * });
 */
export function useGetUsersByOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>(GetUsersByOrganizationDocument, options);
      }
export function useGetUsersByOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>(GetUsersByOrganizationDocument, options);
        }
export function useGetUsersByOrganizationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>(GetUsersByOrganizationDocument, options);
        }
export type GetUsersByOrganizationQueryHookResult = ReturnType<typeof useGetUsersByOrganizationQuery>;
export type GetUsersByOrganizationLazyQueryHookResult = ReturnType<typeof useGetUsersByOrganizationLazyQuery>;
export type GetUsersByOrganizationSuspenseQueryHookResult = ReturnType<typeof useGetUsersByOrganizationSuspenseQuery>;
export type GetUsersByOrganizationQueryResult = Apollo.QueryResult<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUsersByOrganizationQuery((req, res, ctx) => {
 *   const { orgID } = req.variables;
 *   return res(
 *     ctx.data({ getUsersByOrganization })
 *   )
 * })
 */
export const mockGetUsersByOrganizationQuery = (resolver: Parameters<typeof graphql.query<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>>[1]) =>
  graphql.query<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>(
    'GetUsersByOrganization',
    resolver
  )
