import type * as Types from '../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetPrismaticJwtTokenQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPrismaticJwtTokenQuery = { __typename?: 'Query', getPrismaticJwtToken: string | null };


export const GetPrismaticJwtTokenDocument = gql`
    query GetPrismaticJwtToken {
  getPrismaticJwtToken
}
    `;

/**
 * __useGetPrismaticJwtTokenQuery__
 *
 * To run a query within a React component, call `useGetPrismaticJwtTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrismaticJwtTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrismaticJwtTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrismaticJwtTokenQuery(baseOptions?: Apollo.QueryHookOptions<GetPrismaticJwtTokenQuery, GetPrismaticJwtTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrismaticJwtTokenQuery, GetPrismaticJwtTokenQueryVariables>(GetPrismaticJwtTokenDocument, options);
      }
export function useGetPrismaticJwtTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrismaticJwtTokenQuery, GetPrismaticJwtTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrismaticJwtTokenQuery, GetPrismaticJwtTokenQueryVariables>(GetPrismaticJwtTokenDocument, options);
        }
export function useGetPrismaticJwtTokenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPrismaticJwtTokenQuery, GetPrismaticJwtTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPrismaticJwtTokenQuery, GetPrismaticJwtTokenQueryVariables>(GetPrismaticJwtTokenDocument, options);
        }
export type GetPrismaticJwtTokenQueryHookResult = ReturnType<typeof useGetPrismaticJwtTokenQuery>;
export type GetPrismaticJwtTokenLazyQueryHookResult = ReturnType<typeof useGetPrismaticJwtTokenLazyQuery>;
export type GetPrismaticJwtTokenSuspenseQueryHookResult = ReturnType<typeof useGetPrismaticJwtTokenSuspenseQuery>;
export type GetPrismaticJwtTokenQueryResult = Apollo.QueryResult<GetPrismaticJwtTokenQuery, GetPrismaticJwtTokenQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPrismaticJwtTokenQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ getPrismaticJwtToken })
 *   )
 * })
 */
export const mockGetPrismaticJwtTokenQuery = (resolver: Parameters<typeof graphql.query<GetPrismaticJwtTokenQuery, GetPrismaticJwtTokenQueryVariables>>[1]) =>
  graphql.query<GetPrismaticJwtTokenQuery, GetPrismaticJwtTokenQueryVariables>(
    'GetPrismaticJwtToken',
    resolver
  )
