import HeaderName from '@/components/common/Tasks/TaskSection/HeaderName';
import { type ChangeEvent, createRef, type Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MegaModalEmptyList from '../../MegaModal/MegaModalEmptyList/MegaModalEmptyList';
import MegaModalHeader from '../../MegaModal/MegaModalHeader/MegaModalHeader';

import LoadingSpinner from '@/components/common/Tasks/TaskSection/LoadingSpinner';
import { Input } from '@/components/form/Input';
import MagnifyingGlassIcon from '@/components/Icons/MagnifyingGlassIcon';
import type { StepContentProps } from '@/components/Stepper/Stepper';
import { useDebounce } from '@/hooks/useDebounce';
import metrics from '@/util/metrics';
import cx from 'classnames';
import ObjectListRow from '../../AddNewObjectModal/ObjectListRow/ObjectListRow';
import MegaModal from '../../MegaModal/MegaModal';
import { useGetModularObjectNameQuery } from './getModularObjectName.generated';
import { useGetModularObjectsForDependencySelectionQuery } from './getModularObjectsForDependencySelection.generated';

interface DependencySelectionScreenProps extends StepContentProps {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  preselectedId?: string;
  templateId?: string;
  templateName?: string;
  currentObjectId: string;
  isBlockingDependency: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showModal: (content: JSX.Element, options: any) => void;
}

export default function DependencySelectionScreen ({
  incrementStep,
  selectedIds,
  setSelectedIds,
  preselectedId,
  templateId,
  templateName,
  currentObjectId,
  isBlockingDependency,
}: Readonly<DependencySelectionScreenProps>) {
  const inputRef: Ref<HTMLInputElement> = createRef();
  const tableRef = useRef<HTMLDivElement>(null);
  const { data: currentObject } = useGetModularObjectNameQuery({
    variables: { id: currentObjectId },
    skip: !currentObjectId,
  });
  const { data, loading: isLoadingResults } = useGetModularObjectsForDependencySelectionQuery({
    variables: {
      input: { id: currentObjectId },
    },
    fetchPolicy: 'network-only',
  });

  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [hasScrollbar, setHasScrollbar] = useState(false);

  useEffect(() => {
    const tableWrapper = tableRef.current;
    if (tableWrapper?.scrollHeight > tableWrapper?.clientHeight) {
      setHasScrollbar(true);
    } else {
      setHasScrollbar(false);
    }
  }, []);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const handleIncrementStep = useCallback(() => {
    metrics.track('dependency modal - object list', {
      templateId,
      isBlockingDependency,
      amountSelected: selectedIds.length,
      template: templateName,
    });
    incrementStep();
  }, [incrementStep, isBlockingDependency, selectedIds.length, templateId, templateName]);

  const resultList = useMemo(() => {
    if (!data?.getDependencyCandidatesForObjectId) return [];

    if (preselectedId) {
      return data.getDependencyCandidatesForObjectId.sort((a, b) =>
        (a.id === preselectedId) ? -1 : b.id === preselectedId ? 1 : 0
      );
    }

    return data.getDependencyCandidatesForObjectId;
  }, [data?.getDependencyCandidatesForObjectId, preselectedId]);

  const filteredResultList = useMemo(() => {
    return resultList.filter((result) => {
      return result.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
        result.parent?.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
    });
  }, [resultList, debouncedSearchTerm]);

  const modalTitleCTA = isBlockingDependency ? 'Add objects blocked by ' : 'Add objects blocking ';
  const modalTitle = modalTitleCTA + currentObject?.getModularObjectByID.name;

  const handleCheckAll = useCallback((event): void => {
    const { checked } = event.target;
    if (checked) {
      const allObjectIds = filteredResultList.map((item) => item.id);
      setSelectedIds(allObjectIds);
    } else {
      setSelectedIds([]);
    }
  }, [filteredResultList, setSelectedIds]);

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  // Keeps a flicker of "no results" content showing while the data fetch happens
  if (isLoadingResults) {
    return (
      <MegaModal className='flex justify-center items-center h-[551px] w-[1090px]'>
        <LoadingSpinner isLoading={true} />
      </MegaModal>
    );
  }

  return (
    <>
      <MegaModalHeader title={modalTitle} />
      <div className='flex flex-col min-h-0 gap-[24px] grow'>
        <div className='flex gap-4 pb-1 border-b border-black border-solid shrink'>
          <div className='flex items-center'>
            <MagnifyingGlassIcon />
          </div>
          <div className='grow'>
            <Input
              className='flex-1 text-lg placeholder-gray-400 text-black outline-none'
              ref={inputRef}
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder='Search'
            />
          </div>
        </div>
        <div className='flex flex-col gap-2 min-h-0 grow h-[551px] w-[1090px]'>
          <div
            className={cx('flex flex-row', {
              'pr-5': hasScrollbar,
            })}
          >
            {!filteredResultList.length
              ? <div className='text-xs tracking-widest text-gray-400 uppercase'>Results</div>
              : (
                <>
                  <div className='flex flex-row items-center min-w-0 flex-[2] gap-[8px]'>
                    <div className='flex flex-[2] gap-[8px]'>
                      <HeaderName
                        className='flex shrink'
                        text={
                          <input
                            type='checkbox'
                            onChange={handleCheckAll}
                            className='input-checkbox custom-checkbox checked:bg-primary'
                          />
                        }
                      />
                      <div className='flex-1 font-bold leading-[16px] pl-[3px]'>Name</div>
                    </div>
                    <div className='flex-1 font-bold leading-[16px]'>Object Type</div>
                    <div className='font-bold flex-[2] leading-[16px]'>Parent</div>
                  </div>
                  <div className='flex flex-1 min-w-0 gap-[8px]'>
                    <div className='flex-1 font-bold leading-[16px]'>Permission</div>
                    <div className='font-bold flex-[2] leading-[16px]'>Owner</div>
                    <div className='flex-1 font-bold leading-[16px]'>Company</div>
                  </div>
                </>
              )}
          </div>
          {!filteredResultList?.length ?
            (
              <MegaModalEmptyList
                message='No objects match your search'
                showButton={false}
              />
            )
            : (
              <div className='flex overflow-y-auto flex-col flex-1' ref={tableRef} data-testid='add-new-object-list'>
                <div className='flex flex-col gap-[16px]'>
                  {filteredResultList.map((result) => {
                    const isSelected = selectedIds.includes(result.id);
                    return (
                      <ObjectListRow
                        key={`add-new-object-list-row-${result.id}`}
                        object={result}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const newIds = e.target.checked
                            ? [...selectedIds, e.target.value]
                            : [...selectedIds.filter(id => id !== e.target.value)];

                          setSelectedIds(newIds);
                        }}
                        isSelected={isSelected}
                      />
                    );
                  })}
                </div>
              </div>
            )}
        </div>
      </div>
      <div className='flex gap-8'>
        {/* ENG-5000: Hide until start/target date are available on object creation again */}
        {
          /* <button
          className='w-full btn-ghost h-[36px]'
          onClick={() => {
            showModal(
              <CreateNewObjectModal
                createdFromId={currentObjectId}
                createdFromTemplateId={templateId}
                isBlockingDependency={isBlockingDependency}
                isDependency
              />,
              {
                isMegaModal: true,
                showCloseIcon: false,
              },
            );
          }}
        >
          new object
        </button> */
        }
        <button
          disabled={selectedIds?.length === 0}
          onClick={handleIncrementStep}
          className='w-full btn-primary h-[36px]'
        >
          continue
        </button>
      </div>
    </>
  );
}
