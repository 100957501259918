import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type CreateModularObjectMutationVariables = Types.Exact<{
  input: Types.CreateModularObjectsInput;
}>;


export type CreateModularObjectMutation = { __typename?: 'Mutation', createModularObjects: Array<{ __typename?: 'ModularObject', id: string }> };

export type CreateModularObjectCardFragmentFragment = { __typename?: 'ModularObject', id: string };

export const CreateModularObjectCardFragmentFragmentDoc = gql`
    fragment CreateModularObjectCardFragment on ModularObject {
  id
}
    `;
export const CreateModularObjectDocument = gql`
    mutation CreateModularObject($input: CreateModularObjectsInput!) {
  createModularObjects(input: $input) {
    ...CreateModularObjectCardFragment
  }
}
    ${CreateModularObjectCardFragmentFragmentDoc}`;
export type CreateModularObjectMutationFn = Apollo.MutationFunction<CreateModularObjectMutation, CreateModularObjectMutationVariables>;

/**
 * __useCreateModularObjectMutation__
 *
 * To run a mutation, you first call `useCreateModularObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModularObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModularObjectMutation, { data, loading, error }] = useCreateModularObjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModularObjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateModularObjectMutation, CreateModularObjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModularObjectMutation, CreateModularObjectMutationVariables>(CreateModularObjectDocument, options);
      }
export type CreateModularObjectMutationHookResult = ReturnType<typeof useCreateModularObjectMutation>;
export type CreateModularObjectMutationResult = Apollo.MutationResult<CreateModularObjectMutation>;
export type CreateModularObjectMutationOptions = Apollo.BaseMutationOptions<CreateModularObjectMutation, CreateModularObjectMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateModularObjectMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ createModularObjects })
 *   )
 * })
 */
export const mockCreateModularObjectMutation = (resolver: Parameters<typeof graphql.mutation<CreateModularObjectMutation, CreateModularObjectMutationVariables>>[1]) =>
  graphql.mutation<CreateModularObjectMutation, CreateModularObjectMutationVariables>(
    'CreateModularObject',
    resolver
  )
