import { type Editor } from '@tiptap/react';
import cx from 'classnames';
import type { ButtonHTMLAttributes, DetailedHTMLProps, PropsWithChildren } from 'react';

interface MenuItemProps {
  onClick?: () => void;
  editor: Editor;
}

const MenuItem = (
  { children, onClick, editor, ...rest }: PropsWithChildren<
    MenuItemProps & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
  >,
) => {
  if (!editor) {
    return null;
  }

  const shouldDisableButtons = !editor.isEditable || editor.isEmpty;
  return (
    <button
      disabled={shouldDisableButtons}
      type='button'
      className={cx('p-1 rounded transition-all', { 'hover:bg-neutral-200': !shouldDisableButtons })}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};

const SeperatorLine = () => {
  return <div className='border-r border-black w-[1px]' />;
};

interface ToolbarProps {
  editor: Editor;
}

export const Toolbar = ({ editor }: ToolbarProps) => {
  return (
    <div className='flex gap-2 py-1 mb-2'>
      <MenuItem
        editor={editor}
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <i className='fa-kit fa-solid fa-bold' />
      </MenuItem>
      <MenuItem editor={editor} onClick={() => editor.chain().focus().toggleItalic().run()}>
        <i className='fa-kit fa-solid fa-italic' />
      </MenuItem>
      <MenuItem editor={editor} onClick={() => editor.chain().focus().toggleStrike().run()}>
        <i className='fa-solid fa-strikethrough' />
      </MenuItem>
      <SeperatorLine />
      <MenuItem editor={editor} onClick={() => editor.chain().focus().toggleBulletList().run()}>
        <i className='fa-solid fa-list-ul' />
      </MenuItem>
      <MenuItem editor={editor} onClick={() => editor.chain().focus().toggleOrderedList().run()}>
        <i className='fa-solid fa-list-ol' />
      </MenuItem>
    </div>
  );
};
