import { API_URL } from '@/util/constants';
import { env } from '@/util/env.utils';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { getCookie, hasCookie } from 'cookies-next';

export const restApi = createApi({
  reducerPath: 'restApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL()}/rest/v1/`,
    credentials: 'include',
    mode: 'cors',
    cache: 'no-cache',
    prepareHeaders: (headers) => {
      const isImpersonating = localStorage.getItem('impersonation');
      if (isImpersonating) {
        headers.set('x-impersonate', localStorage.getItem('impersonation'));
      }

      if (hasCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'))) {
        const jwt = getCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'));
        headers.set('Authorization', `Bearer ${jwt.toString()}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
