import type { ApprovalRequestChange } from '@/__generated__/types';
import ApprovalChange from '@/components/tables/Approvals/ApprovalRow/ApprovalChange';

interface ApprovalChangesProps {
  changes: ApprovalRequestChange[];
}

export default function ApprovalChanges ({ changes }: ApprovalChangesProps): JSX.Element {
  return (
    <>
      {changes.map((change, idx) => <ApprovalChange key={`approval-change-${idx}`} change={change} />)}
    </>
  );
}
