import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface HeaderButtonProps {
  name?: string;
  icon: IconProp;
  onClick: () => void;
  testId?: string;
}

export function HeaderButton ({ name, icon, onClick, testId }: Readonly<HeaderButtonProps>): JSX.Element {
  return (
    <button
      title={name}
      type='button'
      onClick={onClick}
      className='flex relative justify-center items-center transition-colors text-neutral-500 rounded-[2px] h-[24px] w-[24px] hover:text-neutral-700 hover:bg-gray-90'
      data-testid={testId}
    >
      <FontAwesomeIcon icon={icon} className='text-[16px]' />
    </button>
  );
}
