import { Listbox, Transition } from '@headlessui/react';
import cx from 'classnames';
import { forwardRef, Fragment } from 'react';
import { v5 as uuidv5 } from 'uuid';
const NAMESPACE = '04067169-2E38-4D9D-A99F-FC06F2C52BCD';

export interface Option {
  label: string;
  value: string;
  disabled?: boolean;
}

interface SelectBoxOptionsProps {
  options: Option[];
  style: React.CSSProperties;
  optionClassName?: string;
  selectedItems?: string[];
}

const SelectBoxOptions = forwardRef(
  function SelectBoxOptions (props: SelectBoxOptionsProps, ref: React.Ref<HTMLUListElement>) {
    const { options, style, optionClassName, ...otherProps } = props;
    return (
      <Transition
        leave='transition ease-in duration-100'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        className='listbox-option-list'
        ref={ref}
        style={style}
        {...otherProps}
      >
        <Listbox.Options className='max-h-60 leading-6 sm:leading-5 md:py-2 focus:outline-none'>
          {options.map((option) => (
            <Listbox.Option
              key={uuidv5(option.value, NAMESPACE)}
              value={option.value}
              as={Fragment}
              disabled={option.disabled}
            >
              {({ active, disabled, selected }) => (
                <li
                  className={cx('listbox-option', optionClassName, {
                    'bg-gray-300': selected && !active,
                    'active-listbox-option': active || (selected && active),
                    'opacity-50': disabled,
                  })}
                >
                  {option.label}
                </li>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    );
  },
);

export default SelectBoxOptions;
