import { Loader } from '@/components/loader';

export default function LoadingSpinner ({ isLoading }: { isLoading: boolean }): JSX.Element {
  return isLoading ?
    (
      <div className='flex justify-center item-center'>
        <Loader className='m-auto w-20 h-20' />
      </div>
    ) :
    null;
}
