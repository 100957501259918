import { type ComponentProps } from 'react';

export default function VehicleIcon ({ ...otherProps }: ComponentProps<'svg'>): JSX.Element {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 35.869 36.662' {...otherProps}>
      <path
        data-name='Path 16557'
        fill='currentColor'
        d='M.519 25.788 17.934.579v35.629zM17.934 0 0 25.87l17.934 10.792L35.869 25.87z'
      />
    </svg>
  );
}
