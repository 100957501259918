import cx from 'classnames';
import { forwardRef } from 'react';
export interface TextInputProps extends React.ComponentProps<'input'> {
  type?: string;
  name?: string;
  className?: string;
  containerClassName?: string;
  value?: string;
  prefix?: string;
  suffix?: string;
  disabled?: boolean;
}

const prefixClassList =
  'before:content-[attr(data-content-prefix)] before:mr-1 before:text-xs before:font-effra before:opacity-60 before:tracking-[2.4px] before:uppercase before:align-bottom';

const suffixClassList =
  'after:content-[attr(data-content-suffix)] after:ml-1 after:text-xs after:font-effra after:opacity-60 after:tracking-[2.4px] after:uppercase';

export const Input = forwardRef<HTMLInputElement, TextInputProps>(
  ({ name, type, className, value, prefix, suffix, disabled, containerClassName, ...otherProps }, ref) => {
    return (
      <div
        className={cx(
          'flex items-end',
          containerClassName,
          {
            [prefixClassList]: prefix,
            [suffixClassList]: suffix,
          },
        )}
        data-content-prefix={prefix}
        data-content-suffix={suffix}
      >
        <input
          ref={ref}
          className={className}
          type={type}
          autoComplete='off'
          id={name}
          value={value}
          disabled={Boolean(disabled)}
          {...otherProps}
        />
      </div>
    );
  },
);

Input.displayName = 'Input';
