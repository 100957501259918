interface ErrorIconProps {
  fill?: string;
  props?: React.SVGProps<SVGSVGElement>;
}

function ErrorIcon ({ fill = '#FF9439', props }: ErrorIconProps): JSX.Element {
  return (
    <svg width='24' height='21' viewBox='0 0 24 21' {...props} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.1471 16.508C10.1451 16.3278 10.1806 16.1491 10.2513 15.9833C10.3221 15.8175 10.4265 15.6683 10.5581 15.545C10.6875 15.4153 10.8419 15.313 11.0118 15.2443C11.1817 15.1755 11.3638 15.1418 11.5471 15.145C11.911 15.1412 12.2622 15.2793 12.5261 15.53C12.6605 15.6474 12.7678 15.7926 12.8405 15.9557C12.9132 16.1187 12.9496 16.2956 12.9471 16.474C12.9492 16.6544 12.9138 16.8333 12.843 16.9993C12.7722 17.1652 12.6677 17.3147 12.5361 17.438C12.4067 17.5677 12.2525 17.6699 12.0828 17.7386C11.913 17.8073 11.7312 17.8412 11.5481 17.838C11.3648 17.8389 11.1833 17.8032 11.0141 17.733C10.8495 17.6667 10.6982 17.5715 10.5671 17.452C10.4375 17.3347 10.3334 17.1921 10.2611 17.033C10.1852 16.8685 10.1456 16.6892 10.1471 16.508ZM12.5981 14.154H10.4931V9.33203H12.5931L12.5981 14.154Z'
        fill={fill}
      />
      <path
        d='M23.094 20.8008H0L11.548 0.800781L23.094 20.8008ZM3.97 18.5088H19.124L11.548 5.38478L3.97 18.5088Z'
        fill={fill}
      />
    </svg>
  );
}

export default function Errors ({ error }: { error: string }): JSX.Element {
  return error ?
    (
      <div className='flex gap-3 items-center'>
        <ErrorIcon />
        <p className='font-effra text-[14px]'>{error}</p>
      </div>
    ) :
    null;
}
