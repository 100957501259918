import type { Team } from '@/__generated__/types';
import { useTeamsForTabsQuery } from '@/components/SideNavigation/TabSections/Teams/TeamsForTabs.generated';
import { useLoggedInUserRole } from '@/hooks/useLoggedInUser';
import { useModal } from '@/hooks/useModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';
import { useCallback, useMemo } from 'react';
import type { UserByIdFragment } from '../getUserById.generated';
import UserTeamsModal from './UserTeamsModal';

interface UserProfileTeamsProps {
  user: UserByIdFragment;
}

export default function UserProfileTeams ({ user }: UserProfileTeamsProps): JSX.Element {
  const { showModal, closeModal } = useModal();
  const teams = user?.teams;
  const teamNames = useMemo(() => teams?.map((team) => team.name), [teams]);

  const { data: orgTeamsData } = useTeamsForTabsQuery();
  const orgTeams = orgTeamsData?.teams?.edges ?? [];

  const { isAdmin, isManager, isSuper } = useLoggedInUserRole();
  const canManageTeams = isAdmin || isManager || isSuper;

  const handleOpenTeamsModal = useCallback((): void => {
    showModal(
      <UserTeamsModal
        user={user}
        orgTeams={orgTeams as Team[]}
        closeModal={closeModal}
      />,
      {
        className: 'max-w-[514px] !overflow-y-visible',
      },
    );
  }, [user, orgTeams, showModal, closeModal]);

  return (
    <button
      onClick={canManageTeams ? handleOpenTeamsModal : null}
      className={cx('flex flex-col gap-[4px] group/teams cursor', {
        'cursor-pointer': canManageTeams,
        'cursor-default': !canManageTeams,
      })}
    >
      <div className='flex gap-2 effra-10 text-gray-80'>
        <span>Teams</span>
        {canManageTeams && (
          <FontAwesomeIcon
            icon={faPen}
            className='text-white opacity-0 transition-opacity text-[10px] group-hover/teams:opacity-100'
          />
        )}
      </div>
      <div className='flex flex-wrap gap-1 effra-14 py-[8px]'>
        {!teamNames?.length && <span className='opacity-50'>{canManageTeams ? 'Add to team' : '—'}</span>}
        {teamNames?.map((teamName, i) => <span key={teamName}>{teamName}{(i !== teamNames.length - 1) && ','}</span>)}
      </div>
    </button>
  );
}
