import type { User } from '@/__generated__/types';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { toCalendarDate } from '@/util/toCalendarDate';
import TimelinePoint from './TimelinePoint';
import UserName from './UserName';

interface TimelineEventHeaderProps {
  headerText: string;
  onDate: string;
  byUser: User;
  icon?: JSX.Element;
  color?: string;
  shouldShowTopLine?: boolean;
}

export default function TimelineEventHeader ({
  headerText,
  onDate,
  byUser,
  icon,
  color,
  shouldShowTopLine,
}: TimelineEventHeaderProps): JSX.Element {
  const loggedInUser = useLoggedInUser();
  const displayDate = toCalendarDate(onDate);

  return (
    <TimelinePoint
      icon={icon}
      color={color}
      topLine={shouldShowTopLine}
      noDot
    >
      <div className='flex gap-10 justify-between grow'>
        {/* Header Text */}
        <div className='first-letter:uppercase'>
          <span className='font-bold text-black'>{headerText}</span>
          {' by '}
          <UserName user={byUser} currentUser={loggedInUser} />
          {`, ${displayDate}`}
        </div>
      </div>
    </TimelinePoint>
  );
}
