import { addToastError } from '@/components/Toast/utils';
import { type FileAndSigned, uploadFile } from '@/util/requests.functions';

export async function uploadNewFile (file, tags = ['file']): Promise<FileAndSigned> {
  try {
    return await uploadFile(file, tags);
  } catch (err) {
    addToastError('There was an issue trying to upload the file. Please try again later.');
    console.error(err);
  }
}
