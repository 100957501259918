import { Button } from '@/components/Button/Button';
import { datadogLogs } from '@datadog/browser-logs';
import Image from 'next/legacy/image';
import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error): { hasError: true } {
    datadogLogs.logger.error('Error Boundary getDerivedState', { error });
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo): void {
    datadogLogs.logger.error('Error Boundary didCatch', { error, errorInfo });
  }

  render(): React.ReactNode {
    // @ts-expect-error - hasError is defined on state
    // eslint-disable-next-line react/prop-types
    if (this.state.hasError) {
      return (
        <div className='overflow-hidden w-full h-full select-none'>
          {/* Wordmark */}
          <div className='fixed top-0 left-0 z-10 px-5 pt-8 lg:px-8'>
            <Image
              src='/images/wordmark-white.svg'
              className='absolute top-0 left-0'
              alt='Logo'
              width={140}
              height={35}
            />
          </div>
          {/* Background */}
          <div className='top-0 left-0 fixed overflow-hidden w-full h-full bg-gradient-to-r from-[#461774] to-[#7B25C6]'>
            <Image
              layout='fill'
              src='/images/blackness.jpeg'
              alt='500'
              objectFit='cover'
              objectPosition='center'
              className='opacity-[57%]'
            />
          </div>
          {/* Content */}
          <div className='flex relative flex-col justify-center items-center h-full lg:flex-row lg:gap-20'>
            <div className='font-black text-primary-dark text-[20vh] font-sculpin tracking-[-6.25px] xl:text-[30vh]'>
              Oops
            </div>
            <div className='flex flex-col gap-8 max-w-[33rem]'>
              <div className='font-bold text-white font-leMonde text-[35px]'>You found a little bug.</div>
              <div className='text-white font-effra effra-lg'>
                Sorry about that. Go back and try refreshing the page. If the issue persists, please let us know by
                contacting&nbsp;
                <a className='text-secondary' href='mailto:support@integrate.co'>
                  support@integrate.co
                </a>
              </div>
              <Button
                onClick={() => {
                  if (window) {
                    window.location.reload();
                  }
                }}
                className='py-5 px-8 text-center text-white bg-tertiary effra-xs'
              >
                Go Back
              </Button>
            </div>
          </div>
        </div>
      );
    }

    // @ts-expect-error - children is always defined
    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}
