import type { ModularObject } from '@/__generated__/types';
import ToggleChevronButton from '@/components/Button/ToggleChevronButton';
import SubTaskPill from '@/components/Pill/SubTaskPill/SubTaskPill';
import TaskPill from '@/components/Pill/TaskPill/TaskPill';
import { TaskPillColor } from '@/models/task.model';
import { useAppSelector } from '@/state/hooks';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { selectRootState } from '@/state/selectors';
import getIcon from '@/util/getIcon';
import { getIsOwner, getSameOrg } from '@/util/lookup.functions';
import cx from 'classnames';
import { useMemo, useState } from 'react';
import { dehydrateModularObject } from '../../../ModularObject/utils';
import { useListActionContext } from '../ListActions.context';
import { type TaskListTask, useTaskListContext } from '../TaskList.context';
import TaskListRowLine, { ParentColor } from './TaskListRowLine';

export interface TaskListRowProps {
  task: TaskListTask;
  openTask: (task: ModularObject) => void;
}

export default function TaskListRow ({ task, openTask }: Readonly<TaskListRowProps>): JSX.Element {
  const { hasSubtasks } = useTaskListContext();
  const { selectObject, unselectObject, selectedObjects } = useListActionContext();
  const state = useAppSelector(selectRootState);
  const {
    session: { user },
    users: userState,
  } = state;
  const { openViewObjectCardDrawer } = useObjectCardContext();
  const [shouldShowSubtasks, setShouldShowSubtasks] = useState<boolean>(true);
  const { isPending } = task;

  const taskStatus = task?.status ?? 'TO_DO';

  const openCard = (_task): void => {
    openTask(_task);
  };

  const currentParent = {
    ...task?.parent,
    ...dehydrateModularObject(task?.parent?.data, true),
  };
  const openParent = (_currentParent): void => {
    openViewObjectCardDrawer({ modularObjectId: _currentParent?.id });
  };

  const handleMilestoneListCheckboxChange = (event, task): void => {
    if (event.target.checked) {
      selectObject(task, event);
    } else {
      unselectObject(task, 'tasks');
    }
  };

  const isChecked = Boolean(selectedObjects?.tasks?.[task?.id]);

  const getParentColor = (parent) => {
    let parentColor = null;
    if (!getIsOwner(parent, user) && !getSameOrg(parent, user, userState)) {
      parentColor = ParentColor.Teal;
    }
    if (!getSameOrg(parent, user, userState) || getIsOwner(parent, user)) {
      parentColor = ParentColor.Purple;
    }

    return parentColor;
  };

  const subTasks = useMemo(() => {
    return task?.tasks?.edges.map(({ node }) => node) ?? [];
  }, [task?.tasks?.edges]);

  return (
    <div
      className={cx('flex flex-col justify-center flex-1', {
        'pl-[23px]': hasSubtasks && !subTasks?.length,
        'opacity-60': isPending,
      })}
    >
      <div className='flex flex-1'>
        {!!subTasks?.length && (
          <ToggleChevronButton
            className='pl-[8px]'
            expanded={shouldShowSubtasks}
            onClick={_ => {
              setShouldShowSubtasks(!shouldShowSubtasks);
            }}
          />
        )}
        <TaskListRowLine
          assigneeId={task?.assigneeId}
          ownerId={task?.ownerId}
          onChange={handleMilestoneListCheckboxChange}
          onClick={_ => {
            openCard(task);
          }}
          onKeyDown={_ => {
            openCard(task);
          }}
          onParentClick={_ => {
            openParent(currentParent);
          }}
          id={task?.id}
          isChecked={isChecked}
          name={task?.name}
          parentColor={getParentColor(currentParent)}
          parentIcon={getIcon(currentParent?.name?.toLowerCase())}
          parentName={currentParent?.name}
          pill={
            <TaskPill
              type={task?.template?.name}
              data={{
                assignee: task?.assigneeId,
                isSameOrg: getSameOrg(task, user, userState),
              }}
            />
          }
          startDate={task?.startDate}
          status={taskStatus}
          targetDate={task?.targetDate}
          task={task}
        />
      </div>
      {!!subTasks?.length && (
        <div
          className={cx('flex flex-col transition-all overflow-hidden w-full', {
            'h-0': !shouldShowSubtasks,
            'h-auto': shouldShowSubtasks,
          })}
        >
          {subTasks.map((subTask) => {
            const isChecked = Boolean(selectedObjects?.tasks?.[subTask?.id]);
            const taskStatus = subTask?.status ?? 'TO_DO';

            return (
              <div className='flex flex-1' key={subTask?.id}>
                <TaskListRowLine
                  className='w-full pl-[41px]' // Align checkboxes with parent
                  assigneeId={subTask?.assigneeId}
                  ownerId={subTask?.ownerId}
                  onChange={handleMilestoneListCheckboxChange}
                  onClick={_ => {
                    openCard(subTask);
                  }}
                  onKeyDown={_ => {
                    openCard(subTask);
                  }}
                  onParentClick={_ => {
                    openParent(task);
                  }}
                  id={subTask?.id}
                  isChecked={isChecked}
                  name={subTask?.name}
                  parentColor={getParentColor(task)}
                  parentIcon={getIcon(task?.name?.toLowerCase())}
                  parentName={task?.name}
                  pill={
                    <SubTaskPill
                      color={TaskPillColor.Black}
                      type={subTask?.template?.subType}
                    />
                  }
                  startDate={subTask?.startDate}
                  status={taskStatus}
                  targetDate={subTask?.targetDate}
                  task={subTask}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
