import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type DeleteFilterMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type DeleteFilterMutation = { __typename?: 'Mutation', deleteFilter: { __typename?: 'Filter', id: string, name: string } };


export const DeleteFilterDocument = gql`
    mutation DeleteFilter($id: UUID!) {
  deleteFilter(id: $id) {
    id
    name
  }
}
    `;
export type DeleteFilterMutationFn = Apollo.MutationFunction<DeleteFilterMutation, DeleteFilterMutationVariables>;

/**
 * __useDeleteFilterMutation__
 *
 * To run a mutation, you first call `useDeleteFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFilterMutation, { data, loading, error }] = useDeleteFilterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFilterMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFilterMutation, DeleteFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFilterMutation, DeleteFilterMutationVariables>(DeleteFilterDocument, options);
      }
export type DeleteFilterMutationHookResult = ReturnType<typeof useDeleteFilterMutation>;
export type DeleteFilterMutationResult = Apollo.MutationResult<DeleteFilterMutation>;
export type DeleteFilterMutationOptions = Apollo.BaseMutationOptions<DeleteFilterMutation, DeleteFilterMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteFilterMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ deleteFilter })
 *   )
 * })
 */
export const mockDeleteFilterMutation = (resolver: Parameters<typeof graphql.mutation<DeleteFilterMutation, DeleteFilterMutationVariables>>[1]) =>
  graphql.mutation<DeleteFilterMutation, DeleteFilterMutationVariables>(
    'DeleteFilter',
    resolver
  )
