import type { User } from '@/__generated__/types';
import { ProfileImage } from '@/components/ProfileImage/ProfileImage';
import cx from 'classnames';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { useCollabDiscoveryContext } from './CollabDiscovery.context';
import CollaboratorRoleDropdown, { StaticRole } from './CollaboratorRoleDropDown';
import { DropdownOption } from './hooks/useDropdownOptionMap';
import { useDeleteCollaboratorMutation } from './mutations/deleteShares.generated';
import { useUpdateCollaboratorsMutation } from './mutations/updateShares.generated';

import { GetModularObjectByIdDocument } from '@/components/modals/ObjectSelectionModal/getModularObjectById.generated';
import { GetTopLevelModularObjectsDocument } from '@/components/Schedule/queries/getTopLevelModularObjects.generated';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { Button } from '@/designSystemComponents/Button';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useAppDispatch } from '@/state/hooks';
import { closeModularObjectModal } from '@/state/slices/session.slice';
import Image from 'next/image';
import { useState } from 'react';
import { useScrollWithShadow } from './hooks/useScrollWithShadow';
import { useAddNewCollaboratorsMutation } from './mutations/addShares.generated';
import { useUpdateModularObjectMutation } from './mutations/updateModularObject.generated';

function Collaborator ({ user }: { user: User }) {
  const loggedInUser = useLoggedInUser();

  const isInternal = user?.organization?.id === loggedInUser?.organizationId
    || user?.organizationId === loggedInUser?.organizationId;

  const isDeleted = Boolean(user?.deletedAt);

  return (
    <span
      className={cx('flex gap-2 items-center', {
        'opacity-50': isDeleted,
      })}
    >
      <ProfileImage user={user} size='collaborator' />
      <p
        className={cx('effra-14', {
          'text-gray-60': isDeleted,
          'text-primary': isInternal && !isDeleted,
          'text-tertiary': !isInternal && !isDeleted,
        })}
      >
        {user?.fullName?.trim()}
        {isDeleted && <span className='effra-12 text-gray-60'>(Removed User)</span>}
      </p>
    </span>
  );
}

interface ConfirmCollaboratorRemovalProps {
  shareUpdate: {
    id: string;
    role: DropdownOption;
    externalType: string;
    externalId: string;
    userId: string;
    user: User;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUserToRemove: (value: any) => void;
  currentUserId: string;
}

function ConfirmCollaboratorRemoval ({
  shareUpdate,
  setUserToRemove,
  currentUserId,
}: ConfirmCollaboratorRemovalProps) {
  const dispatch = useAppDispatch();
  const [removeCollaborators] = useDeleteCollaboratorMutation();

  const isCurrentUserBeingRemoved = shareUpdate.userId === currentUserId;

  const handleConfirm = async () => {
    try {
      setUserToRemove(null);
      await removeCollaborators({
        variables: {
          input: [shareUpdate],
        },
        onQueryUpdated: (observableQuery) => {
          void observableQuery.refetch();
          if (isCurrentUserBeingRemoved) dispatch(closeModularObjectModal());
        },
        refetchQueries: isCurrentUserBeingRemoved
          ? [GetTopLevelModularObjectsDocument, GetModularObjectByIdDocument]
          : [],
      });
      addToastSuccess('Collaborator removed');
    } catch (error) {
      console.error(error);
      addToastError('Failed to remove collaborator');
    }
  };

  return (
    <div className='flex flex-col gap-4 justify-center items-center h-[230px]'>
      <p className='effra-14'>Are you sure you want to remove this collaborator?</p>
      <div className='flex gap-4'>
        <Button
          figmaProps={{
            size: 'small',
            style: 'outline',
          }}
          onClick={() => {
            setUserToRemove(null);
          }}
        >
          Cancel
        </Button>
        <Button figmaProps={{ size: 'small', style: 'fill' }} onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
}

interface ConfirmOwnerShipTransferProps {
  shareUpdate: {
    id: string;
    role: DropdownOption;
    externalType: string;
    externalId: string;
    userId: string;
    user: User;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setOwnerToTransfer: (value: any) => void;
  objectOwner: User;
}

function ConfirmOwnerShipTransfer ({ shareUpdate, setOwnerToTransfer, objectOwner }: ConfirmOwnerShipTransferProps) {
  const [addNewCollaborators] = useAddNewCollaboratorsMutation();
  const [updateModularObject] = useUpdateModularObjectMutation({
    onQueryUpdated: (observableQuery) => {
      void observableQuery.refetch();
    },
  });

  const handleConfirm = async () => {
    try {
      setOwnerToTransfer(null);
      await addNewCollaborators({
        variables: {
          input: [{
            ...shareUpdate,
            id: uuidv5(`ownershipTransfer-${objectOwner?.id}`, uuidv4()),
            role: DropdownOption.Editor,
            user: {
              id: objectOwner?.id,
              firstName: objectOwner?.firstName,
              lastName: objectOwner?.lastName,
              email: objectOwner?.email,
              organizationId: objectOwner?.organizationId,
              profileImage: objectOwner?.profileImage,
            },
            userId: objectOwner?.id,
          }],
        },
      });
      await updateModularObject({
        variables: {
          input: {
            diffs: [{
              diffType: 'updated',
              externalID: shareUpdate.externalId,
              externalType: 'modular_object',
              diff: {
                displayNames: { ownerId: 'ownerId' },
                to: { ownerId: shareUpdate.userId },
              },
              fromMigration: false,
            }],
          },
        },
      });
      addToastSuccess('Ownership transferred');
    } catch (error) {
      console.error(error);
      addToastError('Failed to transfer ownership');
    }
  };

  return (
    <div className='flex flex-col gap-4 justify-center items-center h-[230px]'>
      <p className='effra-14'>Are you sure you want to transfer ownership of this object?</p>
      <div className='flex gap-4'>
        <Button
          figmaProps={{
            size: 'small',
            style: 'outline',
          }}
          onClick={() => {
            setOwnerToTransfer(null);
          }}
        >
          Cancel
        </Button>
        <Button figmaProps={{ size: 'small', style: 'fill' }} onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
}

interface ConfirmExternalUserSubmissionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setExternalUserAddition: (value: any) => void;
  submitExternalUsers: () => void;
}

function ConfirmExternalUserSubmission (
  { setExternalUserAddition, submitExternalUsers }: ConfirmExternalUserSubmissionProps,
) {
  function handleConfirmSubmission () {
    setExternalUserAddition(false);
    submitExternalUsers();
  }

  return (
    <div className='flex flex-col gap-4 justify-center items-center h-[230px]'>
      <p className='flex gap-1 text-center effra-14'>
        <i className='fa-sharp fa-solid fa-triangle-exclamation text-warning' />
        <span>You are attempting to share this object with someone outside your company.</span>
      </p>
      <div className='flex gap-4'>
        <Button
          figmaProps={{
            size: 'small',
            style: 'outline',
          }}
          onClick={() => {
            setExternalUserAddition(false);
          }}
        >
          Cancel
        </Button>
        <Button figmaProps={{ size: 'small', style: 'fill' }} onClick={handleConfirmSubmission}>
          Confirm
        </Button>
      </div>
    </div>
  );
}

interface CollaboratorsListProps {
  objectId: string;
}
export default function CollaboratorsList ({ objectId }: CollaboratorsListProps) {
  const {
    objectOwner,
    currentUserRole,
    currentUserId,
    initialUsers: collaborators,
    externalUserAddition,
    setExternalUserAddition,
    handleSubmitSharedUsers,
  } = useCollabDiscoveryContext();

  const [updateCollaborator] = useUpdateCollaboratorsMutation();
  const { boxShadow: { showTop, showBottom }, onScrollHandler } = useScrollWithShadow();

  const [userToRemove, setUserToRemove] = useState(null);
  const [ownerToTransfer, setOwnerToTransfer] = useState(null);

  const handleRoleChange = (shareId, user) => {
    return async (roleValue: DropdownOption) => {
      try {
        const shareUpdate = {
          id: shareId,
          role: roleValue,
          externalType: 'modular_object',
          externalId: objectId,
          userId: user?.id,
          user: {
            id: user?.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            organizationId: user?.organizationId,
            profileImage: user?.profileImage,
          },
        };

        if (roleValue === DropdownOption.Owner) {
          setOwnerToTransfer(shareUpdate);
        } else if (roleValue === DropdownOption.Remove) {
          setUserToRemove(shareUpdate);
        } else {
          await updateCollaborator({
            variables: {
              input: [shareUpdate],
            },
          });
          addToastSuccess('Collaborator role updated');
        }
      } catch (error) {
        console.error(error);
        addToastError('Failed to update collaborator role');
      }
    };
  };

  const { Owner, Editor, Viewer, Remove } = DropdownOption;

  const roleOptionMap = new Map();
  roleOptionMap.set('Owner', [Owner, Editor, Viewer, Remove]);
  roleOptionMap.set('Editor', [Editor, Viewer, Remove]);
  roleOptionMap.set('Viewer', [Viewer, Remove]);

  if (externalUserAddition) {
    return (
      <ConfirmExternalUserSubmission
        setExternalUserAddition={setExternalUserAddition}
        submitExternalUsers={handleSubmitSharedUsers}
      />
    );
  }

  if (userToRemove) {
    return (
      <ConfirmCollaboratorRemoval
        shareUpdate={userToRemove}
        setUserToRemove={setUserToRemove}
        currentUserId={currentUserId}
      />
    );
  }

  if (ownerToTransfer) {
    return (
      <ConfirmOwnerShipTransfer
        shareUpdate={ownerToTransfer}
        setOwnerToTransfer={setOwnerToTransfer}
        // @ts-expect-error - type data needs to be fixed
        objectOwner={objectOwner}
      />
    );
  }

  if (objectOwner?.id === currentUserId) {
    // @ts-expect-error - type data needs to be fixed
    objectOwner.firstName = 'You';
    // @ts-expect-error - type data needs to be fixed
    objectOwner.lastName = '';
  }

  return (
    <div className='relative z-5' data-testid='collaborators-list'>
      <div
        className={cx('absolute h-[50px] -right-4 -left-6 -top-8 bg-white blur', {
          'hidden': !showTop || collaborators.length < 5,
        })}
      />
      <div
        className={cx('absolute h-[50px] -right-4 -left-6 -bottom-8 bg-white blur', {
          'hidden': !showBottom || collaborators.length < 5,
        })}
      />
      <div
        className={cx('flex flex-col gap-2 max-h-[230px]', {
          'overflow-y-scroll': collaborators.length > 4,
        })}
        onScroll={onScrollHandler}
      >
        <div className='flex justify-between items-center'>
          {/* @ts-expect-error - type data needs to be fixed */}
          <Collaborator user={objectOwner} />
          <StaticRole role={DropdownOption.Owner} />
        </div>
        {/* @ts-expect-error - type data needs to be fixed */}
        {collaborators.map(({ id, user, role, __typename, email }) => {
          const isAnonymousUser = __typename === 'AnonymousUser';
          if (user?.id === objectOwner?.id) {
            user.firstName = 'You';
            user.lastName = '';
          }
          return (
            <div key={user?.id} className='flex justify-between items-center'>
              {isAnonymousUser ?
                (
                  <div className='flex items-center'>
                    <div className='flex items-center bg-black rounded-full w-[40px] h-[40px] shrink-0'>
                      <Image
                        src={'/images/default-profile-icon.png'}
                        alt='Profile Image'
                        height={32}
                        width={32}
                        className='rounded-full'
                        style={{ minWidth: '100%', minHeight: '100%', maxWidth: '100%', maxHeight: '100%' }}
                      />
                    </div>
                    <div className='flex flex-col pl-3'>
                      <span className='effra-14'>{email}</span>
                      <span className='effra-12 text-[#666]'>Pending Invite</span>
                    </div>
                  </div>
                ) :
                (
                  <>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error */}
                    {/* @ts-ignore-error */}
                    <Collaborator user={user} />
                    <CollaboratorRoleDropdown
                      autoRotate={collaborators.length > 4}
                      isNotEditor={(user?.id !== currentUserId && currentUserRole === Viewer) || !currentUserRole}
                      value={role as DropdownOption}
                      options={roleOptionMap.get(currentUserRole)}
                      onChange={handleRoleChange(id, user)}
                    />
                  </>
                )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
