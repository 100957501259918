import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { decrementStep, incrementStep, registerStep, setCurrentStep } from './Stepper.actions';
import reducer, { INITIAL_STATE } from './Stepper.reducer';

interface StepperContextType {
  step: number;
  steps: number[];
  register: (step: number) => void;
  increment: () => void;
  decrement: () => void;
  reset: () => void;
}

interface StepperProviderProps {
  children: React.ReactNode;
  initialStep?: number;
}

const StepperContext = createContext<StepperContextType | null>(null);

export function useStepperContext (): StepperContextType {
  const context = useContext(StepperContext);

  if (context === undefined) {
    throw new Error('useStepperContext must be used within a StepperProvider');
  }

  return context;
}

export default function StepperProvider ({ children, initialStep = 1 }: Readonly<StepperProviderProps>): JSX.Element {
  const [state, dispatch] = useReducer(reducer, { ...INITIAL_STATE, step: initialStep });

  const reset = useCallback(() => {
    dispatch(setCurrentStep(1));
  }, []);

  const increment = useCallback(() => {
    dispatch(incrementStep(state));
  }, [dispatch, state]);

  const decrement = useCallback(() => {
    dispatch(decrementStep(state));
  }, [dispatch, state]);

  const register = useCallback((step: number) => {
    dispatch(registerStep(step));
  }, [dispatch]);

  const value = useMemo(
    () => ({ ...state, register, increment, decrement, reset }),
    [state, register, increment, decrement, reset],
  );

  return <StepperContext.Provider value={value}>{children}</StepperContext.Provider>;
}
