import { ParentRangeColor } from '../DatePickerInput';

export default function getParentRangeColor (parentRangeColor: ParentRangeColor): Record<string, string> {
  // TODO: move these into a css file, this is overly verbose only because trying to utilize template literals seems to break the tailwind classes
  const parentRangeClasses = {
    startAndEnd:
      'rounded-l-[4px] !bg-[linear-gradient(90deg,rgba(0,0,0,0,0.2)_0%,rgba(0,0,0,0,0.1))] enabled:hover:!bg-[linear-gradient(90deg,rgba(0,0,0,0,0.4)_0%,rgba(0,0,0,0,0.2))] enabled:hover:!bg-transparent',
    startOnly:
      'rounded-l-[4px] !bg-[linear-gradient(90deg,rgba(0,0,0,0,0.2)_0%,rgba(0,0,0,0,0.0))] enabled:hover:!bg-[linear-gradient(90deg,rgba(0,0,0,0,0.4)_0%,rgba(0,0,0,0,0.0))] enabled:hover:!bg-transparent',
    endAndStart:
      'rounded-r-[4px] !bg-[linear-gradient(270deg,rgba(0,0,0,0,0.2)_0%,rgba(0,0,0,0,0.1))] enabled:hover:!bg-[linear-gradient(270deg,rgba(0,0,0,0,0.4)_0%,rgba(0,0,0,0,0.2))] enabled:hover:!bg-transparent',
    endOnly:
      'rounded-r-[4px] !bg-[linear-gradient(270deg,rgba(0,0,0,0,0.2)_0%,rgba(0,0,0,0,0.0))] enabled:hover:!bg-[linear-gradient(270deg,rgba(0,0,0,0,0.4)_0%,rgba(0,0,0,0,0.0))] enabled:hover:!bg-transparent',
    within: '!bg-[rgba(0,0,0,0,0.1)] enabled:hover:!bg-[rgba(0,0,0,0,0.4)]',
  };

  if (parentRangeColor === ParentRangeColor.Internal) {
    parentRangeClasses.startAndEnd =
      'rounded-l-[4px] !bg-[linear-gradient(90deg,rgba(127,16,208,0.2)_0%,rgba(127,16,208,0.1))] enabled:hover:!bg-[linear-gradient(90deg,rgba(127,16,208,0.4)_0%,rgba(127,16,208,0.2))] enabled:hover:!bg-transparent';
    parentRangeClasses.startOnly =
      'rounded-l-[4px] !bg-[linear-gradient(90deg,rgba(127,16,208,0.2)_0%,rgba(127,16,208,0.0))] enabled:hover:!bg-[linear-gradient(90deg,rgba(127,16,208,0.4)_0%,rgba(127,16,208,0.0))] enabled:hover:!bg-transparent';
    parentRangeClasses.endAndStart =
      'rounded-r-[4px] !bg-[linear-gradient(270deg,rgba(127,16,208,0.2)_0%,rgba(127,16,208,0.1))] enabled:hover:!bg-[linear-gradient(270deg,rgba(127,16,208,0.4)_0%,rgba(127,16,208,0.2))] enabled:hover:!bg-transparent';
    parentRangeClasses.endOnly =
      'rounded-r-[4px] !bg-[linear-gradient(270deg,rgba(127,16,208,0.2)_0%,rgba(127,16,208,0.0))] enabled:hover:!bg-[linear-gradient(270deg,rgba(127,16,208,0.4)_0%,rgba(127,16,208,0.0))] enabled:hover:!bg-transparent';
    parentRangeClasses.within = '!bg-[rgba(127,16,208,0.1)] enabled:hover:!bg-[rgba(127,16,208,0.4)]';
  }

  if (parentRangeColor === ParentRangeColor.External) {
    parentRangeClasses.startAndEnd =
      'rounded-l-[4px] !bg-[linear-gradient(90deg,rgba(26,186,178,0.2)_0%,rgba(26,186,178,0.1))] enabled:hover:!bg-[linear-gradient(90deg,rgba(26,186,178,0.4)_0%,rgba(26,186,178,0.2))] enabled:hover:!bg-transparent';
    parentRangeClasses.startOnly =
      'rounded-l-[4px] !bg-[linear-gradient(90deg,rgba(26,186,178,0.2)_0%,rgba(26,186,178,0.0))] enabled:hover:!bg-[linear-gradient(90deg,rgba(26,186,178,0.4)_0%,rgba(26,186,178,0.0))] enabled:hover:!bg-transparent';
    parentRangeClasses.endAndStart =
      'rounded-r-[4px] !bg-[linear-gradient(270deg,rgba(26,186,178,0.2)_0%,rgba(26,186,178,0.1))] enabled:hover:!bg-[linear-gradient(270deg,rgba(26,186,178,0.4)_0%,rgba(26,186,178,0.2))] enabled:hover:!bg-transparent';
    parentRangeClasses.endOnly =
      'rounded-r-[4px] !bg-[linear-gradient(270deg,rgba(26,186,178,0.2)_0%,rgba(26,186,178,0.0))] enabled:hover:!bg-[linear-gradient(270deg,rgba(26,186,178,0.4)_0%,rgba(26,186,178,0.0))] enabled:hover:!bg-transparent';
    parentRangeClasses.within = '!bg-[rgba(26,186,178,0.1)] enabled:hover:!bg-[rgba(26,186,178,0.4)]';
  }

  return parentRangeClasses;
}
