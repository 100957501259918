import { Button } from '@/designSystemComponents/Button';

interface EnterEmailsProps {
  emails: string;
  handleCancel: () => void;
  handleEmailsInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleLookupEmails: () => void;
}

export default function EnterEmails ({
  emails,
  handleCancel,
  handleEmailsInputChange,
  handleLookupEmails,
}: EnterEmailsProps): JSX.Element {
  return (
    <>
      <div className='sculpin-16'>Enter collaborator emails</div>
      <div className=''>
        <div className='label'>Collaborator Emails</div>
        <input
          className='w-full input-text'
          placeholder='Enter emails'
          onChange={handleEmailsInputChange}
          value={emails}
        />
        <div className='font-effra text-black/60'>Separate multiple emails with commas</div>
      </div>
      <div className='flex gap-4 justify-between pt-3'>
        <Button figmaProps={{ style: 'outline', size: 'large' }} onClick={handleCancel}>Cancel</Button>
        <Button figmaProps={{ style: 'fill', size: 'large' }} onClick={handleLookupEmails}>Continue</Button>
      </div>
    </>
  );
}
