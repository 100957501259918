import Spinner from '@/components/Icons/Spinner';
import { ProfileImage } from '@/components/ProfileImage/ProfileImage';
import { RichText } from '@/components/RichText/RichText';
import { useAppSelector } from '@/state/hooks';
import { selectLoggedInUser } from '@/state/selectors';
import type { Editor, JSONContent } from '@tiptap/react';
import { type PropsWithChildren } from 'react';
import { CommentSubmitButton } from './CommentSubmitButton';

export interface CommentFormProps {
  initialContent?: string;
  handleSubmitComment: (content: JSONContent) => void;
  isSubmitting: boolean;
}

export const CommentForm = (
  { initialContent, handleSubmitComment, isSubmitting }: PropsWithChildren<CommentFormProps>,
) => {
  const loggedInUser = useAppSelector(selectLoggedInUser);

  return (
    <div className='flex'>
      <div className='mr-4'>
        <ProfileImage user={loggedInUser} />
      </div>
      <div className='flex flex-col flex-1 p-5 border border-solid focus-within:shadow-[0px_1px_1px_0px] focus-within:shadow-primary'>
        <RichText
          shouldShowToolbar
          isEditable
          content={initialContent}
          placeholder='Post a comment to all collaborators...'
          className='focus:outline-none min-h-[50px]'
        >
          {(editor: Editor) => {
            return (
              <div className='ml-auto w-20'>
                <CommentSubmitButton
                  editor={editor}
                  handleSubmitComment={handleSubmitComment}
                  isSubmitting={isSubmitting}
                >
                  {isSubmitting ? <Spinner /> : 'Send'}
                </CommentSubmitButton>
              </div>
            );
          }}
        </RichText>
      </div>
    </div>
  );
};
