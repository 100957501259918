import HardwareIcon from '@/components/Icons/HardwareIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faListUl } from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';
import { Filter } from './CreateNewObjectModal';

interface CreateNewObjectModalFiltersProps {
  selectedFilter: string;
  setSelectedFilter: (filter: Filter) => void;
}

export default function CreateNewObjectModalFilters ({
  selectedFilter,
  setSelectedFilter,
}: Readonly<CreateNewObjectModalFiltersProps>) {
  const filterCategory = [Filter.All, Filter.Objects, Filter.Tasks];

  const getIcon = (category) => {
    switch (category) {
      case 'all':
        return <FontAwesomeIcon icon={faListUl} />;
      case 'objects':
        return <HardwareIcon />;
      case 'tasks':
        return <FontAwesomeIcon icon={faCheckCircle} />;
      default:
        return 'all';
    }
  };

  if (!selectedFilter) {
    setSelectedFilter(Filter.All);
  }

  return (
    <div className='flex gap-6 capitalize'>
      {filterCategory.map((cat) => (
        <button
          key={cat}
          className={cx(
            'rounded-[4px] px-[12px] py-[8px] flex items-center gap-[4px] transition-all capitalize hover:bg-neutral-200',
            {
              'bg-neutral-200': cat === selectedFilter,
            },
          )}
          onClick={() => {
            setSelectedFilter(cat);
          }}
        >
          {getIcon(cat)}
          {cat}
        </button>
      ))}
    </div>
  );
}
