import { type Module } from '@/models/template.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/sharp-regular-svg-icons';
import { faXmarkLarge } from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';
import { type ComponentProps, useState } from 'react';
import { getOrderedModuleEntries } from '../common/ModularObject/Page/utils';
import useToggleGroup from '../modules/useToggleGroup';
import HookFormLabel from './HookFormLabel';
import ModuleWrapper from './utils/ModuleWrapper';

interface CollapsibleSectionProps {
  name: string;
  className: string;
  defaultExpanded: boolean;
  labelProps: ComponentProps<typeof CollapsibleSectionLabel>;
  inputProps: ComponentProps<'div'>;
  modules: Record<string, Module>;
  path: string;
  repeaterKey?: string;
  fieldToWatch?: string;
  toggleValue?: string;
  enableSubscription?: boolean;
  onRemove?: (key: string) => void;
}
export default function CollapsibleSection ({
  name,
  className,
  defaultExpanded,
  labelProps,
  inputProps,
  modules,
  path,
  repeaterKey,
  onRemove,
  fieldToWatch,
  toggleValue,
  enableSubscription,
}: CollapsibleSectionProps): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  const shouldShow = useToggleGroup({
    fieldToWatch,
    toggleValue,
    enableSubscription,
  });

  const canRemove = Boolean(onRemove) && typeof onRemove === 'function';

  return shouldShow ?
    (
      <div className={className}>
        <div className='flex justify-between items-center'>
          <button type='button' className='flex items-center gap-[24px] grow' onClick={toggleExpanded}>
            <div className='flex gap-2 items-center shrink-0'>
              <FontAwesomeIcon icon={faChevronDown} className={cx('text-primary', { 'fa-rotate-270': !isExpanded })} />
              <CollapsibleSectionLabel label={name} {...labelProps} />
            </div>
            <div className='w-full h-0.5 bg-neutral-200 grow' />
          </button>
          {canRemove && (
            <button
              type='button'
              className=''
              onClick={() => {
                onRemove(repeaterKey);
              }}
            >
              <FontAwesomeIcon icon={faXmarkLarge} className='text-zinc-500' />
            </button>
          )}
        </div>
        <div
          className={cx('transition-all', {
            'h-0 opacity-20 overflow-hidden': !isExpanded,
            'opacity-100 py-1': isExpanded,
          })}
        >
          <div {...inputProps}>
            {getOrderedModuleEntries(modules)
              .map(([key, module], idx) => (
                <ModuleWrapper
                  key={`${path}.modules.${key}`}
                  module={module}
                  index={idx}
                  path={`${path}.modules.${key}`}
                />
              ))}
          </div>
        </div>
      </div>
    ) :
    null;
}

interface CollapsibleSectionLabelProps extends ComponentProps<typeof HookFormLabel> {
  label: string;
}

export function CollapsibleSectionLabel ({ label, ...props }: CollapsibleSectionLabelProps): JSX.Element {
  return <HookFormLabel className='py-4 font-medium whitespace-nowrap effra-xs !mb-0' label={label} {...props} />;
}
