import { Listbox } from '@headlessui/react';
import cx from 'classnames';
import { forwardRef } from 'react';
import SelectIcon from './SelectIcon';

interface SelectBoxProps extends React.ComponentProps<typeof Listbox.Button> {
  name?: string;
  placeholder?: string;
  selectedItem: string | null;
  ref: React.Ref<HTMLButtonElement>;
  className?: string;
}

const SelectBoxButton = forwardRef(function SelectBoxButton (props: SelectBoxProps, ref: React.Ref<HTMLButtonElement>) {
  const { selectedItem, className, ...rest } = props;
  const placeholder = props?.placeholder ?
    props?.placeholder
    : props?.name
    ? `Select ${props.name}`
    : 'Select an item';
  return (
    <Listbox.Button
      className={cx(
        'listbox-button',
        {
          'border-transparent': selectedItem,
        },
      )}
      ref={ref}
      {...rest}
    >
      <span className={cx('block uppercase truncate effra-xs', className)}>{selectedItem || placeholder}</span>
      <SelectIcon />
    </Listbox.Button>
  );
});

export default SelectBoxButton;
