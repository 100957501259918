import { type User } from '@/__generated__/types';
import { Dropdown } from '@/components/form';
import UserComponent from '@/components/User/User';
import { Button } from '@/designSystemComponents/Button';

interface ConfirmCollaboratorsProps {
  users: User[];
  handleAddCollaborators: () => void;
  handleCancel: () => void;
  handleChangeShareRole: (userId: string, role: string) => void;
}

export default function ConfirmCollaborators ({
  users,
  handleAddCollaborators,
  handleCancel,
  handleChangeShareRole,
}: ConfirmCollaboratorsProps): JSX.Element {
  return (
    <>
      <div className='sculpin-16'>Confirm Collaborators</div>
      <div className='font-effra'>The following users will be added as collaborators to the selected milestones.</div>
      <div className='flex text-black/60 effra-xs'>
        <div className='w-[50%]'>User</div>
        <div className='w-[50%]'>Permission</div>
      </div>
      {users?.map((user) => (
        <div key={user?.id} className='flex gap-3 items-center'>
          <div className='w-[50%]'>
            <UserComponent user={user} noRole showEmail />
          </div>
          <div className='w-[50%]'>
            <Dropdown
              id={'share-role'}
              options={['Viewer', 'Editor']}
              value='Viewer'
              onChange={(role) => {
                handleChangeShareRole(user?.id, role?.target?.value);
              }}
            />
          </div>
        </div>
      ))}
      <div className='flex gap-4 justify-between pt-3'>
        <Button figmaProps={{ style: 'outline', size: 'large' }} onClick={handleCancel}>Cancel</Button>
        <Button figmaProps={{ style: 'fill', size: 'large' }} onClick={handleAddCollaborators}>Continue</Button>
      </div>
    </>
  );
}
