import { identity, mergeRight } from 'ramda';
import { DECREMENT_STEP, INCREMENT_STEP, REGISTER_STEP, SET_CURRENT_STEP } from './Stepper.actions';

export interface StepperState {
  step: number;
  steps: number[];
}

export const INITIAL_STATE: StepperState = { step: 1, steps: [] };

export const HANDLERS = {
  [SET_CURRENT_STEP]: mergeRight,
  [INCREMENT_STEP]: (state, payload) => {
    if (state.step === state.steps.length) return state;
    return mergeRight(state, payload);
  },
  [DECREMENT_STEP]: (state, payload) => {
    if (!state.step) return state;
    return mergeRight(state, payload);
  },
  [REGISTER_STEP]: (state, payload) => {
    if (state.steps.includes(payload.step)) return state;
    return mergeRight(state, { steps: [...state.steps, payload.step] });
  },
};

function reducer (state: StepperState, { type, payload }): StepperState {
  return (HANDLERS[type] ?? identity)(state, payload);
}

export default reducer;
