import { createContext, useContext, useMemo } from 'react';

interface SidenavContextType {
  isExpanded: boolean;
}

interface SidenavProviderProps {
  children: React.ReactNode;
  isExpanded?: boolean;
}

const SidenavContext = createContext<SidenavContextType>(null);

export const useSidenavContext = (): SidenavContextType => {
  const context = useContext(SidenavContext);

  if (!context) {
    throw new Error('SidenavContext must be used within a SidenavProvider');
  }

  return context;
};

export default function SidenavProvider (
  { children, isExpanded = false }: Readonly<SidenavProviderProps>,
): JSX.Element {
  const value = useMemo(() => ({ isExpanded }), [isExpanded]);
  return (
    <SidenavContext.Provider value={value}>
      {children}
    </SidenavContext.Provider>
  );
}
