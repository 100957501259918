import { useUpdateAttributeValue } from '@/hooks/useUpdateAttributeValue';
import { type ChangeEventHandler, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import TextInput, { type TextInputProps } from '../inputs/TextInput/TextInput';

interface HookFormInputProps extends TextInputProps {
  name?: string;
  registration?: Record<string, unknown>;
  isNotAttribute?: boolean;
}

export default function HookFormInput ({
  name,
  registration,
  isNotAttribute,
  ...rest
}: HookFormInputProps): JSX.Element {
  const { register, getValues, getFieldState, setValue } = useFormContext();
  const value = getValues(name);
  const { isDirty } = getFieldState(name);
  const { debouncedUpdateValue, updateValue } = useUpdateAttributeValue();

  const handleChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const newValue = e.target.value;
    setValue(name, newValue, { shouldDirty: true });
    if (isNotAttribute) return;
    void debouncedUpdateValue({ newFieldValue: newValue, attributeName: name });
  };

  const handleBlur = useCallback(async () => {
    if (!isDirty || isNotAttribute) return;
    debouncedUpdateValue.cancel();
    await updateValue({ attributeName: name, newFieldValue: value });
  }, [debouncedUpdateValue, isDirty, name, updateValue, value]);

  return (
    <TextInput
      {...register(name, { required: false, ...(registration ?? {}) })}
      onChange={handleChange}
      onBlur={handleBlur}
      {...rest}
    />
  );
}
